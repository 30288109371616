import Model, {Data} from '@/modules/sdk/core/model';
import SurveyModel from '@/modules/sdk/models/survey.model';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';
import SurveyItemModel from '@/modules/sdk/models/survey-item.model';
import Hash from '@/modules/sdk/core/hash';

export default class SurveyGroupModel extends SurveyItemModel {

  constructor(
    data: Data | Model = {},
    setOriginal = false,
  ) {
    // @ts-ignore
    if (!data.surveyquestionlist) {
      // @ts-ignore
      data.surveyquestionlist = [new SurveyQuestionModel().data]
    }
    super(data, setOriginal);
  }

  default() {
    return {
      hash: this.data.hash ? this.data.hash : Hash.guid(),
      surveyquestionlist: [],
      includeLabel: true,
      includePrependText: false,
      includeAppendText: false,
      prependText: '',
      appendText: '',
      conditions: [],
      action: null,
      label: null,
      description: null,
    };
  }

  relatedMap () {
    return {
      surveyentity: SurveyModel,
      surveyquestionlist: SurveyQuestionModel,
    }
  }

  relatedDeleteMissing() {
    return {
      surveyquestionlist: true,
    };
  }

  assign(data: Model | object, specificKeys = []) {
    super.assign(data, specificKeys);
    return this.sortByProperty('surveyquestionlist', 'position');
  }
}
