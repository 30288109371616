<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'notification'"
      v-model="model"
      :service="service"
      :loading="loading"
      name="notification"
      title="Notification"
    >
      <template #form="{ formErrors, rules }">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="model.data.label"
                v-safechar
                :error-messages="formErrors.label"
                :rules="[rules.required]"
                label="Notification"
                hide-details="auto"
                clearable
                required
                outlined
                @input="formErrors = {}"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="model.data.description"
                v-safechar
                :error-messages="formErrors.description"
                :rules="[]"
                label="Description"
                hide-details="auto"
                clearable
                required
                outlined
                @input="formErrors = {}"
              />
            </v-col>

            <v-col cols="12">
              <Wysiwyg
                v-model="model.data.content"
                label="Extended Content"
              ></Wysiwyg>
            </v-col>

            <v-col cols="12" md="4">
              <v-checkbox
                v-model="model.data.important"
                class="pa-0 ma-0"
                label="Important"
                hide-details="auto"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" md="4">
              <v-checkbox
                v-model="model.data.everyone"
                class="pa-0 ma-0"
                label="Everyone"
                hide-details="auto"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" v-if="!model.data.everyone" class="mt-4">
              <user-autocomplete-component
                v-model="model.data.userlist"
                label="Target Users"
                outlined
                cache-items
                deletable-chips
                multiple
                chips
                clearable
                return-object
              />
            </v-col>

            <v-col cols="12">
              <user-autocomplete-component
                v-model="model.data.useracklist"
                label="Acknowledge Users"
                outlined
                hide-details="auto"
                cache-items
                deletable-chips
                multiple
                chips
                clearable
                return-object
              />
            </v-col>
          </v-row>
      </template>
    </DataForm>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Watch} from 'vue-property-decorator';
import Logger from '@/modules/sdk/core/logger';
import Wysiwyg from '@/modules/common/components/Wysiwyg.vue';
import DataForm from '@/modules/common/components/DataForm.vue';
import {debounce} from 'debounce';
import UserService from '@/modules/sdk/services/user.service';
import UserModel from '@/modules/sdk/models/user.model';
import UserAutocompleteComponent from '@/modules/common/components/UserAutocompleteComponent.vue';
import NotificationService from '@/modules/sdk/services/notification.service';
import NotificationModel from '@/modules/sdk/models/notification.model';

const d = new Logger('views/Admin/Form/NotificationForm');

@Component({
  components: {
    UserAutocompleteComponent,
    Wysiwyg,
    DataForm,
  }
})
export default class NotificationForm extends Vue {

  service = NotificationService.getInstance();
  loading = false;
  model: NotificationModel = new NotificationModel();

  userList: Array<UserModel> = [];
  userListSearch = '';
  userListLoading = false;

  load(id: number) {
    this.loading = true;
    this.service.get({id})
      .then(response => this.model = response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  loadUser(search: string = this.userListSearch) {
    this.loadUserDebounce.clear();
    const order = 'firstName, lastName, email';
    const advanced = {'deleted': 0};
    this.userListLoading = true;
    UserService.getInstance().getAll({search, order, advanced})
      .then(response => {
        this.userList = response.data.view.list;
      }).finally(() => this.userListLoading = false);
  }

  loadUserDebounce = debounce(() => {
    this.loadUser();
  }, 333);

  @Watch('userListSearch')
  onProjectActiveUserSearch(search: string) {
    this.loadUserDebounce()
  }

  created() {
    if (this.$route.params.id) {
      this.load(parseInt(this.$route.params.id));
    }
  }
}
</script>
