import Model, { Data } from '@/modules/sdk/core/model';
import SurveyModel from '@/modules/sdk/models/survey.model';
import SurveyChoiceModel from '@/modules/sdk/models/survey-choice.model';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';
import SurveyGroupModel from '@/modules/sdk/models/survey-group.model';
import SurveySectionModel from '@/modules/sdk/models/survey-section.model';
import UserModel from '@/modules/sdk/models/user.model';
import SurveyAnswerItemModel from '@/modules/sdk/models/survey-answer-item.model';

export default class SurveyAnswerModel extends SurveyAnswerItemModel {

  default() {
    return {
      projectId: null,
      surveyId: null,
      surveySectionId: null,
      surveyGroupId: null,
      surveyQuestionId: null,
      recordId: null,
      content: null,
      type: null,
      unknown: null,
      other: null,
      official: false,
      submitted: false,
    };
  }

  columnCast() {
    return {
      official: 'bool',
      submitted: 'bool',
    };
  }

  relatedMap () {
    return {
      surveyentity: SurveyModel,
      surveysectionentity: SurveySectionModel,
      surveygroupentity: SurveyGroupModel,
      surveyquestionentity: SurveyQuestionModel,
      surveychoicelist: SurveyChoiceModel,
      userentity: UserModel,
    }
  }

  constructor(
    data: Data | Model = {},
    setOriginal = true,
  ) {
    super(data, setOriginal);
    if (this.data.content !== null) {
      switch (this.data.type) {
        case 'datetime':
          this.data.content = new Date(this.data.content);
          break;
        case 'datetime_range':
          this.data.content = this.data.content.map((item: any) => new Date(item));
          break;
        case 'rating':
        case 'year':
          this.data.content = parseInt(this.data.content);
          break;
      }
    }
  }
}
