<template>
  <div>
    <ModalDialog
      v-model="syncedVisible"
      :title="$t('ReviewView.export.title') + ' - ' + project.getLabel()"
      :persistent="loading"
      icon="mdi-file-export-outline"
      max-width="1000"
      scrollable
    >
      <template #content>
        <v-card-text class="background px-0 pb-0">
          <v-container>
            <v-row dense>
              <v-col cols="12" md="6">

                <!-- PROJECT DATA -->
                <v-card>
                  <v-card-title>
                    <span>Project Data</span>
                  </v-card-title>
                  <v-card-text>
                    <v-alert
                      v-if="projectDataList.length === 0"
                      class="mb-0"
                      type="info"
                      color="info"
                      colored-border
                      text
                    >
                      <span>No project data available</span>
                    </v-alert>
                    <template v-else>
                      <v-treeview
                        v-model="projectData"
                        :items="projectDataList"
                        selected-color="primary"
                        selection-type="leaf"
                        selectable
                        open-all
                        return-object
                      />
                    </template>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">

                <!-- USER REVIEW STATUS -->
                <v-card>
                  <v-card-title>
                    <span>User Review Status</span>
                  </v-card-title>
                  <v-card-text>
                    <user-autocomplete-component
                      v-model="userList"
                      :project-id="project.data.id"
                      include-ai-user
                      include-user-mode
                      label="Users"
                      multiple
                      outlined
                      dense
                      return-object
                    />
                  </v-card-text>
                </v-card>

                <!-- SURVEY QUESTIONS -->
                <v-card class="mt-3">
                  <v-card-title>
                    <span>Questions</span>
                  </v-card-title>
                  <v-card-text>
                    <v-alert
                      v-if="surveyQuestionList.length === 0"
                      class="mb-0"
                      type="info"
                      color="info"
                      colored-border
                      text
                    >
                      <span v-text="$t('ReviewView.noQuestionAvailable')"></span>
                    </v-alert>
                    <template v-else>

                      <!-- ANSWERS FROM USERS -->
                      <v-autocomplete
                        v-model="selectedQuestionUserList"
                        :items="questionUserList"
                        :loading="questionUserListLoading"
                        :disabled="questionUserListLoading"
                        label="Answer(s) from"
                        prepend-inner-icon="mdi-account"
                        class="mb-3"
                        multiple
                        clearable
                        outlined
                        hide-details
                        dense
                        return-object
                      />

                      <!-- SURVEY TREE -->
                      <v-treeview
                        v-model="surveyQuestion"
                        :items="surveyQuestionList"
                        selected-color="primary"
                        selection-type="leaf"
                        selectable
                        open-all
                        return-object
                      />
                    </template>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </template>

      <template #buttons>
        <v-select
          v-model="exportType"
          :items="typeList"
          :label="$t('exportComponent.type')"
          style="max-width: 15rem"
          hide-details
          outlined
          dense
        />
        <v-spacer />
        <v-btn
          :loading="loading"
          :disabled="!canExport"
          color="primary"
          large
          @click="exportCsv"
        >
          <span v-text="$t('btn.export')"></span>
        </v-btn>
        <v-btn
          outlined
          large
          @click="close"
        >
          <span v-text="$t('btn.cancel')"></span>
        </v-btn>
      </template>
    </ModalDialog>
  </div>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, VModel, Prop, PropSync, Watch } from 'vue-property-decorator';
import { fieldList, extraFieldList, officialStatusFieldList } from '@/enums/global';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import ProjectModel from '@/models/project.model';
import ArticleModel from '@/models/article.model';
import ArticleService from '@/services/article.service';
import UserAutocompleteComponent from '@/views/Admin/Component/UserAutocompleteComponent.vue';
import UserModel from '@/modules/sdk/models/user.model';
import SurveyModel from '@/modules/sdk/models/survey.model';
import Identity from '@/modules/sdk/core/identity';

@Component({
  components: {
    UserAutocompleteComponent,
    ModalDialog
  }
})
export default class ExportComponent extends Vue {
  @VModel({ type: ProjectModel, default: () => new ProjectModel() }) project!: ProjectModel
  @PropSync('visible', { type: Boolean, default: false }) syncedVisible!: boolean;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: String, default: null }) userType!: string;

  articleLoading = false;
  questionUserListLoading = false;
  articleList: Array<ArticleModel> = [];
  projectData: any[] = []
  surveyQuestion: any[] = []
  exportType = 'xlsx'
  userList: UserModel[] = []
  selectedQuestionUserList: any[] = []
  questionUserList: UserModel[] = []
  typeList = [
    { text: 'XLSX', value: 'xlsx' },
    { text: 'CSV', value: 'csv' },
    { text: 'JSON', value: 'json' },
  ]

  @Watch('syncedVisible', {
    immediate: true,
  })
  onVisibleChanged(visible: boolean) {
    if (visible) {
      this.clear();
    }
    if (visible && !this.articleLoading) {
      this.loadArticles();
    }
    if (visible && !this.questionUserListLoading) {
      this.loadQuestionUserList();
    }
  }

  get projectDataList(): any[] {
    const items: any[] = [];
    if (fieldList.length > 0) {
      items.push({
        id: 'field_all',
        name: 'Fields',
        children: fieldList.map(item => ({
          id: 'field_' + item.value,
          type: 'field',
          key: item.value,
          name: item.text,
        })),
      })
    }
    if (officialStatusFieldList.length > 0) {
      items.push({
        id: 'official_status_all',
        name: 'Official Status',
        children: officialStatusFieldList.map(item => ({
          id: 'official_status_' + item.value,
          type: 'official_status',
          key: item.value,
          name: item.text,
        })).concat({
          id: 'official_status_exclusion_reason',
          type: 'official_status',
          key: 'exclusion_reason',
          name: 'Reason(s) of Exclusion',
        }),
      })
    }
    if (extraFieldList.length > 0) {
      items.push({
        id: 'extra_field_all',
        name: 'Extra Fields',
        children: extraFieldList.map(item => ({
          id: 'extra_field_' + item.value,
          type: 'extra_field',
          key: item.value,
          name: item.text,
        })),
      })
    }
    if (this.articleList.length > 0) {
      items.push({
        id: 'article_all',
        name: 'Articles',
        children: this.articleList.map(item => ({
          id: 'article_' + item.data.id,
          type: 'article',
          key: item.data.id,
          name: item.data.title,
        })),
      })
    }
    return items;
  }

  get surveyQuestionList(): any[] {
    return this.project.data.surveylist.map((survey: SurveyModel) => ({
      id: 'survey_' + survey.data.id,
      name: survey.data.label || 'Untitled (Survey)',
      children: survey.toTreeviewItems(false)
    }));
  }

  get canExport(): boolean {
    return !this.loading
      && (
        this.projectData.length > 0
        || this.surveyQuestion.length > 0
        || this.userList.length > 0
        || this.selectedQuestionUserList.length > 0
      )
  }

  get canSeeAnswerName(): boolean {
    return Identity.hasRole(['dev']);
  }

  get canSeeOfficialAnswers(): boolean {
    return ['leader', 'arbitrator'].includes(this.userType || '')
      && (
        this.project.data.type !== 'systematic_review'
        || this.project.data.stage === 'final'
      )
  }

  loadQuestionUserList() {
    if (this.project.data.surveylist.length > 0) {
      this.questionUserListLoading = true;
      const survey = this.project.data.surveylist[0];
      survey.loadAnswerUserList()
        .then(() => {
          this.questionUserList = survey.getFilteredAnswerUserList(
            this.canSeeAnswerName,
            this.canSeeOfficialAnswers,
            this.project.data.aiUseAllowed && this.project.hasRole(['ai-manager']),
          );
        })
        .finally(() => this.questionUserListLoading = false);
    }
  }

  loadArticles(): void {
    this.articleLoading = true;
    const filters = [{
      field: 'projectId',
      value: this.project.data.id,
      operator: 'equals'
    }];
    ArticleService.getInstance().getAll({filters})
      .then((response) => this.articleList = response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.articleLoading = false);
  }

  exportCsv() {
    this.$emit('export', {
      type: this.exportType,
      data: {
        articles: this.projectData.filter(item => item.type === 'article').map(item => item.key),
        fields: this.projectData.filter(item => ['field', 'official_status', 'extra_field'].includes(item.type)).map(item => item.key),
        questions: this.surveyQuestion.filter(item => item.type === 'question').map(item => item.key),
        questionUsers: this.selectedQuestionUserList.map(item => item.value),
        users: this.userList,
      }
    })
  }

  clear() {
    this.exportType = 'xlsx';
    this.articleList = []
    this.projectData = []
    this.surveyQuestion = []
  }

  open() {
    this.syncedVisible = true;
  }

  close() {
    this.syncedVisible = false;
  }
}
</script>
