import Service, { ZemitData } from '../core/service';
import { AxiosResponse } from 'axios';

export type AuthEndpointListType = {
  get: string,
  login: string,
  loginAs: string,
  logout: string,
  logoutAs: string,
  register: string,
  refresh: string,
  reset: string,
};

export interface ZemitMessage {
  code: number,
  field: string | Array<string>,
  message: string,
  metaData: Array<any>
  type: string
}

export interface GetView {
  jwt: string,
  refreshToken: string,
  loggedIn: boolean,
  loggedInAs: boolean,
  refreshed: boolean,
  validated: boolean,
  saved: boolean,
  hasSession: boolean,
  messages: Array<ZemitMessage>,
  user: any, // @todo
  userAs: any, // @todo,
  permissionList: Array<any>, // @todo
  groupList: Array<any>, // @todo
  roleList: Array<any>|any, // @todo
  typeList: Array<any>, // @todo
}

export interface RegisterView extends GetView {
  loggedIn: boolean;
  loggedInAs: boolean;
  messages: Array<ZemitMessage>;
  saved: boolean;
}

export interface LoginView extends GetView {
  loggedIn: boolean;
  loggedInAs: boolean;
  messages: Array<ZemitMessage>;
  saved: boolean;
}

export interface LogoutView {
  loggedIn: boolean;
  loggedInAs: boolean;
  messages: Array<ZemitMessage>;
  saved: boolean;
}

export interface ResetView {
  sent: boolean;
  reset: boolean;
  messages: Array<ZemitMessage>;
}

export type RefreshView = GetView;

export default class AuthService extends Service {

  modelUrl = '/auth';
  baseUrl = '/api';

  endpointList: AuthEndpointListType = {
    get: '/get',
    logout: '/logout',
    logoutAs: '/logout-as',
    login: '/login',
    loginAs: '/login-as',
    register: '/register',
    refresh: '/refresh',
    reset: '/reset',
  };

  refreshOnUnauthorized = false;

  getGetUrl = () => this.getUrl('get');
  getLoginUrl = () => this.getUrl('login');
  getLoginAsUrl = () => this.getUrl('login-as');
  getLogoutUrl = () => this.getUrl('logout');
  getLogoutAsUrl = () => this.getUrl('logout-as');
  getRegisterUrl = () => this.getUrl('register');
  getRefreshUrl = () => this.getUrl('refresh');
  getResetUrl = () => this.getUrl('reset');

  get = (data = {}): Promise<AxiosResponse<ZemitData<GetView>>> => this.handleRequest(this.getGetUrl(), data);
  login = (data = {}): Promise<AxiosResponse<ZemitData<LoginView>>> => this.handleRequest(this.getLoginUrl(), data);
  loginAs = (data = {}): Promise<AxiosResponse<ZemitData<LoginView>>> => this.handleRequest(this.getLoginAsUrl(), data);
  logout = (data = {}): Promise<AxiosResponse<ZemitData<LogoutView>>> => this.handleRequest(this.getLogoutUrl(), data);
  logoutAs = (data = {}): Promise<AxiosResponse<ZemitData<LogoutView>>> => this.handleRequest(this.getLogoutAsUrl(), data);
  register = (data = {}): Promise<AxiosResponse<ZemitData<RegisterView>>> => this.handleRequest(this.getRegisterUrl(), data);
  refresh = (data = {}): Promise<AxiosResponse<ZemitData<RefreshView>>> => this.handleRequest(this.getRefreshUrl(), data);
  reset = (data = {}): Promise<AxiosResponse<ZemitData<ResetView>>> => this.handleRequest(this.getResetUrl(), data);
}
