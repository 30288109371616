<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'data-extraction-presets'"
      v-model="model"
      :service="service"
      :loading="loading"
      name="data-extraction-presets"
      title="Data Extraction Presets"
    >
      <template #form="{ formErrors, rules }">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model.lazy="model.data.label"
              v-safechar
              :error-messages="formErrors.label"
              :rules="[rules.required]"
              label="Label"
              outlined
              hide-details="auto"
              clearable
              required
              @input="formErrors = {}"
            />

            <SurveyManager
              v-model="model"
              :tab-attrs="{ class: 'mt-4' }"
              class="mt-4"
              editable
              preview
            />
          </v-col>
        </v-row>
      </template>
    </DataForm>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Watch} from 'vue-property-decorator';
import DataForm from '@/modules/common/components/DataForm.vue';
import ProjectService from '@/services/project.service';
import SurveyModel from '@/modules/sdk/models/survey.model';
import SurveyService from '@/modules/sdk/services/survey.service';
import SurveyManager from '@/components/SurveyManager.vue';

@Component({
  components: {
    SurveyManager,
    DataForm,
  }
})
export default class DataExtractionPresetsForm extends Vue {

  service = SurveyService.getInstance();
  loading = false;
  model: SurveyModel = new SurveyModel({
    template: 1
  });

  loadingProjectList = false
  projectList = [];
  projectListSearch = '';

  @Watch('projectListSearch')
  onProjectListSearch(search: string) {
    this.loadingProjectList = true;
    ProjectService.getInstance().getAll({search})
      .then(response => this.projectList = response.data.view.list)
      .finally(() => this.loadingProjectList = false);
  }

  load(id: number) {
    this.loading = true;
    this.service.get({id})
      .then(response => this.model.assign(response.data.view.single))
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  created() {
    const id = this.$route.params.id;
    if (id && id !== 'new') {
      this.load(parseInt(id));
    }
  }
}
</script>
