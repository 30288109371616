<template>
  <v-card v-bind="$attrs" class="d-flex flex-column">

    <!-- MENU: POINT CLICK BEHAVIOUR -->
    <v-menu
      v-model="pointClickMenu.visible"
      :position-x="pointClickMenu.x"
      :position-y="pointClickMenu.y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item @click="onPointMenuClick('show', pointClickMenu.props)">
          <v-list-item-icon>
            <v-icon>mdi-magnify</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Show data sources</v-list-item-title>
        </v-list-item>
        <v-list-item @click="onPointMenuClick('filter', pointClickMenu.props)">
          <v-list-item-icon>
            <v-icon>mdi-filter-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Filter data sources</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- HEADER -->
    <v-card-title v-if="userOwnDashboard">
      <div class="d-flex align-center justify-space-between flex-wrap w-100" style="gap: 1rem">
        <div class="d-flex align-center" style="flex: 1; gap: 1rem">
          <v-text-field
            v-model="model.data.title"
            v-safechar
            :disabled="saving || loading || !userOwnDashboard"
            maxlength="220"
            autofocus
            label="Name"
            outlined
            dense
            clearable
            hide-details="auto"
          />
        </div>

        <div v-if="!readonly" class="d-flex align-center justify-end" style="flex: 1; gap: 1rem">
          <slot name="actions" :loading="loading"></slot>
          <v-divider v-if="$scopedSlots.actions" vertical />
          <v-btn
            :disabled="saving || loading"
            text
            @click="onAddChartClick"
          >
            <v-icon left>mdi-widgets</v-icon>
            <span>Add chart</span>
          </v-btn>

          <v-menu
            v-if="false"
            :disabled="loading"
            bottom
            left
          >
            <template #activator="{ on, attrs }">
              <v-btn
                :disabled="saving"
                icon
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon small>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item :disabled="model.data.graphs.length === 0" @click="onRefreshClick">
                <v-list-item-icon>
                  <v-icon>mdi-refresh</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Refresh</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-card-title>

    <!-- CONTENT -->
    <v-card class="backgroundLight d-flex flex-column" style="flex: 1" flat>

      <!-- FILTERED BY -->
      <v-expand-transition>
        <div v-if="chartState.length > 0" class="ma-3 mb-0">
          <v-card dark color="primary" outlined>
            <v-card-text>
              <div class="d-flex align-center justify-space-between">
                <div class="d-flex align-center">
                  <v-icon left>mdi-filter-variant</v-icon>
                  <span class="title">Filtered by</span>
                  <div class="mx-6 d-flex align-center flex-wrap" style="gap: 1.5rem">
                    <template v-for="chart in chartState">
                      <div
                        v-for="(filter, filterIdx) in chart.filters"
                        :key="chart.i + '_filter_' + filterIdx"
                        class="d-flex align-center flex-wrap"
                        style="gap: 1rem"
                      >
                        <h4>{{ filter.field }}:</h4>
                        <div
                          class="d-flex align-center flex-wrap"
                          style="gap: 0.5rem"
                        >
                          <v-chip
                            v-for="(value, valueIdx) in filter.value"
                            :key="chart.i + '_value_' + valueIdx"
                            close
                            color="white"
                            text-color="primary"
                            @click:close="() => onRemoveChartFilter(chart, filterIdx, valueIdx)"
                          >{{ value }}</v-chip>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <v-btn
                  outlined
                  small
                  @click="onClearAllChartFilters"
                >
                  Clear all filters
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-expand-transition>

      <!-- CHART SETTINGS -->
      <DashboardChartSettingsDialog
        v-if="userOwnDashboard"
        v-model="newWidgetDialog.model"
        :visible.sync="newWidgetDialog.visible"
        :fields="getFields(newWidgetDialog.model)"
        :definitions="definitions"
        :data="data || innerData"
        :is-model="isModel"
        :query-builder="queryBuilder"
        :load-override="loadOverride"
        scrollable
        @on-apply="onApplyWidget"
      />

      <div v-if="model.data.graphs.length === 0" class="pa-4 d-flex flex-column" style="flex: 1">
        <div class="w-100 pa-12 text-center h-100 d-flex align-center justify-center text-center" style="border: rgba(0, 0, 0, 0.2) dashed 3px; border-radius: 0.5rem; flex: 1">
          No chart yet
        </div>
      </div>

      <grid-layout
        v-else
        ref="gridLayout"
        :layout="model.data.graphs"
        :col-num="12"
        :row-height="30"
        :is-draggable="!readonly"
        :is-resizable="!readonly"
        :vertical-compact="true"
      >
        <grid-item
          v-for="(widget, widgetIdx) in model.data.graphs"
          :key="widget.i"
          :x="widget.x"
          :y="widget.y"
          :w="widget.w"
          :h="widget.h"
          :i="widgetIdx"
          :min-h="5"
          :min-w="3"
          drag-allow-from=".handle"
          @resize="onResize"
          @move="onMove"
          @resized="onResized"
          @moved="onMoved"
        >
          <DashboardChart
            v-if="ready"
            v-model="model.data.graphs[widget.i]"
            :readonly="readonly || !userOwnDashboard"
            :ref="'widget_' + widgetIdx"
            :data="data"
            :definitions="definitions"
            :fields="getFields(model.data.graphs[widget.i])"
            :filters="getFilters(widget)"
            :selected-points="getPoints(widget)"
            :is-model="isModel"
            :load-override="loadOverride"
            :query-builder="queryBuilder"
            :skip-watch="skipWatch"
            :explorable="explorable"
            @on-remove="() => onRemoveWidget(widget.i)"
            @reload="onRefreshClick"
            @on-point-click="props => onPointClick(props, widget)"
            @clear-filters="() => onClearFilters(widget)"
            @click:row="onRowClick"
          />
        </grid-item>
      </grid-layout>
    </v-card>

    <v-card-actions v-if="!hideActions && userOwnDashboard">
      <slot name="bottom_left"></slot>
      <v-spacer />
      <template v-if="userOwnDashboard">
        <v-btn
          :disabled="!canReset"
          text
          @click="onResetVisualizationClick"
        >
          Reset
        </v-btn>

        <v-btn
          :disabled="!canSave"
          :loading="saving"
          color="primary"
          @click="onSaveClick"
        >
          Save
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, VModel, Prop, Watch} from 'vue-property-decorator';
import VueGridLayout from 'vue-grid-layout';
import VisualizationModel from '@/modules/sdk/models/visualization.model';
import DashboardChart from '@/modules/common/components/DashboardChart.vue';
import DashboardChartSettingsDialog from '@/modules/common/components/DashboardChartSettingsDialog.vue';
import DashboardChartModel, {IDashboardChart} from '@/modules/sdk/models/dashboard-chart.model';
import VisualizationService from '@/modules/sdk/services/visualization.service';
import Query, { IQueryItem } from '@/modules/sdk/core/query';
import { IDefinition } from '@/interfaces';

@Component({
  components: {
    DashboardChart,
    DashboardChartSettingsDialog,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  }
})
export default class DashboardBuilder extends Vue {

  @VModel({ type: VisualizationModel, default: () => new VisualizationModel() }) model!: VisualizationModel;
  @Prop({ type: Boolean, default: false, }) explorable?: boolean;
  @Prop({ type: Boolean, default: false, }) readonly?: boolean;
  @Prop({ type: Boolean, default: false }) hideActions!: boolean
  @Prop({ type: Boolean, default: false }) disableChartActions!: boolean
  @Prop({ type: Array, default: () => ([]), }) items!: Array<VisualizationModel>;
  @Prop({ type: Array, default: null, }) data!: Array<any> | null;
  @Prop({ type: Array, default: null, }) definitions!: Array<any> | null;
  @Prop({ type: Array, default: null, }) fields!: Array<any> | null;
  @Prop({ type: Array, default: () => ([]), }) filters!: Array<any>;
  @Prop({ type: Number, default: null }) userId!: number
  @Prop({ type: Boolean, default: true }) isModel!: boolean;
  @Prop({ type: Function, default: () => () => {} }) queryBuilder!: any;
  @Prop({ type: Function, default: () => (model: VisualizationModel) => new Promise((resolve) => resolve(model)) }) beforeSave!: (model: VisualizationModel) => Promise<VisualizationModel>
  @Prop({ type: Function, default: () => (widget: IDashboardChart) => new Promise((resolve) => resolve(widget)) }) loadOverride!: (widget: IDashboardChart) => Promise<any>

  ready = false
  deleting = false
  loading = false
  saving = false
  skipWatch = false
  gridRef: any = null
  innerData: Array<any> = []
  newWidgetDialog: {
    visible: boolean,
    model: IDashboardChart,
  } = {
    visible: false,
    model: new DashboardChartModel().data as any,
  }

  pointClickMenu: {
    visible: boolean,
    x: number,
    y: number,
    props: any,
  } = {
    visible: false,
    x: 0,
    y: 0,
    props: {},
  }

  chartState: Array<{
    i: number,
    points: Array<any>,
    filters: Array<IQueryItem>,
    model: any,
  }> = []

  defaultQueryItem = {
    field: null,
    operator: 'equals',
    value: null,
    logic: 'and',
    group: [],
    filter: {
      label: null,
      items: [],
    },
  }

  @Watch('model.data.id', { deep: true })
  onModelIdChanged() {
    this.ready = false;
    setTimeout(() => {
      this.ready = true;
    });
  }

  get userOwnDashboard(): boolean {
    return this.userId === null || this.userId === this.model.data.userId;
  }

  get canSave(): boolean {
    return !this.saving && this.model.isDifferentFromOriginal();
  }

  get canReset(): boolean {
    return this.model.isDifferentFromOriginal() && !this.loading;
  }

  getFields(widget: any): Array<any> | null {
    if (widget.queryOverride) {
      return this.innerData;
    }
    return this.fields;
  }

  getFilters(widget: any): Array<IQueryItem> {

    if (widget.queryOverride) {
      return widget.filters;
    }

    const filters: Array<IQueryItem> = [];
    for (let i = 0; i < this.chartState.length; i++) {
      const state = this.chartState[i];
      if (state.i !== widget.i) {
        filters.push(...state.filters);
      } else if (state.i === widget.i) {
        /*
        * It's fine to return filters at this point since chartState contains the applied
        * filters in order. So we know that this chart was at this state at this point
        * in the chartState array.
        */
        return filters;
      }
    }
    return filters;
  }

  getPoints(widget: any): Array<any> {
    for (let i = 0; i < this.chartState.length; i++) {
      const state = this.chartState[i];
      if (state.i === widget.i) {
        return state.points;
      }
    }
    return [];
  }

  getFilteredData(widget: any): Array<any> {
    return Query.filterItems(
      this.getFilters(widget),
      this.data || [],
    );
  }

  canFilter(widget: any): boolean {
    // for (let i = 0; i < this.chartState.length; i++) {
    //   const chart = this.chartState[i];
    //   if (chart.i === widget.i) {
        return true;
    //   }
    // }
    // return this.chartState.length === 0;
  }

  onRemoveChartFilter(chart: any, filterIdx: number, valueIdx: number): void {
    const point = chart.points[filterIdx][valueIdx];
    point.select && point.select(false, true);

    if (chart.filters[filterIdx].value.length <= 1) {
      chart.filters.splice(filterIdx, 1);
    } else {
      chart.filters[filterIdx].value.splice(valueIdx, 1);
    }
    chart.points[filterIdx].splice(valueIdx, 1);
    if (chart.points[filterIdx].length === 0) {
      chart.points.splice(filterIdx, 1);
    }

    if (chart.points.length === 0) {
      const chartIdx = this.chartState.findIndex(item => item === chart);
      if (chartIdx !== -1) {
        this.chartState.splice(chartIdx, 1);
      }
    }
  }

  onClearAllChartFilters(): void {
    this.chartState.forEach(chart => {
      chart.points.forEach(subPoints => {
        subPoints.forEach((point: any) => {
          point.select && point.select(false, true);
        })
      })
    })
    this.chartState = [];
  }

  onResetVisualizationClick(): void {
    this.model.revertData();
  }

  onSaveClick(): Promise<VisualizationModel> {
    this.saving = true;
    return this.beforeSave(this.model).then(() => {
      return VisualizationService.getInstance().save(this.model)
        .then(response => {
          if (!this.model.data.id) {
            this.$emit('add', response.data.view.single);
          } else {
            this.$emit('update', response.data.view.single);
          }
          this.$root.$globalSnack.success({
            message: 'Dashboard saved successfully!'
          })
          this.model.assign(response.data.view.single);
          return response.data.view.single;
        })
        .catch(reason => this.$root.$zemit.handleError(reason))
        .finally(() => this.saving = false);
    });
  }

  onRowClick(row: any) {
    this.$emit('on-show-data', {
      rows: [row],
      point: [],
    });
  }

  onPointClick(props: any, widget: any): void {
    if (this.disableChartActions) {
      return;
    }

    if (this.canFilter(widget)) {
      Object.assign(this.pointClickMenu, {
        visible: true,
        x: props.event.clientX,
        y: props.event.clientY,
        props,
      })
    } else {
      this.onPointMenuClick('show', props);
    }
  }

  onPointMenuClick(type: string, props: any): void {
    if (type === 'show') {
      const pointsToShow = [props.event.point];
      this.$emit('on-show-data', {
        rows: props.rows,
        point: pointsToShow,
      });
    } else if (type === 'filter') {
      const stateIdx = this.chartState.findIndex(chart => chart.i === props.model.i)
      const state: {
        i: number,
        points: any[][],
        filters: Array<IQueryItem>,
        model: any,
      } = stateIdx === -1 ? {
        i: props.model.i,
        points: [],
        filters: [this.getNewChartFilters()],
        model: props.model,
      } : this.chartState[stateIdx];

      if (stateIdx === -1) {
        this.chartState.push(state);
      }

      let filterIdx;
      let pointIdx;
      for (let i = 0; i < state.points.length; i++) {
        const arr = state.points[i];
        pointIdx = arr.findIndex(item => item === props.event.point);
        if (pointIdx !== -1) {
          filterIdx = i;
          break;
        }
      }

      if (pointIdx !== undefined && filterIdx !== undefined) {
        state.points[filterIdx].splice(pointIdx, 1);
        if (state.points[filterIdx].length === 0) {
          state.points.splice(filterIdx, 1);
        }
        state.filters[filterIdx].value?.splice(pointIdx, 1);
        if (state.filters[filterIdx].value?.length === 0) {
          this.chartState.splice(stateIdx, 1);
        }
        props.event.point.select && props.event.point.select(false, true);
      } else {
        const value = props.event.point.name || props.value;

        if (props.meta.field === state.filters[0].field) {
          state.filters[0].value?.push(value);
          filterIdx = 0;
        } else {
          filterIdx = state.filters[0].field === null ? 0 : state.filters.length;
          if (!state.filters[filterIdx]) {
            const filters = this.getNewChartFilters();
            state.filters.push(filters);
          }
          state.filters[filterIdx].field = props.meta.field;
          state.filters[filterIdx].value = [value];
        }

        const definition: IDefinition | undefined = this.definitions?.find(definition => definition.name === props.meta.field);
        if (definition && !definition.single) {
          state.filters[filterIdx].operator = 'contains';
        }
        if (value === state.model.combineMultipleValuesLabel) {
          state.filters[filterIdx].operator = 'is multiple';
        }

        if (!state.points[filterIdx]) {
          state.points[filterIdx] = [];
        }
        state.points[filterIdx].push(props.event.point);

        props.event.point.select && props.event.point.select(true, true);
      }
    }
  }

  onAddChartClick(): void {
    this.addWidget();
  }

  onRefreshClick(): void {
    let globalLoad = false;

    this.model.data.graphs.forEach((widget: any, index: number) => {
      const ref = this.$refs['widget_' + index];
      if (widget.queryOverride) {
        setTimeout(() => {
          if (ref) {
            // @ts-ignore
            ref[0].refresh();
          }
        });
      } else {
        // @ts-ignore
        ref[0].loading = true;
        globalLoad = true;
      }
    })

    if (globalLoad) {
      this.model.data.graphs.forEach((widget: any, index: number) => {
        const ref = this.$refs['widget_' + index];
        // @ts-ignore
        ref[0].loading = true;
      });
      this.loadData()
        .then(() => {
          this.model.data.graphs.forEach((widget: any, index: number) => {
            const ref = this.$refs['widget_' + index];
            // @ts-ignore
            ref[0].loading = false;
          });
        });
    }
  }

  onRemoveWidget(index: number): void {
    this.model.data.graphs.splice(index, 1);
  }

  onClearFilters(widget: any): void {
    const index = this.chartState.findIndex(chart => chart.i === widget.i);
    if (index !== -1) {
      this.chartState[index].points.forEach(subPoints => {
        subPoints.forEach((point: any) => {
          point.select && point.select(false, true);
        })
      })
      this.chartState.splice(index, 1);
    }
  }

  onApplyWidget(widget: IDashboardChart): void {
    const found = this.model.data.graphs.find((item: IDashboardChart) => item.i === widget.i);
    if (found) {
      Object.assign(found, widget);
      this.onResized(found.i);
    } else {
      this.model.data.graphs.push(widget);
      this.onResized(widget.i);
    }
  }

  onResize(): void {
    this.skipWatch = true;
  }

  onMove(): void {
    this.skipWatch = true;
  }

  onResized(i: number): void {
    setTimeout(() => {
      const ref = this.$refs['widget_' + i];
      if (ref) {
        // @ts-ignore
        ref[0].updateChartSize();
      }
    });
    this.skipWatch = false;
  }

  onMoved(): void {
    this.skipWatch = false;
  }

  addWidget(): void {
    const widget = new DashboardChartModel({
      x: (this.model.data.graphs.length * 3) % 12,
      y: this.model.data.graphs.length + 12,
      i: this.model.data.graphs.length,
    }).data;
    Object.assign(this.newWidgetDialog, {
      visible: true,
      model: widget,
    });
  }

  getNewChartFilters() {
    return structuredClone(this.defaultQueryItem);
  }

  loadData(): Promise<Array<any>> {
    return new Promise((resolve) => {
      resolve([])
    });
  }

  mounted() {
    this.gridRef = this.$refs.gridLayout;
    setTimeout(() => {
      this.ready = true;
    });
  }
}
</script>

<style lang="scss">
.vue-grid-item {
  transition: none !important;
}
.vue-grid-item.vue-grid-placeholder {
  background-color: green;
}
//.vue-grid-item:not(.vue-grid-placeholder) {
//  background: #ccc;
//  border: 1px solid black;
//}
.vue-grid-item .resizing {
  opacity: 0.9;
}
.vue-grid-item .static {
  background: #cce;
}
.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}
.vue-grid-item .minMax {
  font-size: 12px;
}
.vue-grid-item .add {
  cursor: pointer;
}
.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}
.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}
</style>
