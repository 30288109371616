<template>
  <div>
    <ModalDialog
      v-if="project"
      v-model="showDetails"
      title="Project Information"
      icon="mdi-information-outline"
      max-width="1000"
      background-color="backgroundVeryLight"
      scrollable
      draggable
    >
      <template #body>
        <v-container class="pt-4">
          <v-row>
            <v-col cols="12">
              <v-subheader>Description</v-subheader>
              <v-card>
                <v-card-text>
                  <div v-html="project.data.description || 'Not defined yet.'" class="last-p-mb-0"></div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">

              <!-- OBJECTIVES -->
              <v-subheader>Objectives</v-subheader>
              <v-expansion-panels multiple>
                <v-expansion-panel v-for="item in objectiveList" :key="item.key" :disabled="project.data[item.key].length === 0">
                  <v-expansion-panel-header>
                    {{ item.label }}
                    <div class="text-right">
                      <v-chip v-if="project.data[item.key].length === 0" class="mr-2" x-small label>Undefined</v-chip>
                      <v-chip v-else color="primary" class="mr-2" small label>{{ project.data[item.key].length }}</v-chip>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul>
                      <li
                        v-for="str in project.data[item.key]"
                        v-html="$options.filters?.nl2br(str)"
                        :key="str"
                      ></li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <!-- CRITERIA -->
              <v-subheader>Criteria</v-subheader>
              <v-expansion-panels multiple>
                <v-expansion-panel v-for="item in criteriaList" :key="item.key" :disabled="project.data[item.key].length === 0">
                  <v-expansion-panel-header>
                    {{ item.label }}
                    <div class="text-right">
                      <v-chip v-if="project.data[item.key].length === 0" class="mr-2" x-small label>Undefined</v-chip>
                      <v-chip v-else color="primary" class="mr-2" small label>{{ project.data[item.key].length }}</v-chip>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul>
                      <li
                        v-for="str in project.data[item.key]"
                        v-html="$options.filters?.nl2br(str)"
                        :key="str"
                      ></li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col cols="12" md="6">

              <!-- PICOTS -->
              <v-subheader>PICOTS</v-subheader>
              <v-expansion-panels multiple>
                <v-expansion-panel v-for="item in picotList" :key="item.key" :disabled="project.data[item.key].length === 0">
                  <v-expansion-panel-header>
                    {{ item.label }}
                    <div class="text-right">
                      <v-chip v-if="project.data[item.key].length === 0" class="mr-2" x-small label>Undefined</v-chip>
                      <v-chip v-else color="primary" class="mr-2" small label>{{ project.data[item.key].length }}</v-chip>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul>
                      <li
                        v-for="str in project.data[item.key]"
                        v-html="$options.filters?.nl2br(str)"
                        :key="str"
                      ></li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template #buttons>
        <v-btn
          outlined
          large
          @click="showDetails = false"
        >
          Close
        </v-btn>
      </template>
    </ModalDialog>

    <portal to="toolbar_right_first">
      <ShortcutDialog
        :title="$t('shortcutDialog.title')"
        :visible.sync="shortcutDialog.visible"
        :shortcuts="shortcuts"
        max-width="400"
        scrollable
      />
      <div class="d-flex align-center" style="gap: 1rem">
        <v-btn text @click="onShortcutClick">
          <v-icon left>mdi-keyboard</v-icon>
          <span v-text="$t('shortcuts.btn')"></span>
        </v-btn>
      </div>

      <v-divider vertical inset class="mx-3 mt-0" />
    </portal>

    <v-row v-row-resize="showSidePanel ? _rowSizes : false" v-hotkey="keymap" tabindex="1" dense>

      <!-- RECORD -->
      <v-col cols="12" :md="showSidePanel? _rowSizes[0].md : '12'" :lg="showSidePanel? _rowSizes[0].lg : '12'">
        <v-card>
          <Sticky class="overflow-hidden pb-4 mb-n4" app @change="onRecordStickyChange">

            <!-- NO RECORD -->
            <v-alert v-if="!record">
              NOT FOUND
            </v-alert>

            <!-- DELETED -->
            <v-expand-transition>
              <v-alert v-if="record.data.deleted === 1" color="error" class="mb-0 pa-0" tile dark>
                <div class="w-100 d-flex align-center justify-space-between pa-4">
                  <div>
                    <v-icon left>mdi-close</v-icon>
                    <span v-text="$t('recordComponent.deletedAt', {
                      date: record.data.deletedAt
                    })"></span>
                  </div>
                  <v-btn :disabled="!canEditDeleteRestoreRecord" :loading="restoring" @click="onRestoreRecordClick">
                    <v-icon left>mdi-delete-restore</v-icon>
                    <span v-text="$t('btn.restore')"></span>
                  </v-btn>
                </div>
              </v-alert>
            </v-expand-transition>

            <!-- ASSIGN/UNASSIGN -->
            <v-card tile flat>
              <v-alert
                :color="assignColor"
                class="mb-0 pa-0"
                tile
                text
              >
                <div class="w-100 d-flex align-center justify-space-between pa-4">
                  <div v-if="record.data.pilotUserId === currentUserId">
                    <v-icon :color="assignColor" left>mdi-account-check</v-icon>
                    <span>This record is currently assigned to you.</span>
                  </div>
                  <div v-else-if="record.data.pilotUserId !== null">
                    <v-icon :color="assignColor" left>mdi-alert-rhombus-outline</v-icon>
                    <span>This record is currently assigned to {{ getUserFullName(record.data.pilotUserId) }}.</span>
                  </div>
                  <div v-else>
                    <v-icon :color="assignColor" left>mdi-folder-alert-outline</v-icon>
                    <span>This record is currently unassigned.</span>
                  </div>
                  <div class="d-flex align-center flex-nowrap" style="gap: 1rem">
                    <v-autocomplete
                      v-if="canAssignUser"
                      v-model="assignedUserId"
                      :items="userList"
                      :loading="assigning"
                      :disabled="assigning"
                      :item-text="item => item.data.userentity.getLabel()"
                      label="Assigned to"
                      item-value="data.userentity.data.id"
                      style="min-width: 15rem"
                      dense
                      outlined
                      hide-details
                      clearable
                      @change="userId => onAssignRecordClick(userId)"
                    />
                    <v-btn v-else-if="canGetNextRecord && record.data.pilotUserId === null" :disabled="assigning || disabled" :loading="assigning" text @click="() => onAssignRecordClick()">
                      <v-icon left>mdi-check</v-icon>
                      <span>Assign to me</span>
                    </v-btn>
                    <v-btn v-else :disabled="unassigning" :loading="unassigning" text @click="onUnassignRecordClick">
                      <v-icon left>mdi-close</v-icon>
                      <span>Unassign</span>
                    </v-btn>
                  </div>
                </div>
              </v-alert>
              <v-divider />
            </v-card>

            <!-- RECORD TITLE -->
            <v-sheet :class="{ 'elevation-4': recordIsSticked, 'transition-none': true, }">
              <v-card-title>
                <h4 v-if="isAddingNewRecord">New Record</h4>
                <h4 v-else>Record #</h4>
                <v-text-field
                  v-if="!isAddingNewRecord"
                  v-model.number="recordNumber"
                  :disabled="disabled"
                  dense
                  outlined
                  hide-details
                  type="number"
                  class="text-center ml-1"
                  style="max-width: 5rem"
                  @blur="() => onRecordNumberChange(recordNumber)"
                  @keydown.enter="() => onRecordNumberChange(recordNumber)"
                />

                <!-- PROJECT INFORMATION -->
                <v-tooltip v-if="project" bottom>
                  <template #activator="{ attrs, on }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="ml-3"
                      icon
                      @mousedown.stop="showDetails = true"
                    >
                      <v-icon>mdi-book-information-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>Project Information</span>
                </v-tooltip>

                <v-spacer></v-spacer>

                <quick-status-component
                  :v-model="record"
                  :stage="stage"
                  :user-type="userType"
                  :disabled="!canSetStatus"
                  ref="quickStatusComponent"
                  @save-status-success="saveStatusSuccess(true)"
                ></quick-status-component>

                <v-spacer></v-spacer>

                <!-- RECORD POPOVER -->
                <div class="text-center d-flex align-center" style="gap: 1rem">

                  <!-- ADJUST FONT SIZE -->
                  <FontSize :disabled="disabled" />

                  <!-- LINKS MENU -->
                  <links-menu-component
                    :disabled="disabled"
                    :items="links"
                    :highlighting-style.sync="highlightingStyle"
                  />

                  <!-- SIDE PANEL -->
                  <v-tooltip v-if="$vuetify.breakpoint.mdAndUp" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :disabled="disabled"
                        icon
                        @click="toggleSidePanel"
                      >
                        <v-icon v-if="!showSidePanel">mdi-page-layout-sidebar-left</v-icon>
                        <v-icon v-else>mdi-page-layout-sidebar-right</v-icon>
                      </v-btn>
                    </template>
                    <span>
                      <span v-if="showSidePanel">Close sidebar</span>
                      <span v-else>Open sidebar</span>
                    </span>
                  </v-tooltip>
                </div>

              </v-card-title>
            </v-sheet>
          </Sticky>

          <!-- RECORD CONTENT -->
          <v-card-text v-if="!editable" class="pt-0">
            <v-tabs
              v-model="tab"
              style="flex: 1"
            >
              <v-tab>Abstract</v-tab>
              <v-tab
                v-for="(article, articleIdx) in _articles"
                :disabled="disabled || isAddingNewRecord"
                :key="articleIdx"
              >
                <span
                  v-text="article.data.title || 'Untitled'"
                  class="text-truncate"
                  style="max-width: 10rem"
                ></span>
              </v-tab>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :disabled="disabled || isAddingNewRecord"
                    icon
                    class="mt-2 ml-3"
                    @click="onAddNewArticle"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span v-text="$t('recordComponent.addArticle')"></span>
              </v-tooltip>
            </v-tabs>
            <v-tabs-items v-model="tab" class="mt-6 last-p-mb-0">
              <v-tab-item :class="['adjust-font-size', 'highlighting-style-' + highlightingStyle]">

                <!-- SOURCE TYPES -->
                <template v-if="canSeeRecordField('sourceType')">
                  <template v-if="!isEditMode">
                    <h4>Source Type</h4>
                    <p
                      v-text="(sourceTypeList.find(item => item.value === record.data.sourceType) || {}).text || 'Undefined'"
                    ></p>
                  </template>
                  <v-select
                    v-else
                    v-model="tmpRecord.data.sourceType"
                    :items="sourceTypeList"
                    label="Source type"
                    outlined
                    class="mt-1"
                  />
                </template>

                <template v-if="canSeeRecordField('authors') && tick">
                  <h4>Author</h4>
                  <p
                    v-if="!isEditMode"
                    v-html="Utils.stripHtml(record.data.authors + ' (' + record.data.year + ')')"
                    v-highlight-keywords="highlightKeywords"
                  ></p>
                  <v-alert
                    v-else
                    outlined
                    border="left"
                    class="mb-8"
                  >
                    <ListBuilder
                      v-model="tmpRecord.data.authors"
                      :default-model="defaultModel"
                    >
                      <template #item="{ item }">
                        <v-text-field
                          v-model="item.data.label"
                          v-safechar
                          :rules="[rules.required]"
                          clearable
                          outlined
                          dense
                        />
                      </template>
                    </ListBuilder>
                    <v-text-field
                      v-model="tmpRecord.data.year"
                      v-safechar
                      :rules="[rules.required, rules.numeric]"
                      label="Year"
                      dense
                      outlined
                      hide-details="auto"
                      class="mt-4"
                    />
                  </v-alert>
                </template>

                <template v-if="canSeeRecordField('title')">
                  <template v-if="!isEditMode">
                    <h4>Title</h4>
                    <p
                      v-html="addSpaces(Utils.stripHtml(record.data.title))"
                      v-highlight-keywords="highlightKeywords"
                    ></p>
                  </template>
                  <v-text-field
                    v-else
                    v-model="tmpRecord.data.title"
                    v-safechar
                    :rules="[rules.required]"
                    label="Title"
                    dense
                    outlined
                  />
                </template>

                <template v-if="canSeeRecordField('abstract')">
                  <template v-if="!isEditMode">
                    <h4>Abstract</h4>
                    <p
                      v-if="!shouldSplitSection || !Array.isArray(splitSections)"
                      v-html="removeUseless(addSpaces(Utils.stripHtml(record.data.abstract)))"
                      v-highlight-keywords="highlightKeywords"
                    ></p>
                    <ul v-else-if="Array.isArray(splitSections)" class="mb-8">
                      <li
                        v-for="(section, sectionIdx) in splitSections"
                        :key="sectionIdx"
                        class="mt-3"
                      >
                        <h5 v-if="section.title">{{ section.title }}</h5>
                        <div
                          v-html="removeUseless(section.content)"
                          v-highlight-keywords="highlightKeywords"
                        ></div>
                      </li>
                    </ul>
                  </template>
                  <v-textarea
                    v-else
                    v-model="tmpRecord.data.abstract"
                    label="Abstract"
                    dense
                    outlined
                  />
                </template>

                <template v-if="canSeeRecordField('affiliations')">
                  <h4>Affiliations</h4>
                  <p
                    v-if="!isEditMode"
                    v-html="Utils.stripHtml(record.data.affiliations)"
                    v-highlight-keywords="highlightKeywords"
                  ></p>
                  <v-alert
                    v-else
                    outlined
                    border="left"
                    class="mb-8"
                  >
                    <ListBuilder
                      v-model="tmpRecord.data.affiliations"
                      :default-model="defaultModel"
                    >
                      <template #item="{ item }">
                        <v-text-field
                          v-model="item.data.label"
                          v-safechar
                          :rules="[rules.required]"
                          clearable
                          outlined
                          dense
                        />
                      </template>
                    </ListBuilder>
                  </v-alert>
                </template>

                <template v-if="canSeeRecordField('references')">
                  <h4>References</h4>
                  <p
                    v-if="!isEditMode"
                    v-html="Utils.stripHtml(record.data.references)"
                    v-highlight-keywords="highlightKeywords"
                  ></p>
                  <v-alert
                    v-else
                    outlined
                    border="left"
                    class="mb-8"
                  >
                    <ListBuilder
                      v-model="tmpRecord.data.references"
                      :default-model="defaultModel"
                    >
                      <template #item="{ item }">
                        <v-text-field
                          v-model="item.data.label"
                          v-safechar
                          :rules="[rules.required]"
                          clearable
                          outlined
                          dense
                        />
                      </template>
                    </ListBuilder>
                  </v-alert>
                </template>

                <template v-if="canSeeRecordField('doi') && record.data.doi">
                  <template v-if="!isEditMode">
                    <h4>DOI</h4>
                    <p v-html="$options.filters?.parseLinks(Utils.stripHtml(record.data.doi))"></p>
                  </template>
                  <v-text-field
                    v-else
                    v-model="tmpRecord.data.doi"
                    v-safechar
                    :rules="[rules.url]"
                    label="DOI"
                    dense
                    outlined
                  />
                </template>

                <template v-if="canSeeRecordField('url')">
                  <h4 v-if="record.data.url || isEditMode">URL</h4>
                  <p
                    v-if="!isEditMode && record.data.url"
                    v-html="$options.filters?.parseLinks(Utils.stripHtml(record.data.url))">
                  </p>
                  <v-alert
                    v-else-if="isEditMode"
                    outlined
                    border="left"
                    class="mb-8"
                  >
                    <ListBuilder
                      v-model="tmpRecord.data.url"
                      :default-model="defaultModel"
                    >
                      <template #item="{ item }">
                        <v-text-field
                          v-model="item.data.label"
                          v-safechar
                          :rules="[rules.url]"
                          clearable
                          outlined
                          dense
                        />
                      </template>
                    </ListBuilder>
                  </v-alert>
                </template>

                <template v-if="canSeeRecordField('ovid')">
                  <template v-if="!isEditMode">
                    <h4>Ovid</h4>
                    <p v-html="$options.filters?.parseLinks(Utils.stripHtml(record.data.ovid))"></p>
                  </template>
                  <v-text-field
                    v-else
                    v-model="tmpRecord.data.ovid"
                    v-safechar
                    :rules="[rules.url]"
                    label="Ovid"
                    dense
                    outlined
                  />
                </template>

                <template v-if="canSeeRecordField('language')">
                  <template v-if="!isEditMode">
                    <h4>Language</h4>
                    <p
                      v-html="Utils.stripHtml(record.data.language)"
                      v-highlight-keywords="highlightKeywords"
                    ></p>
                  </template>
                  <v-text-field
                    v-else
                    v-model="tmpRecord.data.language"
                    v-safechar
                    :rules="[rules.required]"
                    label="Language"
                    dense
                    outlined
                  />
                </template>

                <template v-if="canSeeRecordField('identifier')">
                  <template v-if="!isEditMode">
                    <h4>Identifier</h4>
                    <p
                      v-html="Utils.stripHtml(record.getIdentifier())"></p>
                  </template>
                  <v-text-field
                    v-else
                    v-model="tmpRecord.data.uid"
                    label="Identifier"
                    disabled
                    dense
                    outlined
                  />
                </template>

                <v-divider class="my-3" />

                <v-card-actions>
                  <v-btn :disabled="!canEditDeleteRestoreRecord" color="error" text @click="onDeleteRecordClick">
                    <v-icon left>mdi-delete-outline</v-icon>
                    <span>Delete this record</span>
                  </v-btn>

                  <v-spacer />

                  <v-btn v-if="!editing" :disabled="!canEditDeleteRestoreRecord" color="primary" @click="onEditRecordClick">
                    <v-icon left>mdi-pencil</v-icon>
                    <span v-text="$t('btn.edit')"></span>
                  </v-btn>
                  <v-btn v-else :loading="saving" :disabled="!canEditDeleteRestoreRecord || saving || !tmpRecord.isDifferentFromOriginal()" color="primary" @click="onApplyRecordChangeClick">
                    <v-icon left>mdi-content-save</v-icon>
                    <span v-text="$t('btn.save')"></span>
                  </v-btn>
                  <v-btn :disabled="disabled || !editing || saving" text @click="onCancelRecordChangeClick">
                    <span v-text="$t('btn.cancel')"></span>
                  </v-btn>
                </v-card-actions>
              </v-tab-item>
              <v-tab-item
                v-for="(article, articleIdx) in _articles"
                :disabled="isAddingNewRecord"
                :key="articleIdx"
              >
                <article-component
                  v-model="_articles[articleIdx]"
                  :highlight-logic="highlightKeywords"
                  :disabed="disabled"
                  :editing="editing"
                  @remove="onRemoveArticle"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- RECORD SIDE PANEL -->
      <v-col cols="12" :md="showSidePanel ? _rowSizes[1].md : 12" :lg="showSidePanel ? _rowSizes[1].lg : 12" class="side-panel pr-0">
        <Sticky :value="showSidePanel" class="mb-n1 pb-1 pr-2" :offset="8" :timeout="500" scrollable app>

          <!-- NEXT RECORD -->
          <v-card v-if="canGetNextRecord" class="mb-3">
            <v-card-text class="d-flex flex-wrap align-center" style="gap: 1rem">
              <v-btn
                :loading="loading"
                :disabled="disabled || loading || noMoreNextRecord"
                block
                outlined
                @click="() => onGetNextRecordClick(index)"
              >
                Next available record
                <v-icon right>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-text>
          </v-card>

          <!-- OFFICIAL STATUS -->
          <v-card v-if="canSeeOfficialStatus" class="mb-3 d-flex align-center justify-space-between">
            <v-card-title class="body-1 font-weight-bold" style="flex: 2">
              Official {{ projectStageLabel }} Status
            </v-card-title>
            <v-sheet
              :color="officialStatus?.color"
              class="font-weight-bold text-center py-4"
              style="flex: 1; border-radius: 0;"
              dark
            >
              {{ officialStatus?.text }}
            </v-sheet>
          </v-card>

          <!-- STATUS -->
          <status-component
            :v-model="record"
            :show-user-list="showUserList"
            :user-type="userType"
            :disabled="!canSetStatus"
            :stage="stage"
            :project="project"
            ref="statusComponent"
            @save-status-success="saveStatusSuccess"
          />

          <CollapsableCard
            title="Tags"
            icon="mdi-tag-text"
            memory-key="review_tags"
            class="mt-3"
          >
            <template #body>
              <v-card-text class="pt-0">

                <!-- TAGS -->
                <tags-component
                  v-model="record.data.taglist"
                  :items="tags"
                  :disabled="disabled || isAddingNewRecord"
                  :record-id="record.data.id"
                  :project-id="record.data.projectId"
                  :self-only="project.data.stage === 'screening' && !['arbitrator', 'leader'].includes(userType)"
                  label="General"
                  @insert="onInsertTag"
                />

                <!-- TAGS CATEGORIES -->
                <template v-for="(category, categoryIndex) in project.data.categorylist">
                  <tags-component
                    v-model="record.data.taglist"
                    :items="null"
                    :key="'category_' + categoryIndex"
                    :label="category.data.label"
                    :color="category.data.color"
                    :disabled="disabled || isAddingNewRecord"
                    :record-id="record.data.id"
                    :project-id="record.data.projectId"
                    :category-id="category.data.id"
                    :self-only="project.data.stage === 'screening' && !['arbitrator', 'leader'].includes(userType)"
                    class="mt-3"
                    @insert="onInsertTag"
                  />
                </template>
              </v-card-text>
            </template>
          </CollapsableCard>

          <!-- COMMENTS -->
          <comments-component
            :disabled="disabled || isAddingNewRecord"
            :record-id="record.data.id"
            :self-only="commentsAreSelfOnly"
            :user-type="userType"
            class="mt-3"
            ref="commentsComponent"
          />

          <!-- SURVEY ANSWERS -->
          <SurveyManager
            v-for="(survey, surveyIdx) in project.data.surveylist"
            v-model="project.data.surveylist[surveyIdx]"
            :key="survey.data.id"
            :answers="answers"
            :record="record"
            :can-use-ai="project.data.aiUseAllowed && project.hasRole(['ai-manager'])"
            :stage="stage"
            :project="project"
            :disabled="!canAnswerSurvey"
            :can-view-answers="canViewAnswers"
            :can-see-answer-name="canSeeAnswerName"
            :can-see-official-answers="canSeeOfficialAnswers"
            :manage-answers="canManageAnswers"
            :readonly="surveyIsReadonly"
            class="mt-3"
            load-answers
          />
        </Sticky>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, ModelSync, Prop, Emit, PropSync, Watch, Ref} from 'vue-property-decorator';
import RecordModel from '@/models/record.model';
import KeywordModel from '@/models/keyword.model';
import RecordService from '@/services/record.service';
import UserModel from '@/modules/sdk/models/user.model';
import Sticky from '@/modules/common/components/Sticky.vue';
import ShortcutDialog from '@/modules/common/components/ShortcutDialog.vue';
import FontSize from '@/components/FontSize.vue';
import ListBuilder from '@/modules/common/components/ListBuilder.vue';
import TagsComponent from '@/views/Admin/Component/Record/TagsComponent.vue';
import CommentsComponent from '@/views/Admin/Component/Record/CommentsComponent.vue';
import StatusComponent from '@/views/Admin/Component/Record/StatusComponent.vue';
import QuickStatusComponent from '@/views/Admin/Component/Record/QuickStatusComponent.vue';
import LinksMenuComponent from '@/views/Admin/Component/Record/LinksMenuComponent.vue';
import ArticleComponent from '@/views/Admin/Component/Record/ArticleComponent.vue';
import ProjectModel from '@/models/project.model';
import ArticleModel from '@/models/article.model';
import ArticleService from '@/services/article.service';
import Utils from '@/modules/sdk/core/utils';
import SynonymModel from '@/models/synonym.model';
import Model from '@/modules/sdk/core/model';
import {sourceTypeList, objectiveList, picotList, criteriaList, statusList, stageList} from '@/enums/global';
import Rules, { IRuleSet } from '@/modules/sdk/core/rules';
import Identity from '@/modules/sdk/core/identity';
import TagModel from '@/models/tag.model';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import CollapsableCard from '@/modules/common/components/CollapsableCard.vue';
import SurveyManager from '@/components/SurveyManager.vue';

interface IShortcut {
  key: string,
  i18n: string,
  callback: () => void,
}

@Component({
  components: {
    SurveyManager,
    CollapsableCard,
    ModalDialog,
    ShortcutDialog,
    LinksMenuComponent,
    ArticleComponent,
    TagsComponent,
    CommentsComponent,
    StatusComponent,
    QuickStatusComponent,
    Sticky,
    FontSize,
    ListBuilder,
  }
})
export default class RecordComponent extends Vue {
  @Ref() readonly statusComponent!: StatusComponent;
  @Ref() readonly commentsComponent!: CommentsComponent;
  @Ref() readonly quickStatusComponent!: QuickStatusComponent;
  @ModelSync('vModel', 'change', { type: RecordModel }) record!: RecordModel;
  @Prop({ default: 'none' }) userType!: string;
  @Prop({ default: () => [] }) readonly keywordList!: Array<KeywordModel>;
  @Prop({ default: () => [] }) readonly userList!: Array<UserModel>;
  @Prop({ default: () => [] }) readonly recordList!: Array<RecordModel>;
  @Prop({ default: () => {} }) readonly options!: {[key:string]: boolean | any};
  @Prop({ default: () => {} }) readonly filters!: {[key:string]: any};
  @Prop({ default: () => 'screening' }) readonly stage!: 'screening' | 'indepth' | 'final' | string;
  @Prop({ default: () => false }) showUserList!: boolean;
  @Prop({ default: () => false }) disabled!: boolean;
  @Prop({ default: null }) index!: number;
  @Prop({ type: Array, default: null }) tags?: Array<TagModel>;
  @Prop({ default: () => new ProjectModel() }) project!: ProjectModel;
  @PropSync('sidePanel', { default: true }) showSidePanel!: boolean;
  @PropSync('rowSizes', {
    default: () => [
      { md: 7, lg: 8 },
      { md: 5, lg: 4 },
    ]
  }) _rowSizes!: Array<any>;

  answers = []
  assignedUserId: number | null = null
  showDetails = false;
  objectiveList = objectiveList;
  picotList = picotList;
  criteriaList = criteriaList;
  sourceTypeList = sourceTypeList
  Utils = Utils
  loading = false;
  noMoreNextRecord = false;
  loadingArticles = false;
  recordIsSticked = false;
  editing = false;
  editable = false;
  saving = false;
  restoring = false;
  assigning = false;
  unassigning = false;
  tab = 0;
  tick = 0;
  recordNumber = 0;
  previousRecordNumber = 0;
  shortcutDialog = {
    visible: false,
  }

  articles: Array<ArticleModel> = [];
  tmpRecord: RecordModel = new RecordModel();
  defaultModel = Model;
  rules: IRuleSet = {}
  shortcuts: Array<IShortcut> = [
    { key: 'h', i18n: 'recordComponent.shortcut.halt', callback: () => this.$emit('status', 'halt') },
    { key: 'p', i18n: 'recordComponent.shortcut.pass', callback: () => this.$emit('status', 'pass') },
    { key: 'home', i18n: 'recordComponent.shortcut.first', callback: () => this.$emit('first') },
    { key: 'end', i18n: 'recordComponent.shortcut.last', callback: () => this.$emit('last') },
    { key: 'left', i18n: 'recordComponent.shortcut.previous', callback: () => this.$emit('previous') },
    { key: 'right', i18n: 'recordComponent.shortcut.next', callback: () => this.$emit('next') },
  ];

  keymap: any = {};

  highlightingStyle = 'background';
  highlightKeywords = {
    keywords: [] as any,
    style: 'background',
    callback: undefined,
    vue: Vue,
  };

  // Links
  googleLink = 'https://scholar.google.com/scholar?q=';

  @Watch('options', {deep: true})
  @Watch('keywordList', {deep: true, immediate: true})
  onKeywordListChange() {
    Object.assign(this.highlightKeywords, {
      callback: this.onHighlightKeyword,
      keywords: [
        ...((this.options.highlightKeywords && this.keywordList.filter(keyword => !keyword.data.deleted)) || []),
        ...((this.options.highlightFilters && this.filters.map((item: any) => item.value)) || []),
      ],
      vue: this,
    });
    this.tick++;
  }

  @Watch('highlightingStyle')
  onHighlightingStyleChange(style: string) {
    localStorage.setItem('review_highlighting_style', style);
  }

  @Watch('record.data.pilotUserId', {
    immediate: true
  })
  onRecordPilotUserIdChange() {
    this.assignedUserId = this.record.data.pilotUserId;
  }

  @Watch('record.data.pid', {
    immediate: true
  })
  onRecordPidChange(pid: number) {
    this.recordNumber = pid;
    this.previousRecordNumber = pid;
    this.assignedUserId = this.record.data.pilotUserId;
  }

  onRecordStickyChange(sticky: boolean) {
    this.recordIsSticked = sticky;
  }

  onShortcutClick() {
    this.shortcutDialog.visible = true;
  }

  onEditRecordClick() {
    this.editRecord(this.record);
  }

  onCreateRecordClick() {
    this.tmpRecord = new RecordModel();
    this.editing = true;
  }

  onRestoreRecordClick() {
    this.restoring = true;
    RecordService.getInstance().restore({
      id: this.record.data.id,
    })
      .then(response => {
        this.record.assign(response.data.view.single);
        this.$root.$globalSnack.success({
          message: this.$i18n.t('recordComponent.restoreRecord.snack')
        });
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.restoring = false);
  }

  onUnassignRecordClick() {
    this.unassigning = true;
    RecordService.getInstance().update({
      id: this.record.data.id,
      pilotUserId: null,
    })
      .then(response => {
        this.record.assign(response.data.view.single);
        this.$root.$globalSnack.success({
          message: 'The record has been unassigned successfully'
        });
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.unassigning = false);
  }

  onAssignRecordClick(pilotUserId = this.currentUserId) {
    if (pilotUserId === this.record.data.pilotUserId || (pilotUserId && !this.userList.find((item: any) => item.data.userentity.data.id === pilotUserId))) {
      return;
    }

    this.assigning = true;
    RecordService.getInstance().update({
      id: this.record.data.id,
      pilotUserId,
    })
      .then(response => {
        this.record.assign(response.data.view.single);
        this.$root.$globalSnack.success({
          message: 'The record has been assigned to you successfully'
        });
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.assigning = false);
  }

  onDeleteRecordClick() {
    this.$root.$globalModal.ask(
      this.$i18n.t('recordComponent.removeRecordConfirm.title'),
      this.$i18n.t('recordComponent.removeRecordConfirm.body'),
      [{
        text: this.$i18n.t('btn.delete'),
        attrs: {
          outlined: true,
        },
        events: {
          click: () => {
            this.$root.$globalModal.setLoading(true);
            RecordService.getInstance().delete({
              id: this.record.data.id,
            })
              .then(response => {
                this.record.assign(response.data.view.single);
                this.$root.$globalSnack.info({
                  message: this.$i18n.t('recordComponent.removeRecordConfirm.snack')
                });
                this.$root.$globalModal.hide();
              })
              .catch(reason => this.$root.$zemit.handleError(reason))
              .finally(() => this.$root.$globalModal.setLoading(false));
          },
        }
      }, {
        text: this.$i18n.t('btn.cancel'),
        attrs: {
          text: true,
        },
        events: {
          click: () => {
            this.$root.$globalModal.hide();
          },
        }
      }],
      'danger',
    );
  }

  onCancelRecordChangeClick() {
    this.editing = false;
  }

  onApplyRecordChangeClick() {
    const record: RecordModel = this.tmpRecord.clone();
    record.data.authors = record.data.authors.map((item: any) => item.label).filter((item: string) => item).join(', ');
    record.data.affiliations = record.data.affiliations.map((item: any) => item.label).filter((item: string) => item).join(', ');
    record.data.references = record.data.references.map((item: any) => item.label).filter((item: string) => item).join(', ');
    record.data.url = record.data.url.map((item: any) => item.label).filter((item: string) => item).join(' ');

    this.saving = true;
    RecordService.getInstance().save(record)
      .then(response => {
        this.record.assign(response.data.view.single);
        this.editing = false;
        this.$root.$globalSnack.success({
          message: this.$i18n.t('recordComponent.saveRecord.snack')
        });
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.saving = false);
  }

  onGetNextRecordClick(index = this.index, looped = false): Promise<RecordModel> | void {
    const checkNextRecord = (index: number): Promise<any> | void => {
      this.loading = true;
      if (!this.recordList[index]) {
        if (looped) {
          this.$root.$globalSnack.warning({
            message: 'No more record available.',
          })
          this.noMoreNextRecord = true;
          this.loading = false;
        } else {
          this.onGetNextRecordClick(this.recordList.length, true);
        }
      }
      else if (this.recordList[index].data.pilotUserId === null) {
        return RecordService.getInstance().get({ id: this.recordList[index].data.id })
          .then(response => {
            const record = response.data.view.single;
            if (
              record.data.pilotUserId !== null
              // && record.data.pilotUserId !== this.currentUserId
              && this.recordList.length !== (index + 1)
            ) {
              this.recordList[index].assign(response.data.view.single);
              return checkNextRecord(index + 1);
            } else {
              this.recordList[index].assign(response.data.view.single);
              this.$emit('set-page', index + 1);
              return response;
            }
          })
          .catch(reason => this.$root.$zemit.handleError(reason))
          .finally(() => this.loading = false);
      } else {
        return checkNextRecord(index + 1);
      }
    }

    if (this.recordList.length === (index + 1)) {
      return checkNextRecord(0);
    } else {
      return checkNextRecord(index + 1);
    }
  }

  get assignColor(): string | undefined {
    return this.record.data.pilotUserId === null ? undefined : this.record.data.pilotUserId === this.currentUserId ? 'success' : 'warning';
  }

  get canSeeOfficialStatus(): boolean {
    return this.userType === 'arbitrator';
  }

  get officialStatus(): {
    value: string,
    text: string,
    color: string,
  } | null {
    return statusList.find(status => status.value === this.record.data[this.stage + 'Status']) || null;
  }

  get projectStageLabel(): string {
    return (stageList.find(stage => stage.value === this.stage) || {}).text || 'Undefined';
  }

  get canEditDeleteRestoreRecord(): boolean {
    return !this.disabled
      && this.record.data.deleted !== 1
      && !this.restoring
      && ['leader', 'arbitrator'].includes(this.userType || '');
  }

  get canSeeAnswerName(): boolean {
    return Identity.hasRole(['dev']);
  }

  get canViewAnswers(): boolean {
    return ['leader', 'arbitrator'].includes(this.userType || '');
  }

  get canManageAnswers(): boolean {
    return ['leader', 'arbitrator'].includes(this.userType || '');
  }

  get canSeeOfficialAnswers(): boolean {
    return ['leader', 'arbitrator'].includes(this.userType || '')
      && (
        this.project.data.type !== 'systematic_review'
        || this.project.data.stage === 'final'
      )
  }

  get surveyIsReadonly(): boolean {
    return this.userType === 'leader';
  }

  get canAssignUser(): boolean {
    return ['leader', 'arbitrator'].includes(this.userType || '');
  }

  get canGetNextRecord(): boolean {
    return ['researcher', 'secondary-researcher'].includes(this.userType || '');
  }

  get canSetStatus(): boolean {
    const user = this.userList.find(user => {
      return (
        (user.data.userType === 'researcher' && this.userType === 'researcher')
        || (user.data.userType === 'secondary-researcher' && this.userType === 'secondary-researcher')
      )
    });

    return (
      (!user || user.data.id === Identity.identity?.user.id) || !user
    ) && this.userType !== 'leader' && !this.isAddingNewRecord && !this.disabled;
  }

  get currentUserId(): number {
    return Identity.identity?.user.id;
  }

  get isAddingNewRecord(): boolean {
    return !this.tmpRecord.data.id && this.editing;
  }

  get isEditMode(): boolean {
    return this.editing;
  }

  get canSeeSurvey() {
    return this.record.data.screeningStatus === 'pass'
      && this.record.data.indepthStatus === 'pass'
      && this.record.data.finalStatus === 'pass';
  }

  get canAnswerSurvey() {
    return !this.disabled && !this.isAddingNewRecord && this.userType !== 'leader';
  }

  get _articles() {
    return this.isAddingNewRecord ? [] : this.articles.filter(article => !article.data.deleted);
  }

  get links() {
    // prepare pdf and doi links
    const links: Array<any> = [
      {href: this.record.data.pdf, label: 'Open PDF', icon: 'mdi-file-pdf-box'},
      {href: this.record.data.doi, label: 'Open DOI', icon: 'mdi-link-variant'},
      {href: this.record.data.ovid, label: 'Open OVID', icon: 'mdi-circle-outline'},
    ];

    // add other links
    if (this.record.data.url) {
      this.record.data.url.split(' ').forEach((item: string, key: number) => {
        links.push({href: item, label: 'Open URL (' + (key + 1) + ')', icon: 'mdi-link'})
      });
    }

    // add google link
    links.push({href: this.googleLink + encodeURIComponent(this.record.data.title), label: 'Google Scholar', icon: 'mdi-google'});

    // add articles
    this._articles.filter(article => article.data.fileentity.data.id).forEach(article => {
      const file = article.data.fileentity;
      const path = [
        file.data.category,
        file.data.id,
        file.data.path,
      ];
      links.push({ href: '/file/download/' + path.join('/'), label: article.data.fileentity.data.name, icon: 'mdi-file-pdf-box'});
    })

    return links;
  }

  get commentsAreSelfOnly(): boolean {
    return this.project.data.stage === 'screening'
      || Identity.hasRole(['dev', 'admin'])
      || ['arbitrator', 'leader'].includes(this.userType || '')
  }

  get canCreateRecord(): boolean {
    return !this.editing && this.stage !== 'screening' && this.record.data.deleted !== 1;
  }

  getUserFullName(id: number) {
    return this.project.getUserById(id)?.getFullName();
  }

  onRecordNumberChange(pid: number): void {
    const index = this.recordList.findIndex(record => record.data.pid === pid);
    if (index !== -1) {
      this.previousRecordNumber = pid;
      this.$emit('pid-change', pid);
    } else {
      this.recordNumber = this.previousRecordNumber;
    }
  }

  onAddNewArticle(): void {
    this.articles.push(new ArticleModel({
      projectId: this.record.data.projectId,
      recordId: this.record.data.id,
    }));
    setTimeout(() => { // Required for tab animation
      this.tab = this.articles.length;
    }, 100);
  }

  onInsertTag(tag: TagModel) {
    this.$emit('insert-tag', tag);
  }

  onRemoveArticle(article: ArticleModel): void {
    const index = this.articles.findIndex(item => item === article);
    this.articles.splice(index, 1);
    if (this.tab > this.articles.length) {
      this.tab = this.articles.length;
    }
  }

  onHighlightKeyword(element: HTMLElement, word: KeywordModel | SynonymModel | string) {
    switch (this.highlightingStyle) {
      case 'background':
        element.style.fontWeight = '';
        if (word instanceof KeywordModel) {
          element.style.backgroundColor = word.data.color;
        } else if (word instanceof SynonymModel) {
          element.classList.add('grey--text');
          element.classList.add(word.getTextClass());
          element.style.backgroundColor = word.data.color;
        }
        break;
      case 'text':
        element.style.fontWeight = 'bold';
        if (word instanceof KeywordModel) {
          element.style.color = word.data.color;
        } else if (word instanceof SynonymModel) {
          element.style.color = word.data.color;
        }
        break;
    }
  }

  canSeeRecordField(key: string): boolean {
    return this.options.recordFields.length === 0 || this.options.recordFields.indexOf(key) !== -1;
  }

  setStatus(status: string) {
    this.quickStatusComponent.saveStatus({
      status,
      color: 'success',
      loading: true,
    })
  }

  toggleSidePanel() {
    this.showSidePanel = !this.showSidePanel;
  }

  editRecord(record: RecordModel) {
    this.tmpRecord = record.clone();
    this.tmpRecord.data.authors = (this.tmpRecord.data.authors || '').split(',').filter((item: any) => item).map((item: string) => new Model({ label: item.trim() }));
    this.tmpRecord.data.affiliations = (this.tmpRecord.data.affiliations || '').split(',').filter((item: any) => item).map((item: string) => new Model({ label: item.trim() }));
    this.tmpRecord.data.references = (this.tmpRecord.data.references || '').split(',').filter((item: any) => item).map((item: string) => new Model({ label: item.trim() }));
    this.tmpRecord.data.url = (this.tmpRecord.data.url || '').split(' ').filter((item: any) => item).map((item: string) => new Model({ label: item.trim() }));
    this.tmpRecord.setOriginalData();
    this.editing = true;
  }

  save(record: RecordModel) {
    this.loading = true;
    RecordService.getInstance().saveUserStatus(record, {
      params: {
        userType: this.userType,
      }
    })
      .then((response) => {
        this.record.assign(response.data.view.single);
      })
      .finally(() => this.loading = false);
  }

  loadArticles(record: RecordModel): Promise<ArticleModel> {
    this.loadingArticles = true;
    return ArticleService.getInstance().getAll({
      filters: [{
        field: 'recordId',
        value: record.data.id,
        operator: 'equals'
      }],
      order: 'id desc'
    })
      .then((response) => this.articles = response.data.view.list)
      .finally(() => this.loadingArticles = false);
  }

  get shouldSplitSection(): boolean {
    return this.options.splitSection;
  }

  get splitSections(): {
    title?: string,
    content: string
  }[] | string {
    const str = Utils.stripHtml(this.record.data.abstract);
    const regex = /(^|[\.|\;|\!|\?][\s|])([A-Z]\S+[\s]?([^\r\n\t\f\v :;.\?\!]+[\s]){0,4}(\S+|[0-9]+):)/gm;
    const splitItems = str.split(regex);
    const totalSections = Math.ceil((splitItems.length - 1) / 5);
    const items: {
      title?: string,
      content: string
    }[] = [];
    for (let i = 0; i < totalSections; i++) {
      const startIdx = (i * 5) + 2;
      items.push({
        title: (splitItems[startIdx] || '').trim().toUpperCase(),
        content: ((splitItems[startIdx + 3] || '')
          + (splitItems[startIdx + 4] || '')).trim(),
      });
    }
    if (items.length === 0) {
      return str;
    }
    return items;
  }

  addSpaces(str?: string) {
    if (!this.options.addSpaces) {
      return str;
    }
    if (typeof str !== 'string') {
      return str;
    }

    str = str.replace(/(?=\S)(\;)(?=\S)/gm, function(
      match: string,
      p1: string,
      offset: number,
      string: string
    ) {
      return p1 + ' ';
    });

    return str;
  }

  removeUseless(str?: string) {
    if (!this.options.removeUseless) {
      return str;
    }
    if (typeof str !== 'string') {
      return str;
    }

    str = str.replace(/([\s]{0,1}copyright[\s]{0,1}©[\s]{0,1}[0-9]+)/gmi, function(
      match: string,
      p1: string,
      offset: number,
      string: string
    ) {
      return '';
    });

    return str;
  }

  @Emit()
  saveStatusSuccess(nextPage = false) {
    this.loadProjectProgress();
    this.onAssignRecordClick();

    if (nextPage) {
      this.nextPage();
    }
  }

  @Emit()
  loadProjectProgress() {
  }

  @Emit()
  nextPage() {
  }

  reload(): Promise<RecordModel> {
    this.loading = true;
    return RecordService.getInstance().get({
      id: this.record.data.id,
    })
      .then((response) => {
        this.record.assign(response.data.view.single);
        this.loadRecordStatus();
        this.loadComments();
        return this.record;
      })
      .finally(() => this.loading = false);
  }

  loadRecordStatus() {
    this.statusComponent.loadRecordStatus();
  }

  loadComments() {
    this.commentsComponent.loadCommentList();
  }

  loadHighlightingStyle(): void {
    const style = localStorage.getItem('review_highlighting_style');
    if (style) {
      this.highlightingStyle = style;
    }
  }

  created(): void {
    this.loadHighlightingStyle();
    this.loadArticles(this.record);
    this.rules = {
      required: (value: string) => Rules.required(value) || this.$t('rules.required').toString(),
      url: (value: string) => Rules.isUrl(value) || this.$t('rules.url').toString(),
      numeric: (value: string|number) => Rules.digit(value) || this.$t('rules.digit').toString(),
    };

    this.shortcuts.forEach((shortcut: any) => {
      this.keymap[shortcut.key] = () => shortcut.callback();
    });
  }
}
</script>

<style lang="scss" scoped>
.side-panel {

  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.3);
    }
  }
}
.v-application.theme--dark .side-panel {
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.1);
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.3);
    }
  }
}
</style>
