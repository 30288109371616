<template>
  <ModalDialog
    v-model="visible"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #header>
      <v-tabs v-if="tabList.length > 1" v-model="tabIdx" grow>
        <v-tab v-for="tab in tabList" :key="tab.value">
          <span>{{ tab.text }}</span>
        </v-tab>
      </v-tabs>
    </template>
    <template #body>
      <v-tabs-items v-model="tabIdx">

        <!-- PROMPT -->
        <v-tab-item>
          <v-form v-model="formIsValid" :class="{ 'mt-6': tabList.length > 1 }">

            <!-- ERRORS -->
            <v-alert
              v-if="errors.length > 0"
              type="error"
              colored-border
              text
            >
              <h5>An issue occurred</h5>
              <ul class="mt-2">
                <li
                  v-for="error in errors"
                  v-text="error.message.content"
                  :key="error.index"
                ></li>
              </ul>
            </v-alert>

            <v-row>

              <!-- SETTINGS -->
              <v-col style="flex: 1">

                <!-- RANGE -->
                <div v-if="canSeeTasks" class="mb-4">

                  <h3 class="mb-4">Record range:</h3>
                  <v-range-slider
                    v-model="config.range"
                    :min="1"
                    :max="maxRecordRange"
                    :step="1"
                    thumb-label
                    hide-details="auto"
                    thumb-color="primary"
                    class="align-center"
                  >
                    <template #prepend>
                      <v-text-field
                        v-safechar
                        :value="config?.range[0]"
                        :disabled="loadingRecordRange"
                        type="number"
                        label="Start"
                        step="1"
                        outlined
                        dense
                        hide-details="auto"
                        style="max-width: 10rem"
                        @change="$set(config?.range, 0, $event)"
                      />
                    </template>
                    <template #append>
                      <v-text-field
                        v-safechar
                        :value="config?.range[1]"
                        :loading="loadingRecordRange"
                        :disabled="loadingRecordRange"
                        type="number"
                        label="End"
                        step="1"
                        outlined
                        dense
                        hide-details="auto"
                        style="max-width: 10rem"
                        @change="$set(config?.range, 1, $event)"
                      />
                    </template>
                  </v-range-slider>
                  <p class="caption text--disabled">The query will be executed within the range of records selected above.</p>

                  <v-divider class="mt-4" />
                </div>

                <!-- SETTINGS -->
                <template>
                  <h3 class="mb-6">Settings:</h3>

                  <!-- GPT MODEL -->
                  <v-autocomplete
                    v-model="config.gptModel"
                    :items="gptModelList"
                    :menu-props="{
                      maxWidth: 600,
                      maxHeight: 400,
                    }"
                    label="GPT Model"
                    hide-details="auto"
                    outlined
                  >
                    <template #item="{ item }">
                      <div class="d-flex align-center py-3" style="gap: 1rem">
                        <v-icon color="primary" large left>mdi-robot</v-icon>
                        <div>
                          <strong>{{ item.text }}</strong>
                          <br><span>{{ item.description }}</span>
                        </div>
                      </div>
                    </template>
                  </v-autocomplete>

                  <!-- SYSTEM PROMPT -->
                  <v-select
                    v-model="aiPrompt"
                    ref="aiPromptSelect"
                    :rules="[rules.required]"
                    :items="aiPromptList"
                    :loading="loadingAiPrompt"
                    label="System prompt:"
                    item-text="data.title"
                    item-value="data.id"
                    outlined
                    hide-details="auto"
                    clearable
                    required
                    return-object
                    class="mt-4"
                    @change="onAiPromptChange"
                  >
                    <template #selection="{ item }">
                      <v-icon v-if="item.data.isDefault" color="primary" left>mdi-check-circle-outline</v-icon>
                      <v-icon v-else left>mdi-circle-outline</v-icon>
                      <span>{{ item.data.title }}</span>
                    </template>
                    <template #item="{ item }">
                      <v-icon v-if="item.data.isDefault" color="primary" left>mdi-check-circle-outline</v-icon>
                      <v-icon v-else left>mdi-circle-outline</v-icon>
                      <span>{{ item.data.title }}</span>
                    </template>
                    <template #append>
                      <v-tooltip v-if="aiPrompt" bottom>
                        <template #activator="{ attrs, on }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="ma-0 pa-0"
                            style="margin-top: -5px !important"
                            icon
                            @click="onAiPromptEditClick"
                          >
                            <v-icon v-if="aiPromptEdit">mdi-eye-off-outline</v-icon>
                            <v-icon v-else>mdi-eye-outline</v-icon>
                          </v-btn>
                        </template>
                        <span><span v-if="!aiPromptEdit">Show</span><span v-else>Hide</span> prompt</span>
                      </v-tooltip>
                    </template>
                  </v-select>
                  <v-expand-transition>
                    <v-textarea
                      v-show="aiPromptEdit"
                      v-if="aiPrompt && !loadingAiPrompt"
                      v-model="aiPromptSystemContent"
                      :loading="loadingAiPrompt"
                      height="15rem"
                      no-resize
                      filled
                      outlined
                      hide-details="auto"
                    />
                  </v-expand-transition>

                  <!-- ADDITIONAL INFO? -->
                  <v-textarea
                    v-model="aiPromptUserContent"
                    :disabled="asking"
                    label="Prompt"
                    placeholder="This is optional.."
                    no-resize
                    height="12rem"
                    outlined
                    class="mt-4"
                    hide-details="auto"
                  />

                  <v-divider class="mt-4 my-1" />

                  <!-- ADVANCED OPTIONS -->
                  <v-expansion-panels v-model="advancedOptionOpened" flat tile>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="px-0">Advanced options</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row class="mx-n6" no-gutters>
                          <v-col cols="12">
                            <v-slider
                              v-model="config.temperature"
                              :min="0"
                              :max="2"
                              :step="0.1"
                              class="align-center"
                              label="Temperature"
                              thumb-label
                              hide-details="auto"
                              thumb-color="primary"
                            >
                              <template #prepend>
                                <v-tooltip right max-width="250">
                                  <template #activator="{ on, attrs }">
                                    <v-icon color="primary" v-bind="attrs" v-on="on">
                                      mdi-information-outline
                                    </v-icon>
                                  </template>
                                  <span>What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic. OpenAI models are non-deterministic, meaning that identical inputs can yield different outputs. Setting temperature to 0 will make the outputs mostly deterministic, but a small amount of variability may remain.</span>
                                </v-tooltip>
                              </template>
                              <template #append>
                                <v-text-field
                                  v-model.number="config.temperature"
                                  type="number"
                                  step="0.1"
                                  outlined
                                  dense
                                  hide-details="auto"
                                  style="max-width: 6rem"
                                />
                              </template>
                            </v-slider>
                          </v-col>
                          <v-col cols="12">
                            <v-slider
                              v-model="config.frequencyPenalty"
                              :min="-2"
                              :max="2"
                              :step="0.1"
                              class="align-center"
                              label="Frequency Penalty"
                              thumb-label
                              hide-details="auto"
                              thumb-color="primary"
                            >
                              <template #prepend>
                                <v-tooltip right max-width="250">
                                  <template #activator="{ on, attrs }">
                                    <v-icon color="primary" v-bind="attrs" v-on="on">
                                      mdi-information-outline
                                    </v-icon>
                                  </template>
                                  <span>Number between -2.0 and 2.0. Positive values penalize new tokens based on whether they appear in the text so far, increasing the model's likelihood to talk about new topics.</span>
                                </v-tooltip>
                              </template>
                              <template #append>
                                <v-text-field
                                  v-model.number="config.frequencyPenalty"
                                  type="number"
                                  step="0.1"
                                  outlined
                                  dense
                                  hide-details="auto"
                                  style="max-width: 6rem"
                                />
                              </template>
                            </v-slider>
                          </v-col>
                          <v-col cols="12">
                            <v-slider
                              v-model="config.presencePenalty"
                              :min="-2"
                              :max="2"
                              :step="0.1"
                              class="align-center"
                              label="Presence Penalty"
                              thumb-label
                              hide-details="auto"
                              thumb-color="primary"
                            >
                              <template #prepend>
                                <v-tooltip right max-width="250">
                                  <template #activator="{ on, attrs }">
                                    <v-icon color="primary" v-bind="attrs" v-on="on">
                                      mdi-information-outline
                                    </v-icon>
                                  </template>
                                  <span>Number between -2.0 and 2.0. Positive values penalize new tokens based on their existing frequency in the text so far, decreasing the model's likelihood to repeat the same line verbatim.</span>
                                </v-tooltip>
                              </template>
                              <template #append>
                                <v-text-field
                                  v-model.number="config.presencePenalty"
                                  type="number"
                                  step="0.1"
                                  outlined
                                  dense
                                  hide-details="auto"
                                  style="max-width: 6rem"
                                />
                              </template>
                            </v-slider>
                          </v-col>
                          <v-col cols="12">
                            <v-slider
                              v-model="config.topP"
                              :min="0"
                              :max="1"
                              :step="0.1"
                              class="align-center"
                              label="Top P"
                              thumb-label
                              hide-details="auto"
                              thumb-color="primary"
                            >
                              <template #prepend>
                                <v-tooltip right max-width="250">
                                  <template #activator="{ on, attrs }">
                                    <v-icon color="primary" v-bind="attrs" v-on="on">
                                      mdi-information-outline
                                    </v-icon>
                                  </template>
                                  <span>An alternative to sampling with temperature, called nucleus sampling, where the model considers the results of the tokens with top_p probability mass. So 0.1 means only the tokens comprising the top 10% probability mass are considered.</span>
                                </v-tooltip>
                              </template>
                              <template #append>
                                <v-text-field
                                  v-model.number="config.topP"
                                  type="number"
                                  step="0.1"
                                  outlined
                                  dense
                                  hide-details="auto"
                                  style="max-width: 6rem"
                                />
                              </template>
                            </v-slider>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-col>

              <!-- SEPARATOR -->
              <v-col style="flex: 0" class="mx-3 text-center">
                <v-divider vertical />
              </v-col>

              <!-- DATA -->
              <v-col style="flex: 1" class="d-flex flex-column">

                <!-- PROJECT DATA -->
                <div class="d-flex flex-column" style="flex: 0">
                  <h3 class="mb-6" style="flex: 0">Include the following project data:</h3>
                  <div class="d-flex align-center" style="gap: 2rem">
                    <v-switch
                      v-model="config.selectedData.objectives"
                      label="Objectives"
                      inset
                      class="ma-0"
                    />
                    <v-switch
                      v-model="config.selectedData.picots"
                      label="PICOTS"
                      inset
                      class="ma-0"
                    />
                    <v-switch
                      v-model="config.selectedData.criteria"
                      label="Selection criteria"
                      inset
                      class="ma-0"
                    />
                  </div>
                </div>

                <!-- ARTICLES DATA -->
                <div class="d-flex flex-column mt-4" style="flex: 0">
                  <h3 class="mb-6" style="flex: 0">Include the following article(s) data:</h3>
                  <div class="d-flex align-center" style="gap: 2rem">
                    <v-switch
                      v-model="config.selectedData.abstract"
                      label="Abstract"
                      inset
                      class="ma-0"
                    />
                    <v-switch
                      v-model="config.selectedData.fullText"
                      label="Full-text"
                      inset
                      class="ma-0"
                    />
                  </div>
                </div>

                <!-- SURVEY DATA -->
                <div v-if="survey?.toTreeviewItems().length > 0" class="d-flex flex-column mt-4" style="flex: 1">
                  <h3 class="mb-6" style="flex: 0">Include the following survey data:</h3>
                  <v-sheet color="backgroundVeryLight" class="pa-4" rounded style="flex: 1">
                    <v-treeview
                      v-model="selection"
                      :items="survey.toTreeviewItems()"
                      selected-color="primary"
                      selection-type="leaf"
                      selectable
                      open-all
                    />
                  </v-sheet>
                </div>

                <!-- MORE QUESTIONS -->
                <div v-if="mode === 'data-extraction'" class="d-flex flex-column mt-4" style="flex: 0">
                  <h3 class="mb-6" style="flex: 0">Include the following additional questions:</h3>
                  <ListBuilder
                    v-model="customQuestions"
                    :default-item="null"
                    group="question_to_ai_custom_questions"
                  >
                    <template #item="question">
                      <v-text-field
                        v-model="customQuestions[question.index]"
                        outlined
                        dense
                        placeholder="Type your question here..."
                        hide-details="auto"
                        clearable
                      />
                    </template>
                  </ListBuilder>
                </div>

              </v-col>
            </v-row>
          </v-form>
        </v-tab-item>

        <!-- TASKS -->
        <v-tab-item v-if="canSeeTasks">
          <v-data-table
            v-model="selectedTasks"
            :headers="taskHeaders"
            :items="tasks"
            :loading="loadingTasks"
            :sort-by="['data.createdAt']"
            :sort-desc="[true]"
            fixed-header
            show-select
            single-select
            item-key="data.id"
            checkbox-color="primary"
            class="mt-3"
          >
            <template #item.data.action="{ item }">
              {{ getTaskActionAttrs(item).text }}
            </template>
            <template #item.data.stage="{ item }">
              <v-chip v-bind="getTaskStageAttrs(item)" label small>
                <v-icon left small>{{ getTaskStageAttrs(item).icon }}</v-icon>
                <span v-text="getTaskStageAttrs(item).text"></span>
              </v-chip>
            </template>
            <template #item.data.status="{ item }">
              <v-chip v-bind="getTaskStatusAttrs(item)" label small>
                <v-icon left small>{{ getTaskStatusAttrs(item).icon }}</v-icon>
                <span v-text="getTaskStatusAttrs(item).text"></span>
              </v-chip>
            </template>
            <template #item.data.result="{ item }">
              <v-chip v-if="!item.data.result" label small>Empty</v-chip>
              <span v-else>{{ item.data.result }}</span>
            </template>
            <template #item.data.count="{ item }">
              <v-chip
                :color="item.data.count > 0 ? 'success' : null"
                label
                small
              >{{ item.data.count }}</v-chip>
            </template>
            <template #item.data.failedCount="{ item }">
              <v-chip
                :color="item.data.failedCount > 0 ? 'error' : null"
                label
                small
              >{{ item.data.failedCount }}</v-chip>
            </template>
            <template #item.__action="{ item, index }">
              <v-tooltip bottom>
                <template #activator="{ attrs, on }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :disabled="item.states.deleting"
                    :loading="item.states.deleting"
                    icon
                    color="error"
                    @click="onDeleteTask(item, index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete task</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-tab-item>

        <!-- HISTORY -->
        <v-tab-item v-if="canSeeHistory">
          <v-data-table
            v-model="selectedHistory"
            :headers="historyHeaders"
            :items="history"
            :sort-by="['data.createdAt']"
            :sort-desc="[true]"
            :loading="loadingHistory"
            show-select
            single-select
            item-key="data.id"
            checkbox-color="primary"
            class="mt-3"
          >
            <template #item.data.status="{ item }">
              <v-chip v-bind="getStatusAttrs(item)" label v-text="getStatusAttrs(item).text"></v-chip>
            </template>
            <template #item.data.comment="{ item }">
              <div
                v-text="item.data.comment"
                style="max-height: 5rem"
                class="overflow-auto"
              />
            </template>
            <template #item.data.question="{ item }">
              <div
                v-text="item.data.question"
                style="max-height: 5rem"
                class="overflow-auto my-4"
              />
            </template>
            <template #item.data.answer="{ item }">
              <div
                v-text="item.data.answer"
                style="max-height: 5rem"
                class="overflow-auto"
              />
            </template>
            <template #item.data.confidence="{ item }">
              {{ item.data.confidence }}%
            </template>
            <template #item.data.rating="{ item }">
              <v-rating
                :value="parseInt(item.data.rating)"
                color="warning"
                hover
                background-color="warning"
                large
                @input="onRatingChange(item)"
              ></v-rating>
            </template>
          </v-data-table>
        </v-tab-item>

      </v-tabs-items>
    </template>
    <template #prepend_actions>
      <v-btn
        v-if="currentTab.value === 'prompt'"
        large
        outlined
        :disabled="!canReset"
        @click="onResetClick"
      >
        <span>Reset</span>
      </v-btn>
    </template>
    <template #buttons>
      <v-btn
        v-if="currentTab.value === 'prompt'"
        large
        color="primary"
        :loading="asking"
        :disabled="!canAsk"
        @click="onAskClick"
      >
        <span v-text="$t('btn.askAi')"></span>
      </v-btn>
      <v-btn
        v-if="['tasks', 'history'].includes(currentTab.value)"
        large
        color="primary"
        :disabled="!canApply"
        @click="onApplyClick"
      >
        <span v-text="$t('btn.apply')"></span>
      </v-btn>
      <v-btn
        large
        text
        :disabled="asking"
        @click="onCancelClick"
      >
        <span v-text="$t('btn.cancel')"></span>
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
// @ts-ignore
import { VSelect } from 'vuetify/lib/components/VSelect'
import { Vue, Component, VModel, Prop, Ref, Watch } from 'vue-property-decorator';
import { IValue, gptModelList, stageList, statusList, taskActionList, taskStatusList } from '@/enums/global';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import RecordModel from '@/models/record.model';
import ProjectModel from '@/models/project.model';
import SurveyModel from '@/modules/sdk/models/survey.model';
import Rules from '@/modules/sdk/core/rules';
import Identity from '@/modules/sdk/core/identity';
import AiPromptModel from '@/models/ai-prompt.model';
import SurveyAiAnswerModel from '@/models/survey-ai-answer.model';
import SurveyAiAnswerService from '@/services/survey-ai-answer.service';
import TaskModel from '@/models/task.model';
import TaskService from '@/services/task.service';
import AiPromptService from '@/services/ai-prompt.service';
import RecordService from '@/services/record.service';
import RecordAiStatusModel from '@/models/record-ai-status.model';
import AiService from '@/services/ai.service';

let pullInterval: any;

export interface ISelectedData {
  objectives: boolean,
  picots: boolean,
  criteria: boolean,
  abstract: boolean,
  fullText: boolean,
}

export interface IConfig {
  selectedData: ISelectedData,
  gptModel: string,
  temperature: number,
  range?: Array<number>,
  frequencyPenalty: number,
  presencePenalty: number,
  topP: number,
  aiQuestionIds: Array<number>
  customQuestions: Array<string>,
}

@Component({
  components: {
    ModalDialog,
  }
})
export default class AskQuestionToAiModal extends Vue {
  @Ref() readonly aiPromptSelect!: VSelect

  @VModel({ type: Boolean }) visible!: boolean
  @Prop({ type: String, default: () => ([]) }) selection!: string[]
  @Prop({ type: String, default: null }) text!: string | null
  @Prop({ type: String, default: 'review' }) mode!: 'review' | 'data-extraction'
  @Prop({ type: Boolean, default: false }) bulk!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: Boolean, default: false }) canSeeTasks!: boolean
  @Prop({ type: Object, default: () => ({}) }) defaultSelectedData!: ISelectedData | null
  @Prop({ type: SurveyModel }) survey!: SurveyModel
  @Prop({ type: RecordModel }) record!: RecordModel
  @Prop({ type: ProjectModel }) project!: ProjectModel
  @Prop({ type: Array, default: () => ([]) }) historyHeaders!: any[]
  @Prop({ type: Function, default: () => new Promise(() => {}) }) historyService!: () => Promise<any>
  @Prop({ type: String }) stage!: 'screening' | 'indepth' | 'final' | string

  @Watch('visible', { immediate: true })
  onVisibleChanged(visible: boolean) {
    if (visible) {
      this.tabIdx = 0;
      this.selectedTasks = [];
      this.selectedHistory = [];
      this.asking = false;
      this.errors = [];

      if (this.defaultSelectedData) {
        this.config.selectedData = this.defaultSelectedData;
      }

      this.defaultConfig = structuredClone(this.config);

      this.loadServerPrompt();
      this.loadUserPrompt();

      if (this.project) {
        this.countRecordRange();
      }
    }
  }

  @Watch('tabIdx', { immediate: true })
  onTabIdxChanged() {
    if (this.currentTab.value === 'history') {
      this.loadHistory();
    }
    else if (this.currentTab.value === 'tasks') {
      this.loadTasks();
    }
  }

  rules: any = {}
  errors: any[] = []
  loadingTasks = false
  loadingHistory = false
  asking = false
  formIsValid = false
  advancedOptionOpened = null
  gptModelList = gptModelList
  loadingAiPrompt = false
  loadingRecordRange = false
  maxRecordRange = 1
  memoryIsGlobal = true
  history: Array<any> = []
  tasks: Array<any> = []
  selectedHistory: Array<any> = []
  selectedTasks: Array<any> = []
  customQuestions: Array<string> = []

  tabIdx = 0
  tabList: IValue[] = [
    { text: 'Prompt', value: 'prompt' },
  ]

  defaultConfig: Partial<IConfig> = {}
  config: IConfig = {
    selectedData: {
      objectives: true,
      picots: true,
      criteria: true,
      abstract: true,
      fullText: true,
    },
    aiQuestionIds: [],
    customQuestions: [],
    gptModel: 'gpt-4o-mini',
    temperature: 1,
    range: [0, 100],
    frequencyPenalty: 0,
    presencePenalty: 0,
    topP: 1,
  }

  aiPromptList: Array<AiPromptModel> = []
  aiPrompt: AiPromptModel|null = null;
  aiPromptSystemContent = '';
  aiPromptUserContent = '';
  aiPromptEdit = false;

  taskHeaders: Array<any> = [
    {
      class: 'text-no-wrap',
      cellClass: 'text-no-wrap',
      width: 0,
      text: 'Date',
      value: 'data.createdAt',
    }, {
      class: 'text-no-wrap',
      text: 'Action',
      value: 'data.action',
      width: '15%',
    }, {
      class: 'text-no-wrap',
      text: 'Stage',
      value: 'data.stage',
      width: 0,
    }, {
      class: 'text-no-wrap',
      text: 'Status',
      value: 'data.status',
      width: 0,
    }, {
      class: 'text-no-wrap',
      width: 0,
      text: 'Success',
      value: 'data.count',
    }, {
      class: 'text-no-wrap',
      width: 0,
      text: 'Failed',
      value: 'data.failedCount',
    }, {
      class: 'text-no-wrap',
      width: 0,
      text: '',
      value: '__action',
    }
  ];

  get currentTab(): IValue {
    return this.tabList[this.tabIdx];
  }

  get canAsk(): boolean {
    const hasRoleAi = Identity.hasRole(['dev', 'admin']) || (this.project && this.project.hasRole(['ai-manager']));
    return !this.asking
      && this.formIsValid
      && this.currentTab.value === 'prompt'
      && hasRoleAi;
  }

  get canApply(): boolean {
    return !this.disabled && ((
      this.currentTab.value === 'history'
      && this.selectedHistory.length > 0
    ) || (
      this.currentTab.value === 'tasks'
      && this.selectedTasks.length > 0
    ))
  }

  get canReset(): boolean {
    return this.currentTab.value === 'prompt' && (
      JSON.stringify(this.config) !== JSON.stringify(this.defaultConfig)
      // && (this.aiPromptUserContent || '').length === 0
    );
  }

  get canSeeHistory(): boolean {
    return !this.project;
  }

  getActionFromMode(mode: string = this.mode) {
    switch (mode) {
      case 'review':
        return 'ai_project_review';
      case 'data-extraction':
        return 'ai_project_data_extraction'
    }
    return 'unknown'
  }

  getPrompActionFromMode(mode: string = this.mode) {
    switch (mode) {
      case 'review':
        return 'record_review';
      case 'data-extraction':
        return 'record_answer'
    }
    return 'default'
  }

  getTaskActionAttrs(item: any): any {
    const found = taskActionList.find(entry => entry.value === item.data.action);
    if (found) {
      return found;
    }
    return {};
  }

  getTaskStatusAttrs(item: any): any {
    const found = taskStatusList.find(entry => entry.value === item.data.status);
    if (found) {
      return found;
    }
    return {};
  }

  getTaskStageAttrs(item: any): any {
    const found = stageList.find(entry => entry.value === item.data.stage);
    if (found) {
      return found;
    }
    return {};
  }

  getStatusAttrs(item: RecordAiStatusModel): any {
    const found = statusList.find(entry => entry.value === item.data.status);
    if (found) {
      return found;
    }
    return {};
  }

  onDeleteTask(task: TaskModel, index: number): void {
    this.$root.$shouldTakeAction.askDelete({
      onAccept: () => {
        task.states.deleting = true;
        TaskService.getInstance().delete({
          id: task.data.id,
        })
          .then(() => {
            this.$root.$globalSnack.success({
              message: 'Task deleted successfully!'
            })
            this.tasks.splice(index, 1);
          })
          .finally(() => task.states.deleting = false);
      }
    })
  }

  onAiPromptEditClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.aiPromptEdit = !this.aiPromptEdit;
    this.aiPromptSelect.isMenuActive = false;
  }

  onAiPromptChange() {
    this.aiPromptSystemContent = this.aiPrompt?.data.content || '';
  }

  onRatingChange(answer: SurveyAiAnswerModel): void {
    answer.states.saving = true;
    SurveyAiAnswerService.getInstance().save(answer)
      .then(response => answer.assign(response.data.view.single))
      .finally(() => answer.states.saving = false);
  }

  onCancelClick(): void {
    this.visible = false;
  }

  onResetClick(): void {
    this.loadUserPrompt();
    this.config = JSON.parse(JSON.stringify(this.defaultConfig));
  }

  onAskClick(): void {
    this.saveUserPrompt();
    if (this.bulk) this.createTask()
    else if (this.mode === 'data-extraction') this.askDataExtraction()
    else if (this.mode === 'review') this.askReview()
  }

  onApplyClick(): void {
    this.visible = false;
    if (this.currentTab.value === 'tasks' && this.selectedTasks.length > 0) {
      this.$emit('apply', this.selectedTasks[0]);
    } else if (this.currentTab.value === 'history' && this.selectedHistory.length > 0) {
      this.$emit('apply', this.selectedHistory[0]);
    }
  }

  prepareData(mode = this.mode, bulk = this.bulk) {
    const projectId = this.record?.data.projectId || this.project?.data.id;
    const recordId = this.record?.data.id;
    const stage = this.stage;
    const action = this.getActionFromMode(mode);
    const aiPromptId = this.aiPrompt?.data.id;
    const aiPromptSystemContent = this.aiPromptSystemContent;
    const aiPromptUserContent = this.aiPromptUserContent;

    const data = {
      recordId,
      projectId,
      stage,
      action,
      aiPromptId,
      aiPromptSystemContent,
      aiPromptUserContent,
      ...structuredClone(this.config),
    };

    if (mode === 'data-extraction') {
      data.customQuestions = structuredClone(this.customQuestions);
      data.aiQuestionIds = this.selection.filter(selection => !isNaN(parseInt(selection))).map(item => parseInt(item));
    }

    if (!bulk) delete data.range;

    return data;
  }

  askDataExtraction() {
    this.asking = true;
    const data = this.prepareData('data-extraction', false)
    AiService.getInstance().getRecordAnswers(data).then(response => {
      this.asking = false;
      if (response.data.view.list) {
        this.errors = [];
        this.tabIdx = this.tabList.findIndex(tab => tab.value === 'history');
        const answers = response.data.view.list.map((item: any) => new SurveyAiAnswerModel(item.data.entity));
        this.history.push(...answers);
        this.$emit('answer', answers);
      } else {
        this.errors = JSON.parse(response.data.view.response.entity.choices);
      }
    });
  }

  askReview() {
    this.asking = true;
    const data = this.prepareData('review', false)
    AiService.getInstance().getRecordStatus(data)
      .then(response => {
        if (response.data.view.list && Array.isArray(response.data.view.list)) {
          this.errors = [];
          this.tabIdx = this.tabList.findIndex(tab => tab.value === 'history');
          const answers = response.data.view.list.map((item: any) => new RecordAiStatusModel(item.data.entity));
          this.history.push(...answers);
          this.$emit('answer', answers);
        } else {
          this.errors = JSON.parse(response.data.view.response.entity.choices);
        }
      })
      .catch(reason => {
        this.$root.$globalSnack.error({
          message: reason.response.data.view?.chat?.error || 'An unexpected error has occurred during the AI request or response.',
          icon: 'mdi-emoticon-dead-outline'
        });
      })
      .finally(() => this.asking = false)
  }

  createTask() {
    this.asking = true;

    const data = this.prepareData(this.mode, true)
    const model = new TaskModel(data);
    model.data.meta = data;

    TaskService.getInstance().save(model)
      .then(response => {
        model.assign(response.data.view.single);
        this.tabIdx = this.tabList.findIndex(tab => tab.value === 'tasks');
        this.loadTasks();
        pullInterval = setInterval(() => {
          this.pullTaskStatus(model);
          if (this.asking) {
            clearInterval(pullInterval);
          }
        }, 1000);
      })
      .catch(reason => {
        this.asking = false;
        return this.$root.$zemit.handleError(reason);
      })
  }

  pullTaskStatus(model: TaskModel): Promise<any> {
    return TaskService.getInstance().get(model).then(response => {
      model.assign(response.data.view.single)
      if (model.data.status !== 'running') {
        this.asking = false;
      } else {
        this.loadTasks();
      }
      return model;
    })
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  getLocaleStorageKeyFromMode(mode = this.mode, bulk = this.bulk) {
    const suffix = bulk? '_project_' + this.project.data.id : '_record_' + this.record.data.id
    return 'ai_user_prompt_' + mode + suffix
  }

  loadUserPrompt(mode = this.mode, bulk = this.bulk): void {
    if (this.text) {
      this.aiPromptUserContent = this.text
    }
    else if (this.memoryIsGlobal) {
      this.aiPromptUserContent = localStorage.getItem('ai_user_prompt_' + mode) || ''
    }
    else {
      this.aiPromptUserContent = this.getLocaleStorageKeyFromMode(mode, bulk)
    }
  }

  saveUserPrompt(mode = this.mode, bulk = this.bulk): void {
    if (this.memoryIsGlobal) {
      localStorage.setItem('ai_user_prompt_' + mode, this.aiPromptUserContent || '')
    }
    else {
      localStorage.setItem(this.getLocaleStorageKeyFromMode(mode, bulk), this.aiPromptUserContent || '')
    }
  }

  loadTasks(): Promise<any> {
    this.loadingTasks = true;
    return TaskService.getInstance().getAll({
      filters: [{
        field: 'projectId',
        operator: 'equals',
        value: this.project.data.id,
      }]
    })
      .then(response => this.tasks = response.data.view.list)
      .finally(() => this.loadingTasks = false);
  }

  loadHistory(): Promise<any> {
    this.loadingHistory = true;
    return this.historyService()
      .then(response => this.history = response.data.view.list)
      .finally(() => this.loadingHistory = false);
  }

  countRecordRange(): Promise<any> {
    this.loadingRecordRange = true;
    return RecordService.getInstance().count({
      filters: [{
        field: 'projectId',
        operator: 'equals',
        value: this.project.data.id,
      }]
    })
      .then(response => {
        this.maxRecordRange = response.data.view.count;
        this.config.range = [1, this.maxRecordRange];
        this.defaultConfig.range = [1, this.maxRecordRange];
      })
      .finally(() => this.loadingRecordRange = false);
  }

  loadServerPrompt(mode = this.mode): Promise<any> {
    const action = this.getPrompActionFromMode(mode)
    this.loadingAiPrompt = true;
    this.aiPromptList = [];
    this.aiPrompt = null;
    return AiPromptService.getInstance().getAll({
      filters: [{
        field: 'action',
        operator: 'equals',
        value: action,
      }, [{ // AND
          field: 'projectType',
          operator: 'is null',
        }, { // OR
          field: 'projectType',
          operator: 'equals',
          value: this.project.data.type,
        }]
      ],
      order: 'isDefault DESC, title ASC',
    })
      .then(response => {
        this.aiPromptList = response.data.view.list
        if (this.aiPromptList[0]) {
          this.aiPrompt = this.aiPromptList[0];
          this.aiPromptSystemContent = this.aiPrompt.data.content;
        }
      })
      .finally(() => this.loadingAiPrompt = false);
  }

  created() {
    if (this.canSeeTasks) {
      this.tabList.push({ text: 'Tasks', value: 'tasks' });
    }
    if (this.canSeeHistory) {
      this.tabList.push({ text: 'History', value: 'history' });
    }

    this.rules = {
      required: (value: string) => Rules.required(value) || this.$t('rules.required'),
    };
  }

  destroyed() {
    clearInterval(pullInterval);
  }
}
</script>
