import Model from '@/modules/sdk/core/model';

export default class AiPromptModel extends Model {

  getActionLabel() {
    return this.data.action.replace('_', ' ');
  }

  columnCast() {
    return {
      isDefault: 'bool',
    };
  }

}
