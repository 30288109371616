import Model from '@/modules/sdk/core/model';
import UserModel from '@/modules/sdk/models/user.model';

export default class ErrorLogModel extends Model {

  relatedMap () {
    return {
      userentity: UserModel,
    }
  }
}
