<template>
  <component
    v-if="mode === 'answer' && model.data.includeLabel && model.data.label"
    v-bind="$attrs"
    :is="headerIs"
  >
    <div class="w-100 d-flex align-start justify-space-between" style="gap: 1rem">
      <h5 v-bind="viewAttrs">
        <v-icon v-if="icon" large class="ml-n2">{{ icon }}</v-icon>
        <span v-if="model.data.required" class="error--text">*</span>
        {{ model.data.label }}
        <span v-if="model.data.required === false" class="text--disabled">(optional)</span>
        <span v-else-if="model.data.required === true" class="font-weight-thin text--disabled">(required)</span>
      </h5>
      <slot :name="(slotPrefix && slotPrefix + '.') + 'header.append'" :model="model"></slot>
    </div>
    <v-chip v-if="debug" label small color="warning">{{ model.data.hash }}</v-chip>
    <p v-if="model.data.description" class="caption mb-0">{{ model.data.description }}</p>
  </component>
  <v-sheet
    v-else-if="mode === 'edit'"
    v-bind="$attrs"
    class="w-100"
    color="transparent"
  >
    <v-card-title v-bind="cardTitleAttrs" class="w-100">
      <div class="d-flex align-center" style="flex: 1">
        <v-checkbox
          v-model="model.data.includeLabel"
          class="pa-0 ma-0 mr-3"
          hide-details
        />
        <div class="d-flex align-start" style="flex: 1">

          <!-- TITLE -->
          <component
            v-bind="titleAttrs"
            v-model.lazy="model.data.label"
            :is="component"
            :rules="model.data.includeLabel ? [rules.required] : []"
            :readonly="readonly"
            :persistent-hint="!model.data.includeLabel"
            :hint="!model.data.includeLabel ? hint : null"
            :label="label"
            class="mt-0 pt-0"
            error-message="Title required"
            hide-details="auto"
            clearable
          >
            <template v-if="model.data.includeLabel" #label>
              <span class="error--text">* </span>
              <span>{{ label }}</span>
            </template>
            <template #append>
              <div class="d-flex align-center">
                <v-chip v-if="debug" label small color="warning" class="mr-1">{{ model.data.hash }}</v-chip>
                <v-chip v-if="model.data.includeUnknown" small label class="mr-1">
                  Can be unknown
                </v-chip>
                <v-chip v-if="model.data.action !== null" small label class="mr-1">
                  <span v-if="model.isHiddenByDefault()">Hidden</span>
                  <span v-else-if="model.isVisibleByDefault()">Visible</span>
                  <span v-else-if="model.canEndSurvey()">Can end of survey</span>
                </v-chip>
              </div>
            </template>
          </component>
        </div>
      </div>
      <div class="text-center d-flex align-center justify-center" style="flex: 0">
        <div class="d-flex align-center justify-center pl-6" style="gap: 0.5rem">
          <slot name="options.prepend"></slot>
          <SurveyRulesDialogActivator
            v-model="model"
            :survey="survey"
            :extra-fields="extraFields"
          />
          <SurveyItemOptionsMenu
            v-model="model"
            :list="list"
            :index="index"
            :include-color="includeColor"
            @force-update="() => $forceUpdate()"
          >
            <template v-if="$scopedSlots['items.options.append']" #items.append>
              <slot name="items.options.append" :model="model"></slot>
            </template>
          </SurveyItemOptionsMenu>
          <slot name="options.append"></slot>
        </div>
      </div>
    </v-card-title>
  </v-sheet>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import SurveyRulesDialogActivator from '@/modules/common/components/SurveyRulesDialogActivator.vue';
import SurveyItemOptionsMenu from '@/modules/common/components/SurveyItemOptionsMenu.vue';
import Model from '@/modules/sdk/core/model';
import SurveyItemModel from '@/modules/sdk/models/survey-item.model';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';
import SurveySectionModel from '@/modules/sdk/models/survey-section.model';
import SurveyGroupModel from '@/modules/sdk/models/survey-group.model';
import SurveyChoiceModel from '@/modules/sdk/models/survey-choice.model';
import SurveyItemMixin from '@/modules/common/mixins/survey';

type IType = SurveySectionModel | SurveyGroupModel | SurveyQuestionModel | SurveyChoiceModel;

@Component({
  components: {
    SurveyRulesDialogActivator,
    SurveyItemOptionsMenu,
  }
})
export default class SurveyHeader extends SurveyItemMixin<SurveyItemModel, SurveyItemModel> {
  @Prop({ type: Model, default: () => ([]) }) list!: Array<IType>
  @Prop({ type: Boolean, default: false, }) includeColor!: boolean
  @Prop({ type: Boolean, default: false, }) expandable!: boolean
  @Prop({ type: String, default: null, }) label!: string
  @Prop({ type: String, default: null, }) icon!: string
  @Prop({ type: String, default: null, }) slotPrefix!: string
  @Prop({ type: String, default: 'v-text-field', }) component!: string
  @Prop({ type: String, default: 'This title is for reference only. If you wish to show it to your users, you must active it by clicking on the left-end checkbox.', }) hint!: string
  @Prop({ type: Object, default: () => ({}) }) titleAttrs!: any
  @Prop({ type: Object, default: () => ({}) }) cardTitleAttrs!: any
  @Prop({ type: Object, default: () => ({}) }) viewAttrs!: any
  @Prop({ type: String, default: 'v-card-title' }) headerIs!: string
}
</script>
