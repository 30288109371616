<template>
  <v-form
    name="forgotPassword"
    ref="form"
    v-test-id="'forgot-password-form'"
    v-model="valid"
    :disabled="requesting"
    @submit="onSubmit"
    lazy-validation
  >

    <v-alert
      v-if="sent"
      border="left"
      colored-border
      color="success"
      dense
      elevation="2"
    >
      If an account with this email address exists, we will send a link to that address to reset your password.
    </v-alert>

    <p v-else>
      Please enter the email address associated with your account.
    </p>

    <v-text-field
      name="email"
      v-test-id="'email'"
      v-model="data.email"
      v-safechar
      :error-messages="errors.email"
      :label="$t('placeholder.email')"
      :rules="[rules.required, rules.email]"
      :autofocus="$vuetify.breakpoint.mdAndUp"
      prepend-inner-icon="mdi-account"
      autocomplete="email"
      tabindex="1"
      outlined
      required
      @input="delete errors.email"
    />

    <v-btn
      v-if="resendTimeout === null"
      v-test-id="'submit'"
      tabindex="2"
      type="submit"
      color="primary"
      :disabled="!canSubmit"
      :loading="requesting"
      block
    >
      Request password reset
    </v-btn>

    <v-btn
      v-else
      v-test-id="'code-resend-alert'"
      :disabled="!canResend"
      :loading="requesting"
      outlined
      block
      class="mb-4"
      @click="onResendClick"
    >
      Resend<span v-if="resendTimeout !== null && resendTimeout > 0"> ({{ resendTimeout }})</span>
    </v-btn>

    <v-btn
      :to="{ name: 'login' }"
      small
      block
      text
      class="mt-2"
    >
      <v-icon left>mdi-arrow-left</v-icon>
      Go back to login
    </v-btn>
  </v-form>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Component, Ref, Vue } from 'vue-property-decorator';
import Rules from '@/modules/sdk/core/rules';
import AuthService from '@/modules/sdk/services/auth.service';
import Logger from '@/modules/sdk/core/logger';

const d = new Logger('Auth/ForgotPassword.vue');
let resendInterval: any = 0;

export type VForm = Vue & { validate: () => boolean }

@Component
export default class ForgotPasswordView extends Vue {
  @Ref() readonly form!: VForm

  requesting = false;
  resendTimeout: number | null = null;

  sent = false;
  valid = true;
  errors = {};
  rules = {};
  data = {}

  get loading() {
    return this.requesting;
  }

  get canSubmit() {
    return !this.loading && this.valid;
  }

  get canResend(): boolean {
    return this.resendTimeout !== null && this.resendTimeout === 0;
  }

  onSubmit(event: any) {
    event.preventDefault();
    this.errors = {};
    if (this.form && this.form.validate()) {
      this.request();
    }
  }

  onResendClick(): void {
    this.request();
  }

  request(): Promise<any> {
    this.sent = false;
    this.requesting = true;
    this.resendCountdown();
    return AuthService.getInstance().reset(this.data)
      .then((response: any) => {
        if (response.data.view.sent) {
          this.sent = true;
          this.resendCountdown();
        }
      })
      .catch(reason => this.$root.$zemit.handleError(reason, this.errors))
      .finally(() => this.requesting = false);
  }

  resendCountdown() {
    this.resendTimeout = 60;
    resendInterval = setInterval(() => {
      if (this.resendTimeout !== null && this.resendTimeout !== 0) {
        this.resendTimeout--;
      }
      if (this.resendTimeout === 0) {
        clearInterval(resendInterval);
      }
    }, 1000);
  }

  destroyed() {
    clearInterval(resendInterval);
  }

  created() {
    this.rules = {
      required: (value: string) => Rules.required(value) || this.$t('rules.required'),
      email: (value: string) => Rules.email(value) || this.$t('rules.email'),
    };
    this.$emit('title', this.$t('auth.title.' + this.$route.name))
  }
}
</script>
