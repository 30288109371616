<template>
  <div>
    <!-- CATEGORY DIALOG -->
    <ModalDialog
      v-model="dialog.visible"
      :persistent="!dialog.category.data.label"
      :loading="dialog.saving"
      title="Category Settings"
      width="390"
      persistent
    >
      <template v-if="dialog.category" #body>

        <v-alert
          v-if="dialog.category.changed"
          outlined
          type="info"
          color="primary"
        >
          <span>The upcoming changes will be applied globally across the system.</span>
        </v-alert>

        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="dialog.category.data.label"
              v-safechar
              label="Category"
              type="text"
              hide-details="auto"
              outlined
              clearable
              required
              @input="dialog.category.changed = true"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-divider class="mt-3"></v-divider>

        <v-color-picker
          v-model="_color"
          mode="hexa"
          hide-canvas
          hide-inputs
          hide-mode-switch
          show-swatches
          width="340"
          swatches-max-height="340"
          @input="dialog.category.changed = true"
        ></v-color-picker>
      </template>

      <!-- BUTTONS -->
      <template #buttons>
        <v-btn
          color="primary"
          :loading="dialog.saving"
          :disabled="dialog.saving || !dialog.category.changed"
          @click="applyCategoryChange"
        >
          <span v-text="$t('btn.apply')"></span>
        </v-btn>
        <v-btn text :disabled="dialog.saving" @click="dialog.visible = false">
          <span v-text="$t('btn.cancel')"></span>
        </v-btn>
      </template>
    </ModalDialog>

    <v-autocomplete
      v-model="model"
      v-bind="$attrs"
      v-on="$listeners"
      :items="categoryList"
      item-text="data.label"
      item-value="data.id"
      outlined
      hide-details="auto"
      multiple
      chips
      required
      return-object
      deletable-chips
    >
      <template #item="{ attrs, item, selected }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          :color="item.data.color"
          :text-color="item.getTextColor(true)"
          chips
          label
        >
          <span class="pr-1" :class="item.getTextClass()" v-text="item.data.label"></span>
        </v-chip>
      </template>
      <template #selection="{ attrs, item, selected }">
        <v-chip
          v-if="typeof item !== 'string'"
          v-bind="attrs"
          :input-value="selected"
          :color="item.data.color"
          :text-color="item.getTextColor(true)"
          chips
          label
          @click.stop="openDialog(item)"
        >
          <span class="pr-1" :class="item.getTextClass()" v-text="item.data.label"></span>
          <v-progress-circular
            v-if="item.loading"
            :size="15"
            class="pl-1"
            width="2"
            indeterminate
          />
          <v-icon
            v-else
            small
            @click.stop="() => removeItem(item)"
          >
            $delete
          </v-icon>
        </v-chip>
      </template>
    </v-autocomplete>
  </div>
</template>
<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Prop, VModel} from 'vue-property-decorator';
import Logger from '@/modules/sdk/core/logger';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import CategoryService from '@/services/category.service';
import CategoryModel from '@/models/category.model';

const d = new Logger('views/Admin/Component/Project/CategoriesComponent');

@Component({
  components: {
    ModalDialog
  }
})
export default class CategoriesComponent extends Vue {
  @VModel({}) model!: Array<CategoryModel>;
  @Prop({default: null}) projectId!: number;
  @Prop({default: null}) recordId!: number;
  @Prop({default: false}) selfOnly!: boolean;

  service = CategoryService.getInstance();

  loadingCategoryList = false;
  categoryList: Array<CategoryModel> = [];

  defaultColor = '#E0E0E0FF';
  color = {} as any;
  dialog: {
    visible: boolean,
    saving: boolean,
    category: CategoryModel,
    original: CategoryModel,
  } = {
    visible: false,
    saving: false,
    category: new CategoryModel(),
    original: new CategoryModel(),
  }

  set _color(value) {
    this.color = value;
  }

  get _color() {
    return this.color;
  }

  removeItem(item: CategoryModel) {
    const index = this.model.findIndex((category: CategoryModel) => category.data.id === item.data.id)
    if (index >= 0) this.model.splice(index, 1)
  }

  openDialog(category: CategoryModel) {
    Object.assign(this.dialog, {
      visible: true,
      category: category.clone(),
      original: category,
    })
    this._color = category.data.color || this.defaultColor;
  }

  applyCategoryChange() {
    this.dialog.category.data.color = this._color;
    this.dialog.saving = true;

    this.service.save(this.dialog.category)
      .then(() => {
        this.dialog.original.assign(this.dialog.category);
        this.dialog.visible = false;
        this.dialog.saving = false;
      });
  }

  loadCategoryList() {
    this.categoryList = [];
    this.loadingCategoryList = true;
    this.service.getAll()
      .then(response => {
        this.categoryList = response.data.view.list;
      }).finally(() => this.loadingCategoryList = false)
  }

  // @Watch('model', {deep: true})
  // onCategoryChange(
  //   newItems: Array<string | CategoryModel>,
  // ) {
  //   newItems.forEach((model, index) => {
  //     if (!(model instanceof CategoryModel)) {
  //       this.model[index] = new CategoryModel({
  //         label: model
  //       })
  //     }
  //   })
  // }

  created() {
    this.loadCategoryList();
  }
}
</script>
