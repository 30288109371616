<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'error_log'"
      v-model="model"
      :service="service"
      :loading="loading"
      :parent-route="parentRoute"
      name="error_log"
      title="Error Log"
      hide-reset-button
      hide-save-button
    >
      <template #form="{ formErrors, rules }">
        <v-text-field
          v-model="model.data.message"
          :error-messages="formErrors.message"
          :rules="[rules.required]"
          label="Message"
          outlined
          readonly
          required
          @input="delete formErrors.message"
        />
        <v-text-field
          v-model="model.data.lastTimestamp"
          :error-messages="formErrors.lastTimestamp"
          :rules="[rules.required]"
          label="Datetime"
          outlined
          readonly
          required
          @input="delete formErrors.lastTimestamp"
        />
        <v-sheet dark rounded class="mb-8">
          <v-textarea
            v-model="model.data.stack"
            :error-messages="formErrors.stack"
            :rules="[rules.required]"
            height="400"
            label="Stack"
            filled
            readonly
            hide-details
            required
            @input="delete formErrors.stack"
          />
          <v-btn
            color="primary"
            block
            @click="onLogToConsoleClick"
          >
            <v-icon left>mdi-gate-nor</v-icon>
            Log to console with Source Maps
          </v-btn>
        </v-sheet>
        <v-text-field
          v-model="model.data.route"
          :error-messages="formErrors.route"
          :rules="[rules.required]"
          label="Route"
          outlined
          readonly
          required
          @input="delete formErrors.route"
        />
        <v-text-field
          v-model="model.data.userAgent"
          :error-messages="formErrors.userAgent"
          :rules="[rules.required]"
          label="User agent"
          outlined
          readonly
          required
          @input="delete formErrors.userAgent"
        />
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="model.data.version"
              :error-messages="formErrors.version"
              :rules="[rules.required]"
              label="Version"
              outlined
              readonly
              required
              @input="delete formErrors.version"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="model.data.count"
              :error-messages="formErrors.count"
              :rules="[rules.required]"
              label="Count"
              outlined
              readonly
              required
              @input="delete formErrors.count"
            />
          </v-col>
        </v-row>
      </template>

      <template #actions>
        <v-btn
          :disabled="model.states.saving"
          :loading="model.states.saving"
          :color="model.data.acknowledged ? 'primary' : null"
          outlined
          @click="onAcknowledgeToggle"
        >
          <v-icon v-if="model.data.acknowledged" left>mdi-check-circle-outline</v-icon>
          <v-icon v-else left>mdi-circle-outline</v-icon>
          <span>Acknowledge</span>
        </v-btn>
      </template>
    </DataForm>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Vue, Component} from 'vue-property-decorator'
import ErrorLogService from '@/modules/sdk/services/error-log.service'
import DataForm from '@/modules/common/components/DataForm.vue'
import ErrorLogModel from '@/modules/sdk/models/error-log.model'
import {Route} from 'vue-router';

@Component({
  components: {
    DataForm,
  }
})
export default class ContactForm extends Vue {

  model: ErrorLogModel = new ErrorLogModel()
  service = ErrorLogService.getInstance()
  parentRoute: Partial<Route> = { name: 'ErrorLogList' };
  loading = false
  formErrors: any = {}

  onLogToConsoleClick() {
    console.log(this.model.data.stack);
  }

  onAcknowledgeToggle() {
    this.model.states.saving = true;
    const model: ErrorLogModel = this.model.clone();
    model.data.acknowledged = !model.data.acknowledged;
    return this.service.save(model)
      .then(response => {
        this.model.assign(response.data.view.single, ['acknowledged']);
        this.$root.$globalSnack.success({
          message: this.model.data.acknowledged ? 'Error acknowledged' : 'Error not acknowledged'
        });
        this.$emit('acknowledged', this.model);
        return this.model;
      })
      .catch(reason => this.$root.$zemit.handleError(reason, this.formErrors))
      .finally(() => this.model.states.saving = false);
  }

  load(id: number) {
    this.loading = true
    this.service.get({id})
      .then(response => this.model = response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false)
  }

  created() {
    if (this.$route.params.id) {
      this.load(parseInt(this.$route.params.id))
    }
  }
}
</script>


