import { Vue, Component, VModel, Prop } from 'vue-property-decorator';
import SurveyItemModel from '@/modules/sdk/models/survey-item.model';
import tinyColor from 'tinycolor2';
import SurveyModel from '@/modules/sdk/models/survey.model';
import CountryModel from '@/modules/sdk/models/country.model';
import Rules from '@/modules/sdk/core/rules';
import SurveyAnswerItemModel from '@/modules/sdk/models/survey-answer-item.model';

@Component
export default class SurveyItemMixin<T extends SurveyItemModel, P extends SurveyItemModel> extends Vue {

  @VModel({ type: [SurveyItemModel, SurveyModel], default: () => new SurveyItemModel({}, false) }) model!: T
  @Prop({ type: SurveyModel, default: () => new SurveyModel({}, false) }) parent!: P | SurveyModel | null
  @Prop({ type: SurveyModel, default: () => new SurveyModel({}, false) }) survey!: SurveyModel
  @Prop({ type: Object, default: () => ({}) }) cascadeAttrs!: {[key: string]: any}
  @Prop({ type: String, default: 'answer' }) mode!: 'edit' | 'answer'
  @Prop({ type: String, default: 'paginated' }) view!: 'paginated' | 'list'
  @Prop({ type: Boolean, default: false, }) preview!: boolean
  @Prop({ type: Boolean, default: false, }) dark!: boolean
  @Prop({ type: String, default: null, }) colorActive!: string
  @Prop({ type: String, default: null, }) colorInactive!: string
  @Prop({ type: Boolean, default: false, }) readonly!: boolean
  @Prop({ type: Boolean, default: false, }) disabled!: boolean
  @Prop({ type: Boolean, default: false }) manageAnswers!: boolean
  @Prop({ type: Boolean, default: true }) canUseAi!: boolean;
  @Prop({ type: Boolean, default: false, }) canViewAnswers!: boolean
  @Prop({ type: Boolean, default: false, }) canSeeAnswerName!: boolean
  @Prop({ type: Boolean, default: false }) canSeeOfficialAnswers!: boolean
  @Prop({ type: Number, default: 0, }) index?: number
  @Prop({ type: Boolean, default: false, }) debug!: boolean
  @Prop({ type: Boolean, default: false, }) sortable!: boolean
  @Prop({ type: Boolean, default: false, }) showRemoved!: boolean
  @Prop({ type: Array, default: () => ([]) }) extraFields!: any[]
  @Prop({ type: Array, default: () => ([]) }) countryList!: CountryModel[]
  @Prop({ type: Array, default: () => ([]) }) answerList!: SurveyAnswerItemModel[]
  @Prop({ type: Array, default: () => ([]) }) userList!: { text: string, value: any }[]
  @Prop({ type: Array, default: () => ({}) }) autocomplete!: any
  @Prop({ type: Array, default: null }) answerFilters!: [{
    field: string,
    operator: string,
    value: any,
  }];

  tick = 0
  errors: any = {}
  rules: {[key: string]: (value: any) => boolean | string} = {}

  get computedCascadeAttrs(): {[key: string]: any} {
    return Object.assign({}, this.cascadeAttrs, this.$attrs, this.$props);
  }

  get isDark(): boolean {
    if (this.model.data.color === null) {
      return false;
    }
    if (this.model.data.color) {
      return tinyColor(this.model.data.color).isDark();
    }
    return this.dark || false;
  }

  get color(): string | null {
    if (this.model.data.color !== undefined) {
      return this.model.data.color;
    }
    return this.mode === 'answer'
      ? this.model.data.includeLabel
        ? this.colorActive
        : null
      : this.model.data.includeLabel
        ? this.colorActive
        : this.colorInactive;
  }

  get hasHeader(): boolean {
    return !!(this.model.data.includeLabel
      && this.model.data.label);
  }

  get includePadding(): boolean {
    return this.mode === 'answer'
      && (
        this.model.data.color
        || this.hasHeader
      );
  }

  onAnswer(value: any): void {
    this.tick++;
    this.$emit('answer', value)
  }

  created() {
    this.rules.required = (value: string) => Rules.required(value) || this.$t('rules.required').toString();
  }
}
