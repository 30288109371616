var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CollapsableCard',{attrs:{"memory-key":'survey_section_' + _vm.model.data.id,"no-header":!_vm.hasHeader && _vm.mode === 'answer',"flat":!_vm.hasHeader && _vm.mode === 'answer',"header-attrs":{
    color: _vm.color,
    dark: _vm.isDark,
  }},scopedSlots:_vm._u([{key:"header_left",fn:function(){return [_c('SurveyHeader',{attrs:{"survey":_vm.parent,"list":_vm.parent?.data.surveysectionlist || [],"index":_vm.index,"mode":_vm.mode,"debug":_vm.debug,"readonly":_vm.readonly,"dark":_vm.model.data.includeLabel,"extra-fields":_vm.extraFields,"icon":_vm.view === 'list' ? 'mdi-head-question' : null,"card-title-attrs":{
        class: 'pa-0'
      },"view-attrs":{
        class: _vm.view === 'paginated' ? 'font-weight-bold display-1' : 'title',
      },"title-attrs":{
        filled: _vm.model.data.includeLabel,
        class: 'font-weight-bold',
      },"label":"Section","color-active":"section","color-inactive":"section lighten-5","slot-prefix":"section","header-is":"div","include-color":"","expandable":""},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(slot,key){return {key:key,fn:function(props){return [_vm._t(key,null,null,props)]}}})],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]},proxy:true},{key:"body",fn:function(){return [(_vm.canViewAnswers)?_c('v-sheet',{attrs:{"color":"backgroundVeryLight"}},[_c('v-card-title',[_c('v-autocomplete',{attrs:{"items":_vm.userList,"loading":_vm.userListLoading || _vm.loadingAnswerList,"disabled":_vm.userListLoading,"label":"See answer(s) from","background-color":"white","prepend-inner-icon":"mdi-forum","multiple":"","clearable":"","outlined":"","hide-details":"","dense":"","return-object":""},on:{"change":() => _vm.selectedUserChanged = true,"blur":_vm.onSelectedUserAnswerBlur},model:{value:(_vm.selectedUserList),callback:function ($$v) {_vm.selectedUserList=$$v},expression:"selectedUserList"}})],1)],1):_vm._e(),(_vm.mode === 'answer')?_c('v-card-text',{class:{
        'pa-0': !_vm.includePadding
      }},[(_vm.model.data.includePrependText)?_c('div',{staticClass:"body-1",domProps:{"innerHTML":_vm._s(_vm.model.data.prependText)}}):_vm._e(),_vm._l((_vm.groupList),function(group,groupIdx){return _c('v-expand-transition',{key:group.data.hash},[(_vm.parent?.isActive(group.data.hash))?_c('div',{attrs:{"tick":_vm.tick}},[_c('SurveyGroup',_vm._b({class:{ 'mt-3': groupIdx > 0 },attrs:{"cascade-attrs":_vm.cascadeAttrs,"survey":_vm.parent,"parent":_vm.model,"index":groupIdx,"user-list":_vm.appliedSelectedUserList,"answer-list":_vm.innerAnswerList},on:{"answer":_vm.onAnswer},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(slot,key){return {key:key,fn:function(props){return [_vm._t(key,null,null,props)]}}})],null,true),model:{value:(_vm.model.data.surveygrouplist[groupIdx]),callback:function ($$v) {_vm.$set(_vm.model.data.surveygrouplist, groupIdx, $$v)},expression:"model.data.surveygrouplist[groupIdx]"}},'SurveyGroup',_vm.computedCascadeAttrs,false))],1):_vm._e()])}),(_vm.model.data.includeAppendText)?_c('div',{staticClass:"body-1 mt-4",domProps:{"innerHTML":_vm._s(_vm.model.data.appendText)}}):_vm._e()],2):_c('v-card-text',[_c('ListBuilder',{attrs:{"default-model":_vm.surveyGroupModel,"default-data":{
          surveyId: _vm.model.data.surveyId,
          projectId: _vm.model.data.projectId,
          surveySectionId: _vm.model.data.id,
        },"labels":{
          add: 'Add a new group',
          remove: 'Remove group',
          empty: 'No group available yet',
        },"btn-attrs":{
          color: 'grey',
          outlined: true,
        },"readonly":_vm.readonly,"sortable":_vm.sortable,"show-removed":_vm.showRemoved,"button-offset":22,"group":"survey_group","order-by":"position","is-model":"","restorable":"","collapsable":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-expand-transition',[(_vm.model.data.includePrependText)?_c('div',[_c('Wysiwyg',{attrs:{"label":"Prepend text","height":200},model:{value:(_vm.model.data.prependText),callback:function ($$v) {_vm.$set(_vm.model.data, "prependText", $$v)},expression:"model.data.prependText"}})],1):_vm._e()])]},proxy:true},{key:"item",fn:function(group){return [_c('SurveyGroup',_vm._b({attrs:{"cascade-attrs":_vm.cascadeAttrs,"survey":_vm.parent,"parent":_vm.model,"index":group.index,"answer-list":_vm.innerAnswerList},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(slot,key){return {key:key,fn:function(props){return [_vm._t(key,null,null,props)]}}})],null,true),model:{value:(group.item),callback:function ($$v) {_vm.$set(group, "item", $$v)},expression:"group.item"}},'SurveyGroup',_vm.computedCascadeAttrs,false))]}},{key:"append",fn:function(){return [_c('v-expand-transition',[(_vm.model.data.includeAppendText)?_c('div',[_c('Wysiwyg',{staticClass:"mt-4",attrs:{"label":"Append text","height":200},model:{value:(_vm.model.data.appendText),callback:function ($$v) {_vm.$set(_vm.model.data, "appendText", $$v)},expression:"model.data.appendText"}})],1):_vm._e()])]},proxy:true}]),model:{value:(_vm.model.data.surveygrouplist),callback:function ($$v) {_vm.$set(_vm.model.data, "surveygrouplist", $$v)},expression:"model.data.surveygrouplist"}})],1),(_vm.showSaveButton)?_c('v-card-actions',[_c('v-divider',{staticClass:"mb-4"}),_c('v-btn',{attrs:{"disabled":_vm.disabled || !_vm.canSaveSection,"loading":_vm.saving,"color":"primary","block":""},on:{"click":_vm.onSaveSectionClick}},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('btn.save'))}})])],1):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }