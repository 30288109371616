<template>
  <v-autocomplete
    v-model="question.data.type"
    :items="computedTypeList"
    :prepend-inner-icon="type.icon"
    :menu-props="{
      maxHeight: 400,
      maxWidth: 500,
    }"
    placeholder="Select type"
    style="min-width: 12rem"
    hide-details
  >
    <template #item="{ item }">
      <v-list-item-icon>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ item.text }}</v-list-item-title>
        <v-list-item-subtitle>{{ $t('survey.question.typesDesc.' + item.value) }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { Vue, Component, VModel } from 'vue-property-decorator';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';

interface IType {
  header?: string,
  icon?: string,
  value?: string
}

@Component({})
export default class SurveyTypeMenu extends Vue {
  @VModel({ type: SurveyQuestionModel, default: () => new SurveyQuestionModel({}, false) }) question!: SurveyQuestionModel

  typeList: Array<IType> = []

  get type(): IType {
    return this.typeList.find(type => type.value === this.question.data.type) || { value: 'input', icon: 'mdi-cursor-text' };
  }

  get computedTypeList(): IType[] {
    return this.typeList.map(type => ({
      ...type,
      text: type.header ? undefined : this.$i18n.t('survey.question.types.' + type.value),
    }))
  }

  created() {
    this.typeList = [
      // Numbers
      { header: this.$t('survey.question.typeGroups.numbers').toString(), },
      { value: 'number', icon: 'mdi-numeric' },
      { value: 'number_range', icon: 'mdi-numeric-10-box-multiple-outline' },
      { value: 'integer', icon: 'mdi-numeric-1' },
      { value: 'percentage', icon: 'mdi-percent' },
      { value: 'decimal', icon: 'mdi-decimal' },

      // Texts
      { header: this.$t('survey.question.typeGroups.texts').toString(), },
      { value: 'input', icon: 'mdi-cursor-text' },
      { value: 'textarea', icon: 'mdi-text' },

      // Options
      { header: this.$t('survey.question.typeGroups.options').toString(), },
      { value: 'checkbox', icon: 'mdi-checkbox-blank-outline' },
      { value: 'radio', icon: 'mdi-radiobox-marked' },
      { value: 'select', icon: 'mdi-format-list-bulleted' },
      { value: 'country', icon: 'mdi-earth' },
      // { value: 'autocomplete', icon: 'mdi-cloud-question' },
      { value: 'likert', icon: 'mdi-table-large' },

      // Dates & times
      { header: this.$t('survey.question.typeGroups.dates').toString(), },
      { value: 'date', icon: 'mdi-calendar' },
      { value: 'date_range', icon: 'mdi-calendar-range' },
      { value: 'datetime', icon: 'mdi-timetable' },
      { value: 'datetime_range', icon: 'mdi-timetable' },
      { value: 'time', icon: 'mdi-av-timer' },
      { value: 'time_range', icon: 'mdi-av-timer' },
      { value: 'year', icon: 'mdi-calendar-month' },

      // Others
      { header: this.$t('survey.question.typeGroups.others').toString(), },
      { value: 'rating', icon: 'mdi-star-outline' },
    ];
  }
}
</script>
