<template>
  <div>

    <DuplicateProjectModal
      v-if="canDuplicateProject"
      v-model="showDuplicateProjectModal"
      :project="model"
      max-width="900"
    />

    <AskQuestionToAiModal
      v-if="model.data.aiUseAllowed"
      v-model="askAiModal.visible"
      :project="askAiModal.project"
      :stage="selectedStage?.value"
      :default-selected-data="defaultSelectedData"
      :selection="selectedAiQuestions"
      :survey="surveyClone"
      :mode="selectedStage?.value === 'final'? 'data-extraction' : 'review'"
      can-see-tasks
      bulk
      title="Ask question to the AI"
      icon="mdi-robot"
      max-width="1200"
      scrollable
      @apply="onAiQuestionApply"
    />

    <v-container fluid class="pa-4">
      <DataForm
        v-test-id="'project'"
        v-model="model"
        :service="service"
        :loading="loading"
        name="project"
        title="Project"
      >
        <template #options>
          <div class="d-flex align-center" style="gap: 0.5rem">

            <import-dialog
              v-model="importCsvModal"
              :project-id="model.data.id"
              :disabled="!model.data.id || isLoading"
              hide-activator
            ></import-dialog>

            <import-status-dialog
              v-model="importExistingStatusModal"
              :disabled="!model.data.id || isLoading"
              :hide-button="true"
            ></import-status-dialog>

            <v-menu offset-y>
              <template #activator="menu">
                <v-tooltip bottom>
                  <template #activator="tooltip">
                    <v-btn
                      v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                      v-on="{ ...menu.on, ...tooltip.on }"
                      icon
                      :disabled="!model.data.id || isLoading"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <span>Options</span>
                </v-tooltip>
              </template>
              <v-list>
                <v-list-item
                  :loading="importCsvLoading"
                  :disabled="importCsvLoading"
                  @click="onImportCsv"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-database-import</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Import Data from CSV
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :loading="importExistingStatusLoading"
                  :disabled="importExistingStatusLoading"
                  @click="onImportExistingStatus"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-database-import</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Import Existing Status
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :disabled="!canDuplicateProject"
                  @click="onDuplicateProject"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-content-duplicate</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    Duplicate Project
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :loading="updateRecordSimilarityScoreLoading"
                  :disabled="updateRecordSimilarityScoreLoading"
                  @click="onUpdateRecordsSimilarityScore"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-compare</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Update Records Similarity Score</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="model.data.aiUseAllowed"
                  :loading="aiRecordStatusLoading"
                  :disabled="aiRecordStatusLoading"
                  @click="onAiRecordStatus"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-robot</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>AI Process {{ selectedStage?.text }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  :loading="resetOfficialStatusLoading"
                  :disabled="resetOfficialStatusLoading"
                  @click="onResetOfficialStatus"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-database-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Reset Official Status</v-list-item-title>
                </v-list-item>
                <v-list-item
                  :loading="resetStatusTypeLoading"
                  :disabled="resetStatusTypeLoading"
                  @click="onResetStatusType"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-database</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Reset Status Type</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>

        <template #form="{ formErrors, rules }">
          <v-row>

            <!-- LABEL -->
            <v-col cols="12">
              <v-text-field
                v-model="model.data.label"
                v-safechar
                :error-messages="formErrors.label"
                :rules="[rules.required]"
                label="Project"
                outlined
                hide-details="auto"
                clearable
                required
                @input="delete formErrors.label"
              />
            </v-col>

            <!-- TYPE -->
            <v-col cols="12">
              <v-select
                v-model="model.data.type"
                :error-messages="formErrors.type"
                :label="$t('placeholder.type')"
                :rules="[rules.required]"
                :items="typeList"
                outlined
                hide-details="auto"
                clearable
                required
                @input="delete formErrors.type"
              ></v-select>
            </v-col>

            <!-- STATUS -->
            <v-col cols="12">
              <v-select
                v-model="model.data.status"
                :error-messages="formErrors.status"
                :label="$t('placeholder.status')"
                :rules="[rules.required]"
                :items="statusList"
                outlined
                hide-details="auto"
                clearable
                required
                @input="delete formErrors.status"
              ></v-select>
            </v-col>

            <!-- STAGE -->
            <v-col cols="12">
              <v-select
                v-model="model.data.stage"
                :error-messages="formErrors.stage"
                :rules="[rules.required]"
                :items="stageList"
                outlined
                label="Stage"
                hide-details="auto"
                clearable
                required
                @input="delete formErrors.stage"
              ></v-select>
            </v-col>

            <!-- TAGS -->
            <v-col cols="12">
              <categories-component
                v-model="model.data.categorylist"
                :error-messages="formErrors.categoryList"
                label="Tag Categories"
                hide-details="auto"
                clearable
                required
                @input="delete formErrors.categoryList"
              />
            </v-col>

            <!-- DESCRIPTION -->
            <v-col cols="12">
              <Wysiwyg
                v-model="model.data.description"
                :error-messages="formErrors.description"
                :rules="[rules.required]"
                outlined
                label="Project Description"
                hide-details="auto"
                clearable
                required
                @input="delete formErrors.description"
              ></Wysiwyg>
            </v-col>

            <!-- PILOT SWITCH -->
            <v-col cols="12">
              <v-switch
                class="ma-0 pa-0 ml-2"
                v-model="model.data.pilot"
                :error-messages="formErrors.pilot"
                :label="$t('placeholder.pilot')"
                hide-details="auto"
                inset
                @input="delete formErrors.pilot"
              ></v-switch>
            </v-col>

            <!-- PILOT SWITCH -->
            <v-col cols="12">
              <v-switch
                class="ma-0 pa-0 ml-2"
                v-model="model.data.aiUseAllowed"
                :error-messages="formErrors.aiUseAllowed"
                label="AI Use Allowed"
                hide-details="auto"
                inset
                @input="delete formErrors.aiUseAllowed"
              ></v-switch>
            </v-col>
          </v-row>

          <!-- OBJECTIVES -->
          <div class="my-3">
            <v-subheader>Objectives</v-subheader>
            <v-sheet color="background" class="pa-3">
              <v-expansion-panels multiple>

                <!-- MAIN OBJECTIVES -->
                <v-expansion-panel v-for="item in objectiveList" :key="item.key">
                  <v-expansion-panel-header>{{ item.label }} ({{ model.data[item.key].length }})
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ListBuilder
                      v-model="model.data[item.key]"
                      group="objectives"
                      sortable
                    >
                      <template #item="{ index }">
                        <v-text-field
                          v-model="model.data[item.key][index]"
                          v-safechar
                          :rules="[rules.required]"
                          outlined
                          hide-details="auto"
                          @paste="event => onPastePicot(event, index, model.data[item.key])"
                          @keydown.enter="event => onKeypressEnterPicot(event, index, model.data[item.key])"
                        />
                      </template>
                    </ListBuilder>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-sheet>
          </div>

          <!-- PICOTS -->
          <div class="my-3">
            <v-subheader>PICOTS</v-subheader>
            <v-sheet color="background" class="pa-3">
              <v-expansion-panels multiple>

                <!-- MAIN OBJECTIVES -->
                <v-expansion-panel v-for="item in picotList" :key="item.key">
                  <v-expansion-panel-header>{{ item.label }} ({{ model.data[item.key].length }})
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p v-if="item.hint" v-text="item.hint" class="body-1"></p>
                    <ListBuilder
                      v-model="model.data[item.key]"
                      group="picots"
                      sortable
                    >
                      <template #item="{ index }">
                        <v-textarea
                          v-model="model.data[item.key][index]"
                          v-safechar
                          :rules="[rules.required]"
                          outlined
                          hide-details="auto"
                          @paste="event => onPastePicot(event, index, model.data[item.key])"
                        />
                      </template>
                    </ListBuilder>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-sheet>
          </div>

          <!-- CRITERIA -->
          <div class="my-3">
            <v-subheader>Criteria</v-subheader>
            <v-sheet color="background" class="pa-3">
              <v-expansion-panels multiple>

                <!-- MAIN OBJECTIVES -->
                <v-expansion-panel v-for="item in criteriaList" :key="item.key">
                  <v-expansion-panel-header>{{ item.label }} ({{ model.data[item.key].length }})
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ListBuilder
                      v-model="model.data[item.key]"
                      group="criteria"
                      sortable
                    >
                      <template #item="{ index }">
                        <v-text-field
                          v-model="model.data[item.key][index]"
                          v-safechar
                          :rules="[rules.required]"
                          outlined
                          hide-details="auto"
                          @paste="event => onPastePicot(event, index, model.data[item.key])"
                          @keydown.enter="event => onKeypressEnterPicot(event, index, model.data[item.key])"
                        />
                      </template>
                    </ListBuilder>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-sheet>
          </div>

          <div class="my-3">
            <v-subheader>User management</v-subheader>
            <v-sheet color="background" class="pa-3">
              <v-expansion-panels multiple>

                <!-- MAIN OBJECTIVES -->
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Roles ({{userNodesTypeListCount}})
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12">
                        <soft-delete-autocomplete
                          v-if="userList"
                          v-model="leaderUserList"
                          :loading="loadingUsers"
                          :items="userNodesTypeList['leader']"
                          :item-text="(item) => item.data.userentity.getLabel()"
                          :item-value="(item) => item.data.userentity.data.id"
                          :item-deleted="(item) => item.data.userentity.data.deleted"
                          outlined
                          label="Leaders"
                          hide-details="auto"
                          deletable-chips
                          multiple
                          chips
                          clearable
                          return-object
                        />
                      </v-col>
                      <v-col cols="12" v-if="model.isPilotOrSystematic()">
                        <soft-delete-autocomplete
                          v-if="userList"
                          v-model="arbitratorUserList"
                          :loading="loadingUsers"
                          :items="userNodesTypeList['arbitrator']"
                          :item-text="(item) => item.data.userentity.getLabel()"
                          :item-value="(item) => item.data.userentity.data.id"
                          :item-deleted="(item) => item.data.userentity.data.deleted"
                          outlined
                          label="Arbitrators"
                          hide-details="auto"
                          deletable-chips
                          multiple
                          chips
                          clearable
                          return-object
                        />
                      </v-col>
                      <v-col cols="12">
                        <soft-delete-autocomplete
                          v-if="userList"
                          v-model="researcherUserList"
                          :loading="loadingUsers"
                          :items="userNodesTypeList['researcher']"
                          :item-text="(item) => item.data.userentity.getLabel()"
                          :item-value="(item) => item.data.userentity.data.id"
                          :item-deleted="(item) => item.data.userentity.data.deleted"
                          outlined
                          label="Primary Researchers"
                          hide-details="auto"
                          deletable-chips
                          multiple
                          chips
                          clearable
                          return-object
                        />
                      </v-col>
                      <v-col cols="12" v-if="model.isPilotOrSystematic()">
                        <soft-delete-autocomplete
                          v-if="userList"
                          v-model="secondaryResearcherUserList"
                          :loading="loadingUsers"
                          :items="userNodesTypeList['secondary-researcher']"
                          :item-text="(item) => item.data.userentity.getLabel()"
                          :item-value="(item) => item.data.userentity.data.id"
                          :item-deleted="(item) => item.data.userentity.data.deleted"
                          outlined
                          label="Secondary Researchers"
                          hide-details="auto"
                          deletable-chips
                          multiple
                          chips
                          clearable
                          return-object
                        />
                      </v-col>
                      <v-col cols="12">
                        <soft-delete-autocomplete
                          v-if="userList"
                          v-model="aiManagerUserList"
                          :loading="loadingUsers"
                          :items="userNodesTypeList['ai-manager']"
                          :item-text="(item) => item.data.userentity.getLabel()"
                          :item-value="(item) => item.data.userentity.data.id"
                          :item-deleted="(item) => item.data.userentity.data.deleted"
                          outlined
                          label="AI Manager"
                          hide-details="auto"
                          deletable-chips
                          multiple
                          chips
                          clearable
                          return-object
                        />
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-sheet>
          </div>
        </template>
      </DataForm>
    </v-container>

    <!-- PROJECT STATUS REASON LIST -->
<!--    <project-status-reason-list-->
<!--      v-if="model.data.id"-->
<!--      :project-id="model.data.id"-->
<!--      :hide-collapsable="false"-->
<!--      class="mt-n4"-->
<!--      collapsed-->
<!--    ></project-status-reason-list>-->

    <!-- KEYWORD LIST -->
    <keyword-list
      v-if="model.data.id"
      :project-id="model.data.id"
      :hide-collapsable="false"
      class="mt-n4"
      collapsed
    ></keyword-list>

    <v-container fluid class="pa-4">
      <RecordAiStatusList
        v-if="model.data.id"
        :filters="[{
          field: 'projectId',
          operator: 'equals',
          value: model.data.id
        }]"
        :items-per-page="5"
        class="mt-n4"
        collapsed
      />
    </v-container>

    <!-- SURVEY -->
    <survey-form
      v-if="model.data.id && model.data.surveylist[0]"
      v-model="surveyClone"
      :id="surveyClone.data.id"
      :project-id="model.data.id"
      class="mt-n4"
      collapsed
    ></survey-form>

<!--    <v-container fluid class="pa-4">-->
<!--      <ProjectDashboardBuilderForm-->
<!--        v-if="model.data.id"-->
<!--        v-model="dashboard"-->
<!--        :items="dashboards"-->
<!--        :project="model"-->
<!--        id="project_dashboard_builder"-->
<!--        class="mt-n4"-->
<!--        collapsed-->
<!--      />-->
<!--    </v-container>-->
  </div>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { projectStatusList, projectTypeList, stageList, objectiveList, picotList, criteriaList } from '@/enums/global';
import DataForm from '@/modules/common/components/DataForm.vue';
import ProjectService from '@/services/project.service';
import ProjectModel from '@/models/project.model';
import UserService from '@/modules/sdk/services/user.service';
import ProjectUserModel from '@/models/project-user.model';
import UserModel from '@/modules/sdk/models/user.model';
// import ProjectStatusReasonList from '@/views/Admin/List/ProjectStatusReasonList.vue';
import KeywordList from '@/views/Admin/List/KeywordList.vue';
import CategoryList from '@/views/Admin/List/CategoryList.vue';
import RecordList from '@/views/Admin/List/RecordList.vue';
import ImportDialog from '@/views/Admin/Dialog/ImportDialog.vue';
import ImportStatusDialog from '@/views/Admin/Dialog/ImportStatusDialog.vue';
import RecordAiStatusList from '@/views/Admin/Component/RecordAiStatusList.vue';
import SurveyModel from '@/modules/sdk/models/survey.model';
import Wysiwyg from '@/modules/common/components/Wysiwyg.vue';
import DuplicateProjectModal from '@/components/DuplicateProjectModal.vue';
import SurveyForm from '@/views/Admin/Form/SurveyForm.vue';
import SoftDeleteAutocomplete from '@/modules/common/components/SoftDeleteAutocomplete.vue';
import AskQuestionToAiModal, {ISelectedData} from '@/components/AskQuestionToAiModal.vue';
import ProjectDashboardBuilderForm from '@/views/Admin/Form/ProjectDashboardBuilderForm.vue';
import VisualizationModel from '@/modules/sdk/models/visualization.model';
import VisualizationService from '@/modules/sdk/services/visualization.service';
import TaskModel from '@/models/task.model';
import TaskService from '@/services/task.service';
import TagsComponent from '@/views/Admin/Component/Record/TagsComponent.vue';
import CategoriesComponent from '@/views/Admin/Component/Project/CategoriesComponent.vue';

@Component({
  components: {
    CategoriesComponent,
    TagsComponent,
    RecordAiStatusList,
    SoftDeleteAutocomplete,
    SurveyForm,
    Wysiwyg,
    DataForm,
    ImportDialog,
    ImportStatusDialog,
    // ProjectStatusReasonList,
    KeywordList,
    CategoryList,
    RecordList,
    AskQuestionToAiModal,
    ProjectDashboardBuilderForm,
    DuplicateProjectModal,
  }
})
export default class ProjectForm extends Vue {
  service = ProjectService.getInstance();
  loading = false;
  loadingDashboards = false;
  surveyClone: SurveyModel = new SurveyModel();
  dashboard: VisualizationModel = new VisualizationModel();
  dashboards: Array<VisualizationModel> = [];
  model: ProjectModel = new ProjectModel({
    surveylist: [new SurveyModel()]
  });

  loadingUsers = false;
  aiRecordStatusLoading = false;
  resetOfficialStatusLoading = false;
  resetStatusTypeLoading = false;
  updateRecordSimilarityScoreLoading = false;
  importExistingStatusLoading = false;
  importCsvLoading = false;
  showDuplicateProjectModal = false;
  userNodesTypeList: { [key: string]: Array<ProjectUserModel> } = {};

  statusList = projectStatusList;
  typeList = projectTypeList;
  stageList = stageList;
  objectiveList = objectiveList;
  picotList = picotList;
  criteriaList = criteriaList;

  userList = [];
  answers = [];

  importCsvModal = false;
  importExistingStatusModal = false;

  askAiModal: {
    visible: boolean,
    project: ProjectModel,
  } = {
    visible: false,
    project: new ProjectModel(),
  }

  @Watch('model.data.id')
  onModelIdChange(id: number) {
    if ((id && !this.model.data.surveylist) || this.model.data.surveylist.length === 0) {
      this.model.data.surveylist = [new SurveyModel()];
      this.model.setOriginalData();
    }
  }

  onKeypressEnterPicot(event: any, index: number, model: Array<string>) {
    event.stopPropagation();
    event.preventDefault();

    model.splice(index + 1, 0, '');

    setTimeout(() => {
      const inputElements = Array.from(event.target.form.querySelectorAll('input:not([disabled]):not([readonly])'));
      const currentIndex = inputElements.indexOf(event.target);
      const element = inputElements[currentIndex < inputElements.length - 1 ? currentIndex + 1 : 0] as any;
      element.focus()
    }, 0);
  }

  onPastePicot(event: any, index: number, model: Array<string>) {

    if ((model[index] || '').trim() !== '') {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    // @ts-ignore
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('Text');
    const splitData = pastedData.split('\n')
      .filter((item: string) => item.trim() !== '')
      .map((item: string) => item.trim());

    model.splice(index + 1, 0, ...splitData);
    model.splice(index, 1);
  }

  get selectedAiQuestions(): string[] {
    const questions: string[] = [];
    this.surveyClone.data.surveysectionlist.forEach((section: any) => {
      section.data.surveygrouplist.forEach((group: any) => {
        group.data.surveyquestionlist.forEach((question: any) => {
          question.data.surveyaiquestionlist.forEach((aiQuestion: any) => {
            questions.push(aiQuestion.data.id);
          });
        });
      })
    });
    return questions;
  }

  get canDuplicateProject(): boolean {
    return !!(this.model.data.id);
  }

  get defaultSelectedData(): ISelectedData {
    const isFinal = this.selectedStage?.value === 'final';
    return {
      objectives: !isFinal,
      picots: !isFinal,
      criteria: !isFinal,
      abstract: !isFinal,
      fullText: isFinal,
    }
  }

  get isLoading(): boolean {
    return this.loading
      || this.resetOfficialStatusLoading
      || this.resetStatusTypeLoading
      || this.aiRecordStatusLoading
      || this.importExistingStatusLoading
      || this.updateRecordSimilarityScoreLoading
  }

  get selectedStage(): undefined | { value: string, text: string } {
    return this.stageList.find(stage => stage.value === this.model.data.stage);
  }

  get leaderUserList(): Array<ProjectUserModel> {
    return this.userListByType('leader');
  }

  set leaderUserList(value: Array<ProjectUserModel>) {
    this.updateModelUserNode(value, 'leader');
  }

  get arbitratorUserList(): Array<ProjectUserModel> {
    return this.userListByType('arbitrator');
  }

  set arbitratorUserList(value: Array<ProjectUserModel>) {
    this.updateModelUserNode(value, 'arbitrator');
  }

  get researcherUserList(): Array<ProjectUserModel> {
    return this.userListByType('researcher');
  }

  set researcherUserList(value: Array<ProjectUserModel>) {
    this.updateModelUserNode(value, 'researcher');
  }

  get secondaryResearcherUserList(): Array<ProjectUserModel> {
    return this.userListByType('secondary-researcher');
  }

  set secondaryResearcherUserList(value: Array<ProjectUserModel>) {
    this.updateModelUserNode(value, 'secondary-researcher');
  }

  get aiManagerUserList(): Array<ProjectUserModel> {
    return this.userListByType('ai-manager');
  }

  set aiManagerUserList(value: Array<ProjectUserModel>) {
    this.updateModelUserNode(value, 'ai-manager');
  }

  get userNodesTypeListCount(): number {
    return this.model.data.usernode.filter((userNode: ProjectUserModel) => !userNode.data.deleted).length || 0;
  }

  onImportCsv(): void {
    this.importCsvModal = true;
  }

  onImportExistingStatus(): void {
    this.importExistingStatusModal = true;
  }

  onAiQuestionApply(): void {
    this.$root.$globalSnack.info({
      message: 'AI Process is currently running..'
    })
  }

  onAiRecordStatus(): void {
    Object.assign(this.askAiModal, {
      visible: true,
      project: this.model,
    });
  }

  onResetOfficialStatus(): void {
    this.$root.$shouldTakeAction.ask({
      title: this.$i18n.t('projectForm.shouldResetOfficialStatus.title'),
      body: this.$i18n.t('projectForm.shouldResetOfficialStatus.body'),
      actionText: this.$i18n.t('btn.reset'),
      color: 'error',
      dark: true,
      onAccept: this.resetOfficialStatus
    })
  }

  onResetStatusType(): void {
    this.$root.$shouldTakeAction.ask({
      title: this.$i18n.t('projectForm.shouldResetStatusType.title'),
      body: this.$i18n.t('projectForm.shouldResetStatusType.body'),
      actionText: this.$i18n.t('btn.reset'),
      color: 'error',
      dark: true,
      onAccept: this.resetStatusType,
    })
  }

  onUpdateRecordsSimilarityScore(): void {
    this.$root.$shouldTakeAction.ask({
      title: this.$i18n.t('projectForm.shouldUpdateRecordSimilarityScore.title'),
      body: this.$i18n.t('projectForm.shouldUpdateRecordSimilarityScore.body'),
      actionText: this.$i18n.t('btn.update'),
      onAccept: this.updateRecordSimilarityScore
    })
  }

  onAddSurvey(): void {
    this.model.data.surveylist.push(new SurveyModel());
  }

  onDuplicateProject() {
    this.showDuplicateProjectModal = true;
  }

  userListByType(type: string): Array<ProjectUserModel> {
    return this.model.data.usernode?.filter((node: ProjectUserModel) => node.data.type === type && !node.data.deleted);
  }

  userNodeByType(type: string): Array<ProjectUserModel> {
    if (!this.userNodesTypeList[type] || !this.userNodesTypeList[type].length) {
      this.userNodesTypeList[type] = this.userList.map((user: UserModel) => new ProjectUserModel({
        type: type,
        userId: user.data.id,
        deleted: 0,
        userentity: user
      }));
    }
    return this.userNodesTypeList[type];
  }

  updateModelUserNode(nodeList: Array<ProjectUserModel>, type: string) {
    if (!Array.isArray(this.model.data.usernode)) {
      this.model.data.usernode = [];
    }

    // merge array of objects by properties
    for (const source of nodeList) {
      source.assignToArrayByProperty(this.model.data.usernode, { userId: source.data.userId, type: type });
    }

    // keep intersect only
    this.model.data.usernode.map((nodeEntity: ProjectUserModel) => {
      if (nodeEntity.data.type === type && !nodeList.some((nodeEntity2: ProjectUserModel) => nodeEntity.data.userId === nodeEntity2.data.userId)) {
        nodeEntity.data.deleted = 1;
      }
      return nodeEntity;
    });
  }

  resetOfficialStatus() {
    this.resetOfficialStatusLoading = true;
    const id = this.model.data.id;
    ProjectService.getInstance().resetOfficialStatus({ id }).then((response) => {
      if (response.data.view.saved) {
        this.$root.$globalSnack.info({
          message: 'Project\'s Official Record Status has been successfully updated (' + response.data.view.saved + ' records).',
        });
      }
    }).catch((response) => {
      this.$root.$globalSnack.warning({
        message: 'An unexpected error has occurred while trying reset the official record status for the project.',
        icon: 'mdi-emoticon-dead-outline'
      });
    }).finally(() => this.resetOfficialStatusLoading = false);
  }

  resetStatusType() {
    this.resetStatusTypeLoading = true;
    const id = this.model.data.id;
    ProjectService.getInstance().resetStatusType({ id }).then((response) => {
      if (response.data.view.saved) {
        this.$root.$globalSnack.info({
          message: 'Project\'s Official Record Status has been successfully updated (' + response.data.view.saved + ' records).',
        });
      }
    }).catch((response) => {
      this.$root.$globalSnack.warning({
        message: 'An unexpected error has occurred while trying reset the official record status for the project.',
        icon: 'mdi-emoticon-dead-outline'
      });
    }).finally(() => this.resetStatusTypeLoading = false);
  }

  updateRecordSimilarityScore() {
    this.updateRecordSimilarityScoreLoading = true;
    const id = this.model.data.id;
    const task = new TaskModel();
    task.data.projectId = id;
    task.data.action = 'project_record_compare';
    task.data.status = 'new';
    TaskService.getInstance().save(task).then((response) => {
      if (response.data.view.saved) {
        this.$root.$globalSnack.info({
          message: 'A new task has been initiated. The similarity scores for the records will be updated asynchronously.'
        });
      }
    }).catch((response) => {
      this.$root.$globalSnack.warning({
        message: 'An unexpected error has occurred while trying to create a new task.',
        icon: 'mdi-emoticon-dead-outline'
      });
    }).finally(() => this.updateRecordSimilarityScoreLoading = false);
  }

  load(id: number) {
    this.loading = true;
    this.service.get({ id })
      .then(response => {
        this.model.assign(response.data.view.single);
        if (this.model.data.surveylist.length > 0) {
          this.surveyClone = this.model.data.surveylist[0].clone();
        } else {
          this.model.data.surveylist.push(new SurveyModel());
        }
        this.surveyClone.data.projectId = this.model.data.id;
        this.model.setOriginalData();
        this.surveyClone.setOriginalData();
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  loadDashboards(id: number): Promise<VisualizationModel> {
    this.loadingDashboards = true;
    return VisualizationService.getInstance().getAll({
      filters: [{
        field: 'projectId',
        operator: 'equals',
        value: id,
      }, {
        field: 'deleted',
        operator: 'equals',
        value: '0'
      }]
    })
      .then(response => {
        this.dashboards = response.data.view.list;
        if (this.dashboards.length > 0) {
          this.dashboard = response.data.view.list[0];
        } else {
          this.dashboard = new VisualizationModel();
        }
        return response.data.view.list;
      })
      .finally(() => this.loadingDashboards = false);
  }

  loadUserList() {
    this.loadingUsers = true;
    UserService.getInstance().getAll({
      order: 'firstName ASC, lastName ASC'
    })
      .then(response => {
        this.userList = response.data.view.list;
        this.userNodeByType('leader');
        this.userNodeByType('arbitrator');
        this.userNodeByType('researcher');
        this.userNodeByType('secondary-researcher');
        this.userNodeByType('ai-manager');
      })
      .finally(() => this.loadingUsers = false);
  }

  created() {
    this.loadUserList();

    if (this.$route.params.id) {
      const projectId = parseInt(this.$route.params.id);
      this.load(projectId);
      this.loadDashboards(projectId);
    }
  }
}
</script>
