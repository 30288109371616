<template>
  <v-form
    v-test-id="'login'"
    v-model="valid"
    :disabled="loginLoading"
    name="login"
    ref="form"
    lazy-validation
    @submit="onSubmit"
  >
    <template v-if="!mustValidateDeviceIp">
      <v-text-field
        name="email"
        v-test-id="'email'"
        v-model="data.email"
        v-safechar
        :error-messages="errors.email"
        :label="$t('placeholder.email')"
        :rules="[rules.required, rules.email]"
        :autofocus="$vuetify.breakpoint.mdAndUp"
        prepend-inner-icon="mdi-account"
        autocomplete="email"
        tabindex="1"
        outlined
        required
        @input="errors = {}"
      />

      <v-text-field
        name="password"
        v-test-id="'password'"
        v-model="data.password"
        v-safechar
        :error-messages="errors.password"
        :label="$t('placeholder.password')"
        :rules="[rules.required]"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        prepend-inner-icon="mdi-lock"
        autocomplete="password"
        tabindex="2"
        outlined
        required
        @click:append="showPassword = !showPassword"
        @input="errors = {}"
      />
    </template>

    <template v-if="mustValidateDeviceIp">
      <v-alert
        v-test-id="'code-sent-alert'"
        type="info"
        text
        class="text-left"
      >Check your mailbox for your code and paste it below.
      </v-alert>
      <v-otp-input
        name="code"
        v-test-id="'code'"
        v-model="data.code"
        :error-messages="errors.code"
        :label="$t('placeholder.code')"
        :rules="[rules.required]"
        type="number"
        length="6"
        @input="delete errors.code"
      ></v-otp-input>
      <p>If you did not received the code, you can always request another one:</p>
      <v-btn
        v-test-id="'code-resend-alert'"
        :disabled="!canResend"
        outlined
        block
        class="mb-4"
        @click="onResendClick"
      >
        Resend<span v-if="resendTimeout !== null && resendTimeout > 0"> ({{ resendTimeout }})</span>
      </v-btn>
    </template>

    <i18n path="auth.acceptTerms" tag="span">
      <a v-text="$t('auth.acceptTermsText')" @click.stop="$emit('terms')"></a>
    </i18n>

    <v-btn
      v-test-id="'submit'"
      class="mt-4"
      tabindex="3"
      type="submit"
      color="primary"
      :disabled="!canSubmit"
      :loading="loginLoading"
      block
    >
      <v-icon left>mdi-login-variant</v-icon>
      <span v-text="$t('btn.login')"></span>
    </v-btn>

    <template v-if="!mustValidateDeviceIp">
      <v-btn
        :to="{ name: 'forgotPassword' }"
        small
        block
        text
        class="mt-2"
      >
        Forgot your password?
      </v-btn>
    </template>
  </v-form>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Component, Ref, Vue } from 'vue-property-decorator';
import Main from '@/main';
import App from '@/App.vue';
import Rules from '@/modules/sdk/core/rules';
import Identity from '@/modules/sdk/core/identity';
import AuthService from '@/modules/sdk/services/auth.service';
import Logger from '@/modules/sdk/core/logger';

const d = new Logger('Auth/Login.vue');
let resendInterval: any = 0;

export type VForm = Vue & { validate: () => boolean }

@Component({})
export default class LoginView extends Vue {
  @Ref() readonly form!: VForm

  loginLoading = false;
  logoutLoading = false;
  resendTimeout: number | null = null;

  mustConsentToTerms = false;
  mustValidateDeviceIp = false;
  showPassword = false;
  valid = true;
  errors = {};
  rules = {};

  data: {
    consent?: boolean
    email: string
    password: string
    code?: string
  } = {
    email: '',
    password: '',
  };

  get loading() {
    return this.loginLoading || this.logoutLoading;
  }

  get canSubmit() {
    return !this.loading && this.valid;
  }

  get canResend(): boolean {
    return this.resendTimeout !== null && this.resendTimeout === 0;
  }

  onSubmit(event: any) {
    event.preventDefault();
    this.errors = {};
    if (this.form && this.form.validate()) {
      this.login();
    }
  }

  onResendClick(): void {
    this.login();
  }

  loginWithConsent() {
    this.data.consent = true;
    return this.login();
  }

  login(): Promise<any> {
    this.loginLoading = true;
    const email = this.data.email;
    const password = this.data.password;
    const code = this.data.code;
    const consent = this.data.consent;
    this.resendCountdown();
    return AuthService.getInstance().login({ email, password, code, consent })
      .then((response: any) => {
        if (response.data.view.loggedIn) {
          Identity.setIdentity(response.data.view);
          this.$router.push(this.$route.params.redirect || { path: '/' }).catch(failure => {
            this.$root.$globalSnack.warning({
              message: 'Navigation failure: ' + failure,
              icon: 'mdi-emoticon-dead-outline'
            });
          })
          Main.render(App);
        }
        else {
          this.$root.$globalSnack.warning({
            message: this.$t('error.login'),
            icon: 'mdi-emoticon-dead-outline'
          });
        }
      })
      .catch(reason => {
        if (reason?.response?.data?.view?.trusted === false) {
          this.resendCountdown();
          this.mustValidateDeviceIp = true;
          return;
        }
        if (reason?.response?.data?.view?.consent === false) {
          this.$emit('terms', this.loginWithConsent);
          return;
        }

        this.$root.$zemit.handleError(reason, this.errors);
      })
      .finally(() => this.loginLoading = false);
  }

  resendCountdown() {
    this.resendTimeout = 60;
    clearInterval(resendInterval);
    resendInterval = setInterval(() => {
      if (this.resendTimeout !== null && this.resendTimeout !== 0) {
        this.resendTimeout--;
      }
      if (this.resendTimeout === 0) {
        clearInterval(resendInterval);
      }
    }, 1000);
  }

  destroyed() {
    clearInterval(resendInterval);
  }

  created() {
    this.rules = {
      required: (value: string) => Rules.required(value) || this.$t('rules.required'),
      email: (value: string) => Rules.email(value) || this.$t('rules.email'),
    };
  }
}
</script>
