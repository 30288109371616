<template>
  <v-card
    :style="{ borderLeft: mode === 'edit' && model.isHiddenByDefault() ? 'rgba(0, 0, 0, 0.25) dashed 5px' : 'var(--v-warning-base) solid 5px' }"
  >
    <SurveyHeader
      v-model="model"
      :survey="survey"
      :list="parent?.data.surveyquestionlist"
      :mode="mode"
      :debug="debug"
      :index="index"
      :readonly="readonly"
      :extra-fields="extraFields"
      :title-attrs="{
        autoGrow: true,
        rows: 1,
        noResize: true,
        class: 'mt-4',
        style: 'word-break: normal',
      }"
      component="v-textarea"
      label="Question"
      slot-prefix="question"
    >
      <template v-for="(slot, key) in $scopedSlots" #[key]="props">
        <slot :name="key" v-bind="props"></slot>
      </template>
      <template v-if="model.fieldCanBeUnknown()" #items.options.append>
        <v-list-item v-if="model.fieldCanBeUnknown()" :class="{ 'v-list-item--active primary--text': model.data.includeUnknown }" @click="() => model.data.includeUnknown = !model.data.includeUnknown">
          <v-list-item-icon>
            <v-icon v-if="model.data.includeUnknown">mdi-checkbox-marked-outline</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Include unknown</v-list-item-title>
        </v-list-item>
      </template>
      <template #options.prepend>
        <SurveyTypeMenu
          v-model="model"
          class="mr-4 mt-4 pt-0"
        />
      </template>
    </SurveyHeader>
    <div v-if="mode === 'answer'" :class="{
      'pa-4': true,
      'pt-0': model.data.includeLabel && model.data.label,
    }">
      <div v-if="model.data.includePrependText" v-html="model.data.prependText" class="body-1"></div>

      <div class="d-flex align-start" style="gap: 0.5rem">
        <v-radio-group
          v-if="viewOfficialize && canSeeAnswers"
          v-model="selectedAnswer"
          class="ma-0 pa-0 mt-2"
          hide-details
          style="flex: 0"
        >
          <v-radio
            value="current"
          />
        </v-radio-group>
        <div style="flex: 1">
          <SurveyChoiceField
            v-model="model"
            v-bind="computedCascadeAttrs"
            :survey="survey"
            :question="model"
            :index="index"
            :tick="tick"
            @answer="onAnswer"
          >
            <template v-for="(slot, key) in $scopedSlots" #[key]="props">
              <slot :name="key" v-bind="props"></slot>
            </template>
          </SurveyChoiceField>
          <div v-if="model.data.official" class="caption font-weight-bold primary--text">
            (Official answer)
          </div>
        </div>
      </div>

      <!-- ANSWER LIST (APPLICABLE) -->
      <v-expand-transition>
        <div v-if="canSeeAnswers">
          <v-alert
            color="primary"
            border="left"
            class="mt-4 mb-0"
            elevation="2"
            colored-border
          >
            <h5>Available answers:</h5>
            <v-alert
              v-if="haveConflicts"
              type="error"
              icon="mdi-message-alert"
              border="left"
              class="my-2"
              colored-border
              text
            >
              <span class="caption text--error">Some of the answers may conflict with each other.</span>
            </v-alert>
            <v-radio-group
              v-model="selectedAnswer"
              class="ma-0 pa-0"
              hide-details
            >
              <div
                v-for="answer in filteredQuestionAnswerList"
                :key="answer.data.id"
                class="d-flex align-start mt-2"
                style="gap: 0.5rem"
              >
                <v-radio :value="answer.data.id" />
                <div>
                  <div :class="['body-1', {
                    'primary--text': canSeeOfficialAnswers && answer.data.official,
                  }]">
                    <strong v-if="canSeeOfficialAnswers && answer.data.official">(Official) </strong>
<!--                    <strong v-if="answer.data.createdBy === currentUserId">(Your answer) </strong>-->
                    <span>{{ getAnswer(answer) }}</span>
                  </div>
                  <div v-if="canSeeAnswerName" class="caption mt-n1">By {{ getAnswerText(answer) }}</div>
                  <div v-text="answer.data.createdAt" class="caption mt-n1"></div>
                </div>
              </div>
            </v-radio-group>
          </v-alert>

          <v-btn
            v-if="viewOfficialize"
            :disabled="!canOfficialize()"
            class="mt-3"
            block
            outlined
            small
            @click="onOfficializeClick"
          >
            Officialize
          </v-btn>
        </div>
      </v-expand-transition>

      <div v-if="model.data.includeAppendText" v-html="model.data.appendText" class="body-1 mt-4"></div>
    </div>
    <v-card-text v-if="mode === 'edit' && (model.canDefineChoices() || model.canDefineOptions() || model.data.includePrependText || model.data.includeAppendText)">
      <v-expand-transition>
        <div v-if="model.data.includePrependText">
          <Wysiwyg
            v-model="model.data.prependText"
            label="Prepend text"
            :height="200"
          ></Wysiwyg>
        </div>
      </v-expand-transition>

      <!-- CHOICES / OPTIONS / LIKERT / AI QUESTIONS -->
      <v-card v-if="model.data.type === 'likert' || model.canDefineChoices() || model.canDefineMultipleQuestions() || model.canDefineOptions() || model.canDefineAiQuestions()" outlined>
        <v-expansion-panels flat>
          <template v-if="model.data.type === 'likert'">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div>
                  <v-icon left>mdi-table-column</v-icon>
                  <span v-text="$tc('survey.columns.title', model.data.keys.length, {
                    total: model.data.keys.length,
                  })"></span>
                </div>

                <div class="mr-3" style="flex: 0">
                  <v-chip v-if="model.data.keys.length === 0" color="error" small label>
                    Required
                  </v-chip>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ListBuilder
                  v-model="model.data.keys"
                  :default-model="surveyChoiceModel"
                  :default-data="{
                    content: ''
                  }"
                  :readonly="readonly"
                  :sortable="sortable"
                  :show-removed="showRemoved"
                  :labels="{
                    add: 'Add a new column',
                    remove: 'Remove column',
                    empty: 'No column available yet',
                  }"
                  :btn-attrs="{
                    color: 'text',
                    outlined: true,
                  }"
                  :button-offset="5"
                  group="survey_likert_column"
                  class="pt-1"
                  restorable
                >
                  <template #item="choice">
                    <SurveyChoice
                      v-model="choice.item"
                      v-bind="computedCascadeAttrs"
                      :survey="survey"
                      :parent="model"
                      :index="choice.index"
                      label="Label"
                    />
                  </template>
                </ListBuilder>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
          <v-expansion-panel v-if="model.canDefineChoices()">
            <v-expansion-panel-header>
              <div>
                <v-icon left>mdi-clipboard-list-outline</v-icon>
                <span v-text="$tc('survey.choice.title', model.data.surveychoicelist.length, {
                  total: model.data.surveychoicelist.length,
                })"></span>
              </div>

              <div class="mr-3" style="flex: 0">
                <v-chip v-if="model.data.surveychoicelist.length === 0" color="error" small label>
                  Required
                </v-chip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ListBuilder
                v-model="model.data.surveychoicelist"
                :default-model="surveyChoiceModel"
                :default-data="{
                  projectId: model.data.projectId,
                  surveyId: model.data.surveyId,
                  surveySectionId: model.data.surveySectionId,
                  surveyGroupId: model.data.surveyGroupId,
                  surveyQuestionId: model.data.id,
                }"
                :readonly="readonly"
                :sortable="sortable"
                :show-removed="showRemoved"
                :labels="{
                  add: 'Add a new choice',
                  remove: 'Remove choice',
                  empty: 'No choice available yet',
                }"
                :btn-attrs="{
                  color: 'text',
                  outlined: true,
                }"
                :button-offset="5"
                group="survey_choice"
                class="pt-1"
                order-by="position"
                restorable
              >
                <template #item="choice">
                  <SurveyQuestion
                    v-if="choice.item.data.isQuestion"
                    v-model="choice.item.data.surveysubquestionentity"
                    v-bind="computedCascadeAttrs"
                    :survey="survey"
                    :parent="model"
                    :index="index"
                    @answer="onAnswer"
                  >
                    <template v-for="(slot, key) in $scopedSlots" #[key]="props">
                      <slot :name="key" v-bind="props"></slot>
                    </template>
                  </SurveyQuestion>
                  <SurveyChoice
                    v-else
                    v-model="choice.item"
                    v-bind="computedCascadeAttrs"
                    :survey="survey"
                    :question="model"
                    :index="choice.index"
                  >
                    <template v-for="(slot, key) in $scopedSlots" #[key]="props">
                      <slot :name="key" v-bind="props"></slot>
                    </template>
                    <template v-if="model.choiceCanIncludeOtherField() || model.choiceCanBeACategory() || model.fieldCanBeUnknown()" #items.append="{ choice }">
                      <v-list-item v-if="model.choiceCanIncludeOtherField()" :class="{ 'v-list-item--active primary--text': choice.data.includeOther }" @click="() => choice.data.includeOther = !choice.data.includeOther">
                        <v-list-item-icon>
                          <v-icon v-if="choice.data.includeOther">mdi-checkbox-marked-outline</v-icon>
                          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Include other field</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="model.choiceCanBeQuestion()" :class="{ 'v-list-item--active primary--text': choice.data.isQuestion }" @click="() => choice.data.isQuestion = !choice.data.isQuestion">
                        <v-list-item-icon>
                          <v-icon v-if="choice.data.isQuestion">mdi-checkbox-marked-outline</v-icon>
                          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Is a question</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="model.choiceCanBeACategory()" :class="{ 'v-list-item--active primary--text': choice.data.isCategory }" @click="() => choice.data.isCategory = !choice.data.isCategory">
                        <v-list-item-icon>
                          <v-icon v-if="choice.data.isCategory">mdi-checkbox-marked-outline</v-icon>
                          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Is a category</v-list-item-title>
                      </v-list-item>
                    </template>
                  </SurveyChoice>
                </template>
              </ListBuilder>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="model.canDefineMultipleQuestions()">
            <v-expansion-panel-header>
              <div>
                <v-icon left>mdi-clipboard-list-outline</v-icon>
                <span v-text="$tc('survey.subQuestions.title', model.data.surveychoicelist.length, {
                  total: model.data.surveychoicelist.length,
                })"></span>
              </div>

              <div class="mr-3" style="flex: 0">
                <v-chip v-if="model.data.surveychoicelist.length === 0" color="error" small label>
                  Required
                </v-chip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <ListBuilder
                v-model="model.data.surveychoicelist"
                :default-model="surveyChoiceModel"
                :default-data="{
                  projectId: model.data.projectId,
                  surveyId: model.data.surveyId,
                  surveySectionId: model.data.surveySectionId,
                  surveyGroupId: model.data.surveyGroupId,
                  surveyQuestionId: model.data.id,
                }"
                :readonly="readonly"
                :sortable="sortable"
                :show-removed="showRemoved"
                :labels="{
                  add: 'Add a new sub-question',
                  remove: 'Remove sub-question',
                  empty: 'No sub-question available yet',
                }"
                :btn-attrs="{
                  color: 'text',
                  outlined: true,
                }"
                :button-offset="5"
                group="survey_choice"
                class="pt-1"
                order-by="position"
                restorable
              >
                <template #item="choice">
                  <SurveyChoice
                    v-model="choice.item"
                    v-bind="computedCascadeAttrs"
                    :survey="survey"
                    :parent="model"
                    :index="choice.index"
                    label="Sub-question"
                  >
                    <template v-for="(slot, key) in $scopedSlots" #[key]="props">
                      <slot :name="key" v-bind="props"></slot>
                    </template>
                    <template v-if="model.fieldCanBeUnknown()" #items.append="{ choice }">
                      <v-list-item v-if="model.fieldCanBeUnknown()" :class="{ 'v-list-item--active primary--text': choice.data.includeUnknown }" @click="() => choice.data.includeUnknown = !choice.data.includeUnknown">
                        <v-list-item-icon>
                          <v-icon v-if="choice.data.includeUnknown">mdi-checkbox-marked-outline</v-icon>
                          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Include unknown</v-list-item-title>
                      </v-list-item>
                    </template>
                  </SurveyChoice>
                </template>
              </ListBuilder>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="model.canDefineOptions()">
            <v-expansion-panel-header>
              <div>
                <v-icon left>mdi-card-bulleted-settings-outline</v-icon>
                Options
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <SurveyQuestionOptions
                v-model="model"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="model.canDefineAiQuestions()">
            <v-expansion-panel-header>
              <div>
                <v-icon left>mdi-robot-confused-outline</v-icon>
                AI Questions
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <SurveyAiQuestions
                v-model="model"
                :readonly="readonly"
                :index="index"
                :survey="survey"
                :show-removed="showRemoved"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>

      <!-- APPEND TEXT -->
      <v-expand-transition>
        <div v-if="model.data.includeAppendText" class="mt-4">
          <Wysiwyg
            v-model="model.data.appendText"
            label="Append text"
            :height="200"
          ></Wysiwyg>
        </div>
      </v-expand-transition>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator';
import SurveyChoice from '@/modules/common/components/SurveyChoice.vue';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';
import SurveyChoiceModel from '@/modules/sdk/models/survey-choice.model';
import SurveyGroupModel from '@/modules/sdk/models/survey-group.model';
import SurveyHeader from '@/modules/common/components/SurveyHeader.vue';
import SurveyTypeMenu from '@/modules/common/components/SurveyTypeMenu.vue';
import SurveyQuestionOptions from '@/modules/common/components/SurveyQuestionOptions.vue';
import SurveyAiQuestions from '@/modules/common/components/SurveyAiQuestions.vue';
import Wysiwyg from '@/modules/common/components/Wysiwyg.vue';
import SurveyChoiceField from '@/modules/common/components/SurveyChoiceField.vue';
import SurveyItemMixin from '@/modules/common/mixins/survey';
import Identity from '@/modules/sdk/core/identity';
import SurveyAnswerItemModel from '@/modules/sdk/models/survey-answer-item.model';

@Component({
  components: {
    SurveyChoiceField,
    Wysiwyg,
    SurveyTypeMenu,
    SurveyHeader,
    SurveyChoice,
    SurveyQuestionOptions,
    SurveyAiQuestions,
  }
})
export default class SurveyQuestion extends SurveyItemMixin<SurveyQuestionModel, SurveyGroupModel> {
  surveyChoiceModel = SurveyChoiceModel
  selectedAnswer: number | null | 'current' = null
  currentUserId = Identity.getIdentity()?.user.id

  @Watch('answerList', { immediate: true, deep: true })
  onAnswerListChange() {
    if (this.selectedAnswer !== 'current') {
      this.selectedAnswer = (this.filteredQuestionAnswerList.find(answer => answer.data.official) || { data: { id: null } }).data.id;
    }
  }

  onApplyClick() {
    this.model.answer = this.selectedAnswerModel?.constructor.name === 'SurveyAiAnswerModel'
      ? this.selectedAnswerModel?.data.answer
      : this.model.valueIsNumeric()
        ? parseInt(this.selectedAnswerModel?.data.content)
        : this.selectedAnswerModel?.data.content;
  }

  onOfficializeClick() {
    const filteredAnswerList = this.answerList.filter(answer => answer.data.surveyQuestionId === this.model.data.id);
    filteredAnswerList.forEach(answer => answer.data.official = false);
    this.model.data.official = false;
    if (this.selectedAnswerModel) {
      // if (this.selectedAnswerModel.constructor.name === 'SurveyAiAnswerModel') {
      //   this.model.data.official = true;
      // }
      this.selectedAnswerModel.data.official = true;
    } else {
      this.model.data.official = true;
    }
  }

  get selectedAnswerIsCurrent(): boolean {
    return this.selectedAnswer === 'current' && !this.selectedAnswerModel?.data.id
  }

  get haveConflicts(): boolean {
    return !this.filteredQuestionAnswerList.every(item => item.data.content === this.filteredQuestionAnswerList[0].data.content);
  }

  get filteredQuestionAnswerList(): SurveyAnswerItemModel[] {
    return this.answerList.filter(answer =>
      answer.data.surveyQuestionId === this.model.data.id
      // && answer.data.createdBy !== Identity.getIdentity()?.user.id
      && (
        this.userList.length === 0
        || !!(this.userList.find(user =>
          user.value === answer.data.userId
          || (user.value === 'official' && answer.data.official)
          || (user.value === 'ai' && answer.constructor.name === 'SurveyAiAnswerModel')
        ))
      )
    )
  }

  get selectedAnswerModel(): SurveyAnswerItemModel | null {
    return this.filteredQuestionAnswerList.find(answer => answer.data.id === this.selectedAnswer || (this.selectedAnswer === 'current' && !answer.data.id && answer.data.surveyQuestionId === this.model.data.id)) || null;
  }

  get canApply(): boolean {
    return this.selectedAnswer !== null
      // Depending on type of value, we want to check with a == and not === (for instance: surveyChoiceId (int) VS content (string))
      // eslint-disable-next-line eqeqeq
      && this.selectedAnswerModel?.data.content != this.model.answer;
  }

  get viewOfficialize(): boolean {
    return Identity.hasRole(['arbitrator']);
  }

  canOfficialize(): boolean {
    return this.viewOfficialize
      && !!(this.selectedAnswer)
      && (
        !!(this.selectedAnswerModel && !this.selectedAnswerModel.data.official)
        || (!this.selectedAnswerModel && !this.model.data.official)
      );
  }

  get canSeeAnswers(): boolean {
    return this.userList.length > 0
      && this.answerList.length > 0
      && this.filteredQuestionAnswerList.length > 0;
  }

  getAnswer(answer: SurveyAnswerItemModel): string | null {
    if (this.model.data.type === 'country') {
      return (this.countryList.find(country => country.data.code === answer.data.content) || { data: { label: null }}).data.label;
    } else if (this.model.data.type === 'autocomplete') {
      return this.autocomplete[answer.data.content];
    } else if (answer.constructor.name === 'SurveyAiAnswerModel') {
      return answer.data.answer;
    }
    return this.model.canDefineChoices()
      ? (this.model.getItems().find(choice => choice.id === parseInt(answer.data.content)) || { text: null }).text
      : answer.data.content;
  }

  getAnswerText(answer: SurveyAnswerItemModel): string {
    return answer.constructor.name === 'SurveyAiAnswerModel'
      ? 'AI'
      : answer.data.userentity.getFullName();
  }
}
</script>
