import moment from 'moment';
import Identity from '@/modules/sdk/core/identity';
import ErrorLogService from '@/modules/sdk/services/error-log.service';
import ErrorLogModel from '@/modules/sdk/models/error-log.model';

interface IError {
  key: string,
  message: string,
  stack: string,
  date: string,
  lastTimestamp: string,
  version: string,
  route: string,
  userId: number,
  count: number,
  userAgent: string,
  sent: boolean,
  // storage: { key: string, value: string | null }[],
}

let errorQueue: IError[] = [];
const consoleErrorOriginalReference = console.error;

let syncErrorInterval: number;

console.error = function(e) {
  if (e instanceof Object) {
    const key = (e.message + ' - ' + e.stack.split('\n')[1].trim()).trim().substring(0, 255);
    const currentDate = moment().format('YYYY-MM-DD');
    const lastTimestamp = moment().format('YYYY-MM-DD HH:mm:ss');
    const version = process.env.VUE_APP_PACKAGE_VERSION;
    const existingError = errorQueue.find(error => error.date === currentDate && error.key === key && error.version === version);
    if (!existingError) {
      errorQueue.push({
        key,
        message: e.message,
        stack: e.stack,
        date: currentDate,
        version,
        userId: Identity.getIdentity()?.user?.id,
        count: 1,
        route: window.location.href,
        userAgent: window.navigator.userAgent,
        lastTimestamp,
        sent: false,
        // storage: Object.keys(localStorage).filter(key => !['errorQueue', 'identity'].includes(key)).map(key => ({
        //   key,
        //   value: localStorage.getItem(key),
        // })),
      })
    } else {
      existingError.count++;
      existingError.lastTimestamp = lastTimestamp;
    }
    syncErrorQueue();
    consoleErrorOriginalReference(e);
  }
}

function saveErrorQueue() {
  localStorage.setItem('errorQueue', JSON.stringify(errorQueue));
}

function loadErrorQueue() {
  errorQueue = []
  errorQueue.push(...JSON.parse(localStorage.getItem('errorQueue') || '[]'));
}

function syncErrorQueue() {
  clearInterval(syncErrorInterval);
  syncErrorInterval = setInterval(() => {
    sendErrorQueue();
  }, 15 * 1000);
  saveErrorQueue();
}

function sendErrorQueue() {
  const currentDate = moment().format('YYYY-MM-DD');
  const models = errorQueue.filter(error => !error.sent)
    .map(error => new ErrorLogModel(error));
  if (models.length > 0) {
    ErrorLogService.getInstance().save(models)
      .then(() => {
        errorQueue.forEach(error => error.sent = true);
        errorQueue = errorQueue.filter(error => error.date === currentDate);
        saveErrorQueue();
      })
      .catch(consoleErrorOriginalReference)
  }
}

loadErrorQueue();
syncErrorQueue();
