<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'videoLibrary'"
      v-model="model"
      :service="service"
      :loading="isLoading"
      name="videoLibrary"
      title="Video Library"
    >
      <template #form="{ formErrors, rules }">
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="model.data.index"
              v-safechar
              :error-messages="formErrors.index"
              :rules="[rules.required]"
              :disabled="!!(model.data.id)"
              name="index"
              label="Index"
              hide-details="auto"
              outlined
              clearable
              required
              @input="delete formErrors.index"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.number="model.data.position"
              :error-messages="formErrors.position"
              label="Position"
              name="position"
              hide-details="auto"
              type="number"
              outlined
              clearable
              required
              @input="delete formErrors.position"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="model.data.label"
              v-safechar
              :error-messages="formErrors.label"
              :rules="[rules.required]"
              name="label"
              label="Label"
              hide-details="auto"
              outlined
              clearable
              @input="() => delete formErrors.label"
            />
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="model.data.category"
              :error-messages="formErrors.category"
              :rules="[rules.required]"
              :items="videoLibraryCategoryList"
              label="Category"
              name="category"
              hide-details="auto"
              outlined
              clearable
              @input="() => delete formErrors.category"
            />
          </v-col>
          <v-col cols="12">
            <Wysiwyg
              v-model="model.data.content"
              :rules="[rules.required]"
              label="Description"
            ></Wysiwyg>
          </v-col>
          <v-col cols="12">
            <ImageFileInput
              v-model="model.data.thumbnailfileentity"
              :error-messages="formErrors.thumbnailFileId"
              :rules="[rules.required]"
              :aspect-ratio="16/9"
              outlined
              ref="thumbnailFileInput"
              hide-details="auto"
              show-size
              chips
              editable
              label="Thumbnail"
              accept="image/*"
              @change="delete formErrors.thumbnailFileId"
            />
          </v-col>
          <v-col cols="12">
            <FileModelInput
              v-model="model.data.videofileentity"
              :error-messages="formErrors.videoFileId"
              :rules="[rules.required]"
              outlined
              ref="videoFileInput"
              hide-details="auto"
              show-size
              chips
              label="Video file"
              accept="video/*"
              @change="delete formErrors.videoFileId"
            ></FileModelInput>
          </v-col>
          <v-col cols="12">
            <FileModelInput
              v-model="model.data.vttfileentity"
              :error-messages="formErrors.vttFileId"
              outlined
              ref="vttFileInput"
              hide-details="auto"
              show-size
              chips
              label="VTT file"
              accept="text/vtt"
              @change="delete formErrors.vttFileId"
            ></FileModelInput>
          </v-col>
        </v-row>
      </template>
    </DataForm>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component} from 'vue-property-decorator';
import DataForm from '@/modules/common/components/DataForm.vue';
import VideoLibraryModel from '@/modules/sdk/models/video-library.model';
import VideoLibraryService from '@/modules/sdk/services/video-library.service';
import FileModelInput from '@/modules/common/components/FileModelInput.vue';
import ImageFileInput from '@/modules/common/components/ImageFileInput.vue';
import Wysiwyg from '@/modules/common/components/Wysiwyg.vue';

@Component({
  components: {
    Wysiwyg,
    FileModelInput,
    ImageFileInput,
    DataForm,
  }
})
export default class VideoLibraryForm extends Vue {
  service = VideoLibraryService.getInstance();
  loading = false;
  model: VideoLibraryModel = new VideoLibraryModel();

  videoLibraryListLoading = false;
  videoLibraryCategoryList: any[] = [
    { text: 'Developer', value: 'dev' },
    { text: 'General', value: 'general' },
  ]

  get isLoading() {
    return this.loading || this.videoLibraryListLoading;
  }

  load(id: number) {
    this.loading = true;
    this.service.get({id})
      .then(response => this.model = response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  created() {
    if (this.$route.params.id) {
      this.load(parseInt(this.$route.params.id));
    }
  }
}
</script>
