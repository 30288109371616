<template>
  <CollapsableCard
    :memory-key="'survey_group_' + model.data.id"
    :no-header="!hasHeader && mode === 'answer'"
    :flat="!hasHeader && mode === 'answer'"
    :card-title-attrs="{
      class: mode === 'answer' ? 'py-1' : null
    }"
    :header-attrs="{
      color,
      dark: isDark,
    }"
  >
    <template #header_left>
      <SurveyHeader
        v-model="model"
        :survey="survey"
        :list="parent?.data.surveygrouplist"
        :mode="mode"
        :debug="debug"
        :index="index"
        :readonly="readonly"
        :extra-fields="extraFields"
        :card-title-attrs="{
          class: 'pa-0'
        }"
        :view-attrs="{
          class: view === 'paginated' ? 'font-weight-bold title' : 'body-1',
        }"
        :title-attrs="{
          filled: model.data.includeLabel,
          class: 'font-weight-bold',
        }"
        label="Group"
        color-active="group lighten-2"
        color-inactive="group lighten-5"
        slot-prefix="group"
        header-is="div"
        expandable
        include-color
      >
        <template v-for="(slot, key) in $scopedSlots" #[key]="props">
          <slot :name="key" v-bind="props"></slot>
        </template>
      </SurveyHeader>
    </template>
    <template #body>
      <v-card-text
        v-if="mode === 'answer'"
        :class="{
          'px-4 pb-4': model.data.includeLabel && model.data.label,
          'pa-0': !(model.data.includeLabel && model.data.label),
        }"
      >
        <div v-if="model.data.includePrependText" v-html="model.data.prependText" class="body-1"></div>
        <v-expand-transition
          v-for="(question, questionIdx) in model.data.surveyquestionlist"
          :key="question.stateId"
        >
          <div v-if="survey.isActive(question.data.hash)" :tick="tick">
            <SurveyQuestion
              v-bind="computedCascadeAttrs"
              :cascade-attrs="cascadeAttrs"
              :value="question"
              :survey="survey"
              :parent="question"
              :index="questionIdx"
              :class="{ 'mt-3': questionIdx > 0 }"
              @answer="onAnswer"
            >
              <template v-for="(slot, key) in $scopedSlots" #[key]="props">
                <slot :name="key" v-bind="props"></slot>
              </template>
            </SurveyQuestion>
          </div>
        </v-expand-transition>
        <div v-if="model.data.includeAppendText" v-html="model.data.appendText" class="body-1 mt-4"></div>
      </v-card-text>
      <v-card-text v-else>
        <ListBuilder
          v-model="model.data.surveyquestionlist"
          :default-model="surveyQuestionModel"
          :default-data="{
            surveyId: model.data.surveyId,
            projectId: model.data.projectId,
            surveySectionId: model.data.id,
          }"
          :labels="{
            add: 'Add a new question',
            remove: 'Remove question',
            empty: 'No group question yet',
          }"
          :btn-attrs="{
            color: 'warning',
            outlined: true,
          }"
          :readonly="readonly"
          :sortable="sortable"
          :show-removed="showRemoved"
          :button-offset="17"
          group="survey_question"
          order-by="position"
          is-model
          restorable
          collapsable
        >
          <template #prepend>
            <v-expand-transition>
              <div v-if="model.data.includePrependText">
                <Wysiwyg
                  v-model="model.data.prependText"
                  label="Prepend text"
                  :height="200"
                ></Wysiwyg>
              </div>
            </v-expand-transition>
          </template>
          <template #item="question">
            <SurveyQuestion
              v-model="question.item"
              v-bind="computedCascadeAttrs"
              :cascade-attrs="cascadeAttrs"
              :survey="survey"
              :parent="model"
              :index="question.index"
              @answer="onAnswer"
            >
              <template v-for="(slot, key) in $scopedSlots" #[key]="props">
                <slot :name="key" v-bind="props"></slot>
              </template>
            </SurveyQuestion>
          </template>
          <template #append>
            <v-expand-transition>
              <div v-if="model.data.includeAppendText">
                <Wysiwyg
                  v-model="model.data.appendText"
                  label="Append text"
                  class="mt-4"
                  :height="200"
                ></Wysiwyg>
              </div>
            </v-expand-transition>
          </template>
        </ListBuilder>
      </v-card-text>
    </template>
  </CollapsableCard>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import CollapsableCard from '@/modules/common/components/CollapsableCard.vue';
import SurveyQuestion from '@/modules/common/components/SurveyQuestion.vue';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';
import SurveySectionModel from '@/modules/sdk/models/survey-section.model';
import Wysiwyg from '@/modules/common/components/Wysiwyg.vue';
import SurveyHeader from '@/modules/common/components/SurveyHeader.vue';
import SurveyChoiceField from '@/modules/common/components/SurveyChoiceField.vue';
import SurveyItemMixin from '@/modules/common/mixins/survey';
import SurveyGroupModel from '@/modules/sdk/models/survey-group.model';
import Survey from '@/modules/common/components/Survey.vue';

@Component({
  components: {
    Survey,
    SurveyChoiceField,
    SurveyHeader,
    Wysiwyg,
    SurveyQuestion,
    CollapsableCard,
  }
})
export default class SurveyGroup extends SurveyItemMixin<SurveyGroupModel, SurveySectionModel> {
  surveyQuestionModel = SurveyQuestionModel
}
</script>
