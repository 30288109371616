<template>
  <div>
    <query-builder
      v-model="conditionList"
      :fields="fields"
      :disabled="disabled"
      ref="queryBuilder"
      @focus="onFocus"
      @blur="onBlur"
    />
  </div>
</template>

<script lang="ts">
import 'reflect-metadata';
import ProjectStatusReasonModel from '@/models/project-status-reason.model'
import ProjectStatusReasonService from '@/services/project-status-reason.service'
import { Vue, Component, VModel, Prop, Watch, Ref } from 'vue-property-decorator';
import {
  extraFieldList,
  fieldList,
  officialStatusFieldList,
  statusReasonFieldList,
  sourceTypeList,
  statusList
} from '@/enums/global'
import RecordService from '@/services/record.service';
import ProjectModel from '@/models/project.model';
import KeywordModel from '@/models/keyword.model';
import TagModel from '@/models/tag.model';
import SurveyGroupModel from '@/modules/sdk/models/survey-group.model';
import SurveyModel from '@/modules/sdk/models/survey.model';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';
import QueryBuilder from '@/modules/common/components/QueryBuilder.vue';
import ProjectUserModel from '@/models/project-user.model';
import CategoryModel from '@/models/category.model'
import RecordTagModel from '@/models/record-tag.model'
import Logger from '@/modules/sdk/core/logger';
import UserModel from '@/modules/sdk/models/user.model';
import UserService from '@/modules/sdk/services/user.service';
import { IQueryAdvanced } from '@/modules/sdk/core/interfaces';
import { SharedQuery } from '@/utils/shared-query';
import Identity from '@/modules/sdk/core/identity';
import CommentModel from '@/models/comment.model';
import SurveySectionModel from '@/modules/sdk/models/survey-section.model';
import {IQueryItem} from '@/modules/sdk/core/query';

@Component({
  components: {
    QueryBuilder,
  }
})
export default class ProjectQueryBuilder extends Vue {
  @Ref() readonly queryBuilder!: QueryBuilder
  @VModel({ type: Array, default: () => [] }) conditionList!: IQueryItem[];
  @Prop({ type: Number, default: null }) projectId!: number;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Object, default: null }) mode!: any;
  @Prop({ type: ProjectModel, default: null }) project?: ProjectModel;
  @Prop({ type: Array, default: null }) comments?: Array<CommentModel>;
  @Prop({ type: Array, default: null }) keywords?: Array<KeywordModel>;
  @Prop({ type: Array, default: null }) tags?: Array<TagModel>;
  @Prop({ type: Object, default: () => ({
      stage: null,
      status: null,
      userId: null,
      userStatus: null,
      reviewed: null,
      order: [],
      orderAsc: []
  }) }) advanced!: IQueryAdvanced;

  loading = false;
  innerProject: ProjectModel | null = null
  innerKeywords: Array<KeywordModel> = []
  innerComments: Array<CommentModel> = []
  innerTags: Array<TagModel> = []
  projectUserList: Array<UserModel> = [];
  projectStatusReasonList: Array<ProjectStatusReasonModel> = [];
  languageList: Array<string> = [];
  loadedList: {
    [key: string]: {
      keys?: string[],
      enabled?: () => boolean,
      loaded: boolean,
    }
  } = {
    comments: {
      keys: [
        'Comment.content',
        '[groupConcatCommentsContent]',
      ],
      loaded: false,
    },
    project: { // always loaded
      loaded: false,
    },
    keywords: {
      keys: [
        'title',
        'abstract',
        'Article.title',
        'Article.content',
      ],
      loaded: false,
    },
    tags: {
      keys: [
        'Tag.label',
        'Category.*',
      ],
      loaded: false,
    },
    record: {
      keys: [
        'language',
      ],
      loaded: false,
    },
    userList: { // loaded on condition
      keys: ['reviewedBy', 'notReviewedBy'],
      enabled: () => this.includeUserList,
      loaded: false,
    },
    statusReasonList: {
      keys: [
        'screeningStatusReason',
        'indepthStatusReason',
        'finalStatusReason',
      ],
      loaded: false,
    },
  }

  @Watch('projectId')
  onProjectIdChange() {
    this.clear();
    this.loadIfRequired();
  }

  @Watch('project', { deep: true })
  onProjectChanged(project: ProjectModel) {
    if (project) {
      this.innerProject = project;
    }
  }

  @Watch('keywords', { deep: true })
  onKeywordsChanged(keywords: Array<KeywordModel>) {
    if (keywords) {
      this.innerKeywords = keywords;
    }
  }

  @Watch('tags', { deep: true })
  onTagsChanged(tags: Array<TagModel>) {
    if (tags) {
      this.innerTags = tags;
    }
  }

  @Watch('comments', { deep: true })
  onCommentsChanged(comments: Array<CommentModel>) {
    if (comments) {
      this.innerComments = comments;
    }
  }

  onFocus(props: any) {
    props.condition.loading = true;
    this.queryBuilder.forceUpdate();
    this.loadIfRequired().finally(() => {
      props.condition.loading = false;
      this.queryBuilder.forceUpdate();
    })
  }

  onBlur(props: any) {
    props.condition.loading = false;
    this.queryBuilder.forceUpdate();
  }

  get flatConditionList(): IQueryItem[] {
    function flattenQueryItems(items: IQueryItem[]): IQueryItem[] {
      const flattened: IQueryItem[] = [];
      for (const item of items) {
        flattened.push(item);
        if (item.group && item.group.length > 0) {
          const nestedFlattened = flattenQueryItems(item.group);
          nestedFlattened.forEach(nested => {
            flattened.push(nested);
          })
        }
      }
      return flattened;
    }
    return flattenQueryItems(this.conditionList);
  }

  get includeUserList(): boolean {
    return this.advanced.stage && this.mode && ['leader', 'arbitrator'].includes(this.mode.value);
  }

  get fields(): Array<any> {
    let fields: Array<any> = [{
      header: this.$i18n.t('builderComponent.fields'),
    }, ...fieldList, {
      header: this.$i18n.t('builderComponent.officialStatuses'),
    }, ...officialStatusFieldList, {
      header: this.$i18n.t('builderComponent.statusReasons'),
    }, ...statusReasonFieldList, {
      header: this.$i18n.t('builderComponent.extraFields'),
    }, ...extraFieldList];

    if (this.innerProject) {

      if (this.includeUserList) {
        fields.push({
          header: 'User Status',
        });
        this.projectUserList.forEach((user: UserModel) => {
          fields.push({
            text: user.getFullName(),
            value: 'User.' + user.data.id,
          })
        });
      }

      // tags categories
      if (this.innerProject.data.categorylist) {
        fields.push({
          header: 'Eligibility Tags',
        });
        this.innerProject.data.categorylist.forEach((category: CategoryModel) => {
          fields.push({
            text: category.data.label,
            value: 'Category.' + category.data.id + '.Tag.' + 'label'
          })
        });
      }

      // survey groups and questions
      if (this.innerProject.data.surveylist) {
        this.innerProject.data.surveylist.forEach((survey: SurveyModel) => {
          survey.data.surveysectionlist.forEach((section: SurveySectionModel) => {
            section.data.surveygrouplist.forEach((group: SurveyGroupModel) => {
              if (group.data.surveyquestionlist.length > 0) {
                fields.push({
                  header: this.$i18n.t('builderComponent.questions') + ' > ' + group.data.label,
                });
                group.data.surveyquestionlist.forEach((question: SurveyQuestionModel) => {
                  fields.push({
                    text: question.data.label,
                    value: 'SurveyQuestion.' + question.data.id + '.SurveyAnswer.content'
                  });
                })
              }
            })
          });
        });
      }
    }

    fields = fields.map(field => ({
      ...field,
      ...this.getValueAttrs(field.value),
    }))

    return fields;
  }

  filterTagsCategory(category: number | null = null) {
    return (tag: TagModel) => {
      return tag.data.recordnode && tag.data.recordnode.findIndex((recordNode: RecordTagModel) => {
        return recordNode.data.categoryId === category;
      }) !== -1;
    }
  }

  filterTagsByOwner(tag: TagModel) {
    return !['leader', 'arbitrator'].includes(this.mode?.value)
      ? tag.data.createdBy === Identity.getIdentity()?.user.id
      : true;
  }

  mapTagModelToItem(tag: TagModel) {
    return {
      text: tag.data.label,
      value: tag.data.label,
      color: tag.data.color,
      tag,
    };
  }

  getValueAttrs(field: string): any {
    let items: Array<any> | null;
    switch (field) {
      case 'pilotUserId':
        items = (this.project || new ProjectModel()).data.usernode
          .filter((model: ProjectUserModel) => ['researcher', 'secondary-researcher'].includes(model.data.type))
          .map((model: ProjectUserModel) => ({
            text: model.data.userentity.getFullName(),
            value: model.data.userId,
            model: model.data.userentity
          }));
        break;
      case 'Tag.label':
        items = this.innerTags
          .filter(this.filterTagsCategory(null))
          .filter(this.filterTagsByOwner)
          .map(model => this.mapTagModelToItem(model));
        break;
      case 'screeningStatus':
      case 'indepthStatus':
      case 'finalStatus':
        items = statusList;
        break;
      case 'screeningStatusReason':
      case 'indepthStatusReason':
      case 'finalStatusReason':
        items = this.projectStatusReasonList
          .filter((model: ProjectStatusReasonModel) => model.data.stage === field.replace('StatusReason', ''))
          .map((model: ProjectStatusReasonModel) => ({
            text: model.data.label,
            value: model.data.id,
            model: model
          }));
        break;
      case 'sourceType':
        items = sourceTypeList;
        break;
      case 'language':
        items = this.languageList;
        break;
      case 'reviewedBy':
      case 'notReviewedBy':
        items = this.projectUserList.map(model => ({
          text: model.getFullName(),
          value: model.data.id,
        }));
        break;
      case 'title':
      case 'abstract':
      case 'Article.title':
      case 'Article.content':
        items = this.innerKeywords.map(model => ({
          text: model.data.label,
          value: model.data.id,
          color: model.data.color,
          model,
        }));
        break;
      case 'Comment.content':
      case '[groupConcatCommentsContent]':
        items = this.innerComments.map(model => ({
          text: model.data.content,
          value: model.data.content,
          model,
        }));
        break;
      default:
        items = null;
        break;
    }

    if (field && field.startsWith('User.')) {
      items = statusList.filter(item => item.value !== 'conflict');
    }

    if (field && field.startsWith('Category.')) {
      const categoryPath = field.split('.');
      items = this.innerTags
        .filter(this.filterTagsCategory(parseInt(categoryPath[1])))
        .filter(this.filterTagsByOwner)
        .map(this.mapTagModelToItem)
    }

    const type = ['id', 'pid', 'year'].includes(field)
      ? 'numeric'
      : Array.isArray(items)
          ? 'list'
          : null;

    return {
      items,
      type,
      itemColor: 'color',
    };
  }

  clear() {
    // this.innerProject = null;
    // this.innerKeywords = [];
    this.conditionList.splice(0, this.conditionList.length, {
      logic: 'and',
      operator: 'contains',
      field: null,
      value: null,
      group: [] as any,
    });
  }

  loadIfRequired(): Promise<any> {
    return new Promise((resolve) => {
      if (this.projectId) {
        const promises: any[] = [];
        this.flatConditionList.forEach(condition => {
          Object.keys(this.loadedList).forEach(listKey => {
            const list: any = this.loadedList[listKey];
            if (!list.loaded && list.keys && list.keys.includes(condition.field)) {
              list.loaded = true;
              switch (listKey) {
                case 'comments': promises.push(this.comments || SharedQuery.getProjectComments(this.projectId, Identity.getIdentity()?.user?.id).then(response => this.innerComments = this.comments || response)); break;
                case 'keywords': promises.push(this.keywords || SharedQuery.getProjectKeywords(this.projectId).then(response => this.innerKeywords = this.keywords || response)); break;
                case 'tags':
                  // Only show own user tags unless dev/admin or project arbitrator/leader
                  const tagUserIdFilter = !Identity.hasRole(['dev', 'admin'])
                    ? Identity.getIdentity()?.user.id
                    : undefined;
                  promises.push(this.tags || SharedQuery.getAllProjectTags(this.projectId, tagUserIdFilter).then(response => this.innerTags = this.tags || response));
                  break;
                case 'record': promises.push(RecordService.getInstance().getAll({
                  filters: [{
                    field: 'projectId',
                    value: this.projectId,
                    operator: 'equals'
                  }],
                  group: 'language', // Distinct
                }).then(response => {
                  const languages: Array<string> = [];
                  response.data.view.list.forEach((item: any) => {
                    const newItems = [];
                    if (item.data.language.indexOf(',') !== -1) {
                      newItems.push(...item.data.language.split(',').map((item: string) => item.trim()));
                    } else {
                      newItems.push(item.data.language);
                    }
                    newItems.forEach((newItem: string) => {
                      if (!languages.includes(newItem)) {
                        languages.push(newItem);
                      }
                    });
                  })
                  this.languageList = languages.sort();
                })); break;
                case 'statusReasonList': promises.push(ProjectStatusReasonService.getInstance().getAll({
                  filters: [[
                    {
                      field: 'projectId',
                      value: this.projectId,
                      operator: 'equals'
                    },
                    {
                      field: 'projectId',
                      operator: 'is null'
                    }
                  ]],
                }).then(response => this.projectStatusReasonList = response.data.view.list)); break;
                case 'userList': promises.push(UserService.getInstance().getAll({ order: 'firstName, lastName, email', advanced: { activeUserProjectId: this.projectId } }).then(response => this.projectUserList = response.data.view.list)); break;
              }
            } else if (listKey === 'project' && list.enabled && list.enabled()) {
              promises.push(this.project || SharedQuery.getProject(this.projectId));
            }
          });
        })

        if (promises.length > 0) {
          this.loading = true;
          Promise.all(promises)
            .then(() => resolve(true))
            .catch(reason => this.$root.$zemit.handleError(reason))
            .finally(() => this.loading = false);
        } else {
          resolve(true)
        }
      }
    });
  }

  created() {
    this.loadIfRequired();
  }
}
</script>
