<template>
  <CollapsableCard
    :memory-key="'survey_section_' + model.data.id"
    :no-header="!hasHeader && mode === 'answer'"
    :flat="!hasHeader && mode === 'answer'"
    :header-attrs="{
      color,
      dark: isDark,
    }"
  >
    <template #header_left>
      <SurveyHeader
        v-model="model"
        :survey="parent"
        :list="parent?.data.surveysectionlist || []"
        :index="index"
        :mode="mode"
        :debug="debug"
        :readonly="readonly"
        :dark="model.data.includeLabel"
        :extra-fields="extraFields"
        :icon="view === 'list' ? 'mdi-head-question' : null"
        :card-title-attrs="{
          class: 'pa-0'
        }"
        :view-attrs="{
          class: view === 'paginated' ? 'font-weight-bold display-1' : 'title',
        }"
        :title-attrs="{
          filled: model.data.includeLabel,
          class: 'font-weight-bold',
        }"
        label="Section"
        color-active="section"
        color-inactive="section lighten-5"
        slot-prefix="section"
        header-is="div"
        include-color
        expandable
      >
        <template v-for="(slot, key) in $scopedSlots" #[key]="props">
          <slot :name="key" v-bind="props"></slot>
        </template>
      </SurveyHeader>
    </template>
    <template #body>

      <!-- USER/AI NAVIGATION -->
      <v-sheet v-if="canViewAnswers" color="backgroundVeryLight">
        <v-card-title>
          <v-autocomplete
            v-model="selectedUserList"
            :items="userList"
            :loading="userListLoading || loadingAnswerList"
            :disabled="userListLoading"
            label="See answer(s) from"
            background-color="white"
            prepend-inner-icon="mdi-forum"
            multiple
            clearable
            outlined
            hide-details
            dense
            return-object
            @change="() => selectedUserChanged = true"
            @blur="onSelectedUserAnswerBlur"
          />
        </v-card-title>
      </v-sheet>

      <!-- GROUPS -->
      <v-card-text
        v-if="mode === 'answer'"
        :class="{
          'pa-0': !includePadding
        }"
      >
        <div v-if="model.data.includePrependText" v-html="model.data.prependText" class="body-1"></div>
        <v-expand-transition
          v-for="(group, groupIdx) in groupList"
          :key="group.data.hash"
        >
          <div v-if="parent?.isActive(group.data.hash)" :tick="tick">
            <SurveyGroup
              v-model="model.data.surveygrouplist[groupIdx]"
              v-bind="computedCascadeAttrs"
              :cascade-attrs="cascadeAttrs"
              :survey="parent"
              :parent="model"
              :index="groupIdx"
              :user-list="appliedSelectedUserList"
              :answer-list="innerAnswerList"
              :class="{ 'mt-3': groupIdx > 0 }"
              @answer="onAnswer"
            >
              <template v-for="(slot, key) in $scopedSlots" #[key]="props">
                <slot :name="key" v-bind="props"></slot>
              </template>
            </SurveyGroup>
          </div>
        </v-expand-transition>
        <div v-if="model.data.includeAppendText" v-html="model.data.appendText" class="body-1 mt-4"></div>
      </v-card-text>

      <!-- EDIT (LIST) -->
      <v-card-text v-else>
        <ListBuilder
          v-model="model.data.surveygrouplist"
          :default-model="surveyGroupModel"
          :default-data="{
            surveyId: model.data.surveyId,
            projectId: model.data.projectId,
            surveySectionId: model.data.id,
          }"
          :labels="{
            add: 'Add a new group',
            remove: 'Remove group',
            empty: 'No group available yet',
          }"
          :btn-attrs="{
            color: 'grey',
            outlined: true,
          }"
          :readonly="readonly"
          :sortable="sortable"
          :show-removed="showRemoved"
          :button-offset="22"
          group="survey_group"
          order-by="position"
          is-model
          restorable
          collapsable
        >
          <template #prepend>
            <v-expand-transition>
              <div v-if="model.data.includePrependText">
                <Wysiwyg
                  v-model="model.data.prependText"
                  label="Prepend text"
                  :height="200"
                ></Wysiwyg>
              </div>
            </v-expand-transition>
          </template>
          <template #item="group">
            <SurveyGroup
              v-model="group.item"
              v-bind="computedCascadeAttrs"
              :cascade-attrs="cascadeAttrs"
              :survey="parent"
              :parent="model"
              :index="group.index"
              :answer-list="innerAnswerList"
            >
              <template v-for="(slot, key) in $scopedSlots" #[key]="props">
                <slot :name="key" v-bind="props"></slot>
              </template>
            </SurveyGroup>
          </template>
          <template #append>
            <v-expand-transition>
              <div v-if="model.data.includeAppendText">
                <Wysiwyg
                  v-model="model.data.appendText"
                  label="Append text"
                  class="mt-4"
                  :height="200"
                ></Wysiwyg>
              </div>
            </v-expand-transition>
          </template>
        </ListBuilder>
      </v-card-text>

      <!-- ACTIONS -->
      <v-card-actions v-if="showSaveButton">
        <v-divider class="mb-4"/>

        <v-btn
          :disabled="disabled || !canSaveSection"
          :loading="saving"
          color="primary"
          block
          @click="onSaveSectionClick"
        >
          <span v-text="$t('btn.save')"></span>
        </v-btn>
      </v-card-actions>
    </template>
  </CollapsableCard>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import SurveyHeader from '@/modules/common/components/SurveyHeader.vue';
import SurveyGroup from '@/modules/common/components/SurveyGroup.vue';
import SurveySectionModel from '@/modules/sdk/models/survey-section.model';
import SurveyGroupModel from '@/modules/sdk/models/survey-group.model';
import Wysiwyg from '@/modules/common/components/Wysiwyg.vue';
import SurveyModel from '@/modules/sdk/models/survey.model';
import SurveyQuestion from '@/modules/common/components/SurveyQuestion.vue';
import SurveyChoiceField from '@/modules/common/components/SurveyChoiceField.vue';
import CollapsableCard from '@/modules/common/components/CollapsableCard.vue';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';
import SurveyAnswerModel from '@/modules/sdk/models/survey-answer.model';
import SurveyItemMixin from '@/modules/common/mixins/survey';
import SurveyAnswerService from '@/modules/sdk/services/survey-answer.service';
import SurveyAiAnswerService from '@/modules/sdk/services/survey-ai-answer.service';
import Survey from '@/modules/common/components/Survey.vue';
import SurveyAnswerItemModel from '@/modules/sdk/models/survey-answer-item.model';

@Component({
  components: {
    Survey,
    SurveyChoiceField,
    SurveyQuestion,
    Wysiwyg,
    SurveyHeader,
    SurveyGroup,
    CollapsableCard,
  },
})
export default class SurveySection extends SurveyItemMixin<SurveySectionModel, SurveyModel> {
  @Prop({ type: Boolean, default: false }) includeSaveButton!: boolean
  @Prop({ type: Boolean, default: false }) userListLoading!: boolean
  @Prop({ type: Function, default: () => {} }) beforeSaveSection!: (answers: SurveyAnswerModel[]) => void

  saving = false
  surveyGroupModel = SurveyGroupModel
  selectedUserList = []
  appliedSelectedUserList = []
  loadingAnswerList = false
  answerListLoaded = false
  selectedUserChanged = false
  innerAnswerList: SurveyAnswerItemModel[] = []

  get showSaveButton(): boolean {
    return this.includeSaveButton
      && !this.readonly
      && this.mode === 'answer';
  }

  get groupList(): SurveySectionModel[] {
    return this.model.data.surveygrouplist;
  }

  get canSaveSection(): boolean {
    const questions = this.getDifferentQuestionsFromSection(this.model as SurveySectionModel);
    const answers = this.innerAnswerList.filter(answer => answer.data.original !== answer.originalData.original || answer.data.official !== answer.originalData.official);
    return !this.disabled && !this.saving && (questions.length > 0 || answers.length > 0);
  }

  getDifferentQuestionsFromSection(section: SurveySectionModel): Array<SurveyQuestionModel> {
    return section.getQuestions().filter((question: any) => JSON.stringify(question.answer) !== JSON.stringify(question.originalAnswer));
  }

  onSaveSectionClick(): void {
    this.saveSection(this.model as SurveySectionModel);
  }

  onSelectedUserAnswerBlur() {
    if (this.selectedUserChanged) {
      this.selectedUserChanged = false;
      this.loadUserAnswerList();
    }
  }

  async saveSection(section: SurveySectionModel) {
    const answers = (this.parent?.getAnswers(true) || []).filter(answer => {
      return answer.data.surveySectionId === section.data.id
        && (
          ( // New entry has content
            !answer.data.id
            && answer.data.content !== null
          )
          || ( // Existing entry is different
            answer.data.id
            && answer.data.content !== answer.originalData.content
          )
        );
    });
    answers.forEach(answer => answer.data.submitted);
    this.beforeSaveSection(answers);

    this.saving = true;
    return SurveyAnswerService.getInstance().save(answers)
      .then((response) => {
        const newAnswers = response.data.view.map((item: any) => new SurveyAnswerModel(item.single));
        this.parent?.clearNewAnswers();
        this.parent?.applyAnswers(newAnswers);

        // Update loaded inner answer list
        this.innerAnswerList.forEach(innerAnswer => {
          const foundAnswer = newAnswers.find((newAnswer: SurveyAnswerModel) => newAnswer.data.id === innerAnswer.data.id);
          innerAnswer.assign(foundAnswer);
        })
        this.$emit('save', answers);
        this.$root.$globalSnack.info({
          message: 'Survey answers successfully saved.',
        });
      })
      .catch(reason => this.$root.$zemit.handleError(reason, this.errors))
      .finally(() => this.saving = false);
  }

  loadUserAnswerList() {
    this.innerAnswerList = [];
    if (this.selectedUserList.length > 0) {
      const filters = [{
        field: 'projectId',
        operator: 'equals',
        value: this.model.data.projectId
      }, {
        field: 'surveyId',
        operator: 'equals',
        value: this.parent?.data.id,
      }];

      if (this.answerFilters) {
        filters.push(...this.answerFilters)
      }

      const promises = [
        SurveyAnswerService.getInstance().getAll({ filters }),
      ];

      const hasAiSelected = !!(this.selectedUserList.find((user: any) => user.value === 'ai'));
      if (hasAiSelected) {
        promises.push(
          SurveyAiAnswerService.getInstance().getAll({ filters })
        )
      }

      this.loadingAnswerList = true;
      return Promise.all(promises)
        .then(responses => {
          this.innerAnswerList = responses[0].data.view.list;
          if (hasAiSelected) {
            this.innerAnswerList.push(...responses[1].data.view.list)
          }
          this.answerListLoaded = true;
          this.appliedSelectedUserList = structuredClone(this.selectedUserList);
          return responses;
        })
        .catch(this.$root.$zemit.handleError)
        .finally(() => this.loadingAnswerList = false);
    }
  }

}
</script>
