<template>
  <v-navigation-drawer
    v-test-id="'app-navigation'"
    v-model="drawer"
    :mini-variant.sync="_mini"
    permanent
    dark
    app
  >
    <template #prepend>
      <v-list-item class="px-2" v-if="canMini">
        <v-btn
          v-test-id="'app-mini-toggle-button2'"
          icon
          @click.stop="() => _mini = !_mini"
        >
          <v-icon v-if="!_mini">mdi-chevron-left</v-icon>
          <v-icon v-else>mdi-chevron-right</v-icon>
        </v-btn>
      </v-list-item>
      <v-tooltip :disabled="!_mini" right>
        <template #activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on" class="px-2">
            <v-list-item-avatar>
              <v-btn
                icon
                :to="{name: 'UserForm', params: {id: currentUserId}}"
              >
                <v-icon>mdi-account-circle-outline</v-icon>
              </v-btn>
            </v-list-item-avatar>

            <v-list-item-title>
              <v-autocomplete
                v-if="canImpersonalize"
                v-model="selectedUser"
                :items="filteredUserList"
                :loading="userListLoading"
                :menu-props="{
                  zIndex: 1000,
                }"
                :background-color="isImpersonalizing ? 'error' : null"
                label="Impersonalize"
                item-text="fullName"
                class="mt-1"
                return-object
                hide-details
                outlined
                dense
                clearable
                @click:clear="onClearImpersonalize"
              >
                <template #item="{ item }">
                  <div>
                    <h5 class="mb-n2">{{ item.fullName }}</h5>
                    <div class="caption text--disabled">{{ item.data.rolelist[0].data.label }}</div>
                  </div>
                </template>
              </v-autocomplete>
              <span v-else>{{ userObj.fullName }}</span>
            </v-list-item-title>

            <v-btn
              v-if="!_mini"
              v-test-id="'app-mini-toggle-button'"
              icon
              @click.stop="() => _mini = !_mini"
            >
              <v-icon v-if="!_mini">mdi-chevron-left</v-icon>
              <v-icon v-else>mdi-chevron-right</v-icon>
            </v-btn>
          </v-list-item>
        </template>
        <span>{{ userObj.fullName }}</span>
      </v-tooltip>

      <v-divider></v-divider>
    </template>

    <template #append>
      <v-slide-y-reverse-transition>
        <v-sheet v-if="!_mini" class="caption text-right text-no-wrap" dark>
          <div class="px-4 py-1" style="opacity: 0.5" v-text="$t('footer.version', {
              version,
            })"></div>
        </v-sheet>
      </v-slide-y-reverse-transition>
    </template>

    <v-list dense>
      <div
        v-for="(group, groupIdx) in menuList"
        :key="groupIdx"
      >
        <v-subheader
          v-if="group.children && group.label"
          v-text="group.label"
          class="d-block text-truncate pt-3"
        ></v-subheader>
        <v-tooltip
          v-for="item in group.children"
          :key="item.title"
          :disabled="!_mini"
          right
        >
          <template #activator="{ on, attrs }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
              :to="item.to"
              color="primary"
              link
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span v-text="item.title"></span>
        </v-tooltip>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Vue, Component, VModel, Watch } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';
import Identity from '@/modules/sdk/core/identity';
import UserModel from '@/modules/sdk/models/user.model';
import UserService from '@/modules/sdk/services/user.service';
import AuthService from '@/modules/sdk/services/auth.service';
import App from '@/App.vue';
import Main from '@/main';

export interface ISidebarItem {
  title: string,
  to: {
    name: string | VueI18n.LocaleMessages,
  },
  icon: string,
  show?: boolean
}

export interface ISidebarGroup {
  label?: string | VueI18n.LocaleMessages,
  children: Array<ISidebarItem>,
  show?: boolean
}

@Component
export default class Sidebar extends Vue {
  @VModel({ type: Array, default: () => ([]) }) menuList!: ISidebarGroup[]

  drawer = true
  userListLoading = true
  user = Identity.identity?.user
  version = process.env.VUE_APP_PACKAGE_VERSION
  mini = false
  isFullscreen = false
  selectedUser = new UserModel(Identity.identity?.user)
  userList: UserModel[] = []

  @Watch('selectedUser')
  onSelectedUserChange(user: UserModel) {
    if (!user) {
      return;
    }
    if (Identity.getIdentity()?.loggedInAs) {
      this.logoutAs().then(() => this.loginAs(user));
    } else {
      this.loginAs(user);
    }
  }

  onClearImpersonalize() {
    this.logoutAs();
  }

  get isImpersonalizing(): boolean {
    return Identity.getIdentity()?.loggedInAs || false;
  }

  get canImpersonalize() {
    return Identity.hasRole(['dev', 'admin']) || Identity.getIdentity()?.loggedInAs
  }

  get userObj() {
    return new UserModel(this.user);
  }

  get canMini() {
    return this.$vuetify.breakpoint.mdAndUp && this.mini;
  }

  get _mini() {
    return this.isFullscreen
    || this.$vuetify.breakpoint.smAndDown ? true : this.mini
  }

  set _mini(value) {
    this.mini = value
  }

  get currentUserId() {
    return Identity.getIdentity()?.user.id
  }

  get filteredUserList(): UserModel[] {
    return this.userList.filter((user: UserModel) => {
      return !Identity.getIdentity()?.loggedInAs
        || user.data.id !== Identity.getIdentity()?.userAs.id;
    })
  }

  loginAs(user: UserModel): Promise<any> {
    this.userListLoading = true;
    return AuthService.getInstance().loginAs({ userId: user.data.id })
      .then((response: any) => {
        if (response.data.view.loggedInAs) {
          Identity.setIdentity(response.data.view);
          Main.render(App);
          setTimeout(() => { // App will be reloaded.. need to wait before continuing
            this.$root.$globalSnack.success({
              message: 'Now impersonating ' + user.fullName
            });
          }, 100)
        } else {
          // @todo error
        }
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.userListLoading = false);
  }

  logoutAs(): Promise<any> {
    this.userListLoading = true;
    return AuthService.getInstance().logoutAs()
      .then((response: any) => {
        if (!response.data.view.loggedInAs) {
          Identity.setIdentity(response.data.view);
          Main.render(App);
          setTimeout(() => { // App will be reloaded.. need to wait before continuing
            this.$root.$globalSnack.success({
              message: 'Back to original user'
            });
          })
        } else {
          // @todo error
        }
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.userListLoading = false);
  }

  loadUserList() {
    this.userListLoading = true;
    UserService.getInstance().getAll({
      order: 'firstName, lastName'
    })
      .then(response => this.userList = response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.userListLoading = false);
  }

  created() {
    this.$root.$on('toggleFullscreen', (isFullscreen: boolean) => {
      this.isFullscreen = isFullscreen
    })

    if (this.canImpersonalize) {
      this.loadUserList();
    }
  }
}
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}
</style>
