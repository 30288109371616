import Model, {Data} from '@/modules/sdk/core/model';
import SurveyModel from '@/modules/sdk/models/survey.model';
import SurveyItemModel from '@/modules/sdk/models/survey-item.model';
import SurveyGroupModel from '@/modules/sdk/models/survey-group.model';
import Hash from '@/modules/sdk/core/hash';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';

export default class SurveySectionModel extends SurveyItemModel {

  constructor(
    data: Data | Model = {},
    setOriginal = false,
  ) {
    // @ts-ignore
    if (!data.surveygrouplist) {
      // @ts-ignore
      data.surveygrouplist = [new SurveyGroupModel().data]
    }
    super(data, setOriginal);
  }

  default() {
    return {
      hash: this.data.hash ? this.data.hash : Hash.guid(),
      surveygrouplist: [],
      includeLabel: true,
      includePrependText: false,
      includeAppendText: false,
      label: null,
      prependText: '',
      appendText: '',
      conditions: [],
      action: null,
    };
  }

  relatedMap () {
    return {
      surveyentity: SurveyModel,
      surveygrouplist: SurveyGroupModel,
    }
  }

  relatedDeleteMissing() {
    return {
      surveygrouplist: true,
    };
  }

  getQuestions(): SurveyQuestionModel[] {
    const questions: SurveyQuestionModel[] = [];
    this.data.surveygrouplist.forEach((group: SurveyGroupModel) => {
      group.data.surveyquestionlist.forEach((question: SurveyQuestionModel) => {
        questions.push(question);
      })
    });
    return questions;
  }

  assign(data: Model | object, specificKeys = []) {
    super.assign(data, specificKeys);
    return this.sortByProperty('surveygrouplist', 'position');
  }
}
