<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'survey'"
      v-bind="$attrs"
      v-model="model"
      :service="service"
      :loading="loading"
      :redirect-on-create="!projectId"
      name="survey"
      title="Data Extraction"
      icon="mdi-comment-question-outline"
      hide-back-button
    >
      <template #form>
        <SurveyManager
          v-model="model"
          :tab-attrs="{ class: 'mt-4' }"
          preview
          editable
          disable-if-answered
          show-templates
          @append-template="onAppendTemplate"
        />
      </template>
    </DataForm>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
import DataForm from '@/modules/common/components/DataForm.vue';
import SurveyModel from '@/modules/sdk/models/survey.model';
import SurveyService from '@/modules/sdk/services/survey.service';
import SurveyManager from '@/components/SurveyManager.vue';

@Component({
  components: {
    SurveyManager,
    DataForm,
  }
})
export default class SurveyForm extends Vue {
  @VModel({ type: SurveyModel, default: () => new SurveyModel() }) model!: SurveyModel;
  @Prop() id!: number;
  @Prop() projectId?: number;
  @Prop({ type: Boolean, default: false }) readonly!: boolean;

  service = SurveyService.getInstance();
  loading = false;

  onAppendTemplate(survey: SurveyModel) {
    if (this.projectId) {
      this.model.setReferenceKeyValue('projectId', this.projectId);
    }
    if (this.model.data.id) {
      this.model.setReferenceKeyValue('surveyId', this.model.data.id);
    }
  }
}
</script>
