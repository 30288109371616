import Model from '@/modules/sdk/core/model';
import SurveyModel from '@/modules/sdk/models/survey.model';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';
import SurveyGroupModel from '@/modules/sdk/models/survey-group.model';
import SurveySectionModel from '@/modules/sdk/models/survey-section.model';

export default class SurveyAnswerItemModel extends Model {

  default() {
    return {
      projectId: null,
      surveyId: null,
      surveySectionId: null,
      surveyGroupId: null,
      surveyQuestionId: null,
      recordId: null,
    };
  }

  relatedMap () {
    return {
      surveyentity: SurveyModel,
      surveysectionentity: SurveySectionModel,
      surveygroupentity: SurveyGroupModel,
      surveyquestionentity: SurveyQuestionModel,
    }
  }
}
