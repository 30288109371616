<template>
  <div>
    <ManageSurveyAnswersModal
      v-if="manageAnswers"
      v-model="viewAnswersModal.visible"
      :question="viewAnswersModal.question"
      :answers="viewAnswersModal.answers"
      max-width="600"
      scrollable
      @apply="onApplyAnswer"
    />

    <!-- LIKERT -->
    <v-simple-table v-if="question.data.type === 'likert'">
      <thead>
        <tr>
          <th></th>
          <th
            v-for="key in question.data.keys"
            :key="key.stateId"
            class="text-center"
          >
            {{ key.data.content }}

            <v-chip v-if="debug" label small color="warning">
              {{ key.stateId }}
            </v-chip>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(choice, choiceIdx) in question.data.surveychoicelist"
          :key="choice.stateId"
        >
          <template v-if="choice.data.isQuestion">
            <td v-if="survey.isActive(choice.data.surveysubquestionentity.data.hash)" :colspan="question.data.keys.length + 1" class="py-3">
              <SurveyQuestion
                v-model="choice.data.surveysubquestionentity"
                v-bind="computedCascadeAttrs"
                :survey="survey"
                :parent="choice"
                :index="choiceIdx"
                @answer="onAnswer"
              />
            </td>
          </template>
          <template v-else-if="choice.data.isCategory">
            <th :colspan="question.data.keys.length + 1" class="group">
              <h4 class="title">{{ choice.data.content }}</h4>
            </th>
          </template>
          <template v-else>
            <th>
              {{ choice.data.content }}
              <v-chip v-if="debug" label small color="warning" class="ml-1">
                {{ choice.stateId }}
              </v-chip>
            </th>
            <td
              v-for="key in question.data.keys"
              :key="key.stateId"
              class="text-center"
            >
              <div class="d-flex justify-center">
                <!-- CHECKBOXES ARE DISGUISED AS RADIOS BECAUSE RADIOS-->
                <!-- ARE DEPENDENT ON V-RADIO-GROUP WHICH BREAKS THE TABLE-->
                <v-checkbox
                  v-model="question.answer[choiceIdx]"
                  :value="key.stateId"
                  :rules="getRules(question)"
                  color="primary"
                  on-icon="$radioOn"
                  off-icon="$radioOff"
                  hide-details
                  class="pa-0 ma-0"
                  @change="onAnswer"
                />
              </div>
            </td>
          </template>
        </tr>
      </tbody>
    </v-simple-table>

    <!-- SELECT -->
    <template v-if="question.data.type === 'select'">
      <template v-if="question.data.surveychoicelist.length > 0">
        <v-select
          v-model="question.answer"
          :items="question.data.surveychoicelist"
          :rules="getRules(question)"
          :placeholder="$tc('surveyAnswer.selectOption', question.data.multiple ? 9 : 1)"
          :disabled="fieldIsDisabled"
          :multiple="question.data.multiple"
          :readonly="readonly"
          :prefix="question.data.prefix"
          :suffix="question.data.suffix"
          :clearable="!readonly"
          item-value="stateId"
          hide-details="auto"
          item-text="data.content"
          outlined
          dense
          @input="onAnswer"
        />
      </template>
      <div v-else>
        <v-alert
          text
          type="info"
          class="mb-0"
        >
          <span v-text="$t('surveyAnswer.noChoiceDefined')"></span>
        </v-alert>
      </div>
    </template>

    <!-- INTEGER -->
    <v-text-field
      v-else-if="question.data.type === 'integer'"
      v-model.number.lazy="question.answer"
      :disabled="fieldIsDisabled"
      :rules="getRules(question)"
      :placeholder="$t('surveyAnswer.typeAnswer')"
      :readonly="readonly"
      :prefix="question.data.prefix"
      :suffix="question.data.suffix"
      :clearable="!readonly"
      hide-details="auto"
      type="number"
      outlined
      dense
      @input="onAnswer"
    />

    <!-- DECIMAL -->
    <v-text-field
      v-else-if="question.data.type === 'decimal'"
      v-model.number.lazy="question.answer"
      :disabled="fieldIsDisabled"
      :rules="getRules(question)"
      :placeholder="$t('surveyAnswer.typeAnswer')"
      :readonly="readonly"
      :prefix="question.data.prefix"
      :suffix="question.data.suffix"
      :clearable="!readonly"
      hide-details="auto"
      type="number"
      outlined
      dense
      @input="onAnswer"
    />

    <!-- NUMBER RANGE -->
    <div
      v-else-if="question.data.type === 'number_range'"
      class="d-flex align-center"
      style="gap: 1rem"
    >
      <v-text-field
        v-model.number.lazy="question.answer[0]"
        :rules="getRules(question)"
        :placeholder="$t('placeholder.from')"
        :readonly="readonly"
        :prefix="question.data.prefix"
        :suffix="question.data.suffix"
        :disabled="fieldIsDisabled"
        :clearable="!readonly"
        hide-details="auto"
        type="number"
        outlined
        dense
        @input="onAnswer"
      />
      <v-text-field
        v-model.number.lazy="question.answer[1]"
        :rules="getRules(question)"
        :placeholder="$t('placeholder.to')"
        :readonly="readonly"
        :prefix="question.data.prefix"
        :suffix="question.data.suffix"
        :disabled="fieldIsDisabled"
        :clearable="!readonly"
        hide-details="auto"
        type="number"
        outlined
        dense
        @input="onAnswer"
      />
    </div>

    <!-- AUTOCOMPLETE -->
    <!-- BUG IN VUETIFY: RETURN-OBJECT IS TRUE BY DEFAULT -->
    <v-combobox
      v-if="question.data.type === 'autocomplete' && autocomplete[question.stateId]"
      v-model="question.answer"
      :items="getAutocompleteItems(question)"
      :rules="getRules(question)"
      :placeholder="$tc('surveyAnswer.selectQuestion', question.data.multiple ? 9 : 1)"
      :multiple="question.data.multiple"
      :readonly="readonly"
      :item-value="preview ? 'autoIncrementId' : 'data.id'"
      :disabled="disabled"
      :clearable="!readonly"
      item-text="data.content"
      prepend-inner-icon="mdi-comment-question-outline"
      hide-details="auto"
      outlined
      dense
      @input="onAnswer"
    />

    <!-- COUNTRIES -->
    <v-autocomplete
      v-if="question.data.type === 'country'"
      v-model="question.answer"
      :items="countryList"
      :rules="getRules(question)"
      :placeholder="$tc('surveyAnswer.selectCountry', question.data.multiple ? 9 : 1)"
      :multiple="question.data.multiple"
      :readonly="readonly"
      :loading="survey.loadingCountries"
      :disabled="survey.loadingCountries"
      :prefix="question.data.prefix"
      :suffix="question.data.suffix"
      :clearable="!readonly"
      hide-details="auto"
      prepend-inner-icon="mdi-earth"
      item-value="data.code"
      item-text="data.label"
      outlined
      dense
      @input="onAnswer"
    />

    <!-- RATING -->
    <v-input
      v-else-if="question.data.type === 'rating'"
      v-model="question.answer"
      :rules="getRules(question)"
      :readonly="readonly"
      hide-details="auto"
    >
      <v-rating
        v-model="question.answer"
        :readonly="readonly"
        :length="question.data.ratingCount || 5"
        background-color="warning"
        color="warning"
        @input="onAnswer"
      />
    </v-input>

    <!-- AUTOCOMPLETE -->
    <!-- BUG IN VUETIFY: RETURN-OBJECT IS TRUE BY DEFAULT -->
    <v-combobox
      v-if="question.data.type === 'autocomplete' && survey.autocompleteList[question.stateId]"
      v-model="question.answer"
      :disabled="fieldIsDisabled"
      :items="getAutocompleteItems(question)"
      :rules="getRules(question)"
      :placeholder="$tc('surveyAnswer.selectQuestion', question.data.multiple ? 9 : 1)"
      :multiple="question.data.multiple"
      :readonly="readonly"
      :prefix="question.data.prefix"
      :suffix="question.data.suffix"
      :clearable="!readonly"
      item-value="stateId"
      item-text="data.content"
      prepend-inner-icon="mdi-comment-question-outline"
      hide-details="auto"
      outlined
      dense
      @input="onAnswer"
    />

    <!-- INPUT -->
    <v-text-field
      v-else-if="question.data.type === 'input'"
      v-model.lazy="question.answer"
      v-safechar
      :disabled="fieldIsDisabled"
      :rules="getRules(question)"
      :placeholder="$t('surveyAnswer.typeAnswer')"
      :readonly="readonly"
      :prefix="question.data.prefix"
      :suffix="question.data.suffix"
      :clearable="!readonly"
      hide-details="auto"
      outlined
      dense
      @input="onAnswer"
    />

    <!-- RADIO -->
    <template v-if="question.data.type === 'radio'">
      <template v-if="question.data.surveychoicelist.length > 0">
        <v-radio-group
          v-model="question.answer"
          :rules="getRules(question)"
          :disabled="fieldIsDisabled"
          hide-details="auto"
          class="ma-0 pa-0"
          @change="onAnswer"
        >
          <template v-for="(choice, choiceIdx) in question.data.surveychoicelist">
            <SurveyQuestion
              v-if="choice.data.isQuestion"
              v-model="choice.data.surveysubquestionentity"
              :survey="survey"
              :mode="mode"
              :parent="choice"
              :index="choiceIdx"
              :readonly="readonly"
              :key="choice.stateId + '_question'"
              class="mt-3 mb-4"
              @answer="onAnswer"
            />
            <v-expand-transition v-else :key="choice.stateId + '_choice'">
              <div v-if="survey.isActive(choice.data.hash)">
                <div v-if="choice.data.includePrependText" v-html="choice.data.prependText" class="body-1"></div>
                <v-radio
                  :value="choice.stateId"
                  :key="choice.stateId"
                  :label="choice.data.content"
                  :readonly="readonly"
                />
                <div v-if="choice.data.includeAppendText" v-html="choice.data.appendText" class="body-1 mt-4"></div>
              </div>
            </v-expand-transition>
            <v-expand-transition :key="choice.stateId + '_other'">
              <v-text-field
                v-if="choice.data.includeOther && question.answer === choice.stateId"
                v-model="question.answerOther"
                :disabled="fieldIsDisabled"
                :placeholder="$t('surveyAnswer.typeAnswer')"
                :readonly="readonly"
                :rules="getRules(question)"
                :clearable="!readonly"
                class="mt-3"
                outlined
                dense
                hide-details="auto"
              />
            </v-expand-transition>
          </template>
        </v-radio-group>
      </template>
      <div v-else>
        <v-alert
          text
          type="info"
          class="mb-0"
        >
          <span v-text="$t('surveyAnswer.noChoiceDefined')"></span>
        </v-alert>
      </div>
    </template>

    <!-- CHECKBOX -->
    <template v-if="question.data.type === 'checkbox'">
      <template v-if="question.data.surveychoicelist.length > 0">
        <template v-for="(choice, choiceIdx) in question.data.surveychoicelist">
          <SurveyQuestion
            v-if="choice.data.isQuestion"
            v-model="choice.data.surveysubquestionentity"
            :survey="survey"
            :mode="mode"
            :parent="choice"
            :index="choiceIdx"
            :readonly="readonly"
            :key="choice.stateId + '_question'"
            class="mt-3 mb-4"
            @answer="onAnswer"
          />
          <v-expand-transition v-else :key="choice.stateId + '_choice'">
            <div v-if="survey.isActive(choice.data.hash)">
              <div v-if="choice.data.includePrependText" v-html="choice.data.prependText" class="body-1"></div>
              <v-checkbox
                v-model="question.answer"
                :value="choice.stateId"
                :disabled="fieldIsDisabled"
                :rules="getRules(question)"
                :readonly="readonly"
                :label="choice.data.content"
                multiple
                class="ma-0 pa-0"
                hide-details="auto"
                @input="onAnswer"
              />
              <div v-if="choice.data.includeAppendText" v-html="choice.data.appendText" class="body-1 mt-4"></div>
            </div>
          </v-expand-transition>
          <v-expand-transition :key="choice.stateId + '_other'">
            <v-text-field
              v-if="choice.data.includeOther && question.answer === choice.stateId"
              v-model="question.answerOther"
              :disabled="fieldIsDisabled"
              :placeholder="$t('surveyAnswer.typeAnswer')"
              :readonly="readonly"
              :rules="getRules(question)"
              :clearable="!readonly"
              outlined
              dense
              hide-details="auto"
            />
          </v-expand-transition>
        </template>
      </template>
      <div v-else>
        <v-alert
          text
          type="info"
          class="mb-0"
        >
          <span v-text="$t('surveyAnswer.noChoiceDefined')"></span>
        </v-alert>
      </div>
    </template>

    <!-- TEXTAREA -->
    <v-textarea
      v-else-if="question.data.type === 'textarea'"
      v-model="question.answer"
      :disabled="fieldIsDisabled"
      :rules="getRules(question)"
      :placeholder="$t('surveyAnswer.typeAnswer')"
      :readonly="readonly"
      :clearable="!readonly"
      hide-details="auto"
      outlined
      @input="onAnswer"
    />

    <!-- PERCENTAGE -->
    <v-text-field
      v-if="question.data.type === 'percentage'"
      v-model.number.lazy="question.answer"
      :disabled="fieldIsDisabled"
      :rules="getRules(question)"
      :placeholder="$t('placeholder.from')"
      :readonly="readonly"
      :prefix="question.data.prefix"
      :suffix="question.data.suffix"
      :clearable="!readonly"
      type="number"
      hide-details="auto"
      outlined
      dense
      @input="onAnswer"
    />

    <!-- NUMBER -->
    <v-text-field
      v-else-if="question.data.type === 'number'"
      v-model.lazy.number="question.answer"
      :disabled="fieldIsDisabled"
      :rules="getRules(question)"
      :placeholder="$t('surveyAnswer.typeAnswer')"
      :readonly="readonly"
      :prefix="question.data.prefix"
      :suffix="question.data.suffix"
      :clearable="!readonly"
      type="number"
      hide-details="auto"
      outlined
      dense
      @input="onAnswer"
    />

    <!-- YEAR -->
    <v-autocomplete
      v-else-if="question.data.type === 'year'"
      v-model="question.answer"
      :disabled="fieldIsDisabled"
      :items="getYears(question)"
      :rules="getRules(question)"
      :placeholder="$tc('surveyAnswer.selectYear', question.data.multiple ? 9 : 1)"
      :multiple="question.data.multiple"
      :readonly="readonly"
      :item-value="preview ? 'stateId' : 'data.content'"
      :prefix="question.data.prefix"
      :suffix="question.data.suffix"
      :clearable="!readonly"
      item-text="data.content"
      hide-details="auto"
      outlined
      dense
      @input="onAnswer"
    />

    <!-- DATE -->
    <div
      v-if="question.data.type === 'date'"
      class="d-flex align-center"
      style="gap: 1rem"
    >
      <DialogDateField
        v-model="question.answer"
        :key="question.data.type + '_' + question.stateId"
        :input-attrs="{
          hideDetails: 'auto',
          rules: getRules(question),
          label: $t('placeholder.chooseDate'),
          prefix: question.data.prefix,
          suffix: question.data.suffix,
          disabled: fieldIsDisabled,
        }"
        @input="onAnswer"
      />
    </div>

    <!-- DATE RANGE -->
    <div
      v-if="question.data.type === 'date_range'"
      class="d-flex align-center"
      style="gap: 1rem"
    >
      <DialogDateField
        v-model="question.answer[range]"
        v-for="range in [0, 1]"
        :key="question.data.type + '_' + question.stateId + '_' + range"
        :input-attrs="{
          hideDetails: 'auto',
          rules: getRules(question),
          label: range === 0 ? $t('placeholder.fromDate') : $t('placeholder.toDate'),
          prefix: question.data.prefix,
          suffix: question.data.suffix,
          disabled: fieldIsDisabled,
        }"
        :date-picker-attrs="
          range === 0
            ? { max: question.answer[1] }
            : { min: question.answer[0] }
          "
      />
    </div>

    <!-- DATETIME -->
    <div v-if="question.data.type === 'datetime'">
      <div class="d-flex align-center w-100" style="gap: 1rem">
        <DialogDateTimeField
          v-model="question.answer"
          :key="question.data.type + '_' + question.stateId"
          :label="$t('placeholder.chooseDateTime')"
        />
      </div>
    </div>

    <!-- DATETIME RANGE -->
    <div v-if="question.data.type === 'datetime_range'">
      <div class="d-flex align-center w-100" style="gap: 1rem">
        <DialogDateTimeField
          v-for="range in [0, 1]"
          v-model="question.answer[range]"
          :key="question.data.type + '_' + question.stateId + '_' + range"
          :label="range === 0 ? $t('placeholder.fromDateTime') : $t('placeholder.toDateTime')"
          :date-picker-attrs="
            range === 0
              ? { max: question.answer[1] && question.answer[1].toISOString() }
              : { min: question.answer[0] && question.answer[0].toISOString() }
            "
        />
      </div>
    </div>

    <!-- TIME -->
    <div
      v-if="question.data.type === 'time'"
      class="d-flex align-center"
      style="gap: 1rem"
    >
      <DialogTimeField
        v-model="question.answer"
        :key="question.data.type + '_' + question.stateId"
        :input-attrs="{
          hideDetails: 'auto',
          rules: getRules(question),
          label: $t('label.pickTime'),
          outlined: true,
          dense: true,
          prefix: question.data.prefix,
          suffix: question.data.suffix,
          disabled: fieldIsDisabled,
        }"
        @input="onAnswer"
      />
    </div>

    <!-- TIME RANGE -->
    <div
      v-if="question.data.type === 'time_range'"
      class="d-flex align-center"
      style="gap: 1rem"
    >
      <DialogTimeField
        v-for="range in [0, 1]"
        v-model="question.answer[range]"
        :key="question.data.type + '_' + question.stateId + '_' + range"
        :input-attrs="{
          hideDetails: 'auto',
          rules: getRules(question),
          label: range === 0 ? $t('placeholder.fromTime') : $t('placeholder.toTime'),
          outlined: true,
          dense: true,
          prefix: question.data.prefix,
          suffix: question.data.suffix,
          disabled: fieldIsDisabled,
        }"
        :date-picker-attrs="
          range === 0
            ? { max: question.answer[1] }
            : { min: question.answer[0] }
        "
        @input="onAnswer"
      />
    </div>

    <!-- OPTIONS: CAN BE UNKNOWN -->
    <div v-if="question.data.includeUnknown" class="mt-3">
      <v-checkbox
        v-model="question.isUnknown"
        label="Unknown"
        color="primary"
        hide-details
        class="ma-0 pa-0"
      />
    </div>

    <slot name="question.append" :question="question"></slot>

    <!-- MANAGE ANSWERS -->
<!--    <template v-if="manageAnswers">-->
<!--      <div v-if="question.answer.items.length > 0">-->
<!--        <v-btn-->
<!--          :disabled="disabled"-->
<!--          :color="question.answer.conflicting ? 'error': null"-->
<!--          text-->
<!--          x-small-->
<!--          @mousedown.prevent.stop="onViewAnswersClick(question, question.answer.items)"-->
<!--        >-->
<!--          <template v-if="question.answer.conflicting">-->
<!--            <v-icon x-small left>mdi-alert</v-icon>-->
<!--            <span v-text="$t('surveyAnswer.conflictingAnswers', {-->
<!--                total: question.answer.items.length,-->
<!--              })"></span>-->
<!--          </template>-->
<!--          <template v-else>-->
<!--            <v-icon x-small left>mdi-forum</v-icon>-->
<!--            <span v-text="$tc('surveyAnswer.viewAnswers', question.answer.items.length, {-->
<!--              total: question.answer.items.length,-->
<!--            })"></span>-->
<!--          </template>-->
<!--        </v-btn>-->

<!--        <div v-if="question.answer.items.length === 1" class="mt-1 ml-2 caption warning&#45;&#45;text">-->
<!--          <v-icon color="warning" x-small left>mdi-alert</v-icon>-->
<!--          <span>Partially answered</span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div v-else class="mt-1 ml-2 caption warning&#45;&#45;text">-->
<!--        <v-icon color="warning" x-small left>mdi-alert</v-icon>-->
<!--        <span>Not answered yet</span>-->
<!--      </div>-->
<!--    </template>-->
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import SurveyChoiceModel from '@/modules/sdk/models/survey-choice.model';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';
import Rules, { IRuleSet } from '@/modules/sdk/core/rules';
import DialogDateField from '@/modules/common/components/DialogDateField.vue';
import DialogDateTimeField from '@/modules/common/components/DialogDateTimeField.vue';
import DialogTimeField from '@/modules/common/components/DialogTimeField.vue';
import SurveyAnswerModel from '@/modules/sdk/models/survey-answer.model';
import AskQuestionToAiModal from '@/components/AskQuestionToAiModal.vue';
import ManageSurveyAnswersModal from '@/modules/common/components/ManageSurveyAnswersModal.vue';
import SurveyItemMixin from '@/modules/common/mixins/survey';

@Component({
  components: {
    ManageSurveyAnswersModal,
    AskQuestionToAiModal,
    DialogDateField,
    DialogDateTimeField,
    DialogTimeField,
    'SurveyQuestion': () => import('@/modules/common/components/SurveyQuestion.vue')
  }
})
export default class SurveyChoiceField extends SurveyItemMixin<SurveyChoiceModel, SurveyQuestionModel> {

  tick = 0
  rules: IRuleSet = {}
  viewAnswersModal: {
    visible: boolean,
    question: SurveyQuestionModel,
    answers: Array<SurveyAnswerModel>,
  } = {
    visible: false,
    question: new SurveyQuestionModel(),
    answers: [],
  }

  get question(): SurveyQuestionModel {
    return this.parent as SurveyQuestionModel;
  }

  get fieldIsDisabled(): boolean {
    return this.question.isUnknown;
  }

  onAnswer(value: any): void {
    this.tick++;
    this.$emit('answer', value)
  }

  onViewAnswersClick(question: SurveyQuestionModel, answers: Array<SurveyAnswerModel>): void {
    Object.assign(this.viewAnswersModal, {
      visible: true,
      question,
      answers,
    })
  }

  onApplyAnswer(answer: SurveyAnswerModel): void {
    this.viewAnswersModal.question.answer = answer.data.content;
  }

  getRules(question: SurveyQuestionModel): Array<any> {
    const rules: Array<any> = [];

    if (question.isUnknown) {
      return rules;
    }

    if (question.data.required) {
      rules.push(this.rules.required);
    }
    if (question.data.positiveOnly) {
      rules.push(this.rules.isPositive);
    }
    if (question.data.negativeOnly) {
      rules.push(this.rules.isNegative);
    }
    if (question.data.pastOnly) {
      rules.push(this.rules.isPast);
    }
    if (
      question.data.minLength !== null ||
      question.data.maxLength !== null
    ) {
      rules.push((value: string) => this.rules.isBetweenLength(value, question.data.minLength, question.data.maxLength));
    }
    if (
      question.data.numberMin !== null ||
      question.data.numberMax !== null
    ) {
      rules.push((value: number) => this.rules.isBetween(value, question.data.numberMin, question.data.numberMax));
    }
    if (
      question.data.decimalMin !== null ||
      question.data.decimalMax !== null
    ) {
      rules.push((value: number) => this.rules.isBetween(value, question.data.decimalMin, question.data.decimalMax));
    }

    return rules;
  }

  getYears(question: SurveyQuestionModel): Array<number> {
    const years = [];
    for (let i = (question.data.numberMax || new Date().getFullYear()); i >= (question.data.numberMin || 1900); i--) {
      years.push(i);
    }
    return years;
  }

  getAutocompleteItems(question: SurveyQuestionModel): Array<any> {
    return this.autocomplete[question.stateId] || [];
  }

  created() {
    this.rules.required = (value: string) => Rules.required(value) || this.$t('rules.required').toString();
    this.rules.isPositive = (value: number) => Rules.isPositive(value) || this.$t('rules.mustBePositive').toString();
    this.rules.isNegative = (value: number) => Rules.isNegative(value) || this.$t('rules.mustBeNegative').toString();
    this.rules.isPast = (value: Date) => !Rules.isFuture(value) || this.$t('rules.mustBePast').toString();
    this.rules.isBetween = (value: number, min: number, max: number) => Rules.isBetween(value, min, max) || this.$t('surveyBuilder.validation.isBetween', {
      value,
      min,
      max
    }).toString();
    this.rules.isBetweenLength = (value: string, min: number, max: number) => Rules.betweenLength(value, min, max) || this.$t('surveyBuilder.validation.isBetweenLength', {
      value,
      min,
      max
    }).toString();
    this.rules.isBetweenDates = (value: Date, min: Date, max: Date) => Rules.isBetweenDates(value, min, max) || this.$t('surveyBuilder.validation.isBetweenDates', {
      value,
      min,
      max
    }).toString();
  }
}
</script>
