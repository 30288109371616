<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'tag'"
      v-model="model"
      :service="service"
      :loading="loading"
      name="tag"
      title="Tag"
    >
      <template #form="{ formErrors, rules }">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="model.data.label"
              v-safechar
              :error-messages="formErrors.label"
              :rules="[rules.required]"
              label="Tag"
              hide-details="auto"
              outlined
              clearable
              required
              @input="delete formErrors.label"
            />
          </v-col>

          <v-col cols="12">
            <text-field-color-expansion
              v-model="model.data.color"
              :error-messages="formErrors.color"
              :rules="[rules.required]"
              label="Color"
              outlined
              clearable
              hide-details="auto"
              mode="hexa"
              hide-canvas
              hide-inputs
              hide-mode-switch
              show-swatches
              width="100%"
              swatches-max-height="360"
              @input="delete formErrors.color"
              @change="delete formErrors.color"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-sheet outlined rounded>
              <v-data-table
                :headers="recordListHeaders"
                :items="model.data.recordnode"
                item-key="data.id"
                sort-by="data.recordentity.title"
                group-by="data.projectentity.data.label"
                show-group-by
              ></v-data-table>
            </v-sheet>
          </v-col>
        </v-row>
      </template>
    </DataForm>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Vue, Component, Watch } from 'vue-property-decorator'
import TagService from '@/services/tag.service'
import Logger from '@/modules/sdk/core/logger'
import Wysiwyg from '@/modules/common/components/Wysiwyg.vue'
import DataForm from '@/modules/common/components/DataForm.vue'
import TagModel from '@/models/tag.model'
import TextFieldColorExpansion from '@/modules/common/components/TextFieldColorExpansion.vue'

const d = new Logger('views/Admin/Form/TagForm')

@Component({
  components: {
    TextFieldColorExpansion,
    Wysiwyg,
    DataForm,
  }
})
export default class TagForm extends Vue {

  service = TagService.getInstance()
  loading = false
  model: TagModel = new TagModel()

  get recordListHeaders() {
    return [
      {
        text: 'Record',
        align: 'start',
        value: 'data.recordentity.data.title',
        groupable: false,
      },
      {
        text: 'Category',
        value: 'data.categoryentity.data.label',
        align: 'right',
        groupable: false,
        sortable: false,
      },
      {
        text: 'Project',
        value: 'data.projectentity.data.label',
        align: 'right',
        sortable: false,
      },
    ]
  }

  load(id: number) {
    this.loading = true
    this.service.get({ id })
    .then(response => this.model = response.data.view.single)
    .catch(reason => this.$root.$zemit.handleError(reason))
    .finally(() => this.loading = false)
  }

  created() {
    if (this.$route.params.id) {
      this.load(parseInt(this.$route.params.id))
    }
  }
}
</script>
