<template>
  <ModalDialog
    v-model="visible"
    v-bind="$attrs"
    v-on="$listeners"
    :title="title"
    :buttons="buttons"
    max-width="500"
  >
    <template #body>
      <div v-html="body"></div>
      <div v-if="hasInput" class="mt-3">
        <v-text-field
          v-model="input"
          :placeholder="$t('globalPrompt.typeAnswerHere')"
          :rules="[rules.required]"
          autofocus
          required
          outlined
          dense
        />
      </div>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import { Component, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import Rules from '@/modules/sdk/core/rules';

@Component({
  components: {
    ModalDialog,
  }
})
export default class GlobalModal extends Vue {

  visible = false
  title: string | TranslateResult | null = null
  body: string | TranslateResult | null = null
  input: string | null = null
  hasInput = false
  buttons: Array<any> = []
  rules: any = {}

  ask(
    title: string | TranslateResult,
    body: string | TranslateResult,
    callback?: (input?: string | null | undefined) => void,
    callbackText?: string | TranslateResult,
    hasInput = false,
    inputValue = null,
  ) {
    this.hasInput = hasInput;
    this.visible = true;
    this.title = title;
    this.input = inputValue;
    this.body = body;
    this.buttons = [
      {
        text: this.$i18n.t('btn.cancel'),
        attrs: {
          text: true,
        },
        events: {
          click: () => {
            this.hide();
          }
        }
      },
      {
        text: callbackText || this.$i18n.t('btn.ok'),
        attrs: {
          color: 'primary',
          disabled: () => {
            return this.hasInput && !this.input;
          }
        },
        events: {
          click: () => {
            if (callback) {
              callback(hasInput ? this.input : undefined);
            } else {
              this.hide();
            }
          }
        }
      },
    ];
  }

  setDisabled(value: boolean) {
    this.buttons[0].attrs.disabled = value;
    this.buttons[1].attrs.disabled = value;
    this.$forceUpdate();
  }

  setLoading(value: boolean) {
    this.buttons[0].attrs.loading = value;
    this.setDisabled(value);
    this.$forceUpdate();
  }

  hide() {
    this.visible = false;
  }

  created() {
    this.$root.$globalPrompt = this;
    this.rules = {
      required: (value: string) => Rules.required(value) || this.$t('rules.required'),
    };
  }
}
</script>
