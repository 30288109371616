import Model, { Data } from '@/modules/sdk/core/model';
import SynonymModel from '@/models/synonym.model';
import ProjectModel from '@/models/project.model';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const tinycolor = require('tinycolor2');

export default class KeywordModel extends Model {
  selected = false;

  default() {
    return {
      synonymlist: [],
    };
  }

  relatedMap () {
    return {
      synonymlist: SynonymModel,
      projectentity: ProjectModel,
    }
  }

  columnCast(): { [key: string]: string } {
    return {
      deleted: 'number',
    };
  }

  relatedDeleteMissing() {
    return {
      synonymlist: true,
    };
  }

  isDark() {
    return tinycolor(this.data.color).isDark();
  }

  getTextClass() {
    return (this.isDark() ? 'white' : 'black') + '--text';
  }

  getTextColor(force = false) {
    return this.selected || force
      ? ((this.isDark() ? 'white' : 'black') + '--text')
      : this.data.color
        ? this.data.color
        : 'black--text';
  }

  selectedToggle(selected = !this.selected) {
    this.selected = selected;
    this.data.synonymlist.forEach((synonym: SynonymModel) => {
      synonym.selected = selected;
    });
  }

  toObject(data: Data | Model = this.data, toSaveObject = false): any {
    const obj: any = super.toObject(data, toSaveObject);
    if (toSaveObject) {
      obj.synonymlist = obj.synonymlist.filter((synonym: any) => synonym.label);
    }
    return obj;
  }
}
