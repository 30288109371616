<template>

  <!-- EDIT -->
  <ListBuilder
    v-if="mode === 'edit'"
    v-model="model.data.surveysectionlist"
    :default-model="surveySectionModel"
    :default-data="{
      surveyId: model.data.id,
      projectId: model.data.projectId,
    }"
    :labels="{
      add: 'Add a new section',
      remove: 'Remove section',
      empty: 'No section available yet',
    }"
    :btn-attrs="{
      color: 'section',
    }"
    :sortable="sortable"
    :show-removed="showRemoved"
    :button-offset="22"
    :min="1"
    group="survey_section"
    order-by="position"
    is-model
    restorable
    collapsable
  >
    <template #item="section">
      <SurveySection
        v-model="section.item"
        v-bind="computedCascadeAttrs"
        :parent="model"
        :index="section.index"
      >
        <template v-for="(slot, key) in $scopedSlots" #[key]="props">
          <slot :name="key" v-bind="props"></slot>
        </template>
      </SurveySection>
    </template>
  </ListBuilder>

  <!-- ANSWER -->
  <v-sheet v-else-if="mode === 'answer'" color="transparent">

    <!-- PAGINATED -->
    <v-card v-if="view === 'paginated'">
      <v-window v-model="panel">
        <v-window-item
          v-for="(section, sectionIdx) in sectionList"
          :key="section.data.hash"
          :value="sectionIdx"
        >
          <v-form v-model="formIsValid[sectionIdx]">
            <SurveySection
              v-model="sectionList[sectionIdx]"
              v-bind="computedCascadeAttrs"
              :cascade-attrs="cascadeAttrs"
              :parent="model"
              :index="sectionIdx"
              :user-list="filteredAnswerUserList"
              :user-list-loading="model.loadingAnswerUserList"
              @answer="onAnswer"
            >
              <template v-for="(slot, key) in $scopedSlots" #[key]="props">
                <slot :name="key" v-bind="props"></slot>
              </template>
            </SurveySection>
          </v-form>
        </v-window-item>
        <v-window-item key="end" :value="sectionList.length">
          <v-sheet tile flat>
            <v-sheet color="success" dark>
              <v-card-title class="font-weight-bold display-1">
                Survey completed
              </v-card-title>
            </v-sheet>
            <v-card-text>
              <div class="body-1">
                Thank you for completing the survey! Your feedback is valuable to us.
              </div>
            </v-card-text>
          </v-sheet>
        </v-window-item>
      </v-window>
    </v-card>

    <!-- LIST -->
    <template v-for="(section, sectionIdx) in sectionList">
      <v-form
        v-model="formIsValid[sectionIdx]"
        :key="section.data.hash"
      >
        <SurveySection
          v-bind="computedCascadeAttrs"
          v-model="sectionList[sectionIdx]"
          :cascade-attrs="cascadeAttrs"
          :parent="model"
          :index="sectionIdx"
          :country-list="innerCountryList"
          :user-list="filteredAnswerUserList"
          :user-list-loading="model.loadingAnswerUserList"
          :before-save-section="beforeSaveSection"
          :class="{ 'mt-3': sectionIdx > 0 }"
          @answer="onAnswer"
        >
          <template v-for="(slot, key) in $scopedSlots" #[key]="props">
            <slot :name="key" v-bind="props"></slot>
          </template>
        </SurveySection>
      </v-form>
    </template>

    <!-- ACTIONS -->
    <slot v-if="!hideActions" name="actions">
      <v-card-text class="background pa-4 d-flex align-center justify-space-between text-center" style="gap: 1rem">
        <v-btn
          v-if="totalSections > 1"
          :disabled="!canGoBack"
          color="primary"
          x-large
          style="flex: 1"
          @click="goBack"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Previous
        </v-btn>
        <div style="flex: 1"></div>
        <v-progress-linear
          v-if="totalSections > 1 && !completed"
          :value="progress"
          color="primary"
          height="8"
          style="flex: 2"
        />
        <div style="flex: 1"></div>
        <v-btn
          v-if="isLastSection"
          :disabled="!canFinish"
          color="primary"
          x-large
          style="flex: 1"
          @click="finish"
        >
          <v-icon left>mdi-send</v-icon>
          Send
        </v-btn>
        <v-btn
          v-else
          :disabled="!canGoNext"
          color="primary"
          x-large
          style="flex: 1"
          @click="goNext"
        >
          Next
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-text>
    </slot>
  </v-sheet>
</template>

<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator';
import SurveySection from '@/modules/common/components/SurveySection.vue';
import SurveyModel from '@/modules/sdk/models/survey.model';
import SurveySectionModel from '@/modules/sdk/models/survey-section.model';
import Rules from '@/modules/sdk/core/rules';
import SurveyAnswerService from '@/modules/sdk/services/survey-answer.service';
import SurveyAnswerModel from '@/modules/sdk/models/survey-answer.model';
import CountryModel from '@/modules/sdk/models/country.model';
import Identity from '@/modules/sdk/core/identity';
import SurveyItemMixin from '@/modules/common/mixins/survey';
import UserModel from '@/modules/sdk/models/user.model';

@Component({
  components: {
    SurveySection
  }
})
export default class Survey extends SurveyItemMixin<SurveyModel, SurveyModel> {
  @Prop({ type: Boolean, default: false, }) hideActions!: boolean
  @Prop({ type: Boolean, default: false, }) loadAnswers!: boolean
  @Prop({ type: Boolean, default: false, }) includeSaveButton!: boolean
  @Prop({ type: Function, default: () => {} }) beforeSaveSection!: (answers: SurveyAnswerModel[]) => void

  @Watch('canViewAnswers', { immediate: true })
  onCanViewAnswersChange(canViewAnswers: boolean) {
    if (canViewAnswers && !this.model.answerListLoaded) {
      this.model.loadAnswerUserList(this.answerFilters)
    }
  }

  panel = 0
  completed = false
  formIsValid: boolean[] = []
  loadingAnswers = false
  innerCountryList: CountryModel[] | null = null;
  surveySectionModel = SurveySectionModel

  @Watch('mode')
  onModeChanged(mode: string) {
    if (mode === 'answer') {
      this.model.updateActiveItems();
      this.model.loadItems();
    }
  }

  onAnswer(value: any): void {
    this.model.updateActiveItems();
    this.$emit('answer', value);
    this.tick++;
  }

  get totalSections(): number {
    return this.sectionList.length;
  }

  get sectionList(): SurveySectionModel[] {
    return this.model.data.surveysectionlist.filter((section: SurveySectionModel) => {
      return this.tick > -1 && this.model.isActive(section.data.hash);
    });
  }

  get progress(): number {
    return this.completed ? 100 : Math.round(
      (this.panel * 100) / this.totalSections - 1
    );
  }

  get canGoBack(): boolean {
    return !this.completed && this.panel > 0;
  }

  get canGoNext(): boolean {
    return !this.completed && this.formIsValid[this.panel]
      && this.panel < this.totalSections - 1;
  }

  get canFinish(): boolean {
    return !this.completed && this.formIsValid[this.panel];
  }

  get isLastSection(): boolean {
    return this.panel >= this.totalSections - 1;
  }

  get filteredAnswerUserList(): any[] {
    return this.model.getFilteredAnswerUserList(
      this.canSeeAnswerName,
      this.canSeeOfficialAnswers,
      this.canUseAi,
    );
  }

  goBack(): void {
    if (this.canGoBack) {
      this.panel--;
    }
  }

  goNext(): void {
    if (this.canGoNext) {
      this.panel++;
    }
  }

  finish(): void {
    this.completed = true;
    this.panel++;
  }

  async loadUserAnswers(): Promise<Array<SurveyAnswerModel>> {
    const filters = [{
      field: 'projectId',
      operator: 'equals',
      value: this.model.data.projectId,
    }, {
      field: 'surveyId',
      operator: 'equals',
      value: this.model.data.id,
    }, {
      field: 'userId',
      operator: 'equals',
      value: Identity.getIdentity()?.user.id,
    }];

    if (this.answerFilters) {
      filters.push(...this.answerFilters)
    }

    this.loadingAnswers = true;
    return SurveyAnswerService.getInstance().getAll({ filters })
      .then((response) => {
        this.model.applyAnswers(response.data.view.list);
        return response.data.view.list;
      })
      .catch(reason => this.$root.$zemit.handleError(reason, this.errors))
      .finally(() => this.loadingAnswers = false);
  }

  created() {
    this.rules.required = (value: string) => Rules.required(value) || this.$t('rules.required').toString();
    this.model.updateActiveItems();

    if (this.mode === 'answer') {
      const promises = [];
      if (this.loadAnswers) {
        promises.push(this.loadUserAnswers());
      }
      for (let i = 0; i < this.model.data.surveysectionlist.length; i++) {
        const section = this.model.data.surveysectionlist[i];
        for (let j = 0; j < section.data.surveygrouplist.length; j++) {
          const group = section.data.surveygrouplist[j];
          for (let k = 0; k < group.data.surveyquestionlist.length; k++) {
            const question = group.data.surveyquestionlist[k];
            if (question.data.type === 'country') {
              promises.push(this.model.loadCountries().then(response => this.innerCountryList = response.data.view.list));
            }
            if (question.data.type === 'autocomplete') {
              promises.push(this.model.loadAutocomplete(question.data.id).then(response => this.autocomplete[question.data.id] = response.data.view.list));
            }
          }
        }
      }
      Promise.all(promises).then(() => this.$forceUpdate());
    }
  }
}
</script>
