import SurveyModel from '@/modules/sdk/models/survey.model';
import SurveySectionModel from '@/modules/sdk/models/survey-section.model';
import SurveyGroupModel from '@/modules/sdk/models/survey-group.model';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';
import SurveyItemModel from '@/modules/sdk/models/survey-item.model';
import Hash from '@/modules/sdk/core/hash';

export default class SurveyChoiceModel extends SurveyItemModel {

  relatedMap () {
    return {
      surveyentity: SurveyModel,
      surveysectionentity: SurveySectionModel,
      surveygroupentity: SurveyGroupModel,
      surveyquestionentity: SurveyQuestionModel,
      surveysubquestionentity: SurveyQuestionModel,
    }
  }

  default() {
    return {
      hash: this.data.hash ? this.data.hash : Hash.guid(),
      surveysubquestionentity: null,
      isQuestion: false,
      isCategory: false,
      includeOther: false,
      includeUnknown: false,
      includePrependText: false,
      includeAppendText: false,
      prependText: '',
      appendText: '',
      content: null,
      conditions: [],
      action: null,
      prefix: null,
      suffix: null,
    };
  }

}
