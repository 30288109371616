<template>
  <div v-if="mode === 'answer'">
    <div v-if="model.data.includePrependText" v-html="model.data.prependText" class="body-1"></div>
    <SurveyChoiceField
      v-model="model"
      v-bind="computedCascadeAttrs"
      :survey="survey"
      :question="question"
      :index="index"
      :tick="tick"
      @answer="onAnswer"
    >
      <template v-for="(slot, key) in $scopedSlots" #[key]="props">
        <slot :name="key" v-bind="props"></slot>
      </template>
    </SurveyChoiceField>
    <div v-if="model.data.includeAppendText" v-html="model.data.appendText" class="body-1"></div>
  </div>
  <div v-else-if="mode === 'edit'">
    <v-expand-transition>
      <div v-if="model.data.includePrependText">
        <Wysiwyg
          v-model="model.data.prependText"
          label="Prepend text"
          :height="200"
        ></Wysiwyg>
      </div>
    </v-expand-transition>

    <v-text-field
      v-model.lazy="model.data.content"
      :rules="[rules.required]"
      :readonly="readonly"
      :background-color="model.data.isCategory ? 'background' : null"
      :style="{ fontWeight: model.data.isCategory ? 'bold' : 'normal' }"
      error-message="Label required"
      hide-details="auto"
      clearable
      outlined
    >
      <template #label>
        <span class="error--text">* </span>
        <span>{{ label }}</span>
      </template>
      <template #append>
        <div class="d-flex align-center" style="margin-top: -0.4rem">
          <v-chip v-if="debug" label small color="warning" class="mr-1">
            {{ model.stateId }}
          </v-chip>
          <v-chip v-if="model.data.includeOther" small label class="mr-1">
            Other
          </v-chip>
          <v-chip v-if="model.data.isCategory" small label class="mr-1">
            Category
          </v-chip>
          <v-chip v-if="model.data.includeUnknown" small label class="mr-1">
            Can be unknown
          </v-chip>
          <v-chip v-if="model.isHiddenByDefault()" small label class="mr-1">
            Hidden
          </v-chip>
          <template v-if="question.canDefineChoices() || question.canDefineMultipleQuestions() || question.choiceCanBeACategory()">
            <SurveyRulesDialogActivator
              v-model="model"
              :survey="survey"
              :extra-fields="extraFields"
            />
            <SurveyItemOptionsMenu
              v-model="model"
              :list="question.data.surveychoicelist"
              :index="index"
              disabled-style
            >
              <template v-if="$scopedSlots['items.append']" #items.append>
                <slot name="items.append" :choice="model" :question="question"></slot>
              </template>
            </SurveyItemOptionsMenu>
          </template>
        </div>
      </template>
    </v-text-field>

    <v-expand-transition>
      <div v-if="model.data.includeAppendText">
        <Wysiwyg
          v-model="model.data.appendText"
          label="Append text"
          :height="200"
        ></Wysiwyg>
      </div>
    </v-expand-transition>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import SurveyItemOptionsMenu from '@/modules/common/components/SurveyItemOptionsMenu.vue';
import SurveyChoiceField from '@/modules/common/components/SurveyChoiceField.vue';
import SurveyChoiceModel from '@/modules/sdk/models/survey-choice.model';
import Rules from '@/modules/sdk/core/rules';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';
import SurveyHeader from '@/modules/common/components/SurveyHeader.vue';
import Wysiwyg from '@/modules/common/components/Wysiwyg.vue';
import SurveyRulesDialogActivator from '@/modules/common/components/SurveyRulesDialogActivator.vue';
import SurveyItemMixin from '@/modules/common/mixins/survey';

@Component({
  components: {
    SurveyRulesDialogActivator,
    Wysiwyg,
    SurveyHeader,
    SurveyItemOptionsMenu,
    SurveyChoiceField,
  }
})
export default class SurveyChoice extends SurveyItemMixin<SurveyChoiceModel, SurveyQuestionModel> {
  @Prop({ type: SurveyQuestionModel, default: () => new SurveyQuestionModel({}, false) }) question!: SurveyQuestionModel
  @Prop({ type: String, default: 'Choice', }) label!: string

  tick = 0
  rules: {[key: string]: (value: any) => boolean | string} = {}

  onAnswer(value: any): void {
    this.tick++;
    this.$emit('answer', value)
  }

  created() {
    this.rules.required = (value: string) => Rules.required(value) || this.$t('rules.required').toString();
  }
}
</script>
