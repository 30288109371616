<template>
  <v-form
    name="resetPassword"
    ref="form"
    v-test-id="'reset-password-form'"
    v-model="valid"
    :disabled="reseting"
    @submit="onSubmit"
    lazy-validation
  >
    <p>Type and confirm your new password below.</p>

    <v-text-field
      name="email"
      v-test-id="'email'"
      v-model="data.email"
      v-safechar
      :error-messages="errors.email"
      :label="$t('placeholder.email')"
      :rules="[rules.required, rules.email]"
      :autofocus="$vuetify.breakpoint.mdAndUp"
      prepend-inner-icon="mdi-account"
      autocomplete="email"
      tabindex="1"
      outlined
      required
      @input="delete errors.email"
    />

    <v-text-field
      name="password"
      v-test-id="'password'"
      v-model="data.password"
      v-safechar
      :error-messages="errors.password"
      :label="$t('placeholder.newPassword')"
      :rules="[rules.required]"
      :type="showPassword ? 'text' : 'password'"
      :append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      prepend-inner-icon="mdi-lock"
      autocomplete="password"
      tabindex="2"
      outlined
      required
      @click:append="showPassword = !showPassword"
      @input="delete errors.password"
    />

    <v-text-field
      name="passwordConfirm"
      v-test-id="'passwordConfirm'"
      v-model="data.passwordConfirm"
      v-safechar
      :error-messages="errors.passwordConfirm"
      :label="$t('placeholder.newPasswordConfirmation')"
      :rules="[rules.required, val => rules.identical(val, data.password)]"
      :type="showPasswordConfirmation ? 'text' : 'password'"
      :append-icon="!showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
      prepend-inner-icon="mdi-lock"
      autocomplete="confirmation"
      tabindex="2"
      outlined
      required
      @click:append="showPasswordConfirmation = !showPasswordConfirmation"
      @input="delete errors.passwordConfirm"
    />

    <v-btn
      v-test-id="'submit'"
      tabindex="2"
      type="submit"
      color="primary"
      :disabled="!canSubmit"
      :loading="reseting"
      block
    >
      Reset password
    </v-btn>

    <v-btn
      :to="{ name: 'login' }"
      small
      block
      text
      class="mt-2"
    >
      <v-icon left>mdi-arrow-left</v-icon>
      Go back to login
    </v-btn>
  </v-form>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Component, Ref, Vue } from 'vue-property-decorator';
import Rules from '@/modules/sdk/core/rules';
import AuthService from '@/modules/sdk/services/auth.service';
import Logger from '@/modules/sdk/core/logger';

const d = new Logger('Auth/ResetPassword.vue');

export type VForm = Vue & { validate: () => boolean }

@Component
export default class ResetPasswordView extends Vue {
  @Ref() readonly form!: VForm

  reseting = false;
  showPassword = false;
  showPasswordConfirmation = false;

  valid = true;
  errors = {};
  rules = {};
  data: {
    token: string,
    email: string,
    password: string,
    passwordConfirm: string,
  } = {
    token: '',
    email: '',
    password: '',
    passwordConfirm: '',
  }

  get loading() {
    return this.reseting;
  }

  get canSubmit() {
    return !this.loading && this.valid;
  }

  onSubmit(event: any) {
    event.preventDefault();
    this.errors = {};
    if (this.form && this.form.validate()) {
      this.resetPassword();
    }
  }

  resetPassword(): Promise<any> {
    this.reseting = true;
    return AuthService.getInstance().reset(this.data)
      .then((response: any) => {
        if (response.data.view.reset) {
          this.$root.$globalSnack.success({
            message: 'Your password has been successfully reset. You may now log in with your new credentials.',
          });
          this.$router.push({ name: 'login' });
        } else {
          this.$root.$globalSnack.warning({
            icon: 'mdi-emoticon-dead-outline',
            message: 'We apologize for the inconvenience, but an unexpected error has occurred. ' +
              'Please attempt to reset your password again. ' +
              'If the issue persists, do not hesitate to contact our support team for assistance at helpdesk@yolarx.com.',
          });
        }
      })
      .catch(reason => this.$root.$zemit.handleError(reason, this.errors))
      .finally(() => this.reseting = false);
  }

  created() {
    if (this.$route.params.token) {
      this.data.token = this.$route.params.token;
    }
    this.rules = {
      required: (value: string) => Rules.required(value) || this.$t('rules.required'),
      email: (value: string) => Rules.email(value) || this.$t('rules.email'),
      identical: (valueA: string, valueB: string) => Rules.identical(valueA, valueB) || this.$t('rules.identical'),
    };
    this.$emit('title', this.$t('auth.title.' + this.$route.name))
  }
}
</script>
