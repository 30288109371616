import Model, { Data } from '@/modules/sdk/core/model';
import Query from '@/modules/sdk/core/query';
import Hash from '@/modules/sdk/core/hash';
import SurveyChoiceModel from '@/modules/sdk/models/survey-choice.model';

export type ISurveyItemType = 'list' | 'numeric' | 'date' | 'date_time' | 'time' | 'string';

export default class SurveyItemModel extends Model {

  public expanded = true
  public answer: any = null
  public parent: SurveyItemModel | null = null

  default(): any {
    return {
      hash: this.data.hash ? this.data.hash : Hash.guid(),
      includePrependText: false,
      includeAppendText: false,
      prependText: '',
      appendText: '',
      conditions: [],
      action: null,
      position: 0,
    };
  }

  jsonCast() {
    return [
      'conditions',
    ]
  }

  getValidConditions(): any[] {
    return Query.getValidFilters(this.data.conditions);
  }

  hasValidConditions(): boolean {
    return this.getValidConditions().length > 0;
  }

  isHiddenByDefault(): boolean {
    return this.data.action === 'visible';
  }

  isVisibleByDefault(): boolean {
    return this.data.action !== 'visible';
  }

  canEndSurvey(): boolean {
    return this.data.action === 'end';
  }

  getQueryBuilderAttrs(prefix = ''): {
    text: string,
    value: string,
    type: ISurveyItemType,
    items: any[]
  }[] {
    if (this.data.type === 'likert') {
      const items: any[] = [];
      this.data.surveychoicelist.forEach((choice: SurveyChoiceModel) => {
        if (choice.data.isCategory) {
          items.push({ header: choice.data.content });
          return;
        }
        if (choice.data.isQuestion) {
          items.push(...choice.data.surveysubquestionentity.getQueryBuilderAttrs());
          return;
        }
        items.push({
          text: prefix + (choice.data.label || choice.data.content || 'Untitled').trim(),
          value: choice.data.hash,
          type: this.getType(),
          items: this.data.keys.map((key: SurveyChoiceModel) => ({
            text: key.data.content,
            value: key.data.hash,
          })),
        })
      });
      return items;
    } else {
      return [{
        text: prefix + (this.data.label || this.data.content || 'Untitled').trim(),
        value: this.data.hash,
        type: this.getType(),
        items: this.getItems(),
      }]
    }
  }

  getType(): ISurveyItemType {
    return 'string';
  }

  getItems(): any[] {
    return [];
  }

  columnCast(): { [key: string]: string } {
    return {
      id: 'number',
      includePrependText: 'bool',
      includeAppendText: 'bool',
      includeUnknown: 'bool',
      includeLabel: 'bool',
      required: 'bool',
      multiple: 'bool',
    };
  }
}
