import Vue from 'vue'
import Vuetify, { UserVuetifyPreset } from 'vuetify'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import DatetimePicker from 'vuetify-datetime-picker'
import 'tiptap-vuetify/dist/main.css'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import en from 'vuetify/src/locale/en'
import fr from 'vuetify/src/locale/fr'
import store from 'store2'

// const userPrefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches

const opts: Partial<UserVuetifyPreset> = {
  lang: {
    locales: { en, fr },
    current: 'en',
  },
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    dark: localStorage.getItem('dark') === null ? false : store.local.get('dark') === 'true',
    options: {
      cspNonce: 'aFz7r1LqXoY',
      customProperties: true,
      variations: false
    },
    themes: {
      light: {
        gold: '#F5BD02',
        sheet: '#FFFFFF',
        backgroundDark: '#BBBBBB',
        background: '#EEEEEE',
        backgroundLight: '#F0F0F0',
        backgroundVeryLight: '#F6F6F6',
        primary: '#00b588',
        secondary: '#035642',
        third: '#F9BE6C',
        accent: '#82B1FF',
        error: '#ff7676',
        failed: '#ff7676',
        info: '#2196F3',
        success: '#5ba056',
        finished: '#5ba056',
        warning: '#F9BE6C',
        skip: '#E0E0E0',
        text: '#000000',
      },
      dark: {
        gold: '#F5BD02',
        sheet: '#242424',
        backgroundDark: '#121212',
        background: '#181818',
        backgroundLight: '#2c2c2c',
        backgroundVeryLight: '#343434',
        primary: '#00b588',
        secondary: '#035642',
        third: '#FFD180',
        accent: '#9FA8DA',
        error: '#ff5252',
        failed: '#ff5252',
        info: '#29B6F6',
        success: '#66BB6A',
        finished: '#66BB6A',
        warning: '#FFCA28',
        skip: '#616161',
        text: '#E0E0E0',
      },
    },
  },
}

const vuetify = new Vuetify(opts)

Vue.use(Vuetify)
Vue.use(DatetimePicker)
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdiSvg'
})

export {
  opts
}

export default vuetify;
