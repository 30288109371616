import Model from '@/modules/sdk/core/model';
import SurveyModel from '@/modules/sdk/models/survey.model';
import SurveyGroupModel from '@/modules/sdk/models/survey-group.model';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';
import SurveyAiQuestionModel from '@/modules/sdk/models/survey-ai-question.model';

export default class SurveyAiAnswerModel extends Model {

  default() {
    return {
      content: '',
    };
  }

  relatedMap () {
    return {
      surveyentity: SurveyModel,
      surveygroupentity: SurveyGroupModel,
      surveyquestionentity: SurveyQuestionModel,
      surveyaiquestionentity: SurveyAiQuestionModel,
    }
  }

}
