<template>
  <v-container class="pa-4" fluid>
    <h3 class="mb-3">Log Error</h3>
    <div class="d-flex align-center" style="gap: 1rem">
      <v-btn
        outlined
        @click="() => onLogErrorClick('this is an error')"
      >
        Static error
      </v-btn>
      <v-btn
        outlined
        @click="() => onLogErrorClick()"
      >
        Random error
      </v-btn>
    </div>

    <h3 class="mt-6 mb-3">New Survey</h3>
    <v-card>
      <SurveyManager
        v-model="survey"
        :tab-attrs="{ class: 'pa-6' }"
        editable
        show-templates
        preview
      />
    </v-card>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component} from 'vue-property-decorator';
import SurveyModel from '@/modules/sdk/models/survey.model';
import SurveyManager from '@/components/SurveyManager.vue';
import Hash from '@/modules/sdk/core/hash';

@Component({
  components: {
    SurveyManager,
  }
})
export default class DevView extends Vue {

  survey: SurveyModel = new SurveyModel()

  onLogErrorClick(message: null | string = null) {
    if (message === null) {
      message = Hash.guid();
    }
    throw new Error(message);
  }
}
</script>
