<template>
  <div>
    <!-- EDIT/CREATE DIALOG -->
    <ModalDialog
      v-model="editDialog.visible"
      :title="$t('presetManager.save.' + (editDialog.model.data.id ? 'edit' : 'create'))"
      :persistent="saving"
      max-width="500"
    >
      <template #body>
        <v-form v-model="editDialog.formIsValid">
          <p v-text="$t('presetManager.save.body')"></p>
          <v-text-field
            v-model="editDialog.model.data.label"
            v-safechar
            :placeholder="$t('globalPrompt.typeAnswerHere')"
            :rules="[rules.required]"
            hide-details="auto"
            autofocus
            required
            outlined
            dense
          />
          <v-switch
            v-model="editDialog.private"
            :label="$t('presetManager.save.private')"
            inset
            hide-details
          ></v-switch>
        </v-form>
      </template>
      <template #buttons>
        <v-btn
          v-if="editDialog.model.data.id"
          color="primary"
          :disabled="!canSaveDialog"
          :loading="saving"
          @click="() => onSaveDialogClick(editDialog.model, editDialog.private, false)"
        >
          <span>Update</span>
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!canSaveDialog"
          :loading="saving"
          @click="() => onSaveDialogClick(editDialog.model, editDialog.private)"
        >
          <span v-text="$t('btn.create')"></span>
        </v-btn>
        <v-btn
          outlined
          :disabled="saving"
          @click="onCancelDialogClick"
        >
          <span v-text="$t('btn.cancel')"></span>
        </v-btn>
      </template>
    </ModalDialog>

    <!-- SELECT OPTIONS -->
    <v-autocomplete
      v-model="selection"
      v-bind="_attrs"
      :items="_items"
      :loading="loading"
      :disabled="!canInteract"
      item-value="data.id"
      item-text="data.label"
      return-object
      background-color="sheet"
      @change="onPresetChange"
      @click:clear="onClearClick"
      @focus="onFocus"
    >
      <template #item="{ item }">
        <v-icon v-if="!item.data.id" color="primary" left small>mdi-plus-circle</v-icon>
        <template v-else>
          <v-icon v-if="item.data.userId" left small>mdi-eye-off-outline</v-icon>
          <v-icon v-else color="success" left small>mdi-eye-outline</v-icon>
        </template>
        <span v-text="item.data.label || $t('btn.new')"></span>
      </template>
      <template #selection="{ item }">
        <v-icon v-if="!item.data.id" color="success" left small>mdi-plus-circle</v-icon>
        <template v-else>
          <v-icon v-if="item.data.userId" left small>mdi-eye-off-outline</v-icon>
          <v-icon v-else color="success" left small>mdi-eye-outline</v-icon>
        </template>
        <span v-text="item.data.label || $t('btn.new')"></span>
      </template>
      <template #append>
        <div class="d-flex align-center" style="gap: 0.5rem; margin-top: -0.15rem">
          <v-btn
            :disabled="!canEdit"
            color="primary"
            small
            icon
            @mousedown.prevent.stop="() => onEditClick(selection)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            :disabled="!canSave"
            color="primary"
            small
            icon
            @mousedown.prevent.stop="() => onSaveClick(selection)"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn
            :disabled="!canRemove"
            color="error"
            small
            icon
            @mousedown.prevent.stop="remove"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script lang="ts">
import 'reflect-metadata';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import {Vue, Component, Prop, VModel} from 'vue-property-decorator';
import Rules from '@/modules/sdk/core/rules';
import Model from '@/modules/sdk/core/model';
import Identity from '@/modules/sdk/core/identity';

@Component({
  components: {
    ModalDialog
  }
})
export default class PresetManager extends Vue {

  @VModel() model!: any;
  @Prop() readonly defaultItem!: any
  @Prop() readonly id!: string
  @Prop() readonly defaultItemArgs!: any
  @Prop() readonly loadCallback!: () => Promise<any>;
  @Prop() readonly saveCallback!: (item: any) => Promise<any>;
  @Prop() readonly removeCallback!: (item: any) => Promise<any>;

  selection: Model | null = null
  modelTmp: any = null
  items: Array<Model> = []
  loaded = false
  loading = false
  saving = false
  removing = false
  rules = {}
  editDialog: {
    visible: boolean,
    private: boolean,
    saving: boolean,
    formIsValid: boolean,
    model: Model
  } = {
    visible: false,
    private: false,
    saving: false,
    formIsValid: false,
    model: new Model()
  }

  get canSaveDialog(): boolean {
    return this.editDialog.formIsValid && !this.editDialog.saving && !this.saving;
  }

  get _attrs(): any {
    return this.$attrs;
  }

  get _items(): Array<any> {
    return this.items.concat(new Model());
  }

  get canEdit(): boolean {
    return this.canInteract && this.selection?.data.id;
  }

  get canSave(): boolean {
    return this.presetsAreDifferent && !this.presetsAreDefault && this.canInteract
  }

  get canRemove(): boolean {
    return this.selection?.data.id && this.canInteract;
  }

  get canInteract(): boolean {
    return !this.saving && !this.removing;
  }

  get presetsAreDifferent(): boolean {
    try {
      return this.modelTmp !== JSON.stringify(this.model);
    } catch (e) {
      return false;
    }
  }

  get presetsAreDefault(): boolean {
    return this.modelTmp === JSON.stringify(this.model);
  }

  onFocus() {
    if (!this.loaded) {
      this.load();
    }
  }

  onEditClick(model: any, isPrivate: boolean | null = null) {
    Object.assign(this.editDialog, {
      visible: true,
      model: model.clone(),
      private: isPrivate === null ? !!(model.data.userId) : isPrivate,
      formIsValid: true,
    })
  }

  onSaveClick(model: any) {
    if (!model) {
      this.onEditClick(new Model());
    } else {
      this.onEditClick(model, this.editDialog.private);
    }
  }

  onSaveDialogClick(model: Model, isPrivate: boolean, isNew = true): void {
    const clone: Model = model.clone();
    clone.data.id = isNew ? null : clone.data.id;
    this.save(this.model, clone, isPrivate)
      .then(() => {
        Object.assign(this.editDialog, {
          visible: false,
        })
      })
  }

  onCancelDialogClick(): void {
    Object.assign(this.editDialog, {
      visible: false,
    })
  }

  onClearClick() {
    const modelTmp = JSON.stringify(this.defaultItem);
    this.modelTmp = modelTmp;
    this.$emit('input', JSON.parse(modelTmp || ''));
  }

  onPresetChange(item: any) {
    if (!item) {
      return;
    }
    try {
      let modelTmp;
      if (item.data.id) {
        modelTmp = item.data.json;
        this.modelTmp = modelTmp;
      }
      this.$emit('input', JSON.parse(modelTmp || ''));
    } catch (e) {

    }
  }

  save(presets: Array<any>, model: Model, isPrivate: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      const userId = isPrivate ? Identity.getIdentity()?.user.id : null;
      if (!model.data.id) {
        const clonedItems = JSON.parse(JSON.stringify(this.items));
        const newItem = {
          ...model.data,
          ...(this.defaultItemArgs || {}),
          json: JSON.stringify(presets),
          userId,
        };
        clonedItems.push(newItem);
        this.saving = true;
        ((this.saveCallback && this.saveCallback(newItem)) || this.saveToLocalStorage(clonedItems))
          .then(item => {
            this.items.push(item);
            this.selection = this.items[this.items.length - 1];
            this.modelTmp = JSON.stringify(this.model);
            this.$root.$globalPrompt.hide();

            this.$root.$globalSnack.success({
              message: this.$i18n.t('presetManager.snackSaved')
            });

            resolve(this.modelTmp);
          })
          .catch(this.$root.$zemit.handleError)
          .finally(() => this.saving = false)
      } else {
        const item = this.items.find(item => item === this.selection);
        if (item) {
          const clone: Model = item.clone();
          clone.assign(model);
          Object.assign(clone.data, {
            ...model.data,
            json: JSON.stringify(presets),
            userId,
          })
          this.saving = true;
          ((this.saveCallback && this.saveCallback(clone)) || this.saveToLocalStorage(JSON.parse(JSON.stringify(this.items))))
            .then(response => {
              this.modelTmp = JSON.stringify(this.model);
              item.assign(clone);
              this.$root.$globalSnack.success({
                message: this.$i18n.t('presetManager.snackSaved')
              });
              resolve(this.modelTmp);
            })
            .catch(this.$root.$zemit.handleError)
            .finally(() => this.saving = false)
        }
      }
    })
  }

  saveToLocalStorage(items: any): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem('presetManager_' + this.id, JSON.stringify(items));
        resolve(items);
      } catch (e) {
        reject(e);
      }
    });
  }

  remove(): void {
    try {
      this.$root.$globalModal.ask(
        this.$i18n.t('presetManager.remove.title'),
        this.$i18n.t('presetManager.remove.body'),
        [{
          text: this.$i18n.t('btn.cancel'),
          attrs: {
            text: true,
          },
          events: {
            click: () => {
              this.$root.$globalModal.hide();
            },
          }
        }, {
          text: this.$i18n.t('btn.remove'),
          attrs: {
            outlined: true,
          },
          events: {
            click: () => {
              const clonedItems = JSON.parse(JSON.stringify(this.items));
              const index = this.items.findIndex((item: any) => item === this.selection);
              if (index >= 0) {
                this.removing = true;
                ((this.removeCallback && this.removeCallback(clonedItems[index])) || this.removeFromLocalStorage(clonedItems, index))
                  .then(() => {
                    this.items.splice(index, 1);
                    this.selection = this.items[index] || this.items[this.items.length -1] || null;
                    if ((this.selection && this.selection.data.id) || !this.selection) {
                      this.selection = null;
                      this.$emit('input', JSON.parse(JSON.stringify(this.defaultItem)));
                    } else {
                      this.$emit('input', this.selection?.data.json);
                    }
                    this.$root.$globalModal.hide();

                    this.$root.$globalSnack.success({
                      message: this.$i18n.t('presetManager.snackRemoved')
                    });
                  })
                  .finally(() => this.removing = false)
              }
            },
          }
        }],
        'danger',
      )
    } catch (e) {

    }
  }

  reset() {
    this.selection = null;
  }

  removeFromLocalStorage(items: Array<any>, index: number): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        items.splice(index, 1);
        localStorage.setItem('presetManager_' + this.id, JSON.stringify(items));
        resolve(items);
      } catch (e) {
        reject(e);
      }
    });
  }

  loadFromLocalStorage(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.modelTmp = JSON.stringify(this.defaultItem);
        const filters = localStorage.getItem('presetManager_' + this.id) || '';
        const json = JSON.parse(filters);
        resolve(json)
      } catch (e) {
        reject(e);
      }
    })
  }

  load() {
    this.loading = true;
    ((this.loadCallback && this.loadCallback()) || this.loadFromLocalStorage())
      .then(items => {
        this.items = items || [];
        this.loaded = true;
      })
      .catch((reason) => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  created() {
    this.rules = {
      required: (value: string) => Rules.required(value) || this.$t('rules.required'),
    };
  }
}
</script>
