<template>
  <ListBuilder
    v-model="question.data.surveyaiquestionlist"
    :labels="_labels.questionsAi"
    :default-model="questionAiModel"
    :default-data="{
      projectId: survey.data.projectId,
      surveyId: survey.data.id,
      surveyGroupId: question.data.surveyGroupId,
      surveyQuestionId: question.data.id,
      content: ''
    }"
    :show-removed="showRemoved"
    order-by="position"
    sortable
    restorable
  >
    <template #item="questionAi">
      <v-text-field
        v-model="questionAi.item.data.content"
        v-safechar
        :readonly="readonly"
        prepend-inner-icon="mdi-message-question-outline"
        placeholder="Type your question here..."
        hide-details="auto"
        clearable
        outlined
      />
    </template>
  </ListBuilder>
</template>

<script lang="ts">
import { Vue, Component, VModel, Prop } from 'vue-property-decorator';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';
import SurveyAiQuestionModel from '@/modules/sdk/models/survey-ai-question.model';
import SurveyModel from '@/modules/sdk/models/survey.model';

@Component
export default class SurveyAiQuestions extends Vue {
  @VModel({ type: SurveyQuestionModel, default: () => new SurveyQuestionModel({}, false) }) question!: SurveyQuestionModel
  @Prop({ type: SurveyModel, default: () => new SurveyModel({}, false) }) survey!: SurveyModel
  @Prop({ type: Number, default: 0, }) index!: number
  @Prop({ type: Boolean, default: false, }) readonly?: boolean
  @Prop({ type: Boolean, default: false, }) showRemoved?: boolean
  @Prop({ type: Object, default: () => null }) labels?: any

  questionAiModel = SurveyAiQuestionModel

  get _labels(): any {
    return Object.assign({}, {
      groups: this.labels?.groups || {},
      questions: this.labels?.questions || {},
      questionsAi: this.labels?.questionsAi || {},
      options: this.labels?.options || {},
    })
  }
}
</script>
