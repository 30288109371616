<template>
  <v-card v-bind="$attrs">
    <slot v-if="!noHeader" name="header">
      <v-sheet v-bind="headerAttrs" :color="headerAttrs.color ? headerAttrs.color : 'transparent'">
        <v-card-title v-bind="cardTitleAttrs">
          <div style="flex: 1">
            <slot name="header_left">
              <v-icon left v-if="icon">{{icon}}</v-icon>
              <slot name="title">{{ title }}</slot>
            </slot>
          </div>
          <div style="flex: 0" class="d-flex align-center flex-nowrap">

            <!-- OPTIONS FIELD -->
            <slot name="options"></slot>

            <v-btn
              v-if="!disabled"
              class="ml-6"
              icon
              @click="onToggleContent"
            >
              <v-icon v-if="!computedCollapsed">mdi-chevron-up</v-icon>
              <v-icon v-else>mdi-chevron-down</v-icon>
            </v-btn>
          </div>
        </v-card-title>
      </v-sheet>
    </slot>
    <v-expand-transition>
      <div v-if="!computedCollapsed">
        <slot></slot>
        <slot name="body"></slot>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, VModel, Prop } from 'vue-property-decorator';

@Component
export default class CollapsableCard extends Vue {

  @VModel({ type: Boolean, default: null }) collapsed!: boolean
  @Prop({ type: String, default: null }) memoryKey!: string
  @Prop({ type: String, default: null }) title!: string
  @Prop({ type: String, default: null }) icon!: string
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: Boolean, default: false }) noHeader!: boolean
  @Prop({ type: Object, default: () => ({}) }) headerAttrs!: any
  @Prop({ type: Object, default: () => ({}) }) cardTitleAttrs!: any

  innerCollapse = false;

  get computedCollapsed(): boolean {
    return this.collapsed === null
      ? this.innerCollapse
      : this.collapsed;
  }

  get computedMemoryKey(): string {
    return 'collapsableCard_' + this.memoryKey;
  }

  onToggleContent(): void {
    if (this.collapsed !== null) {
      this.collapsed = !this.collapsed;
    } else {
      this.innerCollapse = !this.innerCollapse;
    }

    if (this.memoryKey) {
      localStorage.setItem(this.computedMemoryKey, this.computedCollapsed.toString());
    }
  }

  created() {
    this.innerCollapse = (localStorage.getItem(this.computedMemoryKey) || 'false') === 'true'
  }
}
</script>
