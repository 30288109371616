<template>
  <div>

    <!-- SETTINGS -->
    <v-row>

      <!-- REQUIRED? -->
      <v-col v-if="question.canBeRequired()" cols="12" md="3">
        <v-checkbox
          v-model="question.data.required"
          :label="$t('survey.rules.required')"
          class="ma-0 pa-0"
          hide-details="auto"
        />
      </v-col>

      <!-- REQUIRED? -->
      <v-col v-if="question.data.type === 'likert'" cols="12" md="3">
        <v-checkbox
          v-model="question.data.subType"
          :label="$t('survey.rules.slider')"
          class="ma-0 pa-0"
          hide-details="auto"
          value="slider"
        />
      </v-col>

      <!-- POSITIVE ONLY? -->
      <v-col v-if="question.canBePositiveOnly()" cols="12" md="4" lg="3">
        <v-checkbox
          v-model="question.data.positiveOnly"
          :disabled="question.data.negativeOnly"
          :label="$t('survey.rules.positiveOnly')"
          type="number"
          class="ma-0 pa-0"
          hide-details="auto"
          @change="revalidate([
            $refs['min_' + index],
            $refs['max_' + index]
          ])"
        />
      </v-col>

      <!-- NEGATIVE ONLY? -->
      <v-col v-if="question.canBeNegativeOnly()" cols="12" md="4" lg="3">
        <v-checkbox
          v-model="question.data.negativeOnly"
          :label="$t('survey.rules.negativeOnly')"
          :disabled="question.data.positiveOnly"
          type="number"
          class="ma-0 pa-0"
          hide-details="auto"
          @change="revalidate([
            $refs['min_' + index],
            $refs['max_' + index]
          ])"
        />
      </v-col>

      <!-- PAST ONLY? -->
      <v-col v-if="question.canBePastOnly()" cols="12" md="4" lg="3">
        <v-checkbox
          v-model="question.data.pastOnly"
          :label="$t('survey.rules.pastOnly')"
          type="number"
          class="ma-0 pa-0"
          hide-details="auto"
          @change="revalidate([
            $refs['dateFrom_' + index],
            $refs['dateTo_' + index]
          ])"
        />
      </v-col>

      <!-- MULTIPLE? -->
      <v-col v-if="question.canBeMultiple()" cols="12" md="4" lg="3">
        <v-checkbox
          v-model="question.data.multiple"
          :label="$t('survey.rules.multiple')"
          :disabled="question.hasAnswer()"
          class="ma-0 pa-0"
          hide-details="auto"
        />
      </v-col>
    </v-row>

    <!-- COUNTS -->
    <v-row v-if="question.canHaveDecimal() || question.data.type === 'rating'">

      <!-- DECIMAL? -->
      <v-col v-if="question.canHaveDecimal()" cols="12" lg="6">
        <v-text-field
          v-model.lazy.number="question.data.decimalCount"
          :label="$t('survey.rules.decimalCount')"
          type="number"
          prepend-inner-icon="mdi-decimal"
          hide-details="auto"
          outlined
        />
      </v-col>

      <!-- RATING COUNT? -->
      <v-col v-if="question.data.type === 'rating'" cols="12" lg="6">
        <v-text-field
          v-model.lazy.number="question.data.ratingCount"
          :label="$t('survey.rules.ratingCount')"
          :min="0"
          type="number"
          prepend-inner-icon="mdi-star-outline"
          hide-details="auto"
          outlined
        />
      </v-col>
    </v-row>

    <!-- MIN/MAX -->
    <v-row v-if="question.canHaveMinMax() || question.canHaveFromToDate() || question.canHaveFromToTime()">

      <!-- MIN/MAX LENGTH? -->
      <v-col v-if="question.canHaveMinMax()" cols="12" xl="6" class="d-flex align-center" style="gap: 1rem">
        <v-text-field
          v-model.lazy.number="question.data[question.getNumberType('min')]"
          :ref="'min_' + index"
          :label="$t('survey.rules.minLength')"
          :rules="[value => rules.minMaxLength(value, question.data[question.getNumberType('max')], question)]"
          type="number"
          prepend-inner-icon="mdi-counter"
          clearable
          outlined
          hide-details="auto"
        />
        <v-text-field
          v-model.lazy.number="question.data[question.getNumberType('max')]"
          :ref="'max_' + index"
          :label="$t('survey.rules.maxLength')"
          :rules="[value => rules.minMaxLength(question.data[question.getNumberType('min')], value, question)]"
          :min="question.data.positiveOnly ? 0 : null"
          :max="question.data.negativeOnly ? 0 : null"
          type="number"
          prepend-inner-icon="mdi-counter"
          clearable
          outlined
          hide-details="auto"
        />
      </v-col>

      <!-- MIN/MAX DATE/TIME? -->
      <v-col v-if="question.canHaveFromToDate() || question.canHaveFromToTime()" cols="12" xl="6">
        <div v-if="question.canHaveFromToDate()" class="d-flex align-center" style="gap: 1rem">

          <!-- FROM -->
          <v-dialog
            v-model="dialogDateFrom[index]"
            :ref="'dialogDateFrom_' + index"
            :return-value.sync="question.data.dateFrom"
            persistent
            eager
            width="290px"
            style="flex: 1"
            clearable
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model.lazy="question.data.dateFrom"
                v-bind="attrs"
                v-on="on"
                :ref="'dateFrom_' + index"
                :label="$t('placeholder.fromDate')"
                :rules="[value => rules.minMaxDate(value, question.data.dateFrom, question)]"
                prepend-inner-icon="mdi-calendar"
                hide-details="auto"
                readonly
                clearable
                outlined
              />
            </template>
            <v-date-picker
              v-model="question.data.dateFrom"
              :max="question.data.dateTo"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="dialogDateFrom[index] = false"
              >
                <span v-text="$t('btn.cancel')"></span>
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs['dialogDateFrom_' + index]?.save(question.data.dateFrom)"
              >
                <span v-text="$t('btn.apply')"></span>
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <!-- TO -->
          <v-dialog
            v-model="dialogDateTo[index]"
            :ref="'dialogDateTo_' + index"
            :return-value.sync="question.data.dateTo"
            persistent
            width="290px"
            style="flex: 1"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model.lazy="question.data.dateTo"
                v-bind="attrs"
                v-on="on"
                :ref="'dateTo_' + index"
                :label="$t('placeholder.toDate')"
                :rules="[value => rules.minMaxDate(question.data.dateTo, value, question)]"
                prepend-inner-icon="mdi-calendar"
                hide-details="auto"
                readonly
                clearable
                outlined
              />
            </template>
            <v-date-picker
              v-model="question.data.dateTo"
              :min="question.data.dateFrom"
              scrollable
              clearable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="dialogDateTo[index] = false"
              >
                <span v-text="$t('btn.cancel')"></span>
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs['dialogDateTo_' + index]?.save(question.data.dateTo)"
              >
                <span v-text="$t('btn.apply')"></span>
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
        <div v-if="question.canHaveFromToTime()" :class="['d-flex align-center', { 'mt-6': question.canHaveFromToDate() }]" style="gap: 1rem">
          <v-select
            v-model="question.data.timeFrom"
            :items="timeSlots"
            :label="$t('placeholder.fromTime')"
            style="flex: 1"
            prepend-inner-icon="mdi-clock-outline"
            hide-details="auto"
            clearable
            outlined
          />
          <v-select
            v-model="question.data.timeTo"
            :items="timeSlots"
            :label="$t('placeholder.toTime')"
            style="flex: 1"
            prepend-inner-icon="mdi-clock-outline"
            hide-details="auto"
            clearable
            outlined
          />
        </div>
      </v-col>
    </v-row>

    <!-- PREFIX/SUFFIX -->
    <v-row v-if="question.canDefinePrefixSuffix()">
      <v-col cols="12" lg="6">
        <v-text-field
          v-model.lazy="question.data.prefix"
          :readonly="readonly"
          label="Prefix"
          clearable
          outlined
          hide-details="auto"
        />
      </v-col>
      <v-col cols="12" lg="6">
        <v-text-field
          v-model.lazy="question.data.suffix"
          :readonly="readonly"
          label="Suffix"
          clearable
          outlined
          hide-details="auto"
        />
      </v-col>
    </v-row>

    <!-- DESCRIPTION -->
    <v-text-field
      v-model.lazy="question.data.description"
      :label="$t('survey.question.hint')"
      :readonly="readonly"
      clearable
      outlined
      hide-details="auto"
      class="mt-6"
    />
  </div>
</template>

<script lang="ts">
import { Vue, Component, VModel, Prop } from 'vue-property-decorator';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';
import Rules from '@/modules/sdk/core/rules';

@Component({})
export default class SurveyQuestionOptions extends Vue {
  @VModel({ type: SurveyQuestionModel, default: () => new SurveyQuestionModel({}, false) }) question!: SurveyQuestionModel
  @Prop({ type: Number, default: 0, }) index!: number
  @Prop({ type: Boolean, default: false, }) readonly?: boolean

  rules: {[key: string]: (value: any, value2?: any, value3?: any) => boolean | string} = {}
  timeSlots: Array<string> = []
  dialogDateFrom: any = {}
  dialogDateTo: any = {}

  revalidate(ref: any | Array<any>) {
    setTimeout(() => {
      if (!Array.isArray(ref)) {
        ref = [ref];
      }
      ref.forEach((r: any) => {
        const isValid = r.validate();
        r.error = !isValid;
      })
    })
  }

  created() {
    this.rules.required = (value: string) => Rules.required(value) || this.$t('rules.required').toString();
    this.rules.minMaxLength = (min: number, max: number, item: SurveyQuestionModel) => {
      if (
        (item.data.positiveOnly && (min < 0 || max < 0)) ||
        (item.data.negativeOnly && (min > 0 || max > 0))
      ) {
        return this.$t('survey.validation.minMaxLength').toString();
      }
      return ((min && max) ? min < max : true) || this.$t('survey.validation.minMaxLength').toString();
    }
    this.rules.minMaxDate = (min: string, max: string, item: SurveyQuestionModel) => {
      const minTime = Date.parse(min);
      const maxTime = Date.parse(max);
      const today = new Date();
      today.setDate(today.getDate() - 1);
      const currentTime = today.getTime();
      if (item.data.pastOnly && (minTime > currentTime || maxTime > currentTime)) {
        return this.$t('survey.validation.minMaxDate').toString();
      }
      return true;
    }

    this.timeSlots = [];
    for (let i = 0; i < 48; i++) {
      const progress = i * 0.5;
      const isHalf = progress % 1 !== 0;
      const floor = Math.floor(progress);
      const hour = floor < 10 ? '0' + floor : floor;
      const minutes = isHalf ? '30' : '00';
      this.timeSlots.push(hour + ':' + minutes);
    }
  }
}
</script>
