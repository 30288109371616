<template>
  <v-container fluid class="pa-4">
    <ListView
      v-test-id="'error_log'"
      title="Error Logs"
      :headers="headers"
      :service="service"
      index="error_log"
      searchable
      hide-presets
      fill-height
      hide-collapsable
      hide-new-button
    >
      <!-- MESSAGE -->
      <template v-slot:item.message="{ item }">
        <div class="py-2 d-flex">
          <v-icon v-if="!item.data.acknowledged" color="error" left>mdi-alert-circle-outline</v-icon>
          <v-icon v-else color="success" left>mdi-check-circle-outline</v-icon>
          <div>
            <div>
              <span>{{ item.data.message }}</span>
            </div>
            <div class="caption text--disabled">{{ item.data.stack.split('\n')[1].substring(0, 150)}}</div>
          </div>
        </div>
      </template>

      <!-- COUNT -->
      <template v-slot:item.count="{ item }">
        <v-chip :color="item.data.acknowledged ? 'success' : 'error'" small>{{ item.data.count }}</v-chip>
      </template>

      <!-- USER -->
      <template v-slot:item.userentity="{ item }">
        <v-chip
          v-if="item.data.userentity"
          :to="{ name: 'UserForm', params: {id: item.data.userentity.data.id }}"
          label
          small
        >
          {{ item.data.userentity.getFullName() }}
          &lt;{{ item.data.userentity.data.email }}&gt;
        </v-chip>
        <span v-else>No user</span>
      </template>
    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Ref} from 'vue-property-decorator';
import DataTable from '@/modules/common/components/DataTable.vue';
import ListView from '@/modules/common/components/ListView.vue';
import ErrorLogService from '@/modules/sdk/services/error-log.service';
import UserService from '@/modules/sdk/services/user.service';
import UserModel from '@/modules/sdk/models/user.model';

@Component({
  components: {
    ListView,
    DataTable,
  }
})
export default class ContactList extends Vue {
  @Ref() readonly datatable!: DataTable

  service = ErrorLogService.getInstance();
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      width: 72,
      value: '_first',
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'ErrorLogForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    },
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Message',
      align: 'start',
      value: 'message',
      cellClass: 'text-no-wrap',
      filterable: {
        type: 'text',
      },
    },
    {
      text: 'Count',
      align: 'start',
      value: 'count',
      filterable: {
        type: 'number',
        min: 1,
      },
    },
    {
      text: 'Version',
      align: 'start',
      value: 'version',
      filterable: {
        type: 'text',
      },
    },
    {
      text: 'User',
      align: 'start',
      value: 'userentity',
      filterable: {
        field: 'UserEntity.id',
        type: 'distinct',
        service: UserService,
        itemText: (user: UserModel) => user.getFullName()
      }
    },
    {
      text: 'Created at',
      align: 'start',
      sortable: true,
      value: 'createdAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean'
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];
}
</script>
