var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ListBuilder',{attrs:{"labels":_vm._labels.questionsAi,"default-model":_vm.questionAiModel,"default-data":{
    projectId: _vm.survey.data.projectId,
    surveyId: _vm.survey.data.id,
    surveyGroupId: _vm.question.data.surveyGroupId,
    surveyQuestionId: _vm.question.data.id,
    content: ''
  },"show-removed":_vm.showRemoved,"order-by":"position","sortable":"","restorable":""},scopedSlots:_vm._u([{key:"item",fn:function(questionAi){return [_c('v-text-field',{directives:[{name:"safechar",rawName:"v-safechar"}],attrs:{"readonly":_vm.readonly,"prepend-inner-icon":"mdi-message-question-outline","placeholder":"Type your question here...","hide-details":"auto","clearable":"","outlined":""},model:{value:(questionAi.item.data.content),callback:function ($$v) {_vm.$set(questionAi.item.data, "content", $$v)},expression:"questionAi.item.data.content"}})]}}]),model:{value:(_vm.question.data.surveyaiquestionlist),callback:function ($$v) {_vm.$set(_vm.question.data, "surveyaiquestionlist", $$v)},expression:"question.data.surveyaiquestionlist"}})
}
var staticRenderFns = []

export { render, staticRenderFns }