<template>
  <v-app v-test-id="'app'">

    <!-- ARE YOU STILL THERE? MODAL -->
    <StillAliveModal
      :delay="1000 * 60 * 15"
      :enabled="stillAliveEnabled"
    />

    <!-- CHANGE LOGS MODAL -->
    <ChangeLogModal
      v-model="changelogs"
    />

    <Sidebar
      v-model="menuList"
    />

    <Toolbar
      :logo="require('@/assets/images/logo.svg')"
    />

    <v-main>
      <v-container fluid class="background fill-height align-start">
        <router-view :key="$route.fullPath" class="w-100"></router-view>
      </v-container>
    </v-main>

    <v-footer padless fixed app inset style="border-top: rgba(0,0,0,.1) solid 1px">
      <div class="caption pa-1 text-center w-100">
        {{ 'Copyright © 2023-' + new Date().getFullYear() }} YolaRx Consultant Inc. — <strong>All rights reserved.</strong>
      </div>
    </v-footer>

    <global-confetti/>
    <global-overlay/>
    <global-snack top app/>
    <global-modal scrollable/>
    <global-prompt/>
    <should-take-action/>
  </v-app>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Vue, Component } from 'vue-property-decorator'
import Sidebar, { ISidebarGroup } from '@/modules/common/components/Sidebar.vue'
import Identity from '@/modules/sdk/core/identity'
import GlobalSnack from '@/modules/common/components/GlobalSnack.vue'
import GlobalOverlay from '@/modules/common/components/GlobalOverlay.vue'
import GlobalPrompt from '@/modules/common/components/GlobalPrompt.vue'
import GlobalModal from '@/modules/common/components/GlobalModal.vue'
import ShouldTakeAction from '@/modules/common/components/ShouldTakeAction.vue'
import Logger from '@/modules/sdk/core/logger'
import GlobalConfetti from '@/modules/common/components/GlobalConfetti.vue'
import StillAliveModal from '@/modules/common/components/StillAliveModal.vue'
import ChangeLogModal from '@/modules/common/components/ChangeLogModal.vue'
import Toolbar from '@/modules/common/components/Toolbar.vue'
import changelogs from '@/changelogs';

const d = new Logger('App')

@Component({
  components: {
    GlobalConfetti,
    GlobalOverlay,
    GlobalSnack,
    GlobalModal,
    GlobalPrompt,
    ShouldTakeAction,
    StillAliveModal,
    ChangeLogModal,
    Sidebar,
    Toolbar,
  }
})
export default class App extends Vue {

  changelogs = changelogs
  stillAliveEnabled = !Identity.hasRole(['dev'])

  get menuList(): ISidebarGroup[] {

    const dev = Identity.hasRole(['dev'])
    const admin = Identity.hasRole(['admin'])
    const leader = Identity.hasRole(['leader'])
    const arbitrator = Identity.hasRole(['arbitrator'])
    const researcher = Identity.hasRole(['researcher'])

    const items: ISidebarGroup[] = [

      // Default
      {
        children: [
          { title: 'Review', to: { name: 'ReviewView' }, icon: 'mdi-checkbox-multiple-marked-outline', show: dev || admin || leader || arbitrator || researcher },
        ]
      },

      // Admin
      {
        show: admin || dev || leader,
        label: this.$i18n.t('sidebar.admin'),
        children: [
          { title: 'Projects', to: { name: 'ProjectList' }, icon: 'mdi-book-outline', show: dev || admin || leader },
        ]
      },

      // Settings
      {
        show: dev || admin || leader,
        label: this.$i18n.t('sidebar.settings'),
        children: [
          { title: 'Keywords', to: { name: 'KeywordList' }, icon: 'mdi-star-cog-outline', show: dev || admin },
          { title: 'Countries', to: { name: 'CountryList' }, icon: 'mdi-earth', show: dev || admin },
          { title: 'Exclusion Reasons', to: { name: 'ProjectStatusReasonList' }, icon: 'mdi-comment-outline', show: dev || admin || leader},
          { title: 'Data Extraction', to: { name: 'DataExtractionPresetsList' }, icon: 'mdi-comment-question-outline', show: dev || admin || leader},
        ]
      },

      // Tags
      {
        show: dev || admin,
        label: this.$i18n.t('sidebar.tags'),
        children: [
          { title: 'Categories', to: { name: 'CategoryList' }, icon: 'mdi-tag-multiple', show: dev || admin },
          { title: 'Tags', to: { name: 'TagList' }, icon: 'mdi-tag-outline', show: dev || admin },
        ]
      },

      // AI
      {
        show: dev || admin,
        label: this.$i18n.t('sidebar.ai'),
        children: [
          { title: 'AI Prompts', to: { name: 'AiPromptList' }, icon: 'mdi-text', show: dev || admin },
          { title: 'AI Models', to: { name: 'AiModelList' }, icon: 'mdi-robot', show: dev || admin },
          { title: 'AI Tasks', to: { name: 'AiTaskList' }, icon: 'mdi-checkbox-multiple-marked-circle-outline', show: dev || admin },
        ]
      },

      // Managements
      {
        show: dev || admin,
        label: this.$i18n.t('sidebar.management'),
        children: [
          { title: 'Users', to: { name: 'UserList' }, icon: 'mdi-account-outline', show: dev || admin },
          { title: 'Roles', to: { name: 'RoleList' }, icon: 'mdi-briefcase-account-outline', show: dev },
          { title: 'Notifications', to: { name: 'NotificationList' }, icon: 'mdi-bell-outline', show: dev || admin },
          { title: 'Tasks', to: { name: 'TaskList' }, icon: 'mdi-checkbox-multiple-marked-outline', show: dev || admin },
          { title: 'Templates', to: { name: 'TemplateList' }, icon: 'mdi-clipboard-text', show: dev || admin },
          { title: 'Video Library', to: { name: 'VideoLibraryList' }, icon: 'mdi-video-outline', show: dev || admin },
        ]
      },

      // Managements
      {
        show: dev || admin,
        label: this.$i18n.t('sidebar.inscriptions'),
        children: [
          { title: 'Contacts', to: { name: 'ContactList' }, icon: 'mdi-comment-multiple-outline', show: dev || admin },
          { title: 'Satisfactions', to: { name: 'SatisfactionList' }, icon: 'mdi-certificate', show: dev || admin },
        ]
      },

      // Help
      {
        show: dev,
        label: this.$i18n.t('sidebar.help'),
        children: [
          { title: 'Tutorials', to: { name: 'TutorialView' }, icon: 'mdi-video-outline', show: dev || admin },
        ]
      },

      // Dev
      {
        show: dev,
        label: this.$i18n.t('sidebar.dev'),
        children: [
          { title: 'Error Logs', to: { name: 'ErrorLogList' }, icon: 'mdi-bug-check' },
          { title: 'Sandbox', to: { name: 'DevView' }, icon: 'mdi-developer-board' },
        ]
      },
    ]

    // Return only those with correct permissions
    return items.filter(item => ((item.children || []).length > 0 || item.label === undefined) && item.show !== false).map(item => {
      item.children = item.children.filter(child => child.show !== false)
      return item
    })
  }
}
</script>
