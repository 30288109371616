import Service from '@/modules/sdk/core/service';
import ProjectModel from '@/models/project.model';

export default class ProjectService extends Service {
  modelUrl = '/project';
  baseUrl = '/api';
  model = ProjectModel;

  endpointList = {
    ...new Service().endpointList,
    ...{
      getProgress: '/get-progress',
      resetOfficialStatus: '/reset-official-status',
      resetStatusType: '/reset-status-type',
      duplicate: '/duplicate',
    }
  }

  getProgressUrl = () => this.getUrl('getProgress');
  getProgress = (data = {}, config = {}) => this.handleRequest(this.getProgressUrl(), data, config);

  resetOfficialStatusUrl = () => this.getUrl('resetOfficialStatus');
  resetOfficialStatus = (data = {}, config = {}) => this.handleRequest(this.resetOfficialStatusUrl(), data, config);

  resetStatusTypeUrl = () => this.getUrl('resetStatusType');
  resetStatusType = (data = {}, config = {}) => this.handleRequest(this.resetStatusTypeUrl(), data, config);

  duplicateUrl = () => this.getUrl('duplicate');
  duplicate = (data = {}, config = {}) => this.handleRequest(this.duplicateUrl(), data, config);
}
