import Vue, {DirectiveBinding} from 'vue';
import Mark from 'mark.js';
import KeywordModel from '@/models/keyword.model';
import SynonymModel from '@/models/synonym.model';
import Logger from '@/modules/sdk/core/logger';
import TagModel from '@/models/tag.model';

const d = new Logger('directives/highlight-keywords');

const applyCallback = (el: HTMLElement, binding: DirectiveBinding<any>) => {

  // Clean existing selection
  const instance = new Mark(el);
  instance.unmark({
    element: 'span',
    className: 'highlighted-keyword',
  });

  // If valid binding values passed, highlight the passed keywords into the element
  if (typeof binding.value === 'object' && !Array.isArray(binding.value)) {
    const keywords = [];
    if (typeof binding.value.keywords === 'string') {
      keywords.push(binding.value.keywords);
    } else if (Array.isArray(binding.value.keywords)) {
      keywords.push(...binding.value.keywords);
    }
    if (keywords.length > 0) {
      keywords.forEach(keyword => {
        if (keyword === null || (typeof keyword === 'string' && keyword.trim() === '')) { // If keyword is null (cleared), skip
          return;
        }
        const isKeywordModel = keyword instanceof KeywordModel;
        const options: any = {
          element: 'span',
          className: 'highlighted-keyword',
          acrossElements: true,
          caseSensitive: false,
          separateWordSearch: false,
          ...(binding.value.options || {}) // You can override MarkJS options here..
        };

        let label;
        if (keyword instanceof KeywordModel) {
          label = keyword.data.label;
        } else if (typeof keyword === 'string') {
          label = keyword;
        } else if (keyword?.model && keyword.model instanceof TagModel) {
          label = keyword.text;
        }

        if (isKeywordModel) {
          keyword.data.synonymlist.forEach((synonym: SynonymModel) => {
            if (synonym.data.deleted || !synonym.selected) {
              return;
            }

            const prefix = synonym.data.wordOnly ? '\\b(' : '(';
            const suffix = synonym.data.wordOnly ? ')\\b' : ')';
            const flags = synonym.data.caseSensitive ? 'g' : 'gi';
            const escapeRegExp = (str: string, isRegexp = false) => {
              return isRegexp ? str : str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            }
            try {
              const reg = new RegExp(prefix + escapeRegExp(synonym.data.label, synonym.data.regexp) + suffix, flags);

              options.each = (element: HTMLElement) => {
                if (binding.value.callback instanceof Function) {
                  binding.value.callback(element, synonym);
                }
              };

              instance.markRegExp(reg, options)
            } catch (e: any) {
              if (binding.value.vue) {
                binding.value.vue.$root.$globalSnack.error({
                  message: e.message,
                });
              }
              console.error(e);
            }
          });
        } else {
          options.each = (element: HTMLElement) => {
            if (binding.value.callback instanceof Function) {
              binding.value.callback(element, keyword);
            }
          };

          try {
            const reg = new RegExp(label, 'gi');
            instance.markRegExp(reg, options)
          } catch (e: any) {
            if (binding.value.vue) {
              binding.value.vue.$root.$globalSnack.error({
                message: e.message,
              });
            }
            console.error(e);
          }
        }
      })
    }
  }
}

Vue.directive('highlight-keywords', {
  bind: applyCallback,
  componentUpdated: applyCallback,
});
