var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CollapsableCard',{attrs:{"memory-key":'survey_group_' + _vm.model.data.id,"no-header":!_vm.hasHeader && _vm.mode === 'answer',"flat":!_vm.hasHeader && _vm.mode === 'answer',"card-title-attrs":{
    class: _vm.mode === 'answer' ? 'py-1' : null
  },"header-attrs":{
    color: _vm.color,
    dark: _vm.isDark,
  }},scopedSlots:_vm._u([{key:"header_left",fn:function(){return [_c('SurveyHeader',{attrs:{"survey":_vm.survey,"list":_vm.parent?.data.surveygrouplist,"mode":_vm.mode,"debug":_vm.debug,"index":_vm.index,"readonly":_vm.readonly,"extra-fields":_vm.extraFields,"card-title-attrs":{
        class: 'pa-0'
      },"view-attrs":{
        class: _vm.view === 'paginated' ? 'font-weight-bold title' : 'body-1',
      },"title-attrs":{
        filled: _vm.model.data.includeLabel,
        class: 'font-weight-bold',
      },"label":"Group","color-active":"group lighten-2","color-inactive":"group lighten-5","slot-prefix":"group","header-is":"div","expandable":"","include-color":""},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(slot,key){return {key:key,fn:function(props){return [_vm._t(key,null,null,props)]}}})],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]},proxy:true},{key:"body",fn:function(){return [(_vm.mode === 'answer')?_c('v-card-text',{class:{
        'px-4 pb-4': _vm.model.data.includeLabel && _vm.model.data.label,
        'pa-0': !(_vm.model.data.includeLabel && _vm.model.data.label),
      }},[(_vm.model.data.includePrependText)?_c('div',{staticClass:"body-1",domProps:{"innerHTML":_vm._s(_vm.model.data.prependText)}}):_vm._e(),_vm._l((_vm.model.data.surveyquestionlist),function(question,questionIdx){return _c('v-expand-transition',{key:question.stateId},[(_vm.survey.isActive(question.data.hash))?_c('div',{attrs:{"tick":_vm.tick}},[_c('SurveyQuestion',_vm._b({class:{ 'mt-3': questionIdx > 0 },attrs:{"cascade-attrs":_vm.cascadeAttrs,"value":question,"survey":_vm.survey,"parent":question,"index":questionIdx},on:{"answer":_vm.onAnswer},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(slot,key){return {key:key,fn:function(props){return [_vm._t(key,null,null,props)]}}})],null,true)},'SurveyQuestion',_vm.computedCascadeAttrs,false))],1):_vm._e()])}),(_vm.model.data.includeAppendText)?_c('div',{staticClass:"body-1 mt-4",domProps:{"innerHTML":_vm._s(_vm.model.data.appendText)}}):_vm._e()],2):_c('v-card-text',[_c('ListBuilder',{attrs:{"default-model":_vm.surveyQuestionModel,"default-data":{
          surveyId: _vm.model.data.surveyId,
          projectId: _vm.model.data.projectId,
          surveySectionId: _vm.model.data.id,
        },"labels":{
          add: 'Add a new question',
          remove: 'Remove question',
          empty: 'No group question yet',
        },"btn-attrs":{
          color: 'warning',
          outlined: true,
        },"readonly":_vm.readonly,"sortable":_vm.sortable,"show-removed":_vm.showRemoved,"button-offset":17,"group":"survey_question","order-by":"position","is-model":"","restorable":"","collapsable":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-expand-transition',[(_vm.model.data.includePrependText)?_c('div',[_c('Wysiwyg',{attrs:{"label":"Prepend text","height":200},model:{value:(_vm.model.data.prependText),callback:function ($$v) {_vm.$set(_vm.model.data, "prependText", $$v)},expression:"model.data.prependText"}})],1):_vm._e()])]},proxy:true},{key:"item",fn:function(question){return [_c('SurveyQuestion',_vm._b({attrs:{"cascade-attrs":_vm.cascadeAttrs,"survey":_vm.survey,"parent":_vm.model,"index":question.index},on:{"answer":_vm.onAnswer},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(slot,key){return {key:key,fn:function(props){return [_vm._t(key,null,null,props)]}}})],null,true),model:{value:(question.item),callback:function ($$v) {_vm.$set(question, "item", $$v)},expression:"question.item"}},'SurveyQuestion',_vm.computedCascadeAttrs,false))]}},{key:"append",fn:function(){return [_c('v-expand-transition',[(_vm.model.data.includeAppendText)?_c('div',[_c('Wysiwyg',{staticClass:"mt-4",attrs:{"label":"Append text","height":200},model:{value:(_vm.model.data.appendText),callback:function ($$v) {_vm.$set(_vm.model.data, "appendText", $$v)},expression:"model.data.appendText"}})],1):_vm._e()])]},proxy:true}]),model:{value:(_vm.model.data.surveyquestionlist),callback:function ($$v) {_vm.$set(_vm.model.data, "surveyquestionlist", $$v)},expression:"model.data.surveyquestionlist"}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }