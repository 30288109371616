import Model, {Data} from '@/modules/sdk/core/model';
import SurveySectionModel from '@/modules/sdk/models/survey-section.model';
import SurveyGroupModel from '@/modules/sdk/models/survey-group.model';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';
import SurveyChoiceModel from '@/modules/sdk/models/survey-choice.model';
import SurveyItemModel from '@/modules/sdk/models/survey-item.model';
import Query from '@/modules/sdk/core/query';
import CountryService from '@/modules/sdk/services/country.service';
import SurveyAnswerService from '@/modules/sdk/services/survey-answer.service';
import SurveyAnswerModel from '@/modules/sdk/models/survey-answer.model';
import { AxiosResponse } from 'axios';
import { ZemitData } from '@/modules/sdk/core/service';
import * as json2csv from 'json-2-csv';
import UserModel from '@/modules/sdk/models/user.model';

export default class SurveyModel extends SurveyItemModel {

  cacheAnswers: SurveyAnswerModel[] = []
  cacheItems: SurveyItemModel[] | null = null
  cacheQuestions: SurveyQuestionModel[] | null = null
  cacheMappedQuestions: {[key: string]: any} = {}
  activeItems: {[key: string]: boolean} = {}
  endOfSurveyHash: string | null = null
  countryList: AxiosResponse<ZemitData<any>> | null = null;
  customFieldValues: {[key: string]: any} = {}
  autocompleteList: any = {};
  countriesLoaded = false
  loadingCountries = false
  loadingAutocomplete = false
  parentApplied = false
  loadingAnswerUserList = false
  answerListLoaded = false
  answerList: Array<SurveyAnswerModel> = []
  answerUserList: UserModel[] = []

  constructor(
    data: Data | Model = {},
    setOriginal = true,
  ) {
    // @ts-ignore
    if (!data.surveysectionlist) {
      // @ts-ignore
      data.surveysectionlist = [new SurveySectionModel().data]
    }
    super(data, setOriginal);

    if (setOriginal) {
      setTimeout(() => {
        this.setOriginalData();
      })
    }
  }

  default() {
    return {
      label: '',
      surveysectionlist: [],
    };
  }

  relatedMap () {
    return {
      surveysectionlist: SurveySectionModel,
    }
  }

  relatedDeleteMissing() {
    return {
      surveysectionlist: true,
    };
  }

  assign(data: Model | object, specificKeys = []) {
    super.assign(data, specificKeys);
    return this.sortByProperty('surveysectionlist', 'position');
  }

  loadCountries(): Promise<any> {
    this.loadingCountries = true;
    return CountryService.getInstance().getAll()
      .then(countries => {
        this.countriesLoaded = true;
        return countries;
      })
      .finally(() => this.loadingCountries = false);
  }

  loadAutocomplete(questionId: number): Promise<any> {
    this.loadingAutocomplete = true;
    return SurveyAnswerService.getInstance().getAll({
      filters: [{
        field: 'surveyQuestionId',
        operator: 'equals',
        value: questionId,
      }]
    })
      .finally(() => this.loadingAutocomplete = false);
  }

  loadItems() {
    let countryFound = false;
    let autocompleteFound = false;
    for (let x = 0; x < this.data.surveysectionlist.length; x++) {
      const section = this.data.surveysectionlist[x];
      for (let i = 0; i < section.data.surveygrouplist.length; i++) {
        const group = section.data.surveygrouplist[i];
        for (let j = 0; j < group.data.surveyquestionlist.length; j++) {
          const question = group.data.surveyquestionlist[j];
          if (!countryFound && question.data.type === 'country') {
            countryFound = true;
            this.loadCountries().then(response => {
              this.countryList = response.data.view.list;
            });
          }
          if (!autocompleteFound && question.data.type === 'autocomplete') {
            autocompleteFound = true;
            this.loadAutocomplete(question.data.id).then(response => {
              this.autocompleteList[question.data.id] = response.data.view.list;
            });
          }
        }
      }
    }
  }

  exportAnswersToCSV(title: string) {
    const items: any[] = [{
      question: 'Question',
      answer: 'Answer',
      active: 'Active?',
    }];
    const answers = this.getAnswers();
    const questions = this.getQuestions();
    questions.forEach(question => {
      if (!question.data.label) {
        return;
      }

      const answer = answers.find(answer => answer.data.surveyQuestionId === question.stateId);
      if (answer && question.data.type === 'likert') {
        const choices = question.getChoices();
        const values = Array.isArray(answer.data.content) ? answer.data.content : [answer.data.content];
        values.forEach((value, valueIdx) => {
          const key = question.data.keys.find((key: SurveyChoiceModel) => key.data.hash === value);
          if (key) {
            items.push({
              question: choices[valueIdx].data.content,
              answer: key.data.content,
              active: this.isActive(choices[valueIdx].data.hash),
            });
          }
        })
      }
      else if (answer && question.hasChoices()) {
        const choices = question.getChoices();
        const values = Array.isArray(answer.data.content) ? answer.data.content : [answer.data.content];
        values.forEach(value => {
          const choice = choices.find(choice => choice.stateId === value);
          if (choice) {
            items.push({
              question: question.data.label,
              answer: choice.data.content,
              active: this.isActive(question.data.hash),
            });
          }
        })
      } else {
        items.push({
          question: question.data.label,
          answer: answer
            ? answer.data.unknown
              ? 'Unknown'
              : answer.data.other
                ? answer.data.other
                : answer.data.content
            : '',
          active: this.isActive(question.data.hash),
        });
      }
    });

    const csv = json2csv.json2csv(items, {
      prependHeader: false,
    });

    const data = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', title + '.csv');
    link.click();
    link.remove();
  }

  cacheAnswer(
    section: SurveySectionModel,
    group: SurveyGroupModel,
    question: SurveyQuestionModel,
  ) {
    const cache = this.cacheAnswers.find(answer => answer.data.surveyQuestionId === question.stateId);
    const choice = question.data.surveychoicelist.find((choice: SurveyChoiceModel) => choice.stateId === question.answer);
    const data = {
      id: cache?.data.id,
      content: question.answer,
      unknown: question.isUnknown,
      other: question.answerOther,
      type: question.data.type,
      projectId: this.data.projectId,
      surveyId: this.stateId,
      surveySectionId: section.stateId,
      surveyGroupId: group.stateId,
      surveyQuestionId: question.stateId,
      surveyChoiceId: choice ? choice.stateId : null,
    };
    if (!cache) {
      this.cacheAnswers.push(new SurveyAnswerModel(data, false))
    } else {
      Object.assign(cache.data, data);
    }
  }

  toTreeviewItems(includeAiQuestions = true): Array<any> {
    const items: any[] = [];
    this.data.surveysectionlist.forEach((section: any) => {
      const sectionItem: any = {
        id: 'section_' + section.data.id,
        type: 'section',
        key: section.data.id,
        name: section.data.label || 'Untitled (Section)',
        children: [],
      };
      section.data.surveygrouplist.forEach((group: any) => {
        const groupItem: any = {
          id: 'group_' + group.data.id,
          type: 'group',
          key: group.data.id,
          name: group.data.label || 'Untitled (Group)',
          children: [],
        };
        group.data.surveyquestionlist.forEach((question: any) => {
          const questionItem: any = {
            id: 'question_' + question.data.id,
            type: 'question',
            key: question.data.id,
            name: question.data.label || 'Untitled (Question)',
            children: [],
          };
          if (includeAiQuestions) {
            question.data.surveyaiquestionlist.forEach((aiQuestion: any) => {
              const aiQuestionItem: any = {
                id: aiQuestion.data.id,
                type: 'aiQuestion',
                key: aiQuestion.data.id,
                name: aiQuestion.data.content || 'Undefined (AI Question)',
                children: [],
              };
              questionItem.children.push(aiQuestionItem);
            })
          }
          groupItem.children.push(questionItem);
        })
        if (groupItem.children.length > 0) {
          sectionItem.children.push(groupItem);
        }
      })
      if (sectionItem.children.length > 0) {
        items.push(sectionItem);
      }
    });
    return items;
  }

  loadAnswerUserList(additionalFilters?: any[]): Promise<Array<SurveyAnswerModel>> {
    const filters = [{
      field: 'projectId',
      operator: 'equals',
      value: this.data.projectId
    }, {
      field: 'surveyId',
      operator: 'equals',
      value: this.data.id,
    }];

    if (additionalFilters) {
      filters.push(...additionalFilters)
    }

    this.loadingAnswerUserList = true;
    return SurveyAnswerService.getInstance().getAll({ filters })
      .then(response => {
        this.answerList = response.data.view.list;
        this.answerUserList = response.data.view.list
          .map((answer: SurveyAnswerModel) => answer.data.userentity)
          .reduce((acc: any[], obj: any) => {
            if (!acc.some(item => item.data.id === obj.data.id)) {
              acc.push(obj);
            }
            return acc;
          }, []);

        this.answerListLoaded = true;
        return response.data.view.list;
      })
      .finally(() => this.loadingAnswerUserList = false);
  }

  getFilteredAnswerUserList(
    canSeeAnswerName = false,
    canSeeOfficialAnswers = false,
    canUseAi = false,
  ): any[] {
    const items: any[] = [];
    this.answerUserList.forEach((user, userIdx) => {
      items.push({
        text: canSeeAnswerName ? user.getLabel() : 'User #' + (userIdx + 1),
        value: user.data.id,
      })
    })
    items.sort((a: any, b: any) => a.text > b.text ? 1 : -1);

    if (this.answerUserList.length > 0) {
      items.unshift({ header: 'Users' });
    }
    if (canSeeOfficialAnswers || this.answerUserList.length > 0) {
      items.push({ header: 'Entities' });
    }
    if (canSeeOfficialAnswers) {
      items.push({
        text: 'Officialized data',
        value: 'official',
      });
    }
    if (canUseAi) {
      items.push({
        text: 'AI data',
        value: 'ai',
      });
    }
    return items;
  }

  getAnswers(update = true): SurveyAnswerModel[] {
    if (!update) {
      return this.cacheAnswers;
    }
    this.data.surveysectionlist.forEach((section: SurveySectionModel) => {
      section.data.surveygrouplist.forEach((group: SurveyGroupModel) => {
        group.data.surveyquestionlist.forEach((question: SurveyQuestionModel) => {
          const pushQuestion = (question: SurveyQuestionModel) => {
            this.cacheAnswer(section, group, question);
          }
          pushQuestion(question);
          question.data.surveychoicelist.forEach((choice: SurveyChoiceModel) => {
            if (choice.data.isQuestion) {
              pushQuestion(choice.data.surveysubquestionentity);
            }
          });
        })
      })
    });
    return this.cacheAnswers;
  }

  clearAllAnswers() {
    this.cacheAnswers = [];
  }

  clearNewAnswers() {
    this.cacheAnswers = this.cacheAnswers.filter(cacheAnswer => cacheAnswer.data.id);
  }

  applyAnswers(answers: SurveyAnswerModel[]) {
    const questions = this.getFlatItems<SurveyQuestionModel>(true);
    questions.forEach(question => {
      const answer = answers.find(answer => answer.data.surveyQuestionId === question.stateId);
      if (answer) {
        const content = question.canDefineChoices() ? parseInt(answer.data.content) : answer.data.content;
        Object.assign(question, {
          answer: content,
          originalAnswer: content,
          isUnknown: answer.data.unknown,
          answerOther: answer.data.other,
        })

        const cache = this.getAnswers(true).find(answer => answer.data.surveyQuestionId === question.stateId);
        if (cache) {
          Object.assign(cache.data, answer.data);
          cache.setOriginalData();
        }
      }
    });
    this.updateActiveItems();
  }

  isParentActive(hash: string): boolean {
    if (!this.parentApplied) {
      this.applyParents();
    }

    // If parent not active, current hash is not either
    let item = this.cacheItems && this.cacheItems.find(item => item.data.hash === hash);
    if (item) {
      while (item.parent !== null) {
        if (!this.isActive(item.parent.data.hash)) {
          return false;
        }
        item = item.parent;
      }
    }
    return true;
  }

  isActive(hash: string): boolean {
    if (!this.isParentActive(hash)) {
      return false;
    }
    return this.activeItems[hash] || this.activeItems[hash] === undefined;
  }

  setCustomFieldValues(fieldValues: {[key: string]: any}) {
    this.customFieldValues = fieldValues;
  }

  updateActiveItems(reset = false) {
    if (reset || this.cacheItems === null || this.cacheQuestions === null) {
      this.applyParents();
      this.cacheItems = this.getFlatItems();
      this.cacheQuestions = this.getQuestions();
      this.cacheItems.forEach(item => {
        this.activeItems[item.data.hash] = item.isVisibleByDefault();
      })
    }

    if (this.cacheQuestions) {
      this.cacheQuestions.forEach(question => {
        this.cacheMappedQuestions[question.data.hash] = question.answer;
      });
    }

    if (this.cacheItems) {
      this.endOfSurveyHash = null;
      this.cacheItems.forEach(item => {
        this.activeItems[item.data.hash] = item.isVisibleByDefault();
        if (
          !this.isParentActive(item.data.hash)
          || this.endOfSurveyHash
        ) {
          this.activeItems[item.data.hash] = false;
          return;
        }

        if (item.data.conditions.length > 0) {
          let mappedQuestions = this.cacheMappedQuestions;
          const customFields: string[] = [];
          const conditions = structuredClone(item.data.conditions);
          const mapConditions = (conditions: any[]) => {
            for (let i = 0; i < conditions.length; i++) {
              const condition = conditions[i];
              if (condition.group.length > 0) {
                mapConditions(conditions[i].group);
              }
              if (this.cacheItems) {
                for (let j = 0; j < condition.value.length; j++) {
                  const value = conditions[i].value[j];
                  const cacheItem = this.cacheItems.find((item: any) => item.data.hash === value.value);
                  const field = this.cacheItems.find((item: any) => item.data.hash === condition.field);
                  if (cacheItem) {
                    conditions[i].value[j] = cacheItem.data.hash;
                  }

                  // In cases of likert type questions, we need to modify the question field and mapped questions
                  // because the values are within an array in question.answer and not a flatted object
                  // like the other questions.
                  if (field && field instanceof SurveyChoiceModel && this.cacheQuestions) {
                    mappedQuestions = {};
                    this.cacheQuestions.filter(question => question.data.type === 'likert').forEach(question => {
                      const choiceIdx = question.data.surveychoicelist.findIndex((choice: SurveyChoiceModel) => choice.data.hash === field.data.hash)
                      condition.field = question.data.hash;
                      conditions[i].value[j] = value.value;
                      if (choiceIdx !== -1) {
                        mappedQuestions[question.data.hash] = question.answer[choiceIdx];
                      }
                    });
                  }

                  if (!field) {
                    customFields.push(condition.field);
                  }
                }
              }
            }
          }
          mapConditions(conditions);

          // Add custom fields to mappedQuestions
          customFields.forEach(customField => {
            mappedQuestions[customField] = this.customFieldValues[customField];
          })

          const validConditions = Query.getValidFilters(conditions)
          const result = Query.analyze(validConditions, mappedQuestions);
          if (item.data.action === 'end') {
            this.endOfSurveyHash = result ? item.data.hash : null;
          } else {
            this.activeItems[item.data.hash] = result
              ? item.data.action === 'visible'
              : item.data.action === 'hidden';
          }
        }
      })
    }
  }

  getQuestions(): SurveyQuestionModel[] {
    return this.getFlatItems(true) as SurveyQuestionModel[];
  }

  applyParents() {
    this.data.surveysectionlist.forEach((section: SurveySectionModel) => {
      section.data.surveygrouplist.forEach((group: SurveyGroupModel) => {
        group.parent = section;
        function applySubParent(question: SurveyQuestionModel) {
          question.parent = group;
          question.data.surveychoicelist.forEach((choice: SurveyChoiceModel) => {
            choice.parent = question;
            if (choice.data.surveysubquestionentity) {
              applySubParent(choice.data.surveysubquestionentity);
            }
          });
        }
        group.data.surveyquestionlist.forEach((question: SurveyQuestionModel) => {
          if (question) {
            applySubParent(question);
          }
        });
      })
    });
  }

  getFlatItems<T extends SurveyItemModel>(onlyQuestions = false): T[] {
    const items: SurveyItemModel[] = [];
    this.data.surveysectionlist.forEach((section: SurveySectionModel) => {
      !onlyQuestions && section && items.push(section);
      section.data.surveygrouplist.forEach((group: SurveyGroupModel) => {
        !onlyQuestions && items.push(group);
        function pushQuestion(question: SurveyQuestionModel) {
          items.push(question);
          question.data.surveychoicelist.forEach((choice: SurveyChoiceModel) => {
            !onlyQuestions && items.push(choice);
            if (choice.data.isQuestion && choice.data.surveysubquestionentity) {
              pushQuestion(choice.data.surveysubquestionentity);
            }
          });
        }
        group.data.surveyquestionlist.forEach((question: SurveyQuestionModel) => {
          if (question) {
            pushQuestion(question);
          }
        });
      })
    });
    return items as T[];
  }

  setReferenceKeyValue(key: string, value: any): void {
    this.data[key] = value;
    this.data.surveysectionlist.forEach((section: SurveySectionModel) => {
      section.data[key] = value;
      section.data.surveygrouplist.forEach((group: SurveyGroupModel) => {
        group.data[key] = value;
        group.data.surveyquestionlist.forEach((question: SurveyQuestionModel) => {
          question.data[key] = value;
          question.data.surveychoicelist.forEach((choice: SurveyChoiceModel) => {
            choice.data[key] = value;
          });
        })
      })
    });
  }

  cleanReferences(): void {
    this.setReferenceKeyValue('id', null);
    this.setReferenceKeyValue('projectId', null);
    this.setReferenceKeyValue('surveyId', null);
    this.setReferenceKeyValue('surveySectionId', null);
    this.setReferenceKeyValue('surveyGroupId', null);
    this.setReferenceKeyValue('surveyQuestionId', null);
  }

  resetPosition(): void {
    this.data.surveysectionlist.forEach((section: SurveySectionModel, sectionIdx: number) => {
      section.data.position = sectionIdx;
    })
  }

  beforeSave(): void {
    this.setReferenceKeyValue('projectId', this.data.projectId);
  }

}
