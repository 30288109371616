<template>
  <QueryGroupBuilder
    v-model="conditionList"
    v-bind="$attrs"
    v-on="$listeners"
    ref="queryGroupBuilder"
    :is-default-state="isDefaultState"
    :disabled="disabled"
  />
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, VModel, Prop, Ref} from 'vue-property-decorator';
import QueryGroupBuilder from './QueryGroupBuilder.vue'

export const defaultQueryBuilderItem = {
  logic: 'and',
  operator: 'contains',
  field: null,
  value: null,
  group: [] as any,
}

@Component({
  components: {
    QueryGroupBuilder,
  }
})
export default class QueryBuilder extends Vue {
  @Ref() readonly queryGroupBuilder!: QueryGroupBuilder

  @VModel({ type: Array, default: () => [] }) conditionList!: any;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;

  get isDefaultState(): boolean {
    return JSON.stringify(this.conditionList) === JSON.stringify([defaultQueryBuilderItem]);
  }

  clear() {
    this.conditionList.splice(0, this.conditionList.length, structuredClone(defaultQueryBuilderItem));
  }

  forceUpdate() {
    this.$forceUpdate();
    this.queryGroupBuilder.forceUpdate();
  }

  created() {
    if (this.conditionList.length === 0) {
      this.clear();
    }
  }
}
</script>
