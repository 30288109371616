import Vue, { DirectiveBinding } from 'vue';

const defaultCustomPattern = '';
const nonPrintableReg = '[\x00-\x1F\x7F]';

function sanitizeUTF8WithReplacement(input = '', replacement = ''): string {
  const normalizedString = input.normalize('NFC');
  return normalizedString.replace(/[^\x20-\x7E\xA0-\uFFFF]/g, replacement);
}

Vue.directive('safechar', {
  bind(el: HTMLElement | null, binding: DirectiveBinding<string>) {
    const customPattern = binding.value || defaultCustomPattern;
    if (el && !(el instanceof HTMLInputElement)) {
      el = el.querySelector('input, textarea')
    }
    if (el) {
      el.addEventListener('paste', (event: any) => {
        const length = event.target.value.length;
        let value = event.target.value;
        value = value.replace(new RegExp(customPattern, 'gmi'), '');
        value = sanitizeUTF8WithReplacement(value);
        value = value.replace(new RegExp(nonPrintableReg, 'gmi'), '');
        value = value.trim();
        if (length !== value.length) { // Just so the comparison is less taxing
          event.target.value = value;
        }
      });
      el.addEventListener('keydown', (event: any) => {
        // For some extremely weird reason that I couldn't find,
        // when constructing or when receiving the RegExp object
        // from outside the listener, the test function returns
        // true then false over and over event though the parameters
        // are exactly the same.. doesn't make any sense.
        const validCustom = new RegExp(customPattern, 'gmi').test(event.key);
        const isNormalized = event.key === sanitizeUTF8WithReplacement(event.key);
        const hasNonPrintableChars = new RegExp(nonPrintableReg).test(event.key);
        if (!validCustom || hasNonPrintableChars || !isNormalized) {
          event.preventDefault();
        }
      });
    }
  }
});
