<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'template'"
      v-model="model"
      :service="service"
      :loading="isLoading"
      name="template"
      title="Template"
    >
      <template #form="{ formErrors, rules }">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="model.data.index"
              v-safechar
              :error-messages="formErrors.index"
              :rules="[rules.required]"
              :disabled="!!(model.data.id)"
              label="Index"
              hide-details="auto"
              outlined
              clearable
              required
              @input="delete formErrors.index"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="model.data.label"
              v-safechar
              :error-messages="formErrors.label"
              label="Label"
              hide-details="auto"
              outlined
              clearable
              @input="delete formErrors.label"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="model.data.title"
              v-safechar
              :error-messages="formErrors.title"
              :rules="[rules.required]"
              label="Title (Subject)"
              hide-details="auto"
              outlined
              clearable
              required
              @input="delete formErrors.title"
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-row>
              <v-col cols="12" md="8">
                <Wysiwyg
                  v-model="model.data.content"
                  :error-messages="formErrors.content"
                  :toolbar="[
                    'bold italic underline removeformat styles',
                    'numlist bullist outdent indent subscript superscript',
                    'link',
                  ]"
                  ref="wysiwyg"
                  label="Content (Message)"
                  hide-details="auto"
                  outlined
                  clearable
                  @input="delete formErrors.content"
                ></Wysiwyg>
              </v-col>
              <v-col cols="12" md="4">
                <v-subheader>Variables</v-subheader>
                <v-divider />
                <div class="text-body-1 py-4">Click on any variable to add it into your email template.</div>
                <div class="d-flex align-center flex-wrap" style="gap: 0.5rem">
                  <v-btn
                    v-for="(key, value) in variables"
                    :key="key"
                    depressed
                    @mousedown.stop.prevent="onVariableMouseDown(key)"
                  >{{ value }}</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </DataForm>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Ref} from 'vue-property-decorator';
import DataForm from '@/modules/common/components/DataForm.vue';
import TemplateModel from '@/modules/sdk/models/template.model';
import TemplateService from '@/modules/sdk/services/template.service';
import Wysiwyg from '@/modules/common/components/Wysiwyg.vue';

@Component({
  components: {
    Wysiwyg,
    DataForm,
  }
})
export default class TemplateForm extends Vue {

  @Ref() readonly wysiwyg!: Wysiwyg;

  service = TemplateService.getInstance();
  loading = false;
  model: TemplateModel = new TemplateModel();
  variables: {[key: string]: string} = {
    'First name': 'user.firstName',
    'Last name': 'user.lastName',
    'Email': 'user.email',
    'User Token': 'user.token',
    'Reset Link': 'resetLink',
    'Code': 'code',
    'Token': 'token',
    'Url': 'url',
    'Message': 'message',
    'Rating': 'rating',
    'Urgent': 'urgent',
  }

  get isLoading() {
    return this.loading;
  }

  onVariableMouseDown(variable: string): void {
    this.wysiwyg.insertAtCaret(variable);
  }

  load(id: number) {
    this.loading = true;
    this.service.get({id})
      .then(response => this.model = response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  created() {
    if (this.$route.params.id) {
      this.load(parseInt(this.$route.params.id));
    }
  }
}
</script>
