import Model, { Data } from '@/modules/sdk/core/model';
import SurveyChoiceModel from '@/modules/sdk/models/survey-choice.model';
import SurveyItemModel, { ISurveyItemType } from '@/modules/sdk/models/survey-item.model';
import Hash from '@/modules/sdk/core/hash';
import SurveyAiQuestionModel from '@/modules/sdk/models/survey-ai-question.model';

export default class SurveyQuestionModel extends SurveyItemModel {

  public answer: any = null;
  public answerOther: any = null;
  public originalAnswer: any = null;
  public isUnknown = false;

  constructor(
    data: Data | Model = {},
    setOriginal = false,
  ) {
    super(data, false);
    if (this.data.multiple instanceof Number) {
      this.data.multiple = this.data.multiple === 1;
    }
    ['timeFrom', 'timeTo'].forEach(key => {
      if (typeof this.data[key] === 'string') {
        this.data[key] = this.data[key].substring(0, 5);
      }
    })
    this.prepareAnswer();
    if (setOriginal) {
      this.setOriginalData();
    }
  }

  prepareAnswer() {
    if (this.isArray() && !Array.isArray(this.answer)) {
      this.answer = [];
    } else if (this.answer === undefined) {
      this.answer = null;
    }
    if (this.isArray() && !Array.isArray(this.originalAnswer)) {
      this.originalAnswer = [];
    } else if (this.originalAnswer === undefined) {
      this.originalAnswer = null;
    }
  }

  default() {
    return {
      hash: this.data.hash ? this.data.hash : Hash.guid(),
      includeUnknown: false,
      includeLabel: true,
      includePrependText: false,
      includeAppendText: false,
      prependText: '',
      appendText: '',
      label: null,
      description: null,
      surveychoicelist: [],
      surveyaiquestionlist: [],
      type: 'input',
      subType: null,
      conditions: [],
      keys: [],
      action: null,
      required: true,
      multiple: false,
      minLength: 0,
      maxLength: null,
      ratingCount: 0,
      numberMin: 0,
      numberMax: null,
      decimalMin: 0,
      decimalMax: null,
      decimalCount: null,
      negativeOnly: false,
      positiveOnly: false,
      pastOnly: false,
      dateFrom: null,
      dateTo: null,
      datetimeFrom: null,
      datetimeTo: null,
      timeFrom: null,
      timeTo: null,
      prefix: null,
      suffix: null,
    };
  }

  relatedMap () {
    return {
      surveychoicelist: SurveyChoiceModel,
      surveyaiquestionlist: SurveyAiQuestionModel,
      keys: SurveyChoiceModel,
    }
  }

  jsonCast() {
    return [
      'keys',
      'conditions',
    ]
  }

  relatedDeleteMissing() {
    return {
      surveychoicelist: true,
    };
  }

  columnCast() {
    return {
      includeUnknown: 'bool',
      includeLabel: 'bool',
      includePrependText: 'bool',
      includeAppendText: 'bool',
      required: 'bool',
      multiple: 'bool',
      negativeOnly: 'bool',
      positiveOnly: 'bool',
      pastOnly: 'bool',
    };
  }

  isArray(): boolean {
    return this.isMultiple() || this.isRangeType();
  }

  isMultiple(): boolean {
    return this.data.multiple
      || ['checkbox', 'likert'].includes(this.data.type);
  }

  isRangeType(): boolean {
    return ['date_range', 'time_range', 'number_range', 'time_range', 'datetime_range', 'date_range', 'time_range'].includes(this.data.type)
  }

  canBeRequired(): boolean {
    return true;
  }

  canBeMultiple(): boolean {
    return ['select', 'country', 'autocomplete', 'input', 'textarea', 'number', 'integer'].includes(this.data.type);
  }

  canHaveMinMax(): boolean {
    return ['number', 'year', 'number_range', 'decimal', 'integer', 'input', 'textarea', 'percentage'].includes(this.data.type);
  }

  canHaveFromToDate(): boolean {
    return ['datetime', 'date', 'date_range', 'datetime_range'].includes(this.data.type);
  }

  canHaveFromToTime(): boolean {
    return ['datetime', 'datetime_range', 'time_range', 'time'].includes(this.data.type);
  }

  canHaveDecimal(): boolean {
    return ['decimal'].includes(this.data.type);
  }

  canDefineOptions(): boolean {
    return true;
  }

  canDefineAiQuestions(): boolean {
    return true;
  }

  choiceCanIncludeOtherField(): boolean {
    return ['checkbox', 'radio'].includes(this.data.type);
  }

  choiceCanBeQuestion(): boolean {
    return ['checkbox', 'radio', 'likert'].includes(this.data.type);
  }

  choiceCanBeACategory(): boolean {
    return ['likert'].includes(this.data.type);
  }

  fieldCanBeUnknown(): boolean {
    return ['input', 'textarea', 'number', 'number_range', 'autocomplete', 'integer', 'decimal', 'select', 'percentage', 'year', 'radio', 'checkbox', 'date', 'date_range', 'time', 'time_range'].includes(this.data.type);
  }

  canDefineChoices(): boolean {
    return ['select', 'checkbox', 'radio', 'likert'].includes(this.data.type);
  }

  canDefineMultipleQuestions(): boolean {
    return false;
    // return ['input', 'textarea', 'number', 'integer'].includes(this.data.type) && this.isMultiple();
  }

  canDefinePrefixSuffix(): boolean {
    return ['select', 'number', 'decimal', 'integer', 'input', 'percentage', 'autocomplete', 'year', 'country', 'date'].includes(this.data.type);
  }

  canBeNegativeOnly(): boolean {
    return ['number', 'number_range', 'integer', 'percentage', 'decimal'].includes(this.data.type);
  }

  canBePositiveOnly(): boolean {
    return ['number', 'number_range', 'integer', 'percentage', 'decimal'].includes(this.data.type);
  }

  canBePastOnly(): boolean {
    return ['datetime', 'date', 'date_range', 'datetime_range'].includes(this.data.type);
  }

  hasChoices(): boolean {
    return ['select', 'checkbox', 'radio', 'autocomplete', 'country'].includes(this.data.type)
  }

  valueIsNumeric(): boolean {
    return this.canDefineChoices();
  }

  getChoices(): SurveyChoiceModel[] {
    return this.data.surveychoicelist;
  }

  getNumberType(direction = 'min') {
    const ccDirection = direction.substring(0, 1).toUpperCase() + direction.substring(1);
    if (['number', 'decimal'].includes(this.data.type)) {
      return this.data.type + ccDirection;
    }
    if (['integer', 'number_range', 'percentage', 'year'].includes(this.data.type)) {
      return 'number' + ccDirection;
    }
    return direction + 'Length';
  }

  hasAnswer() {
    return !!(this.answer);
  }

  getItems(): any[] {
    if (this.canDefineChoices()) {
      return this.data.surveychoicelist.map((choice: SurveyChoiceModel) => ({
        text: choice.data.content,
        value: choice.data.hash,
        id: choice.data.id,
      }));
    }

    return [];
  }

  getType(): ISurveyItemType {
    if (this.canBeNegativeOnly() || ['rating', 'year'].includes(this.data.type)) {
      return 'numeric';
    }
    if (this.canDefineChoices() || ['country', 'likert'].includes(this.data.type)) {
      return 'list';
    }
    if (['date', 'date_range'].includes(this.data.type)) {
      return 'date';
    }
    if (['datetime', 'datetime_range'].includes(this.data.type)) {
      return 'date_time';
    }
    if (['time', 'time_range'].includes(this.data.type)) {
      return 'time';
    }
    return 'string';
  }
}
