<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'contact'"
      v-model="model"
      :service="service"
      :loading="loading"
      name="contact"
      title="Contact"
    >
      <template #form="{ formErrors, rules }">
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="model.data.type"
              :error-messages="formErrors.type"
              :label="$t('placeholder.type')"
              :rules="[rules.required]"
              :items="contactTypeList"
              outlined
              hide-details="auto"
              clearable
              required
              @input="delete formErrors.status"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="model.data.message"
              v-safechar
              :error-messages="formErrors.message"
              label="Message"
              :rules="[rules.required]"
              hide-details="auto"
              outlined
              clearable
              required
              @input="delete formErrors.message"
            />
          </v-col>
          <v-col cols="12">
            <v-switch
              class="mt-0 ml-2 pa-0"
              v-model="model.data.urgent"
              :error-messages="formErrors.urgent"
              :label="$t('placeholder.urgent')"
              hide-details="auto"
              color="error"
              inset
              required
              @input="delete formErrors.urgent"
            ></v-switch>
          </v-col>
        </v-row>
      </template>
    </DataForm>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata'
import {Vue, Component} from 'vue-property-decorator'
import {contactTypeList} from '@/enums/global';
import ContactService from '@/modules/sdk/services/contact.service'
import Logger from '@/modules/sdk/core/logger'
import DataForm from '@/modules/common/components/DataForm.vue'
import ContactModel from '@/modules/sdk/models/contact.model'

const d = new Logger('views/Admin/Form/ContactForm')

@Component({
  components: {
    DataForm,
  }
})
export default class ContactForm extends Vue {

  model: ContactModel = new ContactModel()
  service = ContactService.getInstance()
  loading = false

  contactTypeList = contactTypeList;

  load(id: number) {
    this.loading = true
    this.service.get({id})
      .then(response => this.model = response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false)
  }

  created() {
    if (this.$route.params.id) {
      this.load(parseInt(this.$route.params.id))
    }
  }
}
</script>


