<template>
  <v-menu left :close-on-content-click="false">

    <ColorInput
      v-if="includeColor"
      v-model="model.data.color"
      v-bind="colorInputMenuAttrs"
      :visible.sync="colorInputVisible"
      :suggestions="[
        $vuetify.theme.themes.light.success,
        $vuetify.theme.themes.light.warning,
        $vuetify.theme.themes.light.error,
        $vuetify.theme.themes.light.skip,
      ]"
      clearable
      skip-alpha
      hide-activator
      @input="() => $emit('forceUpdate')"
    />

    <template #activator="{ on, attrs }">
      <div :style="!disabledStyle ? 'min-width: 64px; margin-right: -0.7rem': ''">
        <v-btn v-bind="attrs" v-on="on" icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </div>
    </template>
    <v-list>
      <v-list-item v-if="includeColor" @click="onColorInputClick">
        <v-list-item-icon>
          <v-icon>mdi-format-color-fill</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Color</v-list-item-title>
      </v-list-item>
      <v-list-item @click="() => list.splice(index, 0, model.clone())">
        <v-list-item-icon>
          <v-icon>mdi-content-duplicate</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Duplicate</v-list-item-title>
      </v-list-item>
      <v-divider class="my-1" />
      <v-list-item :class="{ 'v-list-item--active primary--text': model.data.includePrependText }" @click="() => model.data.includePrependText = !model.data.includePrependText">
        <v-list-item-icon>
          <v-icon v-if="model.data.includePrependText">mdi-checkbox-marked-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Prepend text</v-list-item-title>
      </v-list-item>
      <v-list-item :class="{ 'v-list-item--active primary--text': model.data.includeAppendText }" @click="() => model.data.includeAppendText = !model.data.includeAppendText">
        <v-list-item-icon>
          <v-icon v-if="model.data.includeAppendText">mdi-checkbox-marked-outline</v-icon>
          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Append text</v-list-item-title>
      </v-list-item>
      <v-divider v-if="$scopedSlots['items.append']" class="my-1"></v-divider>
      <slot name="items.append"></slot>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { Vue, Component, VModel, Prop } from 'vue-property-decorator';
import SurveySectionModel from '@/modules/sdk/models/survey-section.model';
import SurveyGroupModel from '@/modules/sdk/models/survey-group.model';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';
import SurveyChoiceModel from '@/modules/sdk/models/survey-choice.model';
import Model from '@/modules/sdk/core/model';
import ColorInput from '@/modules/common/components/ColorInput.vue';

type IType = SurveySectionModel | SurveyGroupModel | SurveyQuestionModel | SurveyChoiceModel;

@Component({
  components: {ColorInput}
})
export default class SurveyItemOptionsMenuMenu extends Vue {
  @VModel({ type: Model, default: () => new SurveyQuestionModel({}, false) }) model!: IType
  @Prop({ type: Model, default: () => ([]) }) list!: Array<IType>
  @Prop({ type: Number, default: 0, }) index!: number
  @Prop({ type: Boolean, default: false, }) disabledStyle!: boolean
  @Prop({ type: Boolean, default: false, }) includeColor!: boolean

  colorInputVisible = false
  colorInputMenuAttrs = {
    positionY: 0,
    positionX: 0,
  }

  onColorInputClick(event: MouseEvent): void {
    Object.assign(this.colorInputMenuAttrs, {
      positionY: event.clientY,
      positionX: event.clientX,
    });
    this.colorInputVisible = true;
  }
}
</script>
