<template>
  <v-menu
    v-model="syncedVisible"
    v-bind="menuAttrs"
    :close-on-content-click="false"
    max-width="330"
  >
    <template v-if="!hideActivator" #activator="{ on, attrs }">
      <v-text-field
        v-model="color"
        v-bind="{ ...attrs, ..._attrs }"
        v-on="{ ...on, ...$listeners }"
      >
        <template #prepend-inner>
          <slot name="prepend-inner">
            <v-icon
              left
              :color="color"
            >
              mdi-circle
            </v-icon>
          </slot>
        </template>
      </v-text-field>
    </template>
    <v-card>
      <v-card-title class="pl-7 pr-11">
        <v-text-field
          v-model="hex"
          hide-details="auto"
          label="Color HEX"
          outlined
          clearable
          @blur="color = _value = hex"
        />
      </v-card-title>
      <template v-if="suggestions && suggestions.length > 0">
        <v-card-text class="d-flex flex-wrap pl-7 pr-11" style="gap: 0.5rem">
          <v-btn
            v-for="suggestion in suggestions"
            :key="suggestion"
            :color="suggestion"
            style="flex: 1; padding: 0"
            depressed
            @click="() => color = _value = suggestion"
          >
            <v-icon v-if="(_value || '').toUpperCase() === suggestion.toUpperCase()">mdi-check-circle</v-icon>
          </v-btn>
        </v-card-text>
        <v-divider />
      </template>
      <v-card-text>
        <v-color-picker
          v-model="color"
          show-swatches
          hide-mode-switch
          hide-inputs
          hide-canvas
          hide-sliders
          swatches-max-height="400"
          mode="hexa"
          @input="onColorInput"
        ></v-color-picker>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import {Vue, VModel, Watch, Prop, PropSync, Component} from 'vue-property-decorator';

@Component
export default class ColorInput extends Vue {
  @VModel({ type: String }) _value!: string
  @PropSync('visible', { type: Boolean, default: false }) syncedVisible!: boolean
  @Prop({ default: () => ([]) }) suggestions?: Array<string>
  @Prop({ type: Boolean, default: false }) skipAlpha?: boolean
  @Prop({ type: Boolean, default: false }) hideActivator?: boolean

  color = ''
  hex = ''

  @Watch('_value')
  onValueChanged(color: any) {
    if (color !== null) {
      this.hex = color;
      this.color = color;
      this.$emit('input', color);
    }
  }

  get menuAttrs(): any {
    return !this.hideActivator
      ? {}
      : this.$attrs;
  }

  get _attrs(): any {
    return this.$attrs;
  }

  onColorInput(value: any) {
    const color = typeof value === 'object' ? value.hex : value;

    // Prevent default color
    if (color.substring(0, 7) === '#FF0000') {
      this.color = '';
      return;
    }

    this.hex = color;
    this._value = this.skipAlpha ? color && color.substring(0, 7) : color;
  }

  created() {
    this.onValueChanged(this._value);
  }
}
</script>
