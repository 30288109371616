<template>
  <ModalDialog
    v-model="visible"
    v-bind="$attrs"
    v-on="$listeners"
    :loading="duplicating"
    title="Duplicate Project"
    icon="mdi-content-duplicate"
    scrollable
    persistent
  >
    <template #content>
      <v-card-text class="background px-0 pb-0">
        <v-container fluid>
          <v-row>
            <v-col
              v-for="(panel, panelIdx) in panels"
              :key="panelIdx"
              cols="12" md="6"
              class="d-flex flex-column"
              style="gap: 1rem"
            >
              <v-card
                v-for="(section, sectionIdx) in panel"
                :key="sectionIdx"
                class="pb-4"
              >
                <v-treeview
                  v-model="section.data"
                  :items="section.items"
                  selected-color="primary"
                  selection-type="leaf"
                  selectable
                  open-all
                  return-object
                  @input="onInput"
                >
                  <template #label="{ item }">
                    <v-card-title v-if="item.id === 'all'">
                      {{ item.name }}
                    </v-card-title>
                    <span v-else>{{ item.name }}</span>
                  </template>
                </v-treeview>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </template>
    <template #buttons>
      <v-btn
        large
        color="primary"
        :loading="duplicating"
        :disabled="!canDuplicate"
        @click="onDuplicateClick"
      >
        <span v-text="$t('btn.duplicate')"></span>
      </v-btn>
      <v-btn
        large
        text
        :disabled="duplicating"
        @click="onCancelClick"
      >
        <span v-text="$t('btn.cancel')"></span>
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import { Vue, Component, VModel, Watch, Prop } from 'vue-property-decorator';
import { criteriaList, objectiveList, picotList } from '@/enums/global';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import ProjectService from '@/services/project.service';
import ProjectModel from '@/models/project.model';

@Component({
  components: {
    ModalDialog
  }
})
export default class DuplicateProjectModal extends Vue {
  @VModel({ type: Boolean, default: false }) visible!: boolean
  @Prop({ type: ProjectModel, default: () => new ProjectModel() }) project!: ProjectModel

  duplicating = false
  dataToDuplicate: string[] = []
  defaultDataToDuplicate: string[] = ['label', 'type']
  objectivesDataToDuplicate: string[] = []
  picotsDataToDuplicate: string[] = []
  criterionDataToDuplicate: string[] = []
  extraDataToDuplicate: string[] = []
  mergedDataToDuplicate: string[] = []

  @Watch('visible')
  onVisibleChange(visible: boolean) {
    if (visible) {
      this.clear();
    }
  }

  get panels(): any[] {
    return [
      [
        { data: this.dataToDuplicate, items: this.projectDataList },
        { data: this.extraDataToDuplicate, items: this.projectExtraDataList },
      ],
      [
        { data: this.objectivesDataToDuplicate, items: this.projectObjectivesDataList },
        { data: this.picotsDataToDuplicate, items: this.projectPicotsDataList },
        { data: this.criterionDataToDuplicate, items: this.projectCriterionDataList },
      ],
    ]
  }

  get projectDataList(): any[] {
    return [
      { id: 'all', name: 'Project Data', children: [
        // { id: 'label', name: 'Label' },
        { id: 'description', name: 'Description' },
        { id: 'pilot', name: 'Is Pilot?' },
        { id: 'aiUseAllowed', name: 'AI Use Allowed?' },
        // { id: 'type', name: 'Type' },
        { id: 'status', name: 'Status' },
        { id: 'stage', name: 'Stage' },
        { id: 'roles', name: 'Roles' },
      ] },
    ];
  }

  get projectObjectivesDataList(): any[] {
    return [
      { id: 'all', name: 'Objectives', children:
        objectiveList.map(item => ({ id: item.key, name: item.label }))
      },
    ]
  }

  get projectPicotsDataList(): any[] {
    return [
      { id: 'all', name: 'PICOTS', children:
        picotList.map(item => ({ id: item.key, name: item.label }))
      },
    ]
  }

  get projectCriterionDataList(): any[] {
    return [
      { id: 'all', name: 'Criterion', children:
        criteriaList.map(item => ({ id: item.key, name: item.label }))
      },
    ]
  }

  get projectExtraDataList(): any[] {
    return [
      { id: 'all', name: 'Extra Data', children: [
        { id: 'projectStatusReason', name: 'Exclusion Reasons' },
        { id: 'keywords', name: 'Keywords' },
        { id: 'dataExtraction', name: 'Data Extraction' },
      ] },
    ];
  }

  get canDuplicate(): boolean {
    return !this.duplicating
      && this.mergedDataToDuplicate.length > 0;
  }

  onInput() {
    this.mergedDataToDuplicate = this.panels.flat().map(item => item.data).flat().map(item => item.id);
    this.mergedDataToDuplicate.push(...this.defaultDataToDuplicate);
  }

  onDuplicateClick(): Promise<any> {
    this.duplicating = true;
    return ProjectService.getInstance().duplicate({
      keysToDuplicate: this.mergedDataToDuplicate,
      id: this.project.data.id,
    })
      .then(response => {
        this.visible = false;
        this.$router.push({ name: 'ProjectForm', params: { id: response.data.view.single.data.id } })
        this.$root.$globalSnack.success({
          message: 'Project duplicated successfully!'
        })
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.duplicating = false);
  }

  onCancelClick(): void {
    this.visible = false;
  }

  clear() {
    this.mergedDataToDuplicate = [];
    this.panels.forEach(panel => panel.forEach((section: any) => section.data = []));
  }
}
</script>

<style lang="scss" scoped>
.v-treeview ::v-deep {
  .v-treeview-node__toggle {
    display: none;
  }
}
</style>
