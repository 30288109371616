<template>
  <div>
    <ModalDialog
      v-if="active"
      v-model="visible"
      icon="mdi-directions-fork"
      title="Rules"
      max-width="1200"
      scrollable
      background-color="background"
    >
      <template #body>
        <v-card class="mt-6">
          <v-card-title>
            If the following is true:
          </v-card-title>
          <v-card-text>
            <QueryBuilder
              v-model="innerModel.data.conditions"
              :fields="fields"
            />
          </v-card-text>

          <v-card-title>
            Then execute this action:
          </v-card-title>

          <v-card-text>
            <v-select
              v-model="innerModel.data.action"
              :items="actionList"
              dense
              hide-details
              outlined
              clearable
              style="max-width: 15rem"
            />
          </v-card-text>
        </v-card>
      </template>
      <template #buttons>
        <v-btn
          :disabled="!canClear"
          large
          outlined
          @click="onClear"
        >
          Clear
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!innerModel.isDifferentFromOriginal()"
          large
          color="primary"
          @click="onApply"
        >
          Apply
        </v-btn>
        <v-btn
          outlined
          large
          @click="visible = false"
        >
          Cancel
        </v-btn>
      </template>
    </ModalDialog>

    <v-badge
      :value="model.hasValidConditions()"
      :content="model.getValidConditions().length"
      color="warning"
      overlap
    >
      <v-btn icon @click="active = true">
        <v-icon>mdi-directions-fork</v-icon>
      </v-btn>
    </v-badge>
  </div>
</template>

<script lang="ts">
import { Vue, Component, VModel, Prop, Watch } from 'vue-property-decorator';
import ModalDialog from '@/modules/common/components/ModalDialog.vue'
import QueryBuilder from '@/modules/common/components/QueryBuilder.vue'
import SurveySectionModel from '@/modules/sdk/models/survey-section.model';
import SurveyGroupModel from '@/modules/sdk/models/survey-group.model';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';
import SurveyChoiceModel from '@/modules/sdk/models/survey-choice.model';
import Model from '@/modules/sdk/core/model';
import SurveyModel from '@/modules/sdk/models/survey.model';
import SurveyItemModel from '@/modules/sdk/models/survey-item.model';

const innerModel = new SurveyItemModel({}, false);
type IType = SurveySectionModel | SurveyGroupModel | SurveyQuestionModel | SurveyChoiceModel;

@Component({
  components: {
    ModalDialog,
    QueryBuilder,
  }
})
export default class SurveyRulesDialogActivator extends Vue {
  @VModel({ type: Model, default: () => new SurveyQuestionModel({}, false) }) model!: IType
  @Prop({ type: SurveyModel, default: () => new SurveyModel({}, false) }) survey!: SurveyModel
  @Prop({ type: Array, default: () => ([]) }) extraFields?: any[]

  @Watch('active')
  onActiveChanged(active: boolean) {
    if (active) {
      this.innerModel = this.model.clone();
      this.check();
      this.fields = this.getFields();

      setTimeout(() => this.visible = true, 1);
    }
  }

  @Watch('visible')
  onVisibleChanged(visible: boolean) {
    if (visible) {
      this.fields = this.getFields();
      this.innerModel = this.model.clone();
      this.check();
    } else {
      setTimeout(() => this.active = false, 300);
    }
  }

  innerModel = innerModel
  fields: any[] = []
  active = false
  visible = false
  actionList = [{
    text: 'Make field visible',
    value: 'visible'
  }, {
    text: 'Make field invisible',
    value: 'hidden'
  }, {
    text: 'End of survey',
    value: 'end'
  }]

  defaultConditions = [{
    logic: 'and',
    operator: 'contains',
    field: null,
    value: null,
    group: [] as any,
  }]

  getFields(): any[] {
    const fields: any[] = [];
    this.survey.data.surveysectionlist.forEach((section: SurveySectionModel) => {
      section.data.label && fields.push({ header: section.data.label.toUpperCase() });
      section.data.surveygrouplist.forEach((group: SurveyGroupModel) => {
        group.data.label && fields.push({ header: group.data.label });
        group.data.surveyquestionlist.forEach((question: SurveyQuestionModel) => {
          const attrs = question.getQueryBuilderAttrs();
          attrs.forEach(attr => {
            fields.push(attr);
          })
        });
      })
    })
    if (this.extraFields) {
      fields.unshift({
        header: 'Survey fields'
      });
      fields.push({
        header: 'Extra fields'
      });
      fields.push(...this.extraFields);
    }
    return fields;
  }

  get canClear(): boolean {
    return JSON.stringify(this.innerModel.data.conditions) !== JSON.stringify(this.defaultConditions)
      || this.innerModel.data.action !== null;
  }

  onApply() {
    this.model.assign(this.innerModel.clone());
    this.visible = false;
  }

  onClear() {
    this.innerModel.data.conditions = structuredClone(this.defaultConditions);
    this.innerModel.data.action = null;
  }

  check() {
    if (this.innerModel.data.conditions.length === 0) {
      this.innerModel.data.conditions = structuredClone(this.defaultConditions);
    }
    this.innerModel.setOriginalData();
  }
}
</script>
