<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'category'"
      v-model="model"
      :service="service"
      :loading="loading"
      :parent-route="parentRoute"
      name="category"
      title="Category"
    >
      <template #form="{ formErrors, rules }">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="model.data.label"
              v-safechar
              :error-messages="formErrors.label"
              :rules="[rules.required]"
              label="Category"
              hide-details="auto"
              outlined
              clearable
              required
              @input="delete formErrors.label"
            />
          </v-col>

          <v-col cols="12">
            <text-field-color-expansion
              v-model="model.data.color"
              :error-messages="formErrors.color"
              :rules="[rules.required]"
              label="Color"
              outlined
              clearable
              hide-details="auto"
              mode="hexa"
              hide-canvas
              hide-inputs
              hide-mode-switch
              show-swatches
              width="100%"
              swatches-max-height="360"
              @input="delete formErrors.color"
              @change="delete formErrors.color"
            />
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              v-model="model.data.projectlist"
              :loading="projectListLoading"
              :items="projectList"
              :item-text="(item) => item.getLabel()"
              :disabled="!!($route.params.projectId)"
              item-value="data.id"
              outlined
              label="Projects"
              hide-details="auto"
              cache-items
              deletable-chips
              multiple
              chips
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
      </template>
    </DataForm>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'
import CategoryService from '@/services/category.service'
import Logger from '@/modules/sdk/core/logger'
import DataForm from '@/modules/common/components/DataForm.vue'
import CategoryModel from '@/models/category.model'
import KeywordChip from '@/components/KeywordChip.vue'
import ModalDialog from '@/modules/common/components/ModalDialog.vue'
import TextFieldColorExpansion from '@/modules/common/components/TextFieldColorExpansion.vue'
import ProjectService from '@/services/project.service'
import ProjectModel from '@/models/project.model'
import { Route } from 'vue-router';

const d = new Logger('views/Admin/Form/CategoryForm')

@Component({
  components: {
    TextFieldColorExpansion,
    ModalDialog,
    KeywordChip,
    DataForm,
  }
})
export default class CategoryForm extends Vue {

  @Prop({ type: Number, default: null }) id!: number
  @Prop({ type: Number, default: null }) projectId!: number

  model: CategoryModel = new CategoryModel()
  service = CategoryService.getInstance()
  loading = false
  parentRoute: Partial<Route> = { name: 'CategoryList' };

  projectListLoading = false
  relations = {
    projectList: [],
  }

  get _id(): number | null {
    return this.id || parseInt(this.$route.params.id);
  }

  get _projectId(): number | null {
    return this.projectId || parseInt(this.$route.params.projectId);
  }

  get projectList(): Array<ProjectModel> {
    return this.projectListLoading
      ? this.model.data.projectlist
      : this.relations.projectList
  }

  load(id: number) {
    this.loading = true
    this.service.get({ id })
    .then(response => this.model = response.data.view.single)
    .catch(reason => this.$root.$zemit.handleError(reason))
    .finally(() => this.loading = false)
  }

  loadRelations(): Promise<any> {
    this.projectListLoading = true
    return ProjectService.getInstance().getAll({
      order: 'label asc'
    })
    .then(response => this.relations.projectList = response.data.view.list)
    .catch(reason => this.$root.$zemit.handleError(reason))
    .finally(() => this.projectListLoading = false)
  }

  created() {
    if (this.$route.params.id) {
      this.load(parseInt(this.$route.params.id))
    }
    this.loadRelations()
      .then(() => {
        if (this._projectId) {

          // set parent route
          this.parentRoute = { name: 'ProjectForm', params: { id: this._projectId.toString() } }

          const project = this.projectList.find(project => project.data.id === this._projectId);
          if (project) {
            this.model.data.projectlist = [project];
            this.model.setOriginalData();
          }
        }
      })
  }
}
</script>


