<template>
  <div>

    <AskQuestionToAiModal
      v-if="canUseAi"
      v-model="questionToAIModal.visible"
      :selection="questionToAIModal.selection"
      :text="questionToAIModal.text"
      :survey="model"
      :project="project"
      :record="record"
      :history-headers="questionToAIModal.headers"
      :history-service="questionToAIModal.service"
      mode="data-extraction"
      stage="final"
      title="Ask question to the AI"
      icon="mdi-robot"
      max-width="1200"
      scrollable
      @answer="onAIAnswer"
      @apply="onAIApplyAnswer"
    />

    <!-- TABS -->
    <v-tabs v-if="editable" v-model="tab" grow height="64">
      <v-tab>
        <span>Edit</span>
      </v-tab>
      <v-tab>
        <span>Preview</span>
      </v-tab>
    </v-tabs>

    <!-- TEMPLATES -->
    <v-sheet v-if="showTemplates && tab === 0" color="backgroundLight" class="py-2">
      <div class="d-flex align-center justify-space-between px-4" style="gap: 1rem">
        <v-progress-linear
          v-if="loadingTemplates"
          indeterminate
          style="max-width: 50%"
        />
        <div v-else class="d-flex align-center" style="gap: 2rem">
          <span class="title">Templates:</span>
          <template v-if="templates.length === 0">
            <v-chip label color="warning" small>No template yet.</v-chip>
            <v-btn
              x-small
              outlined
              @click="onNewTemplate"
            >
              Create one
            </v-btn>
          </template>
          <div v-else class="d-flex align-center" style="gap: 2rem">
            <v-chip-group>
              <v-chip
                v-for="template in templates"
                :key="template.data.id"
                dark
                label
                @click="() => onApplyTemplate(template)"
              >{{ template.data.label }}</v-chip>
            </v-chip-group>
          </div>
        </div>

        <v-spacer />

        <v-checkbox
          v-model="innerShowRemoved"
          label="Show removed"
        />
      </div>
    </v-sheet>

    <!-- CONTENT -->
    <v-tabs-items v-model="tab" class="overflow-visible transparent">

      <!-- EDIT -->
      <v-tab-item v-if="editable" v-bind="tabAttrs" :transition="false">
        <Survey
          v-model="model"
          v-bind="computedCascadeAttrs"
          v-on="$listeners"
          :cascade-attrs="computedCascadeAttrs"
          :answer-filters="innerAnswerFilters"
          :extra-fields="innerExtraFields"
          mode="edit"
        />
      </v-tab-item>

      <!-- ANSWER -->
      <v-tab-item v-bind="tabAttrs" :transition="false">

        <Survey
          v-model="model"
          v-bind="computedCascadeAttrs"
          v-on="$listeners"
          :answer-filters="innerAnswerFilters"
          :extra-fields="innerExtraFields"
          :include-save-button="!preview"
          :before-save-section="onBeforeSaveSection"
          :cascade-attrs="computedCascadeAttrs"
          mode="answer"
          view="list"
          hide-actions
          load-answers
        >
          <!-- QUESTION FOR AI -->
          <template v-if="!preview && canUseAi" #question.header.append="{ model }">
            <v-tooltip bottom>
              <template #activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="disabled"
                  icon
                  @mousedown.prevent.stop="onAskQuestionAi(model)"
                >
                  <v-icon small>mdi-robot</v-icon>
                </v-btn>
              </template>
              <span v-text="$t('surveyAnswer.askQuestionAi')"></span>
            </v-tooltip>
          </template>
        </Survey>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import Survey from '@/modules/common/components/Survey.vue';
import SurveyModel from '@/modules/sdk/models/survey.model';
import { stageList } from '@/enums/global';
import RecordModel from '@/models/record.model';
import AskQuestionToAiModal from '@/components/AskQuestionToAiModal.vue';
import SurveyQuestionModel from '@/modules/sdk/models/survey-question.model';
import SurveyAiAnswerModel from '@/modules/sdk/models/survey-ai-answer.model';
import SurveyAiAnswerService from '@/modules/sdk/services/survey-ai-answer.service';
import SurveyAnswerModel from '@/modules/sdk/models/survey-answer.model';
import SurveyItemMixin from '@/modules/common/mixins/survey';
import ProjectModel from '@/models/project.model';

@Component({
  components: {
    AskQuestionToAiModal,
    Survey,
  }
})
export default class SurveyManager extends SurveyItemMixin<SurveyModel, SurveyModel> {
  @Prop({ type: RecordModel, default: () => new RecordModel() }) record!: RecordModel;
  @Prop({ type: ProjectModel, default: () => new ProjectModel() }) project!: ProjectModel;
  @Prop({ type: Object, default: () => ({}) }) tabAttrs?: any
  @Prop({ type: Boolean, default: false }) editable!: boolean
  @Prop({ type: Boolean, default: false }) loadAnswers!: boolean
  @Prop({ type: Boolean, default: false }) showTemplates!: boolean

  tab = 0
  loadingTemplates = false
  innerShowRemoved = false
  innerAnswerFilters: null | any[] = null
  templates: Array<SurveyModel> = []
  questionToAIModal: {
    visible: boolean,
    text: string | null,
    selection: string[],
    headers: any[],
    service: () => Promise<any>,
    question: SurveyQuestionModel,
  } = {
    visible: false,
    text: null,
    selection: [],
    headers: [],
    service: () => new Promise(() => {}),
    question: new SurveyQuestionModel(),
  }

  innerExtraFields = [
    { text: 'Show from stage', type: 'list', items: stageList, value: 'show_from_stage' }
  ]

  onNewTemplate(): void {
    this.$root.$shouldTakeAction.ask({
      title: 'Add new template',
      body: 'You will be redirected to the Survey Template form and any unsaved changes will be lost. Do you wish to proceed?',
      actionText: 'Continue',
      color: 'warning',
      dark: true,
      onAccept: () => this.$router.push({
        name: 'DataExtractionPresetsForm',
        params: {
          id: 'new'
        }
      })
    })
  }

  onAIAnswer(answer: any): void {
    console.log(answer);
  }

  onAIApplyAnswer(answer: SurveyAiAnswerModel): void {
    this.questionToAIModal.question.answer = answer.data.answer;
  }

  onApplyTemplate(template: SurveyModel) {
    this.$root.$shouldTakeAction.ask({
      title: 'Append template',
      body: 'This action will merge this template into your current data. Do you wish to proceed?',
      actionText: 'Append',
      color: 'warning',
      dark: true,
      onAccept: () => this.appendSurvey(template)
    })
  }

  onAskQuestionAi(question: SurveyQuestionModel): void {
    const activeElement: any = document.activeElement;
    const selObj = window.getSelection();
    let text = null;
    try {
      const range = selObj ? selObj.getRangeAt(0) : null;
      text = activeElement && ['TEXTAREA', 'INPUT'].includes(activeElement.nodeName)
        ? activeElement.value.substring(
          activeElement.selectionStart,
          activeElement.selectionEnd
        )
        : range
          ? range.toString()
          : null;
    }
    catch (e) {

    }

    Object.assign(this.questionToAIModal, {
      visible: true,
      selection: ['question_' + question.data.id],
      text,
      headers: [{
        class: 'text-no-wrap',
        cellClass: 'text-no-wrap',
        width: 0,
        text: this.$i18n.t('askQuestionToAISurveyModal.headers.date'),
        value: 'data.createdAt',
      }, {
        class: 'text-no-wrap',
        text: this.$i18n.t('askQuestionToAISurveyModal.headers.question'),
        value: 'data.question',
        width: '25%',
      }, {
        class: 'text-no-wrap',
        text: this.$i18n.t('askQuestionToAISurveyModal.headers.answer'),
        value: 'data.answer',
        width: '33%',
      }, {
        class: 'text-no-wrap',
        width: 0,
        text: this.$i18n.t('askQuestionToAISurveyModal.headers.confidence'),
        value: 'data.confidence',
      }, {
        class: 'text-no-wrap',
        width: 0,
        text: this.$i18n.t('askQuestionToAISurveyModal.headers.rating'),
        value: 'data.rating',
      }],
      service: () => SurveyAiAnswerService.getInstance().getAll({
        filters: [{
          field: 'surveyQuestionId',
          operator: 'equals',
          value: question.data.id,
        }]
      })
    })
  }

  onBeforeSaveSection(answers: SurveyAnswerModel[]) {
    answers.forEach(answer => answer.data.recordId = this.record.data.id);
  }

  appendSurvey(survey: SurveyModel) {
    const clone: SurveyModel = survey.clone();
    clone.cleanReferences();
    this.model.data.surveygrouplist = this.model.data.surveygrouplist.concat(clone.data.surveygrouplist);
    this.model.resetPosition();
    this.$emit('append-template', clone)
  }

  created() {
    if (this.record?.data.id) {
      this.innerAnswerFilters = [{
        field: 'recordId',
        operator: 'equals',
        value: this.record.data.id,
      }]
    }
  }
}
</script>
