<template>
  <v-row>

    <!-- SUGGESTIONS DIALOG -->
    <ModalDialog
      v-model="suggestionsDialog.visible"
      width="680"
      title="Keyword Lookup"
      scrollable
      background-color="background"
    >
      <template #body>

        <v-text-field
          v-model="suggestionsDialog.search"
          :placeholder="$t('keywordForm.filterKeywords')"
          prepend-inner-icon="mdi-magnify"
          solo
          hide-details
          clearable
          autofocus
          class="mt-6 mb-4"
          @keyup.enter="onSearchKeyUpEnter"
          @keyup="onSearchKeyUp"
        />

        <v-expansion-panels
          v-model="suggestionsDialog.panel"
          @input="() => loadSuggestions(suggestionsDialog.search)"
        >

          <!-- PREVIOUS RECORDS -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              Previous Records
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-if="!previousSynonymLoading">
                <v-alert
                  v-if="previousKeywordList.length === 0 && previousSynonymList.length === 0"
                  class="mb-0"
                  text
                  type="info"
                >
                  <span v-if="isSearching" v-text="$t('keywordForm.searchEmpty')"></span>
                  <span v-else v-text="$t('keywordForm.empty')"></span>
                </v-alert>
                <v-chip-group v-else column>
<!--                  <v-chip-->
<!--                    v-for="(synonym, wordIndex) in previousKeywordList"-->
<!--                    :key="synonym.data.label + '_keyword'"-->
<!--                    label-->
<!--                    outlined-->
<!--                    @click="addPreviousSynonym(previousKeywordList, wordIndex)"-->
<!--                  >-->
<!--                    <div class="d-flex align-center" style="gap: 0.5rem">-->
<!--                      <v-icon :color="synonym.data.color" :size="20">mdi-circle</v-icon>-->
<!--                      <span>{{ synonym.data.label }}</span>-->
<!--                    </div>-->
<!--                  </v-chip>-->
                  <v-chip
                    v-for="(synonym, wordIndex) in previousSynonymList"
                    :key="synonym.data.label + '_synonym'"
                    label
                    outlined
                    @click="addPreviousSynonym(previousSynonymList, wordIndex)"
                  >
                    <div class="d-flex align-center" style="gap: 0.5rem">
                      <v-icon :color="synonym.data.color" :size="20">mdi-circle</v-icon>
                      <span>{{ synonym.data.label }}</span>
                    </div>
                  </v-chip>
                </v-chip-group>
              </div>
              <div v-else>
                <v-progress-linear
                  indeterminate
                ></v-progress-linear>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- WORD API RESULTS -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              Word API Results
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-if="!wordSynonymLoading">
                <v-alert
                  v-if="_wordSynonymList.length === 0"
                  class="mb-0"
                  text
                  type="info"
                >
                  <span v-if="isSearching" v-text="$t('keywordForm.searchEmpty')"></span>
                  <span v-else v-text="$t('keywordForm.empty')"></span>
                </v-alert>
                <v-chip-group v-else column>
                  <v-chip
                    v-for="(word, wordIndex) in _wordSynonymList"
                    :key="word"
                    outlined
                    label
                    @click="addSynonymLabel(wordSynonymList, wordIndex)"
                  >
                    <div class="d-flex align-center" style="gap: 0.5rem">
                      <v-icon :size="20">mdi-plus-circle</v-icon>
                      <span>{{ word }}</span>
                    </div>
                  </v-chip>
                </v-chip-group>
              </div>
              <div v-else>
                <v-progress-linear
                  indeterminate
                ></v-progress-linear>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- OPEN AI -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              Open AI
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-if="!aiSynonymLoading">
                <v-alert
                  v-if="_aiSynonymList.length === 0"
                  class="mb-0"
                  text
                  type="info"
                >
                  <span v-if="isSearching" v-text="$t('keywordForm.searchEmpty')"></span>
                  <span v-else v-text="$t('keywordForm.empty')"></span>
                </v-alert>
                <v-chip-group v-else column>
                  <v-chip
                    v-for="(item, itemIdx) in _aiSynonymList"
                    :key="item"
                    outlined
                    label
                    @click="addSynonymLabel(aiSynonymList, itemIdx)"
                  >
                    <div class="d-flex align-center" style="gap: 0.5rem">
                      <v-icon :size="20">mdi-plus-circle</v-icon>
                      <span>{{ item }}</span>
                    </div>
                  </v-chip>
                </v-chip-group>
              </div>
              <div v-else>
                <v-progress-linear
                  indeterminate
                ></v-progress-linear>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
      <template #buttons>
        <v-btn outlined @click="suggestionsDialog.visible = false">
          <span v-text="$t('btn.close')"></span>
        </v-btn>
      </template>
    </ModalDialog>

    <!-- PROJECT -->
    <v-col cols="12" v-if="!$route.params.projectId">
      <v-autocomplete
        v-model="_model.data.projectId"
        :error-messages="_formErrors.projectId"
        :loading="loadingProjects"
        :search-input.sync="projectListSearch"
        :items="projectList"
        label="Project"
        item-text="data.label"
        item-value="data.id"
        hide-details="auto"
        outlined
        clearable
        @input="delete _formErrors.projectId"
      ></v-autocomplete>
    </v-col>

    <!-- CATEGORY -->
    <v-col cols="12">

      <KeywordChip
        ref="currentChip"
        v-model="_model"
        class="d-none"
        filled
      />

      <v-combobox
        v-model="_model.data.label"
        :error-messages="_formErrors.label"
        :rules="[_rules.required]"
        :items="categoryList"
        :loading="loadingCategoryList"
        label="Keyword Category"
        type="text"
        hide-details="auto"
        outlined
        clearable
        required
        @input="delete _formErrors.label"
      />
      <div class="d-flex align-center mt-2" style="gap: 0.5rem">
        <v-btn small outlined @click="loadSuggestions(_model.data.label)">
          <v-icon left>mdi-magnify</v-icon>
          <span>Lookup</span>
        </v-btn>
        <v-btn small outlined @click="onParameterClick(_model)">
          <v-icon :color="_model.data.color" left>mdi-palette</v-icon>
          <span>Parameters</span>
        </v-btn>
        <v-btn :loading="importingSynonyms" :disabled="importingSynonyms" small outlined @click="importKeywords(_model)">
          <v-icon left>mdi-database-import</v-icon>
          <span>Import keywords</span>
        </v-btn>
      </div>
    </v-col>

    <!-- SYNONYMS -->
    <v-col cols="12">
      <v-combobox
        v-model="synonymList"
        :hide-no-data="!synonymSearch"
        :items="getSelectSynonymList()"
        :search-input.sync="synonymSearch"
        :error-messages="_formErrors.synonymlist"
        :rules="[]"
        item-text="data.label"
        item-color="data.color"
        label="Keywords"
        hide-details="auto"
        hint="do not remove"
        persistent-hint
        outlined
        counter
        multiple
        clearable
        chips
        deletable-chips
        hide-selected
        return-object
      >
        <template #message>
          Please <b class="px-1" style="background-color: yellow">use lowercase</b> when entering new keywords, and reserve uppercase only for cases where it's truly necessary, such as acronyms, abbreviations, proper names, or other specific instances that require it.
        </template>
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <KeywordChip
            v-if="item instanceof SynonymModel"
            v-show="item.data.label"
            v-bind="attrs"
            :ref="'synonym_' + item.data.id"
            :value="item"
            :input-value="selected"
            :auto-edit="autoEditId === item.data.id"
            removable
            editable
            filled
            @remove="parent.selectItem(item)"
          />
        </template>
      </v-combobox>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Prop, PropSync, Watch, VModel, Emit } from 'vue-property-decorator';
import KeywordChip from '@/components/KeywordChip.vue';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import SynonymModel from '@/models/synonym.model';
import axios, { AxiosRequestConfig } from 'axios';
import KeywordModel from '@/models/keyword.model';
import { debounce } from 'debounce';
import KeywordService from '@/services/keyword.service';
import AiService from '@/services/ai.service';
import ProjectService from '@/services/project.service';
import Service from '@/modules/sdk/core/service';
import Rules from '@/modules/sdk/core/rules';

@Component({
  components: {
    ModalDialog,
    KeywordChip: () => import('@/components/KeywordChip.vue'),
  }
})
export default class KeywordFormInner extends Vue {

  @VModel({ default: () => new KeywordModel() }) model!: KeywordModel
  @Prop({ type: Number, default: null }) id!: number
  @Prop({ type: Number, default: null }) projectId!: number
  @Prop({ type: Boolean, default: false }) autoAddSynonym!: boolean
  @Prop({ type: [Number, Boolean] }) autoEditId!: number | null | boolean | undefined
  @Prop({ type: Boolean, default: true }) detailed!: boolean
  @Prop({ type: Service, default: null }) service!: Service
  @PropSync('formErrors', { type: Object, default: () => ({}) }) _formErrors!: any
  @PropSync('rules', { type: Object, default: () => ({
    required: (value: string) => Rules.required(value) || 'This field is required',
  }) }) _rules!: any

  loading = false;
  loadingProjects = false;

  synonymSearch = '';
  SynonymModel = SynonymModel;

  suggestionsDialog: {
    visible: boolean,
    panel: number | null,
    search: string | null,
  } = {
    visible: false,
    panel: null,
    search: null,
  };

  previousSearch: {[key: string]: string | null} = {
    previous: null,
    word: null,
    ai: null,
  }

  wordsApi: AxiosRequestConfig = {
    method: 'GET',
    url: 'https://wordsapiv1.p.rapidapi.com/words/',
    headers: {
      'X-RapidAPI-Key': 'b9d63d6f8cmshda055ba5722834ep110bcfjsnad7dbf1f6f4f',
      'X-RapidAPI-Host': 'wordsapiv1.p.rapidapi.com'
    }
  };

  projectList = [];
  projectListSearch = '';

  defaultColor = '#E0E0E0FF';

  wordSynonymLoading = false;
  wordSynonymList: Array<string> = [];

  loadingCategoryList = false;
  importingSynonyms = false;
  categoryList: Array<any> = []

  aiSynonymLoading = false;
  aiSynonymList: Array<string> = [];

  previousSynonymLoading = false;
  previousSynonymList: Array<SynonymModel> = [];
  previousKeywordList: Array<KeywordModel> = [];

  @Watch('suggestionsDialog.panel')
  onSuggestionDialogPanelChange() {
    this.onSearchKeyUp.clear();
    this.search();
  }

  get _model(): KeywordModel {
    return this.model;
  }

  set _model(model: KeywordModel) {
    this.$emit('input', model);
  }

  get isSearching(): boolean {
    return this.suggestionsDialog.search !== null
      && this.suggestionsDialog.search.trim().length > 0;
  }

  get _wordSynonymList(): Array<string> {
    return this.wordSynonymList.filter(item => {
      return this.wordSynonymList.indexOf(item) !== -1;
    });
  }

  get _aiSynonymList(): Array<string> {
    return this.aiSynonymList.filter(item => {
      return this.aiSynonymList.indexOf(item) !== -1;
    });
  }

  onParameterClick(model: KeywordModel | SynonymModel): void {
    const keywordChip = (this.$refs.currentChip as KeywordChip);
    if (keywordChip) {
      keywordChip.editItem(model, 'parameters');
    }
  }

  onSearchKeyUpEnter(): void {
    this.onSearchKeyUp.clear();
    this.search();
  }

  onSearchKeyUp = debounce(() => {
    this.search();
  }, 500);

  search() {
    if (this.suggestionsDialog.search) {
      this.loadSuggestions(this.suggestionsDialog.search, false);
    }
  }

  importKeywords(model: KeywordModel) {
    this.importingSynonyms = true;
    KeywordService.getInstance().getAll({
      filters: [{
        field: 'label',
        operator: 'equals',
        value: model.data.label,
      }]
    })
      .then(response => {
        let someAdded = false;
        response.data.view.list.forEach((keyword: KeywordModel) => {
          keyword.data.synonymlist.forEach((synonym: SynonymModel) => {
            const found = this._model.data.synonymlist.find((item: SynonymModel) => item.data.label === synonym.data.label);
            if (!found) {
              this._model.data.synonymlist.push(synonym);
              someAdded = true;
            } else {
              found.data.deleted = 0;
            }
          })
        })
        if (someAdded) {
          this.$root.$globalSnack.success({
            message: 'New synonyms has been found and added to your keywords!'
          });
        } else {
          this.$root.$globalSnack.info({
            message: 'No new synonyms has been found.'
          });
        }
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.importingSynonyms = false);
  }

  loadSuggestions(keyword: string | null, reset = true) {
    switch (this.suggestionsDialog.panel) {
      case 0: this.loadPreviousSynonymList(keyword); break;
      case 1: this.loadWordSynonymList(keyword); break;
      case 2: this.loadAiSynonymList(keyword); break;
    }

    Object.assign(this.suggestionsDialog, {
      visible: true,
      panel: !reset ? this.suggestionsDialog.panel : null,
      search: !reset ? this.suggestionsDialog.search : null,
    });
  }

  loadPreviousSynonymList(label: string | null) {

    if (this.previousSearch.previous === label) {
      return;
    }
    this.previousSearch.previous = label;

    this.previousSynonymList = [];
    // this.previousKeywordList = [];
    if (label) {
      this.previousSynonymLoading = true;
      KeywordService.getInstance().getAll({
        filters: [
          [
            [
              { field: 'label', value: label, operator: 'contains word' },
              { field: 'deleted', value: 1, operator: 'does not equal' },
            ],
            // or
            [
              { field: 'Synonym.label', value: label, operator: 'contains word' },
              { field: 'Synonym.deleted', value: 1, operator: 'does not equal' },
            ]
          ],
          // and
          {
            field: 'id',
            value: this._model.data.id || '',
            operator: 'does not equal'
          },
        ],
      }).then((response) => {
        if (response.data.view.list) {
          response.data.view.list.forEach((keyword: KeywordModel) => {
            // this.previousKeywordList = [...this.previousKeywordList, ...[keyword]].filter((value, index, self) =>
            //   index === self.findIndex((t) => (
            //     t.data.label === value.data.label
            //   ))
            // );
            this.previousSynonymList = [...this.previousSynonymList, ...keyword.data.synonymlist].filter((value, index, self) =>
                index === self.findIndex((t) => (
                  t.data.label === value.data.label
                ))
            );
          });
        } else {
          this.$root.$globalSnack.warning({
            message: 'An unexpected error has occurred while trying to load the previous projects synonym list.',
            icon: 'mdi-emoticon-dead-outline'
          });
        }
      }).catch(reason => this.$root.$zemit.handleError(reason))
        .finally(() => this.previousSynonymLoading = false);
    }
  }

  loadWordSynonymList(label: string | null) {

    if (this.previousSearch.word === label) {
      return;
    }
    this.previousSearch.word = label;

    this.wordSynonymList = [];
    if (label) {
      this.wordSynonymLoading = true;
      const options = {
        ...this.wordsApi
      };
      options.url = options.url + label + '/synonyms';
      axios.request(options).then((response) => {
        this.wordSynonymList = response.data.synonyms;
      }).catch(reason => {
        console.error(reason);
      })
        .finally(() => this.wordSynonymLoading = false);
    }
  }

  loadAiSynonymList(label: string | null) {

    if (this.previousSearch.ai === label) {
      return;
    }
    this.previousSearch.ai = label;

    this.aiSynonymList = [];
    if (label) {
      this.aiSynonymLoading = true;
      AiService.getInstance().getKeywordSuggestions({label: label})
        .then(response => {
          this.aiSynonymList = response.data.view.list.map((item: any) => item.data.suggestion);
        }).catch(reason => this.$root.$zemit.handleError(reason))
        .finally(() => this.aiSynonymLoading = false);
    }

  }

  addSynonymLabel(labelList: Array<string>, index: number) {
    const label = labelList[index];

    // Check if the synonym is already in the current list
    const item = this._model.data.synonymlist.find((model: SynonymModel) => model.data.label === label);

    // Synonym exists, make sure it's active
    if (item) {
      item.data.deleted = 0;
    }

    // Synonym doesn't exists, append new synonym
    else {
      this._model.data.synonymlist.push(new SynonymModel({
        label: labelList[index],
        projectId: this._model.data.projectId,
        color: this._model.data.color || this.defaultColor,
        caseSensitive: this._model.data.caseSensitive || false,
        wordOnly: this._model.data.wordOnly || false,
        regexp: this._model.data.regexp || false,
        deleted: 0,
      }));
    }

    // Remove from the suggestion list
    labelList.splice(index, 1);
  }

  addPreviousSynonym(synonymList: Array<SynonymModel|KeywordModel>, index: number) {
    const synonym = synonymList[index];

    // Check if the synonym is already in the current list
    const item = this.model.data.synonymlist.find((model: SynonymModel) => model.data.label === synonym.data.label);

    // Synonym exists, make sure it's active
    if (item) {
      item.data.deleted = 0;
    }
    // Synonym doesn't exists, append new synonym
    else {
      this._model.data.synonymlist.push(new SynonymModel({
        label: synonym.data.label,
        projectId: this._model.data.projectId,
        color: synonym.data.color || this.defaultColor,
        caseSensitive: synonym.data.caseSensitive || false,
        regexp: synonym.data.regexp || false,
        deleted: 0,
      }));
    }

    // Remove from the suggestion list
    synonymList.splice(index, 1);
  }

  getSelectSynonymList(): Array<SynonymModel> {
    return this.model.data.synonymlist?.filter((model: SynonymModel) => !!model.data.deleted);
  }

  get synonymList(): Array<SynonymModel> {
    return this._model.data.synonymlist.filter((model: SynonymModel) => !model.data.deleted);
  }

  set synonymList(synonymList: Array<SynonymModel>) {

    // prepare array
    if (!Array.isArray(this._model.data.synonymlist)) {
      this._model.data.synonymlist = [];
    }

    // merge models or strings to list
    for (const index in synonymList) {

      // transform strings into models
      if (typeof synonymList[index] === 'string') {
        synonymList[index] = new SynonymModel({
          label: synonymList[index],
          projectId: this._model.data.projectId,
          color: this._model.data.color || this.defaultColor,
          caseSensitive: this._model.data.caseSensitive,
          wordOnly: this._model.data.wordOnly,
          regexp: this._model.data.regexp,
          deleted: 0,
        });
      }

      // Vue.js transforming our model to an observable object
      if (!(synonymList[index] instanceof SynonymModel) && synonymList[index].data) {
        synonymList[index] = new SynonymModel(synonymList[index].data);
      }

      // merge or add the model
      if (synonymList[index] instanceof SynonymModel) {
        const model = synonymList[index];
        model.data.deleted = 0;
        model.assignToArrayByProperty(this._model.data.synonymlist, {label: model.data.label});
      }
    }

    // keep intersect only
    this._model.data.synonymlist.map((model: SynonymModel) => {
      if (!synonymList.some((model2: SynonymModel) => model.data.label === model2.data.label)) {
        model.data.deleted = 1;
      }
      return model;
    });
  }

  @Watch('loading')
  onLoadingChanged(loading: boolean) {
    this.$emit('loading', loading);
  }

  @Watch('suggestionsDialog.visible')
  onDialogVisible(visible: boolean) {
    if (visible) {
      this.suggestionsDialog.panel = null;
    }
  }

  @Watch('projectListSearch')
  onProjectListSearch(search: string) {
    this.loadingProjects = true;
    ProjectService.getInstance().getAll({ search, order: 'label asc' })
      .then(response => this.projectList = response.data.view.list)
      .finally(() => this.loadingProjects = false);
  }

  @Watch('model.data.projectId')
  onProjectIdChange(projectId: number) {
    for (const model of this._model.data.synonymlist) {
      model.data.projectId = projectId;
    }
  }

  loadCategoryList() {
    this.loadingCategoryList = true;
    KeywordService.getInstance().getAll({
      filters: [{field: 'deleted', value: 1, operator: 'does not equal'}],
      group: 'label', // Distinct
      order: 'label ASC',
    })
      .then(response => this.categoryList = response.data.view.list.map((item: any) => item.data.label))
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loadingCategoryList = false);
  }

  @Emit()
  async load(id: number) {
    this.loading = true;
    return this.service.get({id})
      .then(response => this._model = response.data.view.single)
      .then(response => {
        this.init();
        return response;
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  @Emit()
  init() {
    if (this.autoAddSynonym) {
      this.autoEditId = null;
    }
    if (this.autoEditId || this.autoEditId === null) {
      const autoEditItem = this.synonymList.find(synonym => synonym.data.id === this.autoEditId);
      if (autoEditItem) {
        setTimeout(() => {
          const ref = (this.$refs['synonym_' + autoEditItem.data.id] as KeywordChip);
          if (ref) {
            ref.editItem(ref._model, 'parameters');
          }
        }, 600)
      }
    }
  }

  created() {
    if (this.id) {
      this.load(this.id);
    }
    this.init();
    this.loadCategoryList();
  }
}
</script>
