import Vue from 'vue';
import VueRouter, { RouteConfig, Route } from 'vue-router';

import ErrorView from '@/views/ErrorView.vue';

import Login from '@/modules/common/views/auth/Login.vue';
import ForgotPassword from '@/modules/common/views/auth/ForgotPassword.vue';
import ResetPassword from '@/modules/common/views/auth/ResetPassword.vue';
import HomeView from '@/views/Admin/HomeView.vue';
import SubView from '@/modules/common/views/SubView.vue';

import MyProfileView from '@/modules/common/views/MyProfileView.vue';
import ReviewView from '@/views/Admin/ReviewView.vue';
import DevView from '@/views/Admin/DevView.vue';

import RoleList from '@/modules/common/views/list/RoleList.vue';
import RoleForm from '@/modules/common/views/form/RoleForm.vue';

import TaskList from '@/views/Admin/List/TaskList.vue';
import TaskForm from '@/views/Admin/Form/TaskForm.vue';

import TemplateList from '@/modules/common/views/list/TemplateList.vue';
import TemplateForm from '@/modules/common/views/form/TemplateForm.vue';

import UserList from '@/modules/common/views/list/UserList.vue';
import UserForm from '@/modules/common/views/form/UserForm.vue';

import ErrorLogList from '@/modules/common/views/list/ErrorLogList.vue';
import ErrorLogForm from '@/modules/common/views/form/ErrorLogForm.vue';

import VideoLibraryList from '@/modules/common/views/list/VideoLibraryList.vue';
import VideoLibraryForm from '@/modules/common/views/form/VideoLibraryForm.vue';

import ProjectList from '@/views/Admin/List/ProjectList.vue';
import ProjectForm from '@/views/Admin/Form/ProjectForm.vue';

import KeywordList from '@/views/Admin/List/KeywordList.vue';
import KeywordForm from '@/views/Admin/Form/KeywordForm.vue';

import CountryList from '@/modules/common/views/list/CountryList.vue';
import CountryForm from '@/modules/common/views/form/CountryForm.vue';

import CategoryForm from '@/views/Admin/Form/CategoryForm.vue';
import CategoryList from '@/views/Admin/List/CategoryList.vue';

import TagForm from '@/views/Admin/Form/TagForm.vue';
import TagList from '@/views/Admin/List/TagList.vue';

import AiPromptForm from '@/views/Admin/Form/AiPromptForm.vue';
import AiPromptList from '@/views/Admin/List/AiPromptList.vue';

import AiModelForm from '@/views/Admin/Form/AiModelForm.vue';
import AiModelList from '@/views/Admin/List/AiModelList.vue';

import AiTaskList from '@/views/Admin/List/AiTaskList.vue';
// import AiTaskForm from '@/views/Admin/Form/AiTaskForm.vue';

import ContactForm from '@/modules/common/views/form/ContactForm.vue';
import ContactList from '@/modules/common/views/list/ContactList.vue';

import SatisfactionForm from '@/modules/common/views/form/SatisfactionForm.vue';
import SatisfactionList from '@/modules/common/views/list/SatisfactionList.vue';

import NotificationForm from '@/modules/common/views/form/NotificationForm.vue';
import NotificationList from '@/modules/common/views/list/NotificationList.vue';

import ProjectStatusReasonList from '@/views/Admin/List/ProjectStatusReasonList.vue';
import ProjectStatusReasonForm from '@/views/Admin/Form/ProjectStatusReasonForm.vue';

import TutorialView from '@/modules/common/views/TutorialView.vue';

import authGuard from '@/router/guards/auth.guard';
import appGuard from '@/router/guards/app.guard';
import roleGuard from '@/router/guards/role.guard';
import DataExtractionPresetsList from '@/views/Admin/List/DataExtractionPresetsList.vue';
import DataExtractionPresetsForm from '@/views/Admin/Form/DataExtractionPresetsForm.vue';
import Identity from '@/modules/sdk/core/identity';
import { NavigationGuardNext } from 'vue-router/types/router';

const devGuard = roleGuard('dev');
const adminGuard = roleGuard(['dev', 'admin']);
const leaderGuard = roleGuard(['dev', 'admin', 'leader']);
const researcherGuard = roleGuard(['dev', 'admin', 'leader', 'arbitrator', 'researcher']);

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // Errors
  { path: '*', redirect: '/error/not-found' },
  { path: '/error/:type?', name: 'error', component: ErrorView, beforeEnter: appGuard },

  // Public
  { path: '/login', name: 'login', component: Login, beforeEnter: authGuard },
  { path: '/forgot-password', name: 'forgotPassword', component: ForgotPassword, beforeEnter: authGuard },
  { path: '/reset-password/:token', name: 'resetPassword', component: ResetPassword, beforeEnter: authGuard },

  // Private
  { path: '/', name: 'HomeView', component: HomeView, beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
    if (Identity.isLoggedIn() && Identity.hasRole(['client'])) {
      return next({
        name: 'ReviewView',
      });
    }
    appGuard(to, from, next);
  } },
  { path: '/my-profile', name: 'MyProfileView', component: MyProfileView, beforeEnter: appGuard },
  { path: '/review', name: 'ReviewView', component: ReviewView, beforeEnter: researcherGuard },

  // List Views
  { path: '/user', name: 'User', component: SubView, redirect: { name: 'UserList' }, beforeEnter: adminGuard, children: [
      { path: '', name: 'UserList', component: UserList },
      { path: 'new', name: 'UserNew', component: UserForm },
      { path: ':id', name: 'UserForm', component: UserForm },
  ] },
  { path: '/role', name: 'Role', component: SubView, redirect: { name: 'RoleList' }, beforeEnter: devGuard, children: [
      { path: '', name: 'RoleList', component: RoleList },
      { path: 'new', name: 'RoleNew', component: RoleForm },
      { path: ':id', name: 'RoleForm', component: RoleForm },
  ] },
  { path: '/template', name: 'Template', component: SubView, redirect: { name: 'TemplateList' }, beforeEnter: devGuard, children: [
      { path: '', name: 'TemplateList', component: TemplateList },
      { path: 'new', name: 'TemplateNew', component: TemplateForm },
      { path: ':id', name: 'TemplateForm', component: TemplateForm },
  ] },
  { path: '/task', name: 'Task', component: SubView, redirect: { name: 'TaskList' }, beforeEnter: devGuard, children: [
      { path: '', name: 'TaskList', component: TaskList },
      { path: 'new', name: 'TaskNew', component: TaskForm },
      { path: ':id', name: 'TaskForm', component: TaskForm },
  ] },
  { path: '/notification', name: 'Notification', component: SubView, redirect: { name: 'NotificationList' }, beforeEnter: adminGuard, children: [
    { path: '', name: 'NotificationList', component: NotificationList },
    { path: 'new', name: 'NotificationNew', component: NotificationForm },
    { path: ':id?', name: 'NotificationForm', component: NotificationForm },
  ] },
  { path: '/category', name: 'Category', component: SubView, redirect: { name: 'CategoryList' }, beforeEnter: adminGuard, children: [
    { path: '', name: 'CategoryList', component: CategoryList },
    { path: 'new', name: 'CategoryNew', component: CategoryForm },
    { path: ':id?', name: 'CategoryForm', component: CategoryForm },
  ] },
  { path: '/tag', name: 'Tag', component: SubView, redirect: { name: 'TagList' }, beforeEnter: adminGuard, children: [
    { path: '', name: 'TagList', component: TagList },
    { path: 'new', name: 'TagNew', component: TagForm },
    { path: ':id?', name: 'TagForm', component: TagForm },
  ] },
  { path: '/country', name: 'Country', component: SubView, redirect: { name: 'CountryList' }, beforeEnter: adminGuard, children: [
    { path: '', name: 'CountryList', component: CountryList },
    { path: 'new', name: 'CountryNew', component: CountryForm },
    { path: ':id?', name: 'CountryForm', component: CountryForm },
  ] },
  { path: '/status-reason', name: 'ProjectStatusReason', component: SubView, redirect: { name: 'ProjectStatusReasonList' }, beforeEnter: leaderGuard, children: [
    { path: '', name: 'ProjectStatusReasonList', component: ProjectStatusReasonList },
    { path: 'new', name: 'ProjectStatusReasonNew', component: ProjectStatusReasonForm },
    { path: ':id?', name: 'ProjectStatusReasonForm', component: ProjectStatusReasonForm },
  ] },
  { path: '/data-extraction-presets', name: 'DataExtractionPresets', component: SubView, redirect: { name: 'DataExtractionPresetsList' }, beforeEnter: leaderGuard, children: [
    { path: '', name: 'DataExtractionPresetsList', component: DataExtractionPresetsList },
    { path: 'new', name: 'DataExtractionPresetsNew', component: DataExtractionPresetsForm },
    { path: ':id?', name: 'DataExtractionPresetsForm', component: DataExtractionPresetsForm },
  ] },
  { path: '/keyword', name: 'Keyword', component: SubView, redirect: { name: 'ProjectStatusReasonList' }, beforeEnter: leaderGuard, children: [
    { path: '', name: 'KeywordList', component: KeywordList },
    { path: 'new', name: 'KeywordNew', component: KeywordForm },
    { path: ':id?', name: 'KeywordForm', component: KeywordForm },
  ] },
  { path: '/ai-prompt', name: 'AiPrompt', component: SubView, redirect: { name: 'AiPromptList' }, beforeEnter: adminGuard, children: [
    { path: '', name: 'AiPromptList', component: AiPromptList },
    { path: 'new', name: 'AiPromptNew', component: AiPromptForm },
    { path: ':id?', name: 'AiPromptForm', component: AiPromptForm },
  ] },
  { path: '/ai-model', name: 'AiModel', component: SubView, redirect: { name: 'AiModelList' }, beforeEnter: adminGuard, children: [
    { path: '', name: 'AiModelList', component: AiModelList },
    { path: 'new', name: 'AiModelNew', component: AiModelForm },
    { path: ':id?', name: 'AiModelForm', component: AiModelForm },
  ] },
  { path: '/ai-task', name: 'AiTask', component: SubView, redirect: { name: 'AiTaskList' }, beforeEnter: adminGuard, children: [
    { path: '', name: 'AiTaskList', component: AiTaskList },
    // { path: 'new', name: 'AiTaskForm', component: AiTaskForm },
    // { path: ':id?', name: 'AiTaskForm', component: AiTaskForm },
  ] },
  { path: '/video-library', name: 'VideoLibrary', component: SubView, redirect: { name: 'VideoLibraryList' }, beforeEnter: adminGuard, children: [
    { path: '', name: 'VideoLibraryList', component: VideoLibraryList },
    { path: 'new', name: 'VideoLibraryNew', component: VideoLibraryForm },
    { path: ':id', name: 'VideoLibraryForm', component: VideoLibraryForm },
  ] },
  { path: '/contact', name: 'Contact', component: SubView, redirect: { name: 'ContactList' }, beforeEnter: adminGuard, children: [
    { path: '', name: 'ContactList', component: ContactList },
    { path: 'new', name: 'ContactNew', component: ContactForm },
    { path: ':id?', name: 'ContactForm', component: ContactForm },
  ] },
  { path: '/satisfaction', name: 'Satisfaction', component: SubView, redirect: { name: 'SatisfactionList' }, beforeEnter: adminGuard, children: [
    { path: '', name: 'SatisfactionList', component: SatisfactionList },
    { path: 'new', name: 'SatisfactionNew', component: SatisfactionForm },
    { path: ':id?', name: 'SatisfactionForm', component: SatisfactionForm },
  ] },
  { path: '/project', name: 'Project', component: SubView, redirect: { name: 'ProjectList' }, beforeEnter: researcherGuard, children: [
    { path: '', name: 'ProjectList', component: ProjectList },
    { path: 'new', name: 'ProjectNew', component: ProjectForm },
    { path: ':id?', name: 'ProjectForm', component: ProjectForm },
    { path: ':projectId/status-reason', name: 'ProjectProjectStatusReasonList', component: ProjectStatusReasonList },
    { path: ':projectId/status-reason/new', name: 'ProjectProjectStatusReasonNew', component: ProjectStatusReasonForm },
    { path: ':projectId/status-reason/:id?', name: 'ProjectProjectStatusReasonForm', component: ProjectStatusReasonForm },
    { path: ':projectId/keyword', name: 'ProjectKeywordList', component: KeywordList },
    { path: ':projectId/keyword/new', name: 'ProjectKeywordNew', component: KeywordForm },
    { path: ':projectId/keyword/:id?', name: 'ProjectKeywordForm', component: KeywordForm },
    { path: ':projectId/category', name: 'ProjectCategoryList', component: CategoryList },
    { path: ':projectId/category/new', name: 'ProjectCategoryNew', component: CategoryForm },
    { path: ':projectId/category/:id?', name: 'ProjectCategoryForm', component: CategoryForm },
  ] },

  // Help
  { path: '/help', name: 'Help', component: SubView, redirect: { name: 'TutorialView' }, children: [
      { path: 'tutorials/:key?', name: 'TutorialView', component: TutorialView },
  ] },

  // Developers
  { path: '/dev', name: 'Dev', component: SubView, redirect: { name: 'DevView' }, beforeEnter: devGuard, children: [
    { path: 'sandbox', name: 'DevView', component: DevView },
    { path: 'error-log', component: SubView, children: [
      { path: '', name: 'ErrorLogList', component: ErrorLogList },
      { path: ':id?', name: 'ErrorLogForm', component: ErrorLogForm },
    ] },
  ] },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
